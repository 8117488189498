import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import usePermission from "../../config/permissions";
import { handleErrorToast, inputMaxLimit, sortProjects } from "../CustomFunctions";
import {
  AddwithWhiteCircle,
  AddwithBlueCircle,
  DeleteDustbin,
  DropdownArrow,
  CrossRed,
  Eye,
  UpdatePencil,
  CheckGreen,
  DropdownArrowOption,
  Attachment,
  DocumentIcon,
  CloseEye,
  Arrow,
  ArrowBullet,
} from "../AllSvg";
import {
  DocumentSection,
  RenderInputField,
  RenderInputTextareaField,
  RenderSelectField,
  statesAndUTs,
  toTitleCase,
} from "../FormdataComponent";
import axiosInstance from "../../config/axiosInstance";
import Select from "react-select";

// ! *************** Project Crud Models Start ***************

const AddProjectDetails = ({ getProjects }) => {
  const [show, setShow] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

  const uniqueStreamTypes = new Set();

  const handleClose = () => {
    setFormData({
      site_prcode: "",
      project_name: "",
      project_short_name: "",
      location: "",
      // sub_company: sessionStorage.getItem('company_id'),
      sub_company: "",
      work_type: "",
      bid_due_date: "null",
      loa_award_date: "null",

      actual_construction_completion_date: "null",
      schedule_construction_completion_date: "null",
      agreement_date: "null",
      commencement_date: "null",

      concern_person: "",
      concern_person_name: "",
      project_length: "",
      tender_id: "",
      actual_completion_date: "null",
      schedule_completion_date: "null",
      construction_cost: 0,
      remark: "",
      consultancy_fees: "",
      contract_mode: "",
      lead: "",
      jv: "",
      our_share: "",
      association: "",
      work_performed_by: "",
      client_name: "",
      project_key_point: "",
      ro: "",
      ro_address: "",
      piu_address: "",
      contractor_address: "",
      // appointed_date:"",
      project_duration: "",
      our_role: "",
      site_office_address: "",
    });
    setErrors({});
    setShow(false);
  };
  const handleShow = () => {
    // setFormData(i);
    setShow(true);
    getAllEmp_code();
    getSubCompany();
    clientList.forEach((entry) => {
      uniqueStreamTypes.add(entry.client_name);
    });
  };

  const [empList, setEmpList] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic


  const [formData, setFormData] = useState({
    site_prcode: "",
    project_name: "",
    project_short_name: "",
    location: "",
    // sub_company: sessionStorage.getItem('company_id'),
    sub_company: "",
    work_type: "",
    bid_due_date: null,
    loa_award_date: null,

    agreement_date: null,
    commencement_date: null,
    concern_person_name: "",
    project_length: "",
    tender_id: "",
    actual_completion_date: null,
    actual_construction_completion_date: null,
    schedule_construction_completion_date: null,
    schedule_completion_date: null,
    remark: "",
    consultancy_fees: "",
    contract_mode: "",
    lead: "",
    ro: "",
    ro_address: "",
    piu_address: "",
    jv: "",
    our_share: "",
    association: "",
    work_performed_by: "",
    client_name: "",
    // appointed_date:"",
    project_duration: "",
    our_role: "",
    site_office_address: "",
    com_operators: [],
  });
  useEffect(() => {
    updateStatusAndRemarks(); // Update status and remarks together
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.actual_completion_date,
    formData.actual_construction_completion_date,
    formData.schedule_completion_date,
    formData.schedule_construction_completion_date,
    formData.commencement_date,
    formData.agreement_date,
    formData.bgid_submission_date,
    formData.loa_award_date,
    formData.bid_due_date,
  ]); // Add only the necessary dependencies

  // !  ************** Validation start **************  ! //


  const getSubCompany = async () => {
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);
    } catch (error) {
      console.error(error);

    }
  }

  const getAllEmp_code = async () => {
    setBuffering(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetailsactive/${formData.sub_company}/`
      );

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: "", numericPart: 0 };
        }
      };

      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } =
          getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } =
          getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setEmpList(sortedList);

      const cli = await axios.get(`${BASE_URL}/siteproject/project-clients/`);
      setClientList(cli.data);


    } catch (err) {
      // alert(err.message);
    } finally {
      setBuffering(false);
    }
  };
  useEffect(() => {
    getAllEmp_code();
  }, [formData.sub_company]);
  const updateStatusAndRemarks = () => {
    let newStatus = "";
    let newRemark = "";

    if (formData.actual_completion_date) {
      newStatus = "Completed";
      newRemark = "The project is successfully completed.";
    } else if (formData.actual_construction_completion_date) {
      newStatus = "Yet to Complete including O&M";
      newRemark = "Construction phase completed; O&M is pending.";
    } else if (formData.schedule_completion_date) {
      newStatus = "In Progress";
      newRemark = "Project is currently in progress.";
    } else if (formData.schedule_construction_completion_date) {
      newStatus = "In Progress including O&M";
      newRemark = "Construction is in progress along with O&M.";
    } else if (formData.commencement_date) {
      newStatus = "Yet to Commence";
      newRemark = "Project commencement is yet to start.";
    } else if (formData.agreement_date) {
      newStatus = "Yet to Sign Agreement";
      newRemark = "Agreement signing is pending.";
    } else if (formData.bgid_submission_date) {
      newStatus = "Yet to Submit BG";
      newRemark = "Bank Guarantee submission is pending.";
    } else if (formData.loa_award_date) {
      newStatus = "Yet to Award";
      newRemark = "Letter of Award is pending.";
    } else if (formData.bid_due_date) {
      newStatus = "Yet to Bid";
      newRemark = "Bid submission is pending.";
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      status: newStatus,
    }));
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "site_prcode",
      "project_name",
      "project_short_name",
      "location",
      "sub_company",
      "work_type",
      "lead",
      "our_role",
      "project_length",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };
  const [filteredList, setFilteredList] = useState([]);

  const handleInputChange = (e) => {
    // const { name, value } = e.target;
    const { name, value } = e.target || e; // Handle event directly or from target

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });

    if (name === "client_name") {
      const filtered = clientList.filter((item) =>
        item?.client_name?.toLowerCase().includes(value.toLowerCase())
      );
      // setFilteredList(filtered);
      const uniqueFiltered = Array.from(
        new Map(filtered.map((item) => [item.client_name, item])).values()
      );
      setFilteredList(uniqueFiltered);
    }
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(
          `${BASE_URL}/siteproject/project/`,
          formData
        );

        if (res.status === 200) {
          setShow(false);
          await getProjects();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const sortedStatesAndUTs = [...statesAndUTs].sort();

  return (
    <>
      <button
        title="Add Project Details"
        className="upload-svg"
        onClick={handleShow}
      >
        <AddwithWhiteCircle /> {" "}
        Project
      </button>

      {/* <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model"> */}
      <Modal show={show} dialogClassName="ourcompany-model">
        <Modal.Header className="justify-center task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Add Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">
              <div title="Tender ID" className="flex-column">
                <label
                  htmlFor="tender_id"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Tender ID
                </label>
                <input
                  type="text"
                  id="tender_id"
                  name="tender_id"
                  placeholder="Tender ID"
                  onChange={handleInputChange}
                  value={formData.tender_id}
                  className={`form-input-div  ${errors.tender_id
                    ? "error"
                    : inputState.tender_id
                      ? "success"
                      : ""
                    }`}
                />
                {errors.tender_id && (
                  <span className="error-message font-size-text ">
                    {errors.tender_id}
                  </span>
                )}
              </div>

              <div title="Project Code" className="flex-column">
                <label
                  htmlFor="site_prcode"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="site_prcode"
                  name="site_prcode"
                  placeholder="Project Code"
                  onChange={handleInputChange}
                  value={formData.site_prcode}
                  className={`form-text-trasformation-uppercase form-input-div  ${errors.site_prcode
                    ? "error"
                    : inputState.site_prcode
                      ? "success"
                      : ""
                    }`}
                />
                {errors.site_prcode && (
                  <span className="error-message font-size-text ">
                    {errors.site_prcode}
                  </span>
                )}
              </div>

              <div title="Project Short Name" className="flex-column">
                <label
                  htmlFor="project_short_name"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Short Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="project_short_name"
                  name="project_short_name"
                  placeholder="Project Short Name"
                  onChange={handleInputChange}
                  value={formData.project_short_name}
                  className={`form-input-div  ${errors.project_short_name
                    ? "error"
                    : inputState.project_short_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_short_name && (
                  <span className="error-message font-size-text ">
                    {errors.project_short_name}
                  </span>
                )}
              </div>

              <div title="Location" className="flex-column">
                <label
                  htmlFor="location"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Location (State)<span className="required">*</span>
                </label>


                <select
                  id="location"
                  name="location"
                  onChange={handleInputChange}
                  value={formData.location}
                  className={`form-input-div  ${errors.location
                    ? "error"
                    : inputState.location
                      ? "success"
                      : ""
                    }`}
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  {sortedStatesAndUTs.map((stateOrUT) => (
                    <option key={stateOrUT} value={stateOrUT}>
                      {stateOrUT}
                    </option>
                  ))}
                </select>

                {errors.location && (
                  <span className="error-message font-size-text ">
                    {errors.location}
                  </span>
                )}
              </div>

              <div title="Project Name" className="flex-column">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Name<span className="required">*</span>
                </label>
                <textarea
                  id="project_name"
                  name="project_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  rows="50"
                  cols="100"
                  className={`form-textarea form-input-div  ${errors.project_name
                    ? "error"
                    : inputState.project_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_name && (
                  <div className="error-message font-size-text">
                    {errors.project_name}
                  </div>
                )}
              </div>

              <div title="Project Key Points" className="flex-column">
                <label
                  htmlFor="project_key_point"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Key Points
                </label>
                <textarea
                  id="project_key_point"
                  name="project_key_point"
                  placeholder="Project Key Points"
                  onChange={handleInputChange}
                  value={formData.project_key_point}
                  rows="50"
                  cols="100"
                  className={`form-textarea form-input-div  ${errors.project_key_point
                    ? "error"
                    : inputState.project_key_point
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_key_point && (
                  <div className="error-message font-size-text">
                    {errors.project_key_point}
                  </div>
                )}
              </div>

              <div title="Site Sub Company" className="flex-column ">
                <div className=" form-group-selectt">
                  <label
                    htmlFor="sub_company"
                    className="form-labels  font-weight500    font-size-subheading"
                  >
                    Site Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    placeholder="Site Sub Company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input-date ${errors.sub_company
                      ? "error"
                      : inputState.sub_company
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select a Site SubCompany</option>
                    {siteSubCompanyList
                      ?.sort((a, b) =>
                        a.title.localeCompare(b.title, "en", {
                          ignorePunctuation: true,
                        })
                      )
                      .map((i, index) => (
                        <option key={index} value={i.id}>
                          {i.title}
                        </option>
                      ))}
                  </select>

                </div>
                <br />
                {errors.sub_company && (
                  <span className="error-message font-size-text ">
                    {errors.sub_company}
                  </span>
                )}
              </div>

              <div title="Work Type" className="flex-column ">
                <div className="form-group-selectt">
                  <label
                    htmlFor="work_type"
                    className="form-labels  font-weight500    font-size-subheading"
                  >
                    Work Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="work_type"
                    name="work_type"
                    placeholder="Site Work Type"
                    value={formData.work_type}
                    onChange={handleInputChange}
                    className={`form-input-date ${errors.work_type
                      ? "error"
                      : inputState.work_type
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select Work Type</option>
                    <option value="construction consultant">
                      Construction Consultant
                    </option>
                    <option value="operations & maintenance consultant">
                      Operation & Maintenance Consultant
                    </option>
                    <option value="safety consultant">Safety Consultant</option>
                    <option value="dpr">DPR</option>
                  </select>

                </div>
                <br />
                {errors.work_type && (
                  <span className="error-message font-size-text ">
                    {errors.work_type}
                  </span>
                )}
              </div>

              <div title="Our Role" className="flex-column ">
                <div className="form-group-selectt">
                  <label
                    htmlFor="our_role"
                    className="form-labels  font-weight500    font-size-subheading"
                  >
                    Our Role<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="our_role"
                    name="our_role"
                    placeholder="Our Role"
                    value={formData.our_role}
                    onChange={handleInputChange}
                    className={`form-input-date ${errors.our_role
                      ? "error"
                      : inputState.our_role
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select Role</option>
                    <option value="active">Active</option>
                    <option value="partially">Partially</option>
                    <option value="silent">Silent</option>
                  </select>
                  {/* <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div> */}
                </div>
                <br />
                {errors.our_role && (
                  <span className="error-message font-size-text ">
                    {errors.our_role}
                  </span>
                )}
              </div>

              <div title="Project Length" className="flex-column">
                <label
                  htmlFor="project_length"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Length (in KM)<span className="required">*</span>
                </label>
                <input
                  type="number"
                  maxLength={10}
                  min={0}
                  id="project_length"
                  name="project_length"
                  placeholder="Project Length"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input-date ${errors.project_length
                    ? "error"
                    : inputState.project_length
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_length && (
                  <span className="error-message font-size-text ">
                    {errors.project_length}
                  </span>
                )}
              </div>

              <div title="Bid Due Date" className="flex-column">
                <label
                  htmlFor="bid_due_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Bid Due Date
                </label>
                <input
                  type="date"
                  id="bid_due_date"
                  name="bid_due_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.bid_due_date}
                  className={`form-input-date ${errors.bid_due_date
                    ? "error"
                    : inputState.bid_due_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.bid_due_date && (
                  <span className="error-message font-size-text">
                    {errors.bid_due_date}
                  </span>
                )}
              </div>

              <div title="LOA Date" className="flex-column">
                <label
                  htmlFor="loa_award_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  LOA Date
                </label>
                <input
                  type="date"
                  id="loa_award_date"
                  name="loa_award_date"
                  min={formData.bid_due_date}
                  placeholder="Award Date"
                  onChange={handleInputChange}
                  value={formData.loa_award_date}
                  className={`form-input-date ${errors.loa_award_date
                    ? "error"
                    : inputState.loa_award_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.loa_award_date && (
                  <span className="error-message font-size-text ">
                    {errors.loa_award_date}
                  </span>
                )}
              </div>

              <div title="Agreement Date" className="flex-column">
                <label
                  htmlFor="agreement_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Agreement Date
                </label>
                <input
                  type="date"
                  id="agreement_date"
                  name="agreement_date"
                  min={formData.bid_due_date}
                  placeholder="Agreement Date"
                  onChange={handleInputChange}
                  value={formData.agreement_date}
                  className={`form-input-date ${errors.agreement_date
                    ? "error"
                    : inputState.agreement_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.agreement_date && (
                  <span className="error-message font-size-text ">
                    {errors.agreement_date}
                  </span>
                )}
              </div>

              <div title="Commencement Date" className="flex-column">
                <label
                  htmlFor="commencement_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Commencement Date
                </label>
                <input
                  type="date"
                  id="commencement_date"
                  name="commencement_date"
                  min={formData.agreement_date}
                  placeholder="Commencement Date"
                  onChange={handleInputChange}
                  value={formData.commencement_date}
                  className={`form-input-date ${errors.commencement_date
                    ? "error"
                    : inputState.commencement_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.commencement_date && (
                  <span className="error-message font-size-text ">
                    {errors.commencement_date}
                  </span>
                )}
              </div>


              <div title="Project Duration" className="flex-column">
                <label
                  htmlFor="project_duration"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Duration (In Months)
                </label>
                <input
                  type="Number"
                  onWheel={(e) => e.target.blur()}
                  id="project_duration"
                  name="project_duration"
                  placeholder="Project Duaration"
                  onChange={handleInputChange}
                  value={formData.project_duration}
                  className={`form-input-date ${errors.project_duration
                    ? "error"
                    : inputState.project_duration
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_duration && (
                  <span className="error-message font-size-text ">
                    {errors.project_duration}
                  </span>
                )}
              </div>

              <div
                title="Schedule Construction Completion Date"
                className="flex-column"
              >
                <label
                  htmlFor="schedule_construction_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Schedule Construction Completion Date
                </label>
                <input
                  type="date"
                  id="schedule_construction_completion_date"
                  name="schedule_construction_completion_date"
                  min={formData.commencement_date}
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.schedule_construction_completion_date}
                  className={`form-input-date ${errors.schedule_construction_completion_date
                    ? "error"
                    : inputState.schedule_construction_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.schedule_construction_completion_date && (
                  <span className="error-message font-size-text">
                    {errors.schedule_construction_completion_date}
                  </span>
                )}
              </div>

              <div title="Target Date" className="flex-column">
                <label
                  htmlFor="schedule_completion_date"
                  className="form-labels  font-weight500   "
                >
                  Schedule Completion Date
                </label>
                <input
                  type="date"
                  id="schedule_completion_date"
                  name="schedule_completion_date"
                  min={formData.actual_construction_completion_date}
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.schedule_completion_date}
                  className={`form-input-date ${errors.schedule_completion_date
                    ? "error"
                    : inputState.schedule_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.schedule_completion_date && (
                  <span className="error-message">
                    {errors.schedule_completion_date}
                  </span>
                )}
              </div>

              <div title="Site Authority Engineer" className="flex-column ">
                <div className=" form-group-selectt">
                  <label className="form-labels  font-weight500    font-size-subheading">
                    Authority Engineer
                  </label>
                  <br />
                  <select
                    name="concern_person_name"
                    onChange={handleInputChange}
                    value={formData.concern_person_name}
                    className={`form-input-date  ${errors.concern_person_name
                      ? "error"
                      : inputState.concern_person_name
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select Concern Person </option>
                    {empList
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        // <option value={`${i.emp_code}|${i.name}`} key={index}>
                        <option value={i.name}>
                          {i.emp_code} - {i.name}
                        </option>
                      ))}
                  </select>
                  {/* <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div> */}
                </div>
                {errors.concern_person_name && (
                  <span className="error-message font-size-text ">
                    {errors.concern_person_name}
                  </span>
                )}
              </div>



              <div title="Remark" className="flex-column">
                <label
                  htmlFor="remark"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Remark
                </label>
                <input
                  type="text"
                  id="remark"
                  name="remark"
                  placeholder="Remark"
                  onChange={handleInputChange}
                  value={formData.remark}
                  className={`form-input-date ${errors.remark ? "error" : inputState.remark ? "success" : ""
                    }`}
                />
                {errors.remark && (
                  <span className="error-message font-size-text ">
                    {errors.remark}
                  </span>
                )}
              </div>

              <div title="Consultancy Fees" className="flex-column">
                <label
                  htmlFor="consultancy_fees"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Consultancy Fees
                </label>
                <input
                  type="number"
                  min={0}
                  id="consultancy_fees"
                  name="consultancy_fees"
                  placeholder="Consultancy Fees"
                  onChange={handleInputChange}
                  value={formData.consultancy_fees}
                  className={`form-input-date  ${errors.consultancy_fees
                    ? "error"
                    : inputState.consultancy_fees
                      ? "success"
                      : ""
                    }`}
                />
                {errors.consultancy_fees && (
                  <span className="error-message font-size-text">
                    {errors.consultancy_fees}
                  </span>
                )}
              </div>
              <div title="Contract Mode" className="flex-column ">
                <div className=" form-group-selectt">
                  <label className="form-labels  font-weight500    font-size-subheading">
                    Contract Mode
                  </label>
                  <br />
                  <select
                    name="contract_mode"
                    onChange={handleInputChange}
                    value={formData.contract_mode}
                    className={`form-input-date ${errors.contract_mode
                      ? "error"
                      : inputState.contract_mode
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select Contract Mode </option>
                    <option value="epc">EPC </option>
                    <option value="ham"> HAM</option>
                    <option value="bot">BOT </option>
                  </select>
                  {/* <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div> */}
                </div>
                {errors.contract_mode && (
                  <span className="error-message font-size-text ">
                    {errors.contract_mode}
                  </span>
                )}
              </div>

              <div title="Lead" className="flex-column">
                <label
                  htmlFor="lead"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Lead<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="lead"
                  name="lead"
                  placeholder="Lead"
                  onChange={handleInputChange}
                  value={formData.lead}
                  className={`form-input-date ${errors.lead ? "error" : inputState.lead ? "success" : ""
                    }`}
                />
                {errors.lead && (
                  <span className="error-message font-size-text ">
                    {errors.lead}
                  </span>
                )}
              </div>

              <div title="JV" className="flex-column">
                <label
                  htmlFor="jv"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  JV
                </label>
                <input
                  type="text"
                  id="jv"
                  name="jv"
                  placeholder="JV"
                  onChange={handleInputChange}
                  value={formData.jv}
                  className={`form-input-date ${errors.jv ? "error" : inputState.jv ? "success" : ""
                    }`}
                />
                {errors.jv && (
                  <span className="error-message font-size-text ">
                    {errors.jv}
                  </span>
                )}
              </div>


              <div title="Our Share" className="flex-column">
                <label
                  htmlFor="our_share"
                  className="form-labels font-weight500 font-size-subheading"
                >
                  {/* {formData.sub_company ? formData.sub_company : "Our"} Share */}
                  Our Share (%)
                  {/* Saptagon Share */}
                </label>

                <input
                  type="text"
                  id="our_share"
                  name="our_share"
                  placeholder="Enter percentage (%)"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (
                      value === "" ||
                      (/^\d+\.?\d{0,2}$/.test(value) && value <= 100)
                    ) {
                      handleInputChange(e);
                    }
                  }}
                  value={`${formData.our_share ? formData.our_share : ""}`}
                  className={`form-input-date ${errors.our_share
                    ? "error"
                    : inputState.our_share
                      ? "success"
                      : ""
                    }`}
                />
                {errors.our_share && (
                  <span className="error-message font-size-text ">
                    {errors.our_share}
                  </span>
                )}
              </div>

              <div title="Association" className="flex-column">
                <label
                  htmlFor="association"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Association
                </label>
                <input
                  type="text"
                  id="association"
                  name="association"
                  placeholder="Association"
                  onChange={handleInputChange}
                  value={formData.association}
                  className={`form-input-date ${errors.association
                    ? "error"
                    : inputState.association
                      ? "success"
                      : ""
                    }`}
                />
                {errors.association && (
                  <span className="error-message font-size-text ">
                    {errors.association}
                  </span>
                )}
              </div>



              <div title="Client Name" className="flex-column">
                <label
                  htmlFor="client_name"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Client
                </label>
                <input
                  type="text"
                  id="client_name"
                  name="client_name"
                  placeholder="Client Name"
                  onChange={handleInputChange}
                  value={formData.client_name}
                  className={`form-input-date ${errors.client_name
                    ? "error"
                    : inputState.client_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.client_name && (
                  <span className="error-message font-size-text ">
                    {errors.client_name}
                  </span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.client_name}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div title="Regional Office Name" className="flex-column">
                <label htmlFor="work_performed_by" className="form-labels  font-weight500    font-size-subheading">
                  Regional Office Name (RO Name)
                </label>
                <input
                  type="text"
                  id="ro"
                  name="ro"
                  placeholder="Regional Office Name"
                  onChange={handleInputChange}
                  value={formData.ro}
                  className={`form-input-div  ${errors.ro ? "error" : inputState.ro ? "success" : ""
                    }`}
                />
                {errors.ro && (
                  <span className="error-message font-size-text ">
                    {errors.ro}
                  </span>
                )}
              </div>

              <div
                title="Regional Office Address (RO Address)"
                className="flex-column"
              >
                <label
                  htmlFor="ro_address"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Regional Office Address (RO Address)
                </label>
                <textarea
                  id="ro_address"
                  name="ro_address"
                  placeholder="Regional Office Address"
                  onChange={handleInputChange}
                  value={formData.ro_address}
                  rows="50"
                  cols="100"
                  className={`form-textarea form-input-div  ${errors.ro_address
                    ? "error"
                    : inputState.ro_address
                      ? "success"
                      : ""
                    }`}
                />
                {errors.ro_address && (
                  <div className="error-message font-size-text">
                    {errors.ro_address}
                  </div>
                )}
              </div>

              <div title="PIU Address" className="flex-column">
                <label
                  htmlFor="piu_address"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  PIU Address
                </label>
                <textarea
                  id="piu_address"
                  name="piu_address"
                  placeholder="PIU Address"
                  onChange={handleInputChange}
                  value={formData.piu_address}
                  rows="50"
                  cols="100"
                  className={`form-textarea form-input-div  ${errors.piu_address
                    ? "error"
                    : inputState.piu_address
                      ? "success"
                      : ""
                    }`}
                />
                {errors.piu_address && (
                  <div className="error-message font-size-text">
                    {errors.piu_address}
                  </div>
                )}
              </div>

              <div title="Site Address" className="flex-column">
                <label
                  htmlFor="site_office_address"
                  className="form-labels  font-weight500 font-size-subheading"
                >
                  Site Address
                </label>
                <textarea
                  id="site_office_address"
                  name="site_office_address"
                  placeholder="Site Address"
                  onChange={handleInputChange}
                  value={formData.site_office_address}
                  rows="50"
                  cols="100"
                  className={`form-textarea form-input-div  ${errors.site_office_address
                    ? "error"
                    : inputState.site_office_address
                      ? "success"
                      : ""
                    }`}
                />
                {errors.site_office_address && (
                  <div className="error-message font-size-text">
                    {errors.site_office_address}
                  </div>
                )}
              </div>


            </div>

            <div className="button-models">
              <button
                type="button"
                onClick={handleClose}
                className="model-button font-weight500 model-button-cancel"
              >
                {/* //loading logic */}
                Cancel
              </button>
              <button
                type="button"
                onClick={handleFormSubmit}
                className="model-button font-weight500 model-button-submit"
                disabled={loading}
              >
                {/* //loading logic */}
                Submit
              </button>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};



const UpdateProjectAllDetails = ({ i, getProjects, siteSubCompanyList }) => {
  const [show, setShow] = useState(false);
  const uniqueStreamTypes = new Set();

  const handleShow = () => {
    getAllEmp_code();
    setFormData(i);
    setShow(true);
    clientList.forEach(entry => {
      uniqueStreamTypes.add(entry.client_name);
    });
  };
  const [clientList, setClientList] = useState([]);

  const [empList, setEmpList] = useState([]);

  const handleClose = () => {
    getProjects();
    setErrors({});
    setShow(false);
  };

  const [buffer, setBuffering] = useState(true); //buffering logic

  const [formData, setFormData] = useState({
    site_prcode: "",
    project_name: "",
    project_short_name: "",
    ro: "",
    ro_address: "",
    piu_address: "",
    project_key_point: "",


  });

  const getAllEmp_code = async (toggleCompany) => {
    setBuffering(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetailsactive/${toggleCompany ? toggleCompany : "null"
        }/`
      );

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: "", numericPart: 0 };
        }
      };

      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } =
          getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } =
          getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setEmpList(sortedList);
      // setEmp_code(res.data);

      const cli = await axios.get(`${BASE_URL}/siteproject/project-clients/`);
      setClientList(cli.data);
    } catch (err) {
      // alert(err.message);
    } finally {
      setBuffering(false);
    }
  };


  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      "site_prcode",
      "project_name",
      "project_short_name",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim() && newErrors[name]) {
        delete newErrors[name];
      }
      return newErrors;
    });


  };



  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleProjectUpdate = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/project/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          setShow(false);
          await getProjects();
          toast.success("Upadate Project Details Successfully.");
          toast.dismiss(loadingToastId);

        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button
        title="Update All Project Details"
        style={{ margin: 0 }}
        className="upload-svg update-button-size"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="white"
          viewBox="0 0 24 24"
          id="pen-new-square"
        >
          <path
            fill=""
            fill-rule="evenodd"
            d="M11.9426 1.25L13.5 1.25C13.9142 1.25 14.25 1.58579 14.25 2C14.25 2.41421 13.9142 2.75 13.5 2.75H12C9.62177 2.75 7.91356 2.75159 6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.23505 4.75455 2.91245 5.86509 2.80417 8.03734C2.78355 8.45104 2.43146 8.76969 2.01776 8.74907C1.60406 8.72845 1.28541 8.37636 1.30603 7.96266C1.41642 5.74802 1.74641 4.12187 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM22 9.75C22.4142 9.75 22.75 10.0858 22.75 10.5V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574L1.25 12C1.25 11.5858 1.58579 11.25 2 11.25C2.41421 11.25 2.75 11.5858 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12V10.5C21.25 10.0858 21.5858 9.75 22 9.75Z"
            clip-rule="evenodd"
          ></path>
          <path
            fill=""
            fill-rule="evenodd"
            d="M16.7705 2.27592C18.1384 0.908029 20.3562 0.908029 21.7241 2.27592C23.092 3.6438 23.092 5.86158 21.7241 7.22947L18.0928 10.8607C17.7999 11.1536 17.3251 11.1536 17.0322 10.8607C16.7393 10.5678 16.7393 10.093 17.0322 9.80006L19.1051 7.72709C18.5892 7.50519 17.9882 7.14946 17.4193 6.58065C16.8505 6.01185 16.4948 5.41082 16.2729 4.89486L11.2175 9.95026C10.801 10.3668 10.6376 10.532 10.4988 10.71C10.3274 10.9297 10.1804 11.1676 10.0605 11.4192C9.96337 11.623 9.88868 11.8429 9.7024 12.4017L9.27051 13.6974L10.3026 14.7295L11.5983 14.2976C12.1571 14.1113 12.377 14.0366 12.5808 13.9395C12.8324 13.8196 13.0703 13.6726 13.2901 13.5012C13.468 13.3624 13.6332 13.199 14.0497 12.7825C14.3426 12.4896 14.8175 12.4896 15.1104 12.7825C15.4033 13.0754 15.4033 13.5503 15.1104 13.8432L15.076 13.8776C14.7047 14.2489 14.4721 14.4815 14.2126 14.684C13.9069 14.9224 13.5761 15.1268 13.2261 15.2936C12.929 15.4352 12.6169 15.5392 12.1188 15.7052L9.21426 16.6734C8.67801 16.8521 8.0868 16.7126 7.68711 16.3129C7.28742 15.9132 7.14785 15.322 7.3266 14.7857L8.29477 11.8812C8.46079 11.3831 8.56479 11.071 8.7064 10.7739C8.87319 10.4239 9.07761 10.0931 9.31605 9.78742C9.51849 9.52787 9.7511 9.29529 10.1224 8.92401L16.7705 2.27592ZM17.4546 3.7132C17.4773 3.80906 17.509 3.92327 17.5532 4.05066C17.6965 4.46372 17.9677 5.00771 18.48 5.51999C18.9923 6.03227 19.5363 6.30346 19.9493 6.44677C20.0767 6.49097 20.1909 6.52273 20.2868 6.54543L20.6634 6.16881C21.4455 5.38671 21.4455 4.11867 20.6634 3.33658C19.8813 2.55448 18.6133 2.55448 17.8312 3.33658L17.4546 3.7132Z"
            clip-rule="evenodd"
          ></path>
        </svg>{" "}
        Update Details
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Project  Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <br />
            <div className="form-flex-wrap">


              <div title="Project Code" className="flex-column">
                <label
                  htmlFor="site_prcode"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="site_prcode"
                  name="site_prcode"
                  placeholder="Project Code"
                  onChange={handleInputChange}
                  value={formData.site_prcode}
                  className={`form-text-trasformation-uppercase form-input ${errors.site_prcode
                    ? "error"
                    : inputState.site_prcode
                      ? "success"
                      : ""
                    }`}
                />
                {errors.site_prcode && (
                  <span className="error-message font-size-text ">
                    {errors.site_prcode}
                  </span>
                )}
              </div>

              <div title="Project Short Name" className="flex-column">
                <label
                  htmlFor="project_short_name"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Short Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="project_short_name"
                  name="project_short_name"
                  placeholder="Project Short Name"
                  onChange={handleInputChange}
                  value={formData.project_short_name}
                  className={`form-input ${errors.project_short_name
                    ? "error"
                    : inputState.project_short_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_short_name && (
                  <span className="error-message font-size-text ">
                    {errors.project_short_name}
                  </span>
                )}
              </div>


              <div title="Project Name" className="flex-column">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Name<span className="required">*</span>
                </label>
                <textarea
                  id="project_name"
                  name="project_name"
                  placeholder="Project Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  rows="50"
                  cols="100"
                  className={`form-textarea ${errors.project_name
                    ? "error"
                    : inputState.project_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_name && (
                  <div className="error-message font-size-text">
                    {errors.project_name}
                  </div>
                )}
              </div>

              <div title="Project Key Points" className="flex-column">
                <label
                  htmlFor="project_key_point"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Key Points
                </label>
                <textarea
                  id="project_key_point"
                  name="project_key_point"
                  placeholder="Project Key Points"
                  onChange={handleInputChange}
                  value={formData.project_key_point}
                  rows="50"
                  cols="100"
                  className={`form-textarea ${errors.project_key_point
                    ? "error"
                    : inputState.project_key_point
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_key_point && (
                  <div className="error-message font-size-text">
                    {errors.project_key_point}
                  </div>
                )}
              </div>


              <div title="Regional Office Name" className="flex-column">
                <label htmlFor="work_performed_by" className="form-labels  font-weight500    font-size-subheading">
                  Regional Office Name (RO Name)
                </label>
                <input
                  type="text"
                  id="ro"
                  name="ro"
                  placeholder="Regional Office Name"
                  onChange={handleInputChange}
                  value={formData.ro}
                  className={`form-input ${errors.ro ? "error" : inputState.ro ? "success" : ""
                    }`}
                />
                {errors.ro && (
                  <span className="error-message font-size-text ">
                    {errors.ro}
                  </span>
                )}
              </div>

              <div
                title="Regional Office Address (RO Address)"
                className="flex-column"
              >
                <label
                  htmlFor="ro_address"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Regional Office Address (RO Address)
                </label>
                <textarea
                  id="ro_address"
                  name="ro_address"
                  placeholder="Regional Office Address"
                  onChange={handleInputChange}
                  value={formData.ro_address}
                  rows="50"
                  cols="100"
                  className={`form-textarea ${errors.ro_address
                    ? "error"
                    : inputState.ro_address
                      ? "success"
                      : ""
                    }`}
                />
                {errors.ro_address && (
                  <div className="error-message font-size-text">
                    {errors.ro_address}
                  </div>
                )}
              </div>

              <div title="PIU Address" className="flex-column">
                <label
                  htmlFor="piu_address"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  PIU Address
                </label>
                <textarea
                  id="piu_address"
                  name="piu_address"
                  placeholder="PIU Address"
                  onChange={handleInputChange}
                  value={formData.piu_address}
                  rows="50"
                  cols="100"
                  className={`form-textarea ${errors.piu_address
                    ? "error"
                    : inputState.piu_address
                      ? "success"
                      : ""
                    }`}
                />
                {errors.piu_address && (
                  <div className="error-message font-size-text">
                    {errors.piu_address}
                  </div>
                )}
              </div>



            </div>
            <div className="button-models">

              <button
                onClick={handleProjectUpdate}
                type="submit"
                className="model-button font-weight500 model-button-submit"
              // disabled={loading}
              >
                Update
              </button>
            </div>


          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateConsultantDetails = ({ i, getProjects, siteSubCompanyList }) => {
  const [show, setShow] = useState(false);
  const uniqueStreamTypes = new Set();

  const handleShow = () => {
    getAllEmp_code();
    setFormData(i);
    setShow(true);
    clientList.forEach(entry => {
      uniqueStreamTypes.add(entry.client_name);
    });
  };
  const [clientList, setClientList] = useState([]);

  const [empList, setEmpList] = useState([]);

  const handleClose = () => {
    getProjects();
    setErrors({});
    setShow(false);
  };

  const [buffer, setBuffering] = useState(true); //buffering logic

  const [formData, setFormData] = useState({

    location: "",
    // sub_company: sessionStorage.getItem('company_id'),
    sub_company: "",
    work_type: "",
    bid_due_date: null,
    loa_award_date: null,

    actual_construction_completion_date: null,
    schedule_construction_completion_date: "",
    agreement_date: null,
    commencement_date: null,

    concern_person: "",
    concern_person_name: "",
    project_length: "",
    tender_id: "",
    actual_completion_date: null,
    schedule_completion_date: null,
    construction_cost: 0,
    remark: "",
    consultancy_fees: "",
    contract_mode: "",
    lead: "",
    jv: "",
    our_share: "",
    association: "",
    work_performed_by: "",
    client_name: "",
    contractor_address: "",
    project_duration: "",
    our_role: "",
    site_office_address: "",
  });

  const getAllEmp_code = async (toggleCompany) => {
    setBuffering(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetailsactive/${toggleCompany ? toggleCompany : "null"
        }/`
      );

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: "", numericPart: 0 };
        }
      };

      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } =
          getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } =
          getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setEmpList(sortedList);
      // setEmp_code(res.data);

      const cli = await axios.get(`${BASE_URL}/siteproject/project-clients/`);
      setClientList(cli.data);
    } catch (err) {
      // alert(err.message);
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    updateStatusAndRemarks(); // Update status and remarks together
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData?.actual_completion_date,
    formData?.actual_construction_completion_date,
    formData?.schedule_completion_date,
    formData?.schedule_construction_completion_date,
    formData?.commencement_date,
    formData?.agreement_date,
    formData?.bgid_submission_date,
    formData?.loa_award_date,
    formData?.bid_due_date,
  ]); // Add only the necessary dependencies

  // !  ************** Validation start **************  ! //

  const updateStatusAndRemarks = () => {
    let newStatus = "";
    let newRemark = "";

    if (formData.actual_completion_date) {
      newStatus = "Completed";
      newRemark = "The project is successfully completed.";
    } else if (formData.actual_construction_completion_date) {
      newStatus = "Yet to Complete including O&M";
      newRemark = "Construction phase completed; O&M is pending.";
    } else if (formData.schedule_completion_date) {
      newStatus = "In Progress";
      newRemark = "Project is currently in progress.";
    } else if (formData.schedule_construction_completion_date) {
      newStatus = "In Progress including O&M";
      newRemark = "Construction is in progress along with O&M.";
    } else if (formData.commencement_date) {
      newStatus = "Yet to Commence";
      newRemark = "Project commencement is yet to start.";
    } else if (formData.agreement_date) {
      newStatus = "Yet to Sign Agreement";
      newRemark = "Agreement signing is pending.";
    } else if (formData.bgid_submission_date) {
      newStatus = "Yet to Submit BG";
      newRemark = "Bank Guarantee submission is pending.";
    } else if (formData.loa_award_date) {
      newStatus = "Yet to Award";
      newRemark = "Letter of Award is pending.";
    } else if (formData.bid_due_date) {
      newStatus = "Yet to Bid";
      newRemark = "Bid submission is pending.";
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      status: newStatus,
    }));
  };

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      "location",
      "sub_company",
      "work_type",
      "lead",
      "our_role",
      "project_length",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  console.log(errors)


  const [filteredList, setFilteredList] = useState([]);


  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });

  //   if (name === "client_name") {
  //     const filtered = clientList.filter((item) =>
  //       item?.client_name?.toLowerCase().includes(value.toLowerCase())
  //     );
  //     const uniqueFiltered = Array.from(new Map(filtered.map(item => [item.client_name, item])).values());
  //     setFilteredList(uniqueFiltered);
  //   }

  //   setErrors((prevErrors) => {
  //     const newErrors = { ...prevErrors };
  //     if (value.trim() && newErrors[name]) {
  //       delete newErrors[name];
  //     }
  //     return newErrors;
  //   });


  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the field is a date field
    if (e.target.type === "date") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value || null, // Set to null if cleared
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    if (name === "client_name") {
      const filtered = clientList.filter((item) =>
        item?.client_name?.toLowerCase().includes(value.toLowerCase())
      );
      const uniqueFiltered = Array.from(
        new Map(filtered.map((item) => [item.client_name, item])).values()
      );
      setFilteredList(uniqueFiltered);
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim() && newErrors[name]) {
        delete newErrors[name];
      }
      return newErrors;
    });
  };



  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleProjectUpdate = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/project/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          setShow(false);
          await getProjects();
          toast.success("Consultant Details are Updated Successfully")
          toast.dismiss(loadingToastId);

        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const sortedStatesAndUTs = [...statesAndUTs].sort();

  return (
    <>
      <button
        title="Update Consultant Details"
        style={{ margin: 0 }}
        className="upload-svg update-button-size"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="white"
          viewBox="0 0 24 24"
          id="pen-new-square"
        >
          <path
            fill=""
            fill-rule="evenodd"
            d="M11.9426 1.25L13.5 1.25C13.9142 1.25 14.25 1.58579 14.25 2C14.25 2.41421 13.9142 2.75 13.5 2.75H12C9.62177 2.75 7.91356 2.75159 6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.23505 4.75455 2.91245 5.86509 2.80417 8.03734C2.78355 8.45104 2.43146 8.76969 2.01776 8.74907C1.60406 8.72845 1.28541 8.37636 1.30603 7.96266C1.41642 5.74802 1.74641 4.12187 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM22 9.75C22.4142 9.75 22.75 10.0858 22.75 10.5V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574L1.25 12C1.25 11.5858 1.58579 11.25 2 11.25C2.41421 11.25 2.75 11.5858 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12V10.5C21.25 10.0858 21.5858 9.75 22 9.75Z"
            clip-rule="evenodd"
          ></path>
          <path
            fill=""
            fill-rule="evenodd"
            d="M16.7705 2.27592C18.1384 0.908029 20.3562 0.908029 21.7241 2.27592C23.092 3.6438 23.092 5.86158 21.7241 7.22947L18.0928 10.8607C17.7999 11.1536 17.3251 11.1536 17.0322 10.8607C16.7393 10.5678 16.7393 10.093 17.0322 9.80006L19.1051 7.72709C18.5892 7.50519 17.9882 7.14946 17.4193 6.58065C16.8505 6.01185 16.4948 5.41082 16.2729 4.89486L11.2175 9.95026C10.801 10.3668 10.6376 10.532 10.4988 10.71C10.3274 10.9297 10.1804 11.1676 10.0605 11.4192C9.96337 11.623 9.88868 11.8429 9.7024 12.4017L9.27051 13.6974L10.3026 14.7295L11.5983 14.2976C12.1571 14.1113 12.377 14.0366 12.5808 13.9395C12.8324 13.8196 13.0703 13.6726 13.2901 13.5012C13.468 13.3624 13.6332 13.199 14.0497 12.7825C14.3426 12.4896 14.8175 12.4896 15.1104 12.7825C15.4033 13.0754 15.4033 13.5503 15.1104 13.8432L15.076 13.8776C14.7047 14.2489 14.4721 14.4815 14.2126 14.684C13.9069 14.9224 13.5761 15.1268 13.2261 15.2936C12.929 15.4352 12.6169 15.5392 12.1188 15.7052L9.21426 16.6734C8.67801 16.8521 8.0868 16.7126 7.68711 16.3129C7.28742 15.9132 7.14785 15.322 7.3266 14.7857L8.29477 11.8812C8.46079 11.3831 8.56479 11.071 8.7064 10.7739C8.87319 10.4239 9.07761 10.0931 9.31605 9.78742C9.51849 9.52787 9.7511 9.29529 10.1224 8.92401L16.7705 2.27592ZM17.4546 3.7132C17.4773 3.80906 17.509 3.92327 17.5532 4.05066C17.6965 4.46372 17.9677 5.00771 18.48 5.51999C18.9923 6.03227 19.5363 6.30346 19.9493 6.44677C20.0767 6.49097 20.1909 6.52273 20.2868 6.54543L20.6634 6.16881C21.4455 5.38671 21.4455 4.11867 20.6634 3.33658C19.8813 2.55448 18.6133 2.55448 17.8312 3.33658L17.4546 3.7132Z"
            clip-rule="evenodd"
          ></path>
        </svg>{" "}
        Update Consultant Details
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Consultant Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <br />
            <div className="form-flex-wrap">
              <div title="Tender ID" className="flex-column">
                <label
                  htmlFor="tender_id"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Tender ID
                </label>
                <input
                  type="text"
                  id="tender_id"
                  name="tender_id"
                  placeholder="Tender ID"
                  onChange={handleInputChange}
                  value={formData?.tender_id}
                  className={`form-input-small-four ${errors.tender_id
                    ? "error"
                    : inputState.tender_id
                      ? "success"
                      : ""
                    }`}
                />
                {errors.tender_id && (
                  <span className="error-message font-size-text ">
                    {errors.tender_id}
                  </span>
                )}
              </div>


              <div title="Location" className="flex-column">
                <label
                  htmlFor="location"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Location (State)<span className="required">*</span>
                </label>
                <select
                  id="location"
                  name="location"
                  onChange={handleInputChange}
                  value={formData.location}
                  className={`form-input-small-four ${errors.location
                    ? "error"
                    : inputState.location
                      ? "success"
                      : ""
                    }`}
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  {sortedStatesAndUTs.map((stateOrUT) => (
                    <option key={stateOrUT} value={stateOrUT}>
                      {stateOrUT}
                    </option>
                  ))}
                </select>
                {errors.location && (
                  <span className="error-message font-size-text ">
                    {errors.location}
                  </span>
                )}
              </div>






              <div title="Site Sub Company" className="flex-column ">
                <div className=" form-group-selectt">
                  <label
                    htmlFor="sub_company"
                    className="form-labels  font-weight500    font-size-subheading"
                  >
                    Site Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    placeholder="Site SubCompany"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input-small-four ${errors.sub_company
                      ? "error"
                      : inputState.sub_company
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select a Site SubCompany</option>
                    {siteSubCompanyList
                      ?.sort((a, b) =>
                        a.title.localeCompare(b.title, "en", {
                          ignorePunctuation: true,
                        })
                      )
                      .map((i, index) => (
                        <option key={index} value={i.id}>
                          {i.title}
                        </option>
                      ))}
                  </select>

                </div>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">
                    {errors.sub_company}
                  </span>
                )}
              </div>

              <div title="Work Type" className="flex-column ">
                <div className="form-group-selectt">
                  <label
                    htmlFor="work_type"
                    className="form-labels  font-weight500    font-size-subheading"
                  >
                    Work Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="work_type"
                    name="work_type"
                    placeholder="Site Work Type"
                    value={formData.work_type}
                    onChange={handleInputChange}
                    className={`form-input-small-four ${errors.work_type
                      ? "error"
                      : inputState.work_type
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select Work Type</option>
                    <option value="construction consultant">
                      Construction Consultant
                    </option>
                    <option value="operations & maintenance consultant">
                      Operation & Maintenance Consultant
                    </option>
                    <option value="safety consultant">Safety Consultant</option>
                    <option value="dpr">DPR</option>
                  </select>

                </div>
                {errors.work_type && (
                  <span className="error-message font-size-text ">
                    {errors.work_type}
                  </span>
                )}
              </div>

              <div title="Our Role" className="flex-column ">
                <div className="form-group-selectt">
                  <label
                    htmlFor="our_role"
                    className="form-labels  font-weight500    font-size-subheading"
                  >
                    Our Role<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="our_role"
                    name="our_role"
                    placeholder="Our Role"
                    value={formData.our_role}
                    onChange={handleInputChange}
                    className={`form-input-small-four ${errors.our_role
                      ? "error"
                      : inputState.our_role
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select Role</option>
                    <option value="active">Active</option>
                    <option value="partially">Partially</option>
                    <option value="silent">Silent</option>
                  </select>

                </div>
                {errors.our_role && (
                  <span className="error-message font-size-text ">
                    {errors.our_role}
                  </span>
                )}
              </div>

              <div title="Project Length" className="flex-column">
                <label
                  htmlFor="project_length"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Length (in KM)<span className="required">*</span>
                </label>
                <input
                  type="number"
                  maxLength={10}
                  min={0}
                  id="project_length"
                  name="project_length"
                  placeholder="Project Length"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input-small-four ${errors.project_length
                    ? "error"
                    : inputState.project_length
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_length && (
                  <span className="error-message font-size-text ">
                    {errors.project_length}
                  </span>
                )}
              </div>

              <div title="Bid Due Date" className="flex-column">
                <label
                  htmlFor="bid_due_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Bid Due Date
                </label>
                <input
                  type="date"
                  id="bid_due_date"
                  name="bid_due_date"
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.bid_due_date}
                  className={`form-input-small-four ${errors.bid_due_date
                    ? "error"
                    : inputState.bid_due_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.bid_due_date && (
                  <span className="error-message font-size-text">
                    {errors.bid_due_date}
                  </span>
                )}
              </div>

              <div title="LOA Date" className="flex-column">
                <label
                  htmlFor="loa_award_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  LOA Date
                </label>
                <input
                  type="date"
                  id="loa_award_date"
                  name="loa_award_date"
                  min={formData.bid_due_date}
                  placeholder="Award Date"
                  onChange={handleInputChange}
                  value={formData.loa_award_date}
                  className={`form-input-small-four ${errors.loa_award_date
                    ? "error"
                    : inputState.loa_award_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.loa_award_date && (
                  <span className="error-message font-size-text ">
                    {errors.loa_award_date}
                  </span>
                )}
              </div>

              <div title="Agreement Date" className="flex-column">
                <label
                  htmlFor="agreement_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Agreement Date
                </label>
                <input
                  type="date"
                  id="agreement_date"
                  name="agreement_date"
                  min={formData.bid_due_date}
                  placeholder="Agreement Date"
                  onChange={handleInputChange}
                  value={formData.agreement_date}
                  className={`form-input-small-four ${errors.agreement_date
                    ? "error"
                    : inputState.agreement_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.agreement_date && (
                  <span className="error-message font-size-text ">
                    {errors.agreement_date}
                  </span>
                )}
              </div>

              <div title="Commencement Date" className="flex-column">
                <label
                  htmlFor="commencement_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Commencement Date
                </label>
                <input
                  type="date"
                  id="commencement_date"
                  name="commencement_date"
                  min={formData.agreement_date}
                  placeholder="Commencement Date"
                  onChange={handleInputChange}
                  value={formData.commencement_date}
                  className={`form-input-small-four ${errors.commencement_date
                    ? "error"
                    : inputState.commencement_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.commencement_date && (
                  <span className="error-message font-size-text ">
                    {errors.commencement_date}
                  </span>
                )}
              </div>

              <div title="Project Duration" className="flex-column">
                <label
                  htmlFor="project_duration"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Duration (In Month)
                </label>
                <input
                  type="number"
                  id="project_duration"
                  name="project_duration"
                  placeholder="Project Duaration"
                  onChange={handleInputChange}
                  value={formData.project_duration}
                  className={`form-input-small-four ${errors.project_duration
                    ? "error"
                    : inputState.project_duration
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_duration && (
                  <span className="error-message font-size-text ">
                    {errors.project_duration}
                  </span>
                )}
              </div>

              <div
                title="Schedule Construction Completion Date"
                className="flex-column"
              >
                <label
                  htmlFor="schedule_construction_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Schedule Const. Completion
                </label>
                <input
                  type="date"
                  id="schedule_construction_completion_date"
                  name="schedule_construction_completion_date"
                  min={formData.commencement_date}
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.schedule_construction_completion_date}
                  className={`form-input-small-four ${errors.schedule_construction_completion_date
                    ? "error"
                    : inputState.schedule_construction_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.schedule_construction_completion_date && (
                  <span className="error-message font-size-text">
                    {errors.schedule_construction_completion_date}
                  </span>
                )}
              </div>

              <div title="Target Date" className="flex-column">
                <label
                  htmlFor="schedule_completion_date"
                  className="form-labels  font-weight500   "
                >
                  Schedule Completion Date
                </label>
                <input
                  type="date"
                  id="schedule_completion_date"
                  name="schedule_completion_date"
                  min={formData.actual_construction_completion_date}
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.schedule_completion_date}
                  className={`form-input-small-four ${errors.schedule_completion_date
                    ? "error"
                    : inputState.schedule_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.schedule_completion_date && (
                  <span className="error-message">
                    {errors.schedule_completion_date}
                  </span>
                )}
              </div>

              <div
                title="Actual Construction Completion Date"
                className="flex-column"
              >
                <label
                  htmlFor="actual_construction_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Actual Const. Completion
                </label>
                <input
                  type="date"
                  id="actual_construction_completion_date"
                  name="actual_construction_completion_date"
                  min={formData.commencement_date}
                  placeholder="Target Date"
                  onChange={handleInputChange}
                  value={formData.actual_construction_completion_date}
                  className={`form-input-small-four ${errors.actual_construction_completion_date
                    ? "error"
                    : inputState.actual_construction_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.actual_construction_completion_date && (
                  <span className="error-message font-size-text">
                    {errors.actual_construction_completion_date}
                  </span>
                )}
              </div>

              <div title="Actual Completion Date" className="flex-column">
                <label
                  htmlFor="actual_completion_date"
                  className="form-labels  font-weight500   "
                >
                  Actual Completion Date
                </label>
                <input
                  type="date"
                  id="actual_completion_date"
                  name="actual_completion_date"
                  min={formData.actual_completion_date}
                  placeholder="Actual Completion Date"
                  onChange={handleInputChange}
                  value={formData.actual_completion_date}
                  className={`form-input-small-four ${errors.actual_completion_date
                    ? "error"
                    : inputState.actual_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.actual_completion_date && (
                  <span className="error-message">
                    {errors.actual_completion_date}
                  </span>
                )}
              </div>

              <div title="Site Authority Engineer" className="flex-column ">
                <div className=" form-group-selectt">
                  <label className="form-labels  font-weight500    font-size-subheading">
                    Authority Engineer
                  </label>
                  <br />
                  <select
                    name="concern_person_name"
                    onChange={handleInputChange}
                    value={formData.concern_person_name}
                    className={`form-input-small-four form-group-selection form-input-background ${errors.concern_person_name
                      ? "error"
                      : inputState.concern_person_name
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select Concern Person </option>
                    {empList
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        // <option value={`${i.emp_code}|${i.name}`} key={index}>
                        <option value={i.name}>
                          {i.emp_code} - {i.name}
                        </option>
                      ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                </div>
                {errors.concern_person_name && (
                  <span className="error-message font-size-text ">
                    {errors.concern_person_name}
                  </span>
                )}
              </div>
              <div title="Remark" className="flex-column">
                <label
                  htmlFor="remark"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Remark
                </label>
                <input
                  type="text"
                  id="remark"
                  name="remark"
                  placeholder="Remark"
                  onChange={handleInputChange}
                  value={formData.remark}
                  className={`form-input-small-four ${errors.remark ? "error" : inputState.remark ? "success" : ""
                    }`}
                />
                {errors.remark && (
                  <span className="error-message font-size-text ">
                    {errors.remark}
                  </span>
                )}
              </div>

              <div title="Consultancy Fees" className="flex-column">
                <label
                  htmlFor="consultancy_fees"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Consultancy Fees
                </label>
                <input
                  type="number"
                  min={0}
                  id="consultancy_fees"
                  name="consultancy_fees"
                  placeholder="Consultancy Fees"
                  onChange={handleInputChange}
                  value={formData.consultancy_fees}
                  className={`form-input-small-four ${errors.consultancy_fees
                    ? "error"
                    : inputState.consultancy_fees
                      ? "success"
                      : ""
                    }`}
                />
                {errors.consultancy_fees && (
                  <span className="error-message font-size-text">
                    {errors.consultancy_fees}
                  </span>
                )}
              </div>
              <div title="Contract Mode" className="flex-column ">
                <div className=" form-group-selectt">
                  <label className="form-labels  font-weight500    font-size-subheading">
                    Contract Mode
                  </label>
                  <br />
                  <select
                    name="contract_mode"
                    onChange={handleInputChange}
                    value={formData.contract_mode}
                    className={`form-input-small-four form-group-selection form-input-background ${errors.contract_mode
                      ? "error"
                      : inputState.contract_mode
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select Contract Mode </option>
                    <option value="epc">EPC </option>
                    <option value="ham"> HAM</option>
                    <option value="bot">BOT </option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                </div>
                {errors.contract_mode && (
                  <span className="error-message font-size-text ">
                    {errors.contract_mode}
                  </span>
                )}
              </div>

              <div title="Lead" className="flex-column">
                <label
                  htmlFor="lead"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Lead<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="lead"
                  name="lead"
                  placeholder="Lead"
                  onChange={handleInputChange}
                  value={formData.lead}
                  className={`form-input-small-four ${errors.lead ? "error" : inputState.lead ? "success" : ""
                    }`}
                />
                {errors.lead && (
                  <span className="error-message font-size-text ">
                    {errors.lead}
                  </span>
                )}
              </div>

              <div title="JV" className="flex-column">
                <label
                  htmlFor="jv"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  JV
                </label>
                <input
                  type="text"
                  id="jv"
                  name="jv"
                  placeholder="JV"
                  onChange={handleInputChange}
                  value={formData.jv}
                  className={`form-input-small-four ${errors.jv ? "error" : inputState.jv ? "success" : ""
                    }`}
                />
                {errors.jv && (
                  <span className="error-message font-size-text ">
                    {errors.jv}
                  </span>
                )}
              </div>


              <div title="Our Share" className="flex-column">
                <label
                  htmlFor="our_share"
                  className="form-labels font-weight500 font-size-subheading"
                >
                  {/* {formData.sub_company ? formData.sub_company : "Our"} Share */}
                  Our Share (%)
                  {/* Saptagon Share */}
                </label>

                <input
                  type="text"
                  id="our_share"
                  name="our_share"
                  placeholder="Enter percentage (%)"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (
                      value === "" ||
                      (/^\d+\.?\d{0,2}$/.test(value) && value <= 100)
                    ) {
                      handleInputChange(e);
                    }
                  }}
                  value={`${formData.our_share ? formData.our_share : ""}`}
                  className={`form-input-small-four ${errors.our_share
                    ? "error"
                    : inputState.our_share
                      ? "success"
                      : ""
                    }`}
                />
                {errors.our_share && (
                  <span className="error-message font-size-text ">
                    {errors.our_share}
                  </span>
                )}
              </div>

              <div title="Association" className="flex-column">
                <label
                  htmlFor="association"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Association
                </label>
                <input
                  type="text"
                  id="association"
                  name="association"
                  placeholder="Association"
                  onChange={handleInputChange}
                  value={formData.association}
                  className={`form-input-small-four ${errors.association
                    ? "error"
                    : inputState.association
                      ? "success"
                      : ""
                    }`}
                />
                {errors.association && (
                  <span className="error-message font-size-text ">
                    {errors.association}
                  </span>
                )}
              </div>


              <div title="Client Name" className="flex-column">
                <label
                  htmlFor="client_name"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Client
                </label>
                <input
                  type="text"
                  id="client_name"
                  name="client_name"
                  placeholder="Client Name"
                  onChange={handleInputChange}
                  value={formData.client_name}
                  className={`form-input-small-four ${errors.client_name
                    ? "error"
                    : inputState.client_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.client_name && (
                  <span className="error-message font-size-text ">
                    {errors.client_name}
                  </span>
                )}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.client_name}
                      </div>
                    ))}
                  </div>
                )}
              </div>


              <div title="Site Address" className="flex-column">
                <label
                  htmlFor="site_office_address"
                  className="form-labels  font-weight500 font-size-subheading "
                >
                  Site Address
                </label>
                <textarea
                  id="site_office_address"
                  name="site_office_address"
                  placeholder="Site Address"
                  onChange={handleInputChange}
                  value={formData.site_office_address}
                  rows="50"
                  cols="100"
                  className={`form-textarea form-input-small-four ${errors.site_office_address
                    ? "error"
                    : inputState.site_office_address
                      ? "success"
                      : ""
                    }`}
                />
                {errors.site_office_address && (
                  <div className="error-message font-size-text">
                    {errors.site_office_address}
                  </div>
                )}
              </div>

              {/* <div title="Client Name" className="flex-column">
                <label htmlFor="client_name" className="form-labels  font-weight500    font-size-subheading">
                  Client
                </label>

                <CreatableSelect
                  id="client_name"
                  name="client_name"
                  options={clientList.map(client => ({ value: client.client_name, label: client.client_name }))}
                  value={
                    formData.client_name
                      ? { value: formData.client_name, label: formData.client_name }
                      : null
                  }
                  onChange={handleInputChangeClient}
                  onInputChange={(inputValue) =>
                    handleInputChangeClient({
                      value: inputValue,
                      label: inputValue
                    })
                  }
                  inputValue={formData.client_name}
                  placeholder="Select or type a new Client Name"
                  className={`form-input ${errors.client_name ? "error" : inputState.client_name ? "success" : ""}`}
                  isClearable
                  formatCreateLabel={(inputValue) =>
                    `Create new Client Name: ${inputValue}`
                  }
                  isValidNewOption={(inputValue, selectValue, selectOptions) =>
                    inputValue.trim() !== "" &&
                    !selectOptions.some(
                      (option) =>
                        option.value.toLowerCase() === inputValue.toLowerCase()
                    )
                  }
                />

                <CreatableSelect
                  id="client_name"
                  name="client_name"
                  options={clientList}
                  value={formData.client_name ? { value: formData.client_name, label: formData.client_name } : null}  // Ensure state reflects this
                  onChange={(option) => handleChange(index, { target: { name: 'stream', value: option ? option.value : '' } })}
                  placeholder="Select or type a new stream"
                  className={`form-input ${errors.client_name ? "error" : inputState.client_name ? "success" : ""}`}
                  // className={`${errors[stream_${index}] ? 'error' : field.stream ? 'success' : ''}`}
                  // isClearable
                  formatCreateLabel={(inputValue) => Create new client_name:${inputV}}
                isValidNewOption={(inputValue, selectValue, selectOptions) =>
                  inputValue.trim() !== '' &&
                  !selectOptions.find(option => option.label.toLowerCase() === inputValue.toLowerCase())
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '33vw',
                    height: '50px',
                    borderRadius: '10px',
                    border: '1px solid #e3e3e3',
                    margin: '10px 0px 20px 0px',
                    color: '#4a4a4a',
                    backgroundColor: '#f7f8fa',
                    padding: '0px 10px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#4a4a4a',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '10px',
                    marginTop: '0',
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: '#4a4a4a',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#4a4a4a',
                  }),
                }}
                />

                {errors.client_name && <span className="error-message font-size-text ">{errors.client_name}</span>}
              </div> */}
            </div>
            {/* <div className="flex-column flex-row button-models" style={{ width: "50%", justifyContent: 'center' }}> */}
            <div className="button-models">

              <button
                onClick={handleProjectUpdate}
                type="submit"
                className="model-button font-weight500 model-button-submit"
                disabled={loading}
              >
                Update
              </button>
            </div>


          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

// const AddProjectBill = ({
//   project,
//   getProjects,
//   contractor,
// }) => {
//   const [show, setShow] = useState(false);
//   const handleShow = () => {
//     setShow(true);
//     getLetters();
//   };

//   const handleClose = () => {
//     setShow(false);
//     setFormData({
//       project: project ? project : "",
//       // month: '',
//       // year: '',
//       date: "",
//       bill_type: "",
//       bill_num: "",
//       bill_period_start: "",
//       bill_period_end: "",
//       billing_date: "",
//       bill_catagory: "",
//       workperformedby: "contractor",

//       recommended_bill_amount: "",
//       recommended_percentage: "",
//       recomended_bill_date: "",

//       submitted_bill_amount: "",
//       submitted_bill_date: "",
//       submitted_percentage: "",

//       submitted_letter_num: "",
//       recomended_letter_num: "",
//       ho_percentage_amt: "",
//       deduction_amount: "",

//       ld_amount: "",
//       withheld_amt: "",
//       released_withheld_amt: "",
//       remark: "",
//       site_recomended_bill_date: null,
//       site_recommended_bill_amount: "",
//       site_recommended_percentage: "",
//       site_ld_amount: "",
//       site_withheld_amt: "",
//       site_released_withheld_amt: "",
//       site_deduction_amount: "",
//       site_remark: "",
//       site_attachment: "",
//       ho_attachment: "",
//       gst: "",
//       escalation: "",
//       site_gst: "",
//       site_escalation: "",
//       ho_escalation: "",
//       ho_gst: "",
//       ho_work_done_amt: "",
//       contractor_deduction_amt: "",
//       percentage_amt: "",
//       site_percentage_amt: "",
//       work_done_amt: "",
//       site_work_done_amt: "",


//     });
//   };
//   const [formData, setFormData] = useState({
//     project: project ? project : "",
//     date: null,
//     bill_type: "",
//     contractor_name: "",
//     work_done_amt: "",
//     bill_num: "",
//     bill_period_start: null,
//     bill_period_end: null,
//     billing_date: null,
//     bill_catagory: "",
//     workperformedby: "contractor",
//     site_percentage_amt: "",
//     recommended_bill_amount: "",
//     recommended_percentage: "",
//     recomended_bill_date: null,
//     ho_percentage_amt: "",

//     submitted_bill_amount: "",
//     submitted_bill_date: null,
//     submitted_percentage: "",
//     percentage_amt: "",

//     submitted_letter_num: "",
//     recomended_letter_num: "",

//     deduction_amount: "",
//     ld_amount: "",
//     withheld_amt: "",
//     released_withheld_amt: "",
//     remark: "",

//     site_recomended_bill_date: null,
//     site_recommended_bill_amount: "",
//     site_recommended_percentage: "",
//     site_ld_amount: "",
//     site_withheld_amt: "",
//     site_released_withheld_amt: "",
//     site_deduction_amount: "",
//     site_remark: "",
//     site_attachment: "",
//     // ho_attachment: "",
//     escalation: "",
//     gst: "",
//     site_escalation: "",
//     site_gst: "",
//     ho_escalation: "",
//     ho_gst: "",
//     contractor_deduction_amt: "",
//     ho_work_done_amt: "",
//     site_work_done_amt: "",
//     contractor: contractor


//   });



//   useEffect(() => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       contractor: contractor,
//     }));
//   }, [contractor]);




//   const { deduction_amount, submitted_bill_amount, recommended_bill_amount, site_deduction_amount, site_recommended_bill_amount } =
//     formData;

//   // const updaterecommendedPercentage = submitted_bill_amount
//   //   ? (
//   //     (recommended_bill_amount /
//   //       (submitted_bill_amount - (deduction_amount || 0))) *
//   //     100
//   //   ).toFixed(2)
//   //   : 0;


//   const updatesiterecommendedPercentage = submitted_bill_amount
//     ? (
//       (site_recommended_bill_amount /
//         (submitted_bill_amount - (site_deduction_amount || 0))) *
//       100
//     ).toFixed(2)
//     : 0;

//   const [trackRecordList, setTrackRecordList] = useState([]);

//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState({});

//   const getLetters = async () => {
//     try {
//       const res = await axios.get(
//         `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/billing/`
//       );
//       setTrackRecordList(res.data);
//     } catch (err) {
//       toast.error("Error fetching letters.");
//     }
//   };



//   const validateForm = () => {
//     const newErrors = {};
//     const requiredFields = [
//       "bill_type",
//       "bill_num",
//       "bill_period_start",
//       "bill_period_end",
//       "submitted_bill_date",
//       "bill_catagory",
//       "submitted_bill_amount",

//     ];

//     requiredFields.forEach((field) => {
//       if (!formData[field]) {
//         newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
//           } is required!`;
//       }
//     });

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     setFormData((prevFormData) => {
//       let updatedFormData = { ...prevFormData, [name]: value };

//       // Check for recommended_bill_amount and deduction_amount constraints
//       if (name === "recommended_bill_amount" || name === "deduction_amount") {
//         const maxLimit = prevFormData.submitted_bill_amount; // Set the max limit dynamically

//         const { success, error } = inputMaxLimit(name, value, maxLimit);
//         if (!success) {
//           setErrors((prevErrors) => ({
//             ...prevErrors,
//             [name]: error,
//           }));
//           return prevFormData;
//         } else {
//           setErrors((prevErrors) => ({
//             ...prevErrors,
//             [name]: "",
//           }));
//         }
//       }

//       // Dynamically calculate submitted_bill_amount
//       if ([
//         // contractor

//         "gst",
//         "escalation",
//         "work_done_amt",
//         "contractor_deduction_amt",
//         "percentage_amt",
//         "submitted_percentage",

//         // site 

//         "site_work_done_amt",
//         "site_escalation",
//         "site_gst",
//         "site_deduction_amount",
//         "site_recommended_bill_amount",
//         "site_percentage_amt",
//         "site_recommended_percentage",

//         // ho

//         "ho_gst",
//         "ho_escalation",
//         "ho_work_done_amt",
//         "deduction_amount",
//         "recommended_bill_amount",
//         "recommended_percentage",
//         "ho_percentage_amt"

//       ].includes(name)) {

//         // contractor

//         const gst = parseFloat(updatedFormData.gst || 0);
//         const escalation = parseFloat(updatedFormData.escalation || 0);
//         const workDoneAmount = parseFloat(updatedFormData.work_done_amt || 0);
//         const deductionSubmittedAmount = parseFloat(updatedFormData.contractor_deduction_amt || 0);
//         const submittedPercentageAmount = parseFloat(updatedFormData.percentage_amt || 0);

//         // site 

//         const site_gst = parseFloat(updatedFormData.site_gst || 0);
//         const site_escalation = parseFloat(updatedFormData.site_escalation || 0);
//         const site_work_done_amt = parseFloat(updatedFormData.site_work_done_amt || 0);
//         const site_deduction_amount = parseFloat(updatedFormData.site_deduction_amount || 0);
//         const site_percentage_amt = parseFloat(updatedFormData.site_percentage_amt || 0);

//         // ho

//         const ho_gst = parseFloat(updatedFormData.ho_gst || 0);
//         const ho_escalation = parseFloat(updatedFormData.ho_escalation || 0);
//         const ho_work_done_amt = parseFloat(updatedFormData.ho_work_done_amt || 0);
//         const deduction_amount = parseFloat(updatedFormData.deduction_amount || 0);
//         const ho_percentage_amt = parseFloat(updatedFormData.ho_percentage_amt || 0);

//         updatedFormData = {
//           ...updatedFormData,
//           // contractor

//           submitted_bill_amount: ((workDoneAmount + gst + escalation) - deductionSubmittedAmount).toFixed(), // Ensures precision
//           submitted_percentage: ((submittedPercentageAmount / submitted_bill_amount) * 100).toFixed(2),

//           // site

//           site_recommended_bill_amount: ((site_gst + site_escalation + site_work_done_amt) - site_deduction_amount).toFixed(), // Ensures precision
//           site_recommended_percentage: ((site_percentage_amt / site_recommended_bill_amount) * 100).toFixed(2),


//           // ho

//           recommended_bill_amount: ((ho_work_done_amt + ho_gst + ho_escalation) - deduction_amount).toFixed(), // Ensures precision
//           recommended_percentage: ((ho_percentage_amt / recommended_bill_amount) * 100).toFixed(2),
//         };
//       }

//       return updatedFormData;
//     });
//   };





//   const handleFileChange = (e) => {
//     const { name } = e.target;
//     const file = e.target.files[0];

//     if (file) {
//       // Update the form data with the selected file
//       setFormData({ ...formData, [name]: file });
//       // Update the file name for display

//     }
//   };




//   const handleFormSubmit = async (e) => {
//     e.preventDefault();

//     // const date = formData.date?.split('-')

//     const updatedfromdata = {
//       ...formData,
//       // recommended_percentage: updaterecommendedPercentage,
//       site_recommended_percentage: updatesiterecommendedPercentage,
//       // month: date[1],
//       // year: date[0]
//     };

//     if (validateForm()) {
//       setLoading(true);
//       const loadingToastId = toast.loading("Loading: Please wait...");

//       try {

//         const formDataToSend = new FormData();

//         // Append general form fields
//         Object.keys(updatedfromdata).forEach((key) => {
//           if (updatedfromdata[key]) {

//             formDataToSend.append(key, updatedfromdata[key]);
//           }
//         });


//         const res = await axiosInstance.post(
//           `${BASE_URL}/siteproject/billingdetailfield/`,
//           formDataToSend
//         );

//         if (res.status === 200) {
//           // await getProjects();
//           toast.dismiss(loadingToastId);
//           toast.success("Billing detail added successfully!");
//           handleClose();
//           getProjects();
//         } else {
//           toast.dismiss(loadingToastId);
//           toast.error("Failed to add bill detail!");
//         }
//       } catch (err) {
//         toast.dismiss(loadingToastId);
//         toast.error("Error processing your request.");
//       } finally {
//         setLoading(false);
//       }
//     }
//   };
const AddProjectBill = ({
  project,
  getProjects,
  contractor,
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
    getLetters();
  };

  const handleClose = () => {
    setShow(false);
    setFormData({
      project: project ? project : "",
      date: null,
      bill_type: "",
      bill_num: "",
      bill_period_start: null,
      bill_period_end: null,
      billing_date: null,
      bill_catagory: "",
      workperformedby: "contractor",
      recommended_bill_amount: "",
      recommended_percentage: "",
      recomended_bill_date: null,
      submitted_bill_amount: "",
      submitted_bill_date: null,
      submitted_percentage: "",
      submitted_letter_num: "",
      recomended_letter_num: "",
      ho_percentage_amt: "",
      deduction_amount: "",
      ld_amount: "",
      withheld_amt: "",
      released_withheld_amt: "",
      remark: "",
      site_recomended_bill_date: null,
      site_recommended_bill_amount: "",
      site_recommended_percentage: "",
      site_ld_amount: "",
      site_withheld_amt: "",
      site_released_withheld_amt: "",
      site_deduction_amount: "",
      site_remark: "",
      site_attachment: "",
      ho_attachment: "",
      gst: "",
      escalation: "",
      site_gst: "",
      site_escalation: "",
      ho_escalation: "",
      ho_gst: "",
      ho_work_done_amt: "",
      contractor_deduction_amt: "",
      percentage_amt: "",
      site_percentage_amt: "",
      work_done_amt: "",
      site_work_done_amt: "",
      contractor: contractor,
    });
  };

  const [formData, setFormData] = useState({
    project: project ? project : "",
    date: null,
    bill_type: "",
    contractor_name: "",
    work_done_amt: "",
    bill_num: "",
    bill_period_start: null,
    bill_period_end: null,
    billing_date: null,
    bill_catagory: "",
    workperformedby: "contractor",
    site_percentage_amt: "",
    recommended_bill_amount: "",
    recommended_percentage: "",
    recomended_bill_date: null,
    ho_percentage_amt: "",
    submitted_bill_amount: "",
    submitted_bill_date: null,
    submitted_percentage: "",
    percentage_amt: "",
    submitted_letter_num: "",
    recomended_letter_num: "",
    deduction_amount: "",
    ld_amount: "",
    withheld_amt: "",
    released_withheld_amt: "",
    remark: "",
    site_recomended_bill_date: null,
    site_recommended_bill_amount: "",
    site_recommended_percentage: "",
    site_ld_amount: "",
    site_withheld_amt: "",
    site_released_withheld_amt: "",
    site_deduction_amount: "",
    site_remark: "",
    site_attachment: "",
    ho_attachment: "",
    escalation: "",
    gst: "",
    site_escalation: "",
    site_gst: "",
    ho_escalation: "",
    ho_gst: "",
    contractor_deduction_amt: "",
    ho_work_done_amt: "",
    site_work_done_amt: "",
    contractor: contractor,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contractor: contractor,
    }));
  }, [contractor]);

  const { deduction_amount, submitted_bill_amount, recommended_bill_amount, site_deduction_amount, site_recommended_bill_amount } = formData;

  const updatesiterecommendedPercentage = submitted_bill_amount
    ? (
      (site_recommended_bill_amount /
        (submitted_bill_amount - (site_deduction_amount || 0))) *
      100
    ).toFixed(2)
    : 0;

  const [trackRecordList, setTrackRecordList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const getLetters = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/letterrecords/filter/null/null/null/null/${project}/${formData.workperformedby}/billing/null/null/${contractor}/null/`);
      // const res = await axios.get(

      //   `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/billing/`
      // );
      setTrackRecordList(res.data);
    } catch (err) {
      toast.error("Error fetching letters.");
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "bill_type",
      "bill_num",
      "bill_period_start",
      "bill_period_end",
      "submitted_bill_date",
      "bill_catagory",
      "submitted_bill_amount",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      let updatedFormData = { ...prevFormData, [name]: value };

      // Check for recommended_bill_amount and deduction_amount constraints
      if (name === "recommended_bill_amount" || name === "deduction_amount") {
        const maxLimit = prevFormData.submitted_bill_amount; // Set the max limit dynamically

        const { success, error } = inputMaxLimit(name, value, maxLimit);
        if (!success) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
          }));
          return prevFormData;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
        }
      }

      // Dynamically calculate submitted_bill_amount
      if ([
        "gst",
        "escalation",
        "work_done_amt",
        "contractor_deduction_amt",
        "percentage_amt",
        "submitted_percentage",
        "site_work_done_amt",
        "site_escalation",
        "site_gst",
        "site_deduction_amount",
        "site_recommended_bill_amount",
        "site_percentage_amt",
        "site_recommended_percentage",
        "ho_gst",
        "ho_escalation",
        "ho_work_done_amt",
        "deduction_amount",
        "recommended_bill_amount",
        "recommended_percentage",
        "ho_percentage_amt",
      ].includes(name)) {

        const gst = parseFloat(updatedFormData.gst || 0);
        const escalation = parseFloat(updatedFormData.escalation || 0);
        const workDoneAmount = parseFloat(updatedFormData.work_done_amt || 0);
        const deductionSubmittedAmount = parseFloat(updatedFormData.contractor_deduction_amt || 0);
        const submittedPercentageAmount = parseFloat(updatedFormData.percentage_amt || 0);

        const site_gst = parseFloat(updatedFormData.site_gst || 0);
        const site_escalation = parseFloat(updatedFormData.site_escalation || 0);
        const site_work_done_amt = parseFloat(updatedFormData.site_work_done_amt || 0);
        const site_deduction_amount = parseFloat(updatedFormData.site_deduction_amount || 0);
        const site_percentage_amt = parseFloat(updatedFormData.site_percentage_amt || 0);

        const ho_gst = parseFloat(updatedFormData.ho_gst || 0);
        const ho_escalation = parseFloat(updatedFormData.ho_escalation || 0);
        const ho_work_done_amt = parseFloat(updatedFormData.ho_work_done_amt || 0);
        const deduction_amount = parseFloat(updatedFormData.deduction_amount || 0);
        const ho_percentage_amt = parseFloat(updatedFormData.ho_percentage_amt || 0);

        updatedFormData = {
          ...updatedFormData,
          submitted_bill_amount: ((workDoneAmount + gst + escalation) - deductionSubmittedAmount).toFixed(),
          submitted_percentage: ((submittedPercentageAmount / updatedFormData.submitted_bill_amount) * 100).toFixed(2),
          site_recommended_bill_amount: ((site_gst + site_escalation + site_work_done_amt) - site_deduction_amount).toFixed(),
          site_recommended_percentage: ((site_percentage_amt / updatedFormData.site_recommended_bill_amount) * 100).toFixed(2),
          recommended_bill_amount: ((ho_work_done_amt + ho_gst + ho_escalation) - deduction_amount).toFixed(),
          recommended_percentage: ((ho_percentage_amt / updatedFormData.recommended_bill_amount) * 100).toFixed(2),
        };
      }

      return updatedFormData;
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      setFormData({ ...formData, [name]: file });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedfromdata = {
      ...formData,
      site_recommended_percentage: updatesiterecommendedPercentage,
    };

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        const formDataToSend = new FormData();

        // Append general form fields
        Object.keys(updatedfromdata).forEach((key) => {
          if (updatedfromdata[key]) {
            formDataToSend.append(key, updatedfromdata[key]);
          }
        });

        const res = await axiosInstance.post(
          `${BASE_URL}/siteproject/billingdetailfield/`,
          formDataToSend
        );

        if (res.status === 200) {
          toast.dismiss(loadingToastId);
          toast.success("Billing detail added successfully!");
          handleClose();
          getProjects();
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add bill detail!");
        }
      } catch (err) {
        toast.dismiss(loadingToastId);
        toast.error("Error processing your request.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <button title="Add Bill" className="upload-svg" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "}
        Add Bill
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Billing  Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />

          <div className="form-flex-wrap">
            <div className="flex-column" style={{ width: "100%", marginBottom: "10px" }}>
              <div className="jd-heading-outer-flex " >
                <h2 className="jd-heading-main font-weight600  font-size-heading"> Bill Details</h2>
              </div>
              <div>
                <div className="jd-heading-bottom-bold" style={{ width: "160px" }}></div>
                <div className="jd-heading-bottom-light"></div>
              </div>
            </div>
            <div className="form-flex-wrap">
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Type">
                  Bill Type<span className="required">*</span>
                </label>
                <select
                  name="bill_type"
                  title="Bill Type"
                  value={formData.bill_type}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_type ? "is-invalid" : ""
                    }`}
                >
                  <option value="" title="Select a Type">Select a Type</option>
                  <option value="sps">SPS</option>
                  <option value="ipc">IPC</option>
                  <option value="milestonebill">Milestone Bill</option>

                  <option value="maintenance">Maintenance</option>
                  <option value="rs">RA</option>

                  <option value="other">Other</option>
                </select>
                {errors.bill_type && (
                  <div className="error-message font-size-text">
                    {errors.bill_type}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Category">
                  Bill Category<span className="required">*</span>
                </label>
                <select
                  title="Bill Category"
                  name="bill_catagory"
                  value={formData.bill_catagory}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_catagory ? "is-invalid" : ""
                    }`}
                >
                  <option value="">Select a Category</option>
                  <option value="bills">Bills</option>
                  <option value="balancebills">Balance Bills</option>
                </select>
                {errors.bill_catagory && (
                  <div className="error-message font-size-text">
                    {errors.bill_catagory}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Number">
                  Bill Num<span className="required">*</span>
                </label>
                <input
                  type="text"
                  title="Bill Number"
                  name="bill_num"
                  placeholder="Bill Num"
                  value={formData.bill_num}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_num ? "is-invalid" : ""
                    }`}
                />
                {errors.bill_num && (
                  <div className="error-message font-size-text">
                    {errors.bill_num}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Period Start">
                  Bill Period Start<span className="required">*</span>
                </label>
                <input
                  type="date"
                  name="bill_period_start"
                  title="Bill Period Start"
                  value={formData.bill_period_start}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_period_start ? "is-invalid" : ""
                    }`}
                />
                {errors.bill_period_start && (
                  <div className="error-message font-size-text">
                    {errors.bill_period_start}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Period End">
                  Bill Period End<span className="required">*</span>
                </label>
                <input
                  type="date"
                  name="bill_period_end"
                  title="Bill Period End"
                  value={formData.bill_period_end}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_period_end ? "is-invalid" : ""
                    }`}
                  min={formData.bill_period_start}
                />
                {errors.bill_period_end && (
                  <div className="error-message font-size-text">
                    {errors.bill_period_end}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Submitted Bill Date">
                  Submitted Bill Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  title="Submitted Bill Date"
                  name="submitted_bill_date"
                  value={formData.submitted_bill_date}
                  min={formData.bill_period_end}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.submitted_bill_date ? "is-invalid" : ""
                    }`}
                />
                {errors.submitted_bill_date && (
                  <div className="error-message font-size-text">
                    {errors.submitted_bill_date}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Work Done Amount
                </label>
                <input
                  type="text"
                  title="Work Done Amount "
                  name="work_done_amt"
                  placeholder="Work Done Amount "
                  value={formData.work_done_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.work_done_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.work_done_amt && (
                  <div className="error-message font-size-text">
                    {errors.gst}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Escalation">
                  Escalation Amount
                </label>
                <input
                  type="text"
                  title="Escalation Amount"
                  name="escalation"
                  placeholder="Escalation Amount"
                  value={formData.escalation}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.escalation ? "is-invalid" : ""
                    }`}
                />
                {errors.escalation && (
                  <div className="error-message font-size-text">
                    {errors.escalation}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  GST Amount
                </label>
                <input
                  type="text"
                  title="GST Amount"
                  name="gst"
                  placeholder="GST Amount"
                  value={formData.gst}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.gst ? "is-invalid" : ""
                    }`}
                />
                {errors.gst && (
                  <div className="error-message font-size-text">
                    {errors.gst}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Deduction Submitted Amount
                </label>
                <input
                  type="text"
                  title="GST Amount"
                  name="contractor_deduction_amt"
                  placeholder="Deduction Submitted Amount"
                  value={formData.contractor_deduction_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.contractor_deduction_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.contractor_deduction_amt && (
                  <div className="error-message font-size-text">
                    {errors.contractor_deduction_amt}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label
                  className="form-labels font-weight500 font-size-subheading"
                  title="Submitted Bill Amount"
                >
                  Submitted Bill Amount<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title="Submitted Bill Amount"
                  placeholder="1,00,000"
                  name="submitted_bill_amount"
                  value={formData.submitted_bill_amount}
                  readOnly // Make this field read-only to prevent manual edits
                  className={`form-input-date ${errors.submitted_bill_amount ? "is-invalid" : ""
                    }`}
                />
                {errors.submitted_bill_amount && (
                  <div className="error-message font-size-text">
                    {errors.submitted_bill_amount}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Submitted Percentage Amount
                </label>
                <input
                  type="number"
                  title=" Submitted Percentage Amount"
                  name="percentage_amt"
                  placeholder=" Submitted Percentage Amount"
                  // readOnly
                  value={formData.percentage_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.percentage_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.percentage_amt && (
                  <div className="error-message font-size-text">
                    {errors.percentage_amt}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Submitted Percentage<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title=" Submitted Percentage"
                  name="submitted_percentage"
                  placeholder=" Submitted Percentage"
                  readOnly
                  value={formData.submitted_percentage}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.submitted_percentage ? "is-invalid" : ""
                    }`}
                />
                {errors.submitted_percentage && (
                  <div className="error-message font-size-text">
                    {errors.submitted_percentage}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Submitted Letter">
                  Submitted Letter
                </label>
                <select
                  name="submitted_letter_num"
                  title="Submitted Letter"
                  value={formData.submitted_letter_num}
                  onChange={handleInputChange}
                  className={`form-input-date`}
                >
                  <option value="">Select an existing letter</option>
                  {trackRecordList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.letter_num}
                    </option>
                  ))}
                </select>
                {errors.submitted_letter_num && (
                  <div className="error-message font-size-text">
                    {errors.submitted_letter_num}
                  </div>
                )}
              </div>

              <div className="flex-column">
                {formData.submitted_letter_num ? (
                  ""
                ) : (
                  <div
                    className="flex-row "
                    style={{
                      width: "23.5vw",
                      justifyContent: "space-between",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      <div>OR</div>
                    </div>
                    <div className="button-models">
                      <AddTrackRecordDetails
                        projectId={project}
                        letterfor="contractor"
                        lettertype="billing"
                        getProjects={getLetters}
                        title="Bill Letter Details"
                        contractor={contractor}
                      />
                    </div>
                  </div>
                )}
              </div>

            </div>

            <div className="flex-column" style={{ width: "100%", marginBottom: "10px" }}>
              <div className="jd-heading-outer-flex " >
                <h2 className="jd-heading-main font-weight600  font-size-heading"> Site Bill Details</h2>
              </div>
              <div>
                <div className="jd-heading-bottom-bold" style={{ width: "160px" }}></div>
                <div className="jd-heading-bottom-light"></div>
              </div>
            </div>




            <div className="form-flex-wrap">
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Recomended Bill Date">
                  Site Recomended Bill Date
                </label>
                <input
                  type="date"
                  name="site_recomended_bill_date"
                  title="Site Recomended Bill Date"
                  value={formData.site_recomended_bill_date}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_recomended_bill_date ? "is-invalid" : ""
                    }`}
                  min={formData.site_recomended_bill_date}
                />
                {errors.site_recomended_bill_date && (
                  <div className="error-message font-size-text">
                    {errors.site_recomended_bill_date}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site LD Amount">
                  Site LD Amount
                </label>
                <input
                  type="number"
                  title="Site LD Amount"
                  min={0}
                  name="site_ld_amount"
                  placeholder="Site LD Amount"
                  value={formData.site_ld_amount}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_ld_amount ? "is-invalid" : ""
                    }`}
                />
                {errors.site_ld_amount && (
                  <div className="error-message font-size-text">
                    {errors.site_ld_amount}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Withheld Amount">
                  Site withheld Amount
                </label>
                <input
                  type="number"
                  title="Site Withheld Amount"
                  min={0}
                  name="site_withheld_amt"
                  placeholder="Site withheld Amount"
                  value={formData.site_withheld_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_withheld_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.site_withheld_amt && (
                  <div className="error-message font-size-text">
                    {errors.site_withheld_amt}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Site Work Done Amount
                </label>
                <input
                  type="text"
                  title="Site Work Done Amount "
                  name="site_work_done_amt"
                  placeholder="Work Done Amount "
                  value={formData.site_work_done_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_work_done_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.site_work_done_amt && (
                  <div className="error-message font-size-text">
                    {errors.site_work_done_amt}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Escalation">
                  Site Escalation Amount
                </label>
                <input
                  type="text"
                  title="Site Escalation Amount"
                  name="site_escalation"
                  placeholder="Site Escalation Amount"
                  value={formData.site_escalation}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_escalation ? "is-invalid" : ""
                    }`}
                />
                {errors.site_escalation && (
                  <div className="error-message font-size-text">
                    {errors.site_escalation}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title=" Site GST">
                  Site GST Amount
                </label>
                <input
                  type="text"
                  title="Site GST Amount"
                  name="site_gst"
                  placeholder="GST Amount"
                  value={formData.site_gst}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_gst ? "is-invalid" : ""
                    }`}
                />
                {errors.site_gst && (
                  <div className="error-message font-size-text">
                    {errors.site_gst}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Deduction Amount">
                  Site Deduction Amount
                </label>
                <input
                  type="text"
                  title="GST Amount"
                  name="site_deduction_amount"
                  placeholder="Site Deduction Amount"
                  value={formData.site_deduction_amount}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_deduction_amount ? "is-invalid" : ""
                    }`}
                />
                {errors.site_deduction_amount && (
                  <div className="error-message font-size-text">
                    {errors.site_deduction_amount}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label
                  className="form-labels font-weight500 font-size-subheading"
                  title="Site Recommended Bill Amount"
                >
                  Site Recommended Bill Amount<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title="Site Recommended Bill Amount"
                  placeholder="Site Recommended Bill Amount"
                  name="site_recommended_bill_amount"
                  value={formData.site_recommended_bill_amount}
                  readOnly // Make this field read-only to prevent manual edits
                  className={`form-input-date ${errors.site_recommended_bill_amount ? "is-invalid" : ""
                    }`}
                />
                {errors.site_recommended_bill_amount && (
                  <div className="error-message font-size-text">
                    {errors.site_recommended_bill_amount}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Recommended Percentage Amount">
                  Site Recommended Percentage Amount
                </label>
                <input
                  type="number"
                  title=" Site Recommended Percentage Amount"
                  name="site_percentage_amt"
                  placeholder=" Site Recommended Percentage Amount"
                  // readOnly
                  value={formData.site_percentage_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_percentage_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.site_percentage_amt && (
                  <div className="error-message font-size-text">
                    {errors.site_percentage_amt}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Recommended Percentage">
                  Site Recommended Percentage<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title=" Site Recommended Percentage"
                  name="site_recommended_percentage"
                  placeholder=" Site Recommended Percentage"
                  readOnly
                  value={formData.site_recommended_percentage}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_recommended_percentage ? "is-invalid" : ""
                    }`}
                />
                {errors.site_recommended_percentage && (
                  <div className="error-message font-size-text">
                    {errors.site_recommended_percentage}
                  </div>
                )}
              </div>


              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Released withheld Amount">
                  Site Released withheld Amount
                </label>
                <input
                  type="number"
                  title="Site Released Withheld Amount"
                  min={0}
                  name="site_released_withheld_amt"
                  placeholder="Site released withheld Amount"
                  value={formData.site_released_withheld_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_released_withheld_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.site_released_withheld_amt && (
                  <div className="error-message font-size-text">
                    {errors.site_released_withheld_amt}
                  </div>
                )}
              </div>



              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Attachment">
                  Site Attachment{" "}
                </label>
                <input
                  type="file"
                  title="Site Attachment"
                  name="site_attachment"
                  placeholder="Site Attachment"
                  onChange={handleFileChange}
                  className={`form-input-date ${errors.site_attachment ? "is-invalid" : ""}`}
                />
                {errors.site_attachment && (
                  <div className="error-message font-size-text">
                    {errors.site_attachment}
                  </div>
                )}
              </div>


              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Rermark">
                  {" "}
                  Site Remark
                </label>
                <textarea
                  type="text"
                  name="site_remark"
                  title="Site Remarks"
                  placeholder="Site Remark"
                  value={formData.site_remark}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_remark ? "is-invalid" : ""
                    }`}
                />
                {errors.site_remark && (
                  <div className="error-message font-size-text">
                    {errors.site_remark}
                  </div>
                )}
              </div>
            </div>




            {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? (
              ""
            ) : (
              <>
                <div className="flex-column" style={{ width: "100%", marginBottom: "10px" }}>
                  <div className="jd-heading-outer-flex " >
                    <h2 className="jd-heading-main font-weight600  font-size-heading"> HO Bill Details</h2>
                  </div>
                  <div>
                    <div className="jd-heading-bottom-bold" style={{ width: "160px" }}></div>
                    <div className="jd-heading-bottom-light"></div>
                  </div>
                </div>
                <div className="form-flex-wrap">
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="Recommend Bill Date">
                      HO Recomended Bill Date
                    </label>
                    <input
                      type="date"
                      name="recomended_bill_date"
                      title="Recommended Bill Date"
                      placeholder="Recomended Bill Date"
                      value={formData.recomended_bill_date}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.recomended_bill_date ? "is-invalid" : ""
                        }`}
                    />
                    {errors.recomended_bill_date && (
                      <div className="error-message font-size-text">
                        {errors.recomended_bill_date}
                      </div>
                    )}
                  </div>

                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="GST">
                      HO Work Done Amount
                    </label>
                    <input
                      type="text"
                      title="HO Work Done Amount "
                      name="ho_work_done_amt"
                      placeholder="HO Work Done Amount "
                      value={formData.ho_work_done_amt}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ho_work_done_amt ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ho_work_done_amt && (
                      <div className="error-message font-size-text">
                        {errors.ho_work_done_amt}
                      </div>
                    )}
                  </div>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Escalation Amount">
                      HO Escalation Amount
                    </label>
                    <input
                      type="text"
                      title="HO Escalation Amount"
                      name="ho_escalation"
                      placeholder="HO Escalation Amount"
                      value={formData.ho_escalation}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ho_escalation ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ho_escalation && (
                      <div className="error-message font-size-text">
                        {errors.ho_escalation}
                      </div>
                    )}
                  </div>

                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO GST Amount">
                      HO GST Amount
                    </label>
                    <input
                      type="text"
                      title=" HO GST Amount"
                      name="ho_gst"
                      placeholder=" HO GST Amount"
                      value={formData.ho_gst}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ho_gst ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ho_gst && (
                      <div className="error-message font-size-text">
                        {errors.ho_gst}
                      </div>
                    )}
                  </div>


                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Deduction Amount">
                      HO Deduction Amount
                    </label>
                    <input
                      type="number"
                      title="HO Deduction Amount"
                      min={0}
                      max={formData.submitted_bill_amount}
                      name="deduction_amount"
                      placeholder="HO Deduction Amount"
                      value={formData.deduction_amount}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.deduction_amount ? "is-invalid" : ""
                        }`}
                    />
                    {errors.deduction_amount && (
                      <div className="error-message font-size-text">
                        {errors.deduction_amount}
                      </div>
                    )}
                  </div>


                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Recommended Bill Amount">
                      HO Recommended Bill Amount<span className="required">(Read-Only)</span>
                    </label>
                    <input
                      title="HO Recommended Bill Amount"
                      type="number"
                      min={0}
                      max={formData.submitted_bill_amount}
                      name="recommended_bill_amount"
                      placeholder="HO Recommended Bill Amount"
                      value={formData.recommended_bill_amount}
                      readOnly
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.recommended_bill_amount ? "is-invalid" : ""
                        }`}
                    />
                    {errors.recommended_bill_amount && (
                      <div className="error-message font-size-text">
                        {errors.recommended_bill_amount}
                      </div>
                    )}
                  </div>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title=" HO Recommended Percentage Amount">
                      HO Recommended Percentage Amount
                    </label>
                    <input
                      title=" HO Recommended Percentage Amount"
                      type="number"
                      min={0}
                      max={formData.submitted_bill_amount}
                      name="ho_percentage_amt"
                      placeholder=" HO Recommended Percentage Amount"
                      value={formData.ho_percentage_amt}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ho_percentage_amt ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ho_percentage_amt && (
                      <div className="error-message font-size-text">
                        {errors.ho_percentage_amt}
                      </div>
                    )}
                  </div>


                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Recommended Bill Percentage">
                      HO Recommended Bill Percentage<span className="required">(Read-Only)</span>
                    </label>
                    <input
                      type="number"
                      title="HO Recommended Bill Percentage"
                      min={0}
                      max={100}
                      name="recommended_percentage"
                      placeholder="HO Recommended Bill Percentage"
                      value={formData.recommended_percentage}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.recommended_percentage ? "is-invalid" : ""
                        }`}
                      readOnly
                    />
                    {errors.recommended_percentage && (
                      <div className="error-message font-size-text">
                        {errors.recommended_percentage}
                      </div>
                    )}
                  </div>



                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title=" LD (Penalty) Amount">
                      LD (Penalty) Amount
                    </label>
                    <input
                      type="number"
                      min={0}
                      name="ld_amount"
                      title=" LD (Penalty) Amount"
                      placeholder="LD (Penalty) Amount"
                      value={formData.ld_amount}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ld_amount ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ld_amount && (
                      <div className="error-message font-size-text">
                        {errors.ld_amount}
                      </div>
                    )}
                  </div>

                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="Withheld Amount">
                      Withheld Amount
                    </label>
                    <input
                      type="number"
                      title="Withheld Amount"
                      min={0}
                      name="withheld_amt"
                      placeholder="Withheld Amount"
                      value={formData.withheld_amt}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.withheld_amt ? "is-invalid" : ""
                        }`}
                    />
                    {errors.withheld_amt && (
                      <div className="error-message font-size-text">
                        {errors.withheld_amt}
                      </div>
                    )}
                  </div>

                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="Release Withheld Amount">
                      Release Withheld Amount
                    </label>
                    <input
                      type="number"
                      title="Released Withheld Amount"
                      min={0}
                      name="released_withheld_amt"
                      placeholder="Release withheld Amount"
                      value={formData.released_withheld_amt}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.released_withheld_amt ? "is-invalid" : ""
                        }`}
                    />
                    {errors.released_withheld_amt && (
                      <div className="error-message font-size-text">
                        {errors.released_withheld_amt}
                      </div>
                    )}
                  </div>




                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Attachment">
                      HO Attachment{" "}
                    </label>
                    <input
                      type="file"
                      title="HO Attachment"
                      name="ho_attachment"
                      placeholder="HO Attachment"
                      onChange={handleFileChange} // Use handleFileChange for file input
                      className={`form-input-date ${errors.ho_attachment ? "is-invalid" : ""}`}
                    />
                    {errors.ho_attachment && (
                      <div className="error-message font-size-text">
                        {errors.ho_attachment}
                      </div>
                    )}
                  </div>





                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="Recommended Letter">
                      Recommended Letter
                    </label>
                    <select
                      name="recomended_letter_num"
                      title="Recommended Letter"
                      value={formData.recomended_letter_num}
                      onChange={handleInputChange}
                      className={`form-input-date`}
                    >
                      <option value="">
                        Select an existing letter (Optional)
                      </option>
                      {trackRecordList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.letter_num}
                        </option>
                      ))}
                    </select>
                  </div>

                  {formData.recomended_letter_num ? (
                    ""
                  ) : (
                    <div
                      className="flex-row "
                      style={{
                        width: "23.5vw",
                        justifyContent: "space-between",
                        marginTop: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>OR</div>
                      </div>
                      <div className="button-models">
                        <AddTrackRecordDetails
                          projectId={project}
                          letterfor={formData.workperformedby}
                          lettertype="billing"
                          getProjects={getLetters}
                          title="Recomended Letter Details"
                          contractor={contractor}
                        />
                      </div>
                    </div>
                  )}


                </div>
              </>
            )}
          </div>
          <div className="button-models">
            <button
              className="model-button font-weight500 model-button-leave font-size-heading"
              onClick={handleFormSubmit}
              disabled={loading}
            >
              {loading ? "Adding..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateProjectBill = ({ project, getProjects, contractor, i }) => {
  const [show, setShow] = useState(false);

  // const handleShow = () => {
  //   setShow(true)
  //   setFormData(i)
  //   getLetters()
  //   getContractors()
  // }

  const handleShow = () => {
    setShow(true);
    setFormData(i)

    getLetters();

  };
  const [formData, setFormData] = useState({
    project: project ? project : "",
    date: null,
    bill_type: "",
    contractor_name: "",
    work_done_amt: "",
    bill_num: "",
    bill_period_start: null,
    bill_period_end: null,
    billing_date: null,
    bill_catagory: "",
    workperformedby: "contractor",
    site_percentage_amt: "",
    recommended_bill_amount: "",
    recommended_percentage: "",
    recomended_bill_date: null,
    ho_percentage_amt: "",

    submitted_bill_amount: "",
    submitted_bill_date: null,
    submitted_percentage: "",
    percentage_amt: "",

    submitted_letter_num: "",
    recomended_letter_num: "",

    deduction_amount: "",
    ld_amount: "",
    withheld_amt: "",
    released_withheld_amt: "",
    remark: "",

    site_recomended_bill_date: null,
    site_recommended_bill_amount: "",
    site_recommended_percentage: "",
    site_ld_amount: "",
    site_withheld_amt: "",
    site_released_withheld_amt: "",
    site_deduction_amount: "",
    site_remark: "",
    site_attachment: "",
    // ho_attachment: "",
    escalation: "",
    gst: "",
    site_escalation: "",
    site_gst: "",
    ho_escalation: "",
    ho_gst: "",
    contractor_deduction_amt: "",
    ho_work_done_amt: "",
    site_work_done_amt: "",
    contractor: contractor


  });





  const { deduction_amount, submitted_bill_amount, recommended_bill_amount, site_deduction_amount, site_recommended_bill_amount } =
    formData;

  const updaterecommendedPercentage = submitted_bill_amount
    ? (
      (recommended_bill_amount /
        (submitted_bill_amount - (deduction_amount || 0))) *
      100
    ).toFixed(2)
    : 0;

  const updatesiterecommendedPercentage = submitted_bill_amount
    ? (
      (site_recommended_bill_amount /
        (submitted_bill_amount - (site_deduction_amount || 0))) *
      100
    ).toFixed(2)
    : 0;


  const [trackRecordList, setTrackRecordList] = useState([]);



  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const getLetters = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/contractor/billing/`
      );
      setTrackRecordList(res.data);
      console.log("Update Letter");
      console.log(res.data);
    } catch (err) {
      toast.error("Error fetching letters.");
    }
  };


  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "bill_type",
      "bill_num",
      "bill_period_start",
      "bill_period_end",
      "submitted_bill_date",
      "bill_catagory",
      "submitted_bill_amount",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      let updatedFormData = { ...prevFormData, [name]: value };

      // Check for recommended_bill_amount and deduction_amount constraints
      if (name === "recommended_bill_amount" || name === "deduction_amount") {
        const maxLimit = prevFormData.submitted_bill_amount; // Set the max limit dynamically

        const { success, error } = inputMaxLimit(name, value, maxLimit);
        if (!success) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
          }));
          return prevFormData;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
        }
      }

      // Dynamically calculate submitted_bill_amount
      if ([
        // contractor

        "gst",
        "escalation",
        "work_done_amt",
        "contractor_deduction_amt",
        "percentage_amt",
        "submitted_percentage",

        // site 

        "site_work_done_amt",
        "site_escalation",
        "site_gst",
        "site_deduction_amount",
        "site_recommended_bill_amount",
        "site_percentage_amt",
        "site_recommended_percentage",

        // ho

        "ho_gst",
        "ho_escalation",
        "ho_work_done_amt",
        "deduction_amount",
        "recommended_bill_amount",
        "recommended_percentage",
        "ho_percentage_amt"

      ].includes(name)) {

        // contractor

        const gst = parseFloat(updatedFormData.gst || 0);
        const escalation = parseFloat(updatedFormData.escalation || 0);
        const workDoneAmount = parseFloat(updatedFormData.work_done_amt || 0);
        const deductionSubmittedAmount = parseFloat(updatedFormData.contractor_deduction_amt || 0);
        const submittedPercentageAmount = parseFloat(updatedFormData.percentage_amt || 0);

        // site 

        const site_gst = parseFloat(updatedFormData.site_gst || 0);
        const site_escalation = parseFloat(updatedFormData.site_escalation || 0);
        const site_work_done_amt = parseFloat(updatedFormData.site_work_done_amt || 0);
        const site_deduction_amount = parseFloat(updatedFormData.site_deduction_amount || 0);
        const site_percentage_amt = parseFloat(updatedFormData.site_percentage_amt || 0);

        // ho

        const ho_gst = parseFloat(updatedFormData.ho_gst || 0);
        const ho_escalation = parseFloat(updatedFormData.ho_escalation || 0);
        const ho_work_done_amt = parseFloat(updatedFormData.ho_work_done_amt || 0);
        const deduction_amount = parseFloat(updatedFormData.deduction_amount || 0);
        const ho_percentage_amt = parseFloat(updatedFormData.ho_percentage_amt || 0);

        updatedFormData = {
          ...updatedFormData,
          // contractor

          submitted_bill_amount: ((workDoneAmount + gst + escalation) - deductionSubmittedAmount).toFixed(), // Ensures precision
          submitted_percentage: ((submittedPercentageAmount / submitted_bill_amount) * 100).toFixed(2),

          // site

          site_recommended_bill_amount: ((site_gst + site_escalation + site_work_done_amt) - site_deduction_amount).toFixed(), // Ensures precision
          site_recommended_percentage: ((site_percentage_amt / site_recommended_bill_amount) * 100).toFixed(2),


          // ho

          recommended_bill_amount: ((ho_work_done_amt + ho_gst + ho_escalation) - deduction_amount).toFixed(), // Ensures precision
          recommended_percentage: ((ho_percentage_amt / recommended_bill_amount) * 100).toFixed(2),
        };
      }

      return updatedFormData;
    });
  };



  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      // Update the form data with the selected file
      setFormData({ ...formData, [name]: file });
      // Update the file name for display

    }
  };






  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedfromdata = {
      ...formData,


    };

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {

        const formDataToSend = new FormData();

        // Append general form fields excluding site_attachment and ho_attachment if they are links
        Object.keys(updatedfromdata).forEach((key) => {
          if (updatedfromdata[key]) {
            // Exclude site_attachment if it's a link
            if (
              (key === "site_attachment" && /^https?:\/\//.test(updatedfromdata[key])) ||
              (key === "ho_attachment" && /^https?:\/\//.test(updatedfromdata[key]))
            ) {
              return; // Skip appending these fields
            }

            formDataToSend.append(key, updatedfromdata[key]);
          }
        });
        console.log(formDataToSend)

        const res = await axiosInstance.put(
          `${BASE_URL}/siteproject/billingdetailfield/${i.id}/`,
          formDataToSend
        );

        if (res.status === 200) {
          await getProjects();
          toast.dismiss(loadingToastId);
          toast.success("Billing detail added successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add bill detail!");
        }
      } catch (err) {
        toast.dismiss(loadingToastId);
        toast.error("Error processing your request.");
      } finally {
        setLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   getLetters();
  //   getContractors();
  // }, [formData.workperformedby, project]);

  return (
    <>
      <button
        title="Update Bill"
        className="model-edit-button"
        onClick={handleShow}
      >
        <UpdatePencil />
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Bill Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <form onSubmit={handleFormSubmit}>
            <div className="form-flex-wrap">
              {/* Bill Details */}

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Type">
                  Bill Type<span className="required">*</span>
                </label>
                <select
                  name="bill_type"
                  title="Bill Type"
                  value={formData.bill_type}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_type ? "is-invalid" : ""
                    }`}
                >
                  <option value="" title="Select a Type">Select a Type</option>
                  <option value="sps">SPS</option>
                  <option value="ipc">IPC</option>
                  <option value="milestonebill">Milestone Bill</option>

                  <option value="maintenance">Maintenance</option>
                  <option value="rs">RA</option>

                  <option value="other">Other</option>
                </select>
                {errors.bill_type && (
                  <div className="error-message font-size-text">
                    {errors.bill_type}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Category">
                  Bill Category<span className="required">*</span>
                </label>
                <select
                  title="Bill Category"
                  name="bill_catagory"
                  value={formData.bill_catagory}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_catagory ? "is-invalid" : ""
                    }`}
                >
                  <option value="">Select a Category</option>
                  <option value="bills">Bills</option>
                  <option value="balancebills">Balance Bills</option>
                </select>
                {errors.bill_catagory && (
                  <div className="error-message font-size-text">
                    {errors.bill_catagory}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Number">
                  Bill Num<span className="required">*</span>
                </label>
                <input
                  type="text"
                  title="Bill Number"
                  name="bill_num"
                  placeholder="Bill Num"
                  value={formData.bill_num}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_num ? "is-invalid" : ""
                    }`}
                />
                {errors.bill_num && (
                  <div className="error-message font-size-text">
                    {errors.bill_num}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Period Start">
                  Bill Period Start<span className="required">*</span>
                </label>
                <input
                  type="date"
                  name="bill_period_start"
                  title="Bill Period Start"
                  value={formData.bill_period_start}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_period_start ? "is-invalid" : ""
                    }`}
                />
                {errors.bill_period_start && (
                  <div className="error-message font-size-text">
                    {errors.bill_period_start}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Bill Period End">
                  Bill Period End<span className="required">*</span>
                </label>
                <input
                  type="date"
                  name="bill_period_end"
                  title="Bill Period End"
                  value={formData.bill_period_end}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.bill_period_end ? "is-invalid" : ""
                    }`}
                  min={formData.bill_period_start}
                />
                {errors.bill_period_end && (
                  <div className="error-message font-size-text">
                    {errors.bill_period_end}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Submitted Bill Date">
                  Submitted Bill Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  title="Submitted Bill Date"
                  name="submitted_bill_date"
                  value={formData.submitted_bill_date}
                  min={formData.bill_period_end}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.submitted_bill_date ? "is-invalid" : ""
                    }`}
                />
                {errors.submitted_bill_date && (
                  <div className="error-message font-size-text">
                    {errors.submitted_bill_date}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Work Done Amount
                </label>
                <input
                  type="text"
                  title="Work Done Amount "
                  name="work_done_amt"
                  placeholder="Work Done Amount "
                  value={formData.work_done_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.work_done_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.work_done_amt && (
                  <div className="error-message font-size-text">
                    {errors.gst}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Escalation">
                  Escalation Amount
                </label>
                <input
                  type="text"
                  title="Escalation Amount"
                  name="escalation"
                  placeholder="Escalation Amount"
                  value={formData.escalation}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.escalation ? "is-invalid" : ""
                    }`}
                />
                {errors.escalation && (
                  <div className="error-message font-size-text">
                    {errors.escalation}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  GST Amount
                </label>
                <input
                  type="text"
                  title="GST Amount"
                  name="gst"
                  placeholder="GST Amount"
                  value={formData.gst}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.gst ? "is-invalid" : ""
                    }`}
                />
                {errors.gst && (
                  <div className="error-message font-size-text">
                    {errors.gst}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Deduction Submitted Amount
                </label>
                <input
                  type="text"
                  title="GST Amount"
                  name="contractor_deduction_amt"
                  placeholder="Deduction Submitted Amount"
                  value={formData.contractor_deduction_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.contractor_deduction_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.contractor_deduction_amt && (
                  <div className="error-message font-size-text">
                    {errors.contractor_deduction_amt}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label
                  className="form-labels font-weight500 font-size-subheading"
                  title="Submitted Bill Amount"
                >
                  Submitted Bill Amount<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title="Submitted Bill Amount"
                  placeholder="1,00,000"
                  name="submitted_bill_amount"
                  value={formData.submitted_bill_amount}
                  readOnly // Make this field read-only to prevent manual edits
                  className={`form-input-date ${errors.submitted_bill_amount ? "is-invalid" : ""
                    }`}
                />
                {errors.submitted_bill_amount && (
                  <div className="error-message font-size-text">
                    {errors.submitted_bill_amount}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Submitted Percentage Amount
                </label>
                <input
                  type="number"
                  title=" Submitted Percentage Amount"
                  name="percentage_amt"
                  placeholder=" Submitted Percentage Amount"
                  // readOnly
                  value={formData.percentage_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.percentage_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.percentage_amt && (
                  <div className="error-message font-size-text">
                    {errors.percentage_amt}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Submitted Percentage<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title=" Submitted Percentage"
                  name="submitted_percentage"
                  placeholder=" Submitted Percentage"
                  readOnly
                  value={formData.submitted_percentage}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.submitted_percentage ? "is-invalid" : ""
                    }`}
                />
                {errors.submitted_percentage && (
                  <div className="error-message font-size-text">
                    {errors.submitted_percentage}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Submitted Letter">
                  Submitted Letter
                </label>
                <select
                  name="submitted_letter_num"
                  title="Submitted Letter"
                  value={formData.submitted_letter_num}
                  onChange={handleInputChange}
                  className={`form-input-date`}
                >
                  <option value="">Select an existing letter</option>
                  {trackRecordList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.letter_num}
                    </option>
                  ))}
                </select>
                {errors.submitted_letter_num && (
                  <div className="error-message font-size-text">
                    {errors.submitted_letter_num}
                  </div>
                )}
              </div>

              <div className="flex-column">
                {formData.submitted_letter_num ? (
                  ""
                ) : (
                  <div
                    className="flex-row "
                    style={{
                      width: "23.5vw",
                      justifyContent: "space-between",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      <div>OR</div>
                    </div>
                    <div className="button-models">
                      <AddTrackRecordDetails
                        projectId={project}
                        letterfor="contractor"
                        lettertype="billing"
                        getLetters={getLetters}
                        title="Submit Letter Details"
                      />
                    </div>
                  </div>
                )}
              </div>







              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Recomended Bill Date">
                  Site Recomended Bill Date
                </label>
                <input
                  type="date"
                  name="site_recomended_bill_date"
                  title="Site Recomended Bill Date"
                  value={formData.site_recomended_bill_date}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_recomended_bill_date ? "is-invalid" : ""
                    }`}
                  min={formData.site_recomended_bill_date}
                />
                {errors.site_recomended_bill_date && (
                  <div className="error-message font-size-text">
                    {errors.site_recomended_bill_date}
                  </div>
                )}
              </div>




              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site LD Amount">
                  Site LD Amount
                </label>
                <input
                  type="number"
                  title="Site LD Amount"
                  min={0}
                  name="site_ld_amount"
                  placeholder="Site LD Amount"
                  value={formData.site_ld_amount}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_ld_amount ? "is-invalid" : ""
                    }`}
                />
                {errors.site_ld_amount && (
                  <div className="error-message font-size-text">
                    {errors.site_ld_amount}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Withheld Amount">
                  Site withheld Amount
                </label>
                <input
                  type="number"
                  title="Site Withheld Amount"
                  min={0}
                  name="site_withheld_amt"
                  placeholder="Site withheld Amount"
                  value={formData.site_withheld_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_withheld_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.site_withheld_amt && (
                  <div className="error-message font-size-text">
                    {errors.site_withheld_amt}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="GST">
                  Site Work Done Amount
                </label>
                <input
                  type="text"
                  title="Site Work Done Amount "
                  name="site_work_done_amt"
                  placeholder="Work Done Amount "
                  value={formData.site_work_done_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_work_done_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.site_work_done_amt && (
                  <div className="error-message font-size-text">
                    {errors.site_work_done_amt}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Escalation">
                  Site Escalation Amount
                </label>
                <input
                  type="text"
                  title="Site Escalation Amount"
                  name="site_escalation"
                  placeholder="Site Escalation Amount"
                  value={formData.site_escalation}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_escalation ? "is-invalid" : ""
                    }`}
                />
                {errors.site_escalation && (
                  <div className="error-message font-size-text">
                    {errors.site_escalation}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title=" Site GST">
                  Site GST Amount
                </label>
                <input
                  type="text"
                  title="Site GST Amount"
                  name="site_gst"
                  placeholder="GST Amount"
                  value={formData.site_gst}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_gst ? "is-invalid" : ""
                    }`}
                />
                {errors.site_gst && (
                  <div className="error-message font-size-text">
                    {errors.site_gst}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Deduction Amount">
                  Site Deduction Amount
                </label>
                <input
                  type="text"
                  title="GST Amount"
                  name="site_deduction_amount"
                  placeholder="Site Deduction Amount"
                  value={formData.site_deduction_amount}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_deduction_amount ? "is-invalid" : ""
                    }`}
                />
                {errors.site_deduction_amount && (
                  <div className="error-message font-size-text">
                    {errors.site_deduction_amount}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label
                  className="form-labels font-weight500 font-size-subheading"
                  title="Site Recommended Bill Amount"
                >
                  Site Recommended Bill Amount<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title="Site Recommended Bill Amount"
                  placeholder="Site Recommended Bill Amount"
                  name="site_recommended_bill_amount"
                  value={formData.site_recommended_bill_amount}
                  readOnly // Make this field read-only to prevent manual edits
                  className={`form-input-date ${errors.site_recommended_bill_amount ? "is-invalid" : ""
                    }`}
                />
                {errors.site_recommended_bill_amount && (
                  <div className="error-message font-size-text">
                    {errors.site_recommended_bill_amount}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Recommended Percentage Amount">
                  Site Recommended Percentage Amount
                </label>
                <input
                  type="number"
                  title=" Site Recommended Percentage Amount"
                  name="site_percentage_amt"
                  placeholder=" Site Recommended Percentage Amount"
                  // readOnly
                  value={formData.site_percentage_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_percentage_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.site_percentage_amt && (
                  <div className="error-message font-size-text">
                    {errors.site_percentage_amt}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Recommended Percentage">
                  Site Recommended Percentage<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title=" Site Recommended Percentage"
                  name="site_recommended_percentage"
                  placeholder=" Site Recommended Percentage"
                  readOnly
                  value={formData.site_recommended_percentage}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_recommended_percentage ? "is-invalid" : ""
                    }`}
                />
                {errors.site_recommended_percentage && (
                  <div className="error-message font-size-text">
                    {errors.site_recommended_percentage}
                  </div>
                )}
              </div>


              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Released withheld Amount">
                  Site Released withheld Amount
                </label>
                <input
                  type="number"
                  title="Site Released Withheld Amount"
                  min={0}
                  name="site_released_withheld_amt"
                  placeholder="Site released withheld Amount"
                  value={formData.site_released_withheld_amt}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_released_withheld_amt ? "is-invalid" : ""
                    }`}
                />
                {errors.site_released_withheld_amt && (
                  <div className="error-message font-size-text">
                    {errors.site_released_withheld_amt}
                  </div>
                )}
              </div>



              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Attachment">
                  Site Attachment{" "}
                </label>
                <input
                  type="file"
                  title="Site Attachment"
                  min={0}
                  name="site_attachment"
                  placeholder="Site Attachment"
                  value={formData.site_attachment}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_attachment ? "is-invalid" : ""
                    }`}
                />
                {errors.site_attachment && (
                  <div className="error-message font-size-text">
                    {errors.site_attachment}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Site Rermark">
                  {" "}
                  Site Remark
                </label>
                <textarea
                  type="text"
                  name="site_remark"
                  title="Site Remarks"
                  placeholder="Site Remark"
                  value={formData.site_remark}
                  onChange={handleInputChange}
                  className={`form-input-date ${errors.site_remark ? "is-invalid" : ""
                    }`}
                />
                {errors.site_remark && (
                  <div className="error-message font-size-text">
                    {errors.site_remark}
                  </div>
                )}
              </div>




              {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? (
                ""
              ) : (
                <>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="Recommend Bill Date">
                      Recomended Bill Date
                    </label>
                    <input
                      type="date"
                      name="recomended_bill_date"
                      title="Recommended Bill Date"
                      placeholder="Recomended Bill Date"
                      value={formData.recomended_bill_date}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.recomended_bill_date ? "is-invalid" : ""
                        }`}
                    />
                    {errors.recomended_bill_date && (
                      <div className="error-message font-size-text">
                        {errors.recomended_bill_date}
                      </div>
                    )}
                  </div>

                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="GST">
                      HO Work Done Amount
                    </label>
                    <input
                      type="text"
                      title="HO Work Done Amount "
                      name="ho_work_done_amt"
                      placeholder="HO Work Done Amount "
                      value={formData.ho_work_done_amt}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ho_work_done_amt ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ho_work_done_amt && (
                      <div className="error-message font-size-text">
                        {errors.ho_work_done_amt}
                      </div>
                    )}
                  </div>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Escalation Amount">
                      HO Escalation Amount
                    </label>
                    <input
                      type="text"
                      title="HO Escalation Amount"
                      name="ho_escalation"
                      placeholder="HO Escalation Amount"
                      value={formData.ho_escalation}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ho_escalation ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ho_escalation && (
                      <div className="error-message font-size-text">
                        {errors.ho_escalation}
                      </div>
                    )}
                  </div>

                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO GST Amount">
                      HO GST Amount
                    </label>
                    <input
                      type="text"
                      title=" HO GST Amount"
                      name="ho_gst"
                      placeholder=" HO GST Amount"
                      value={formData.ho_gst}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ho_gst ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ho_gst && (
                      <div className="error-message font-size-text">
                        {errors.ho_gst}
                      </div>
                    )}
                  </div>


                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Deduction Amount">
                      HO Deduction Amount
                    </label>
                    <input
                      type="number"
                      title="HO Deduction Amount"
                      min={0}
                      max={formData.submitted_bill_amount}
                      name="deduction_amount"
                      placeholder="HO Deduction Amount"
                      value={formData.deduction_amount}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.deduction_amount ? "is-invalid" : ""
                        }`}
                    />
                    {errors.deduction_amount && (
                      <div className="error-message font-size-text">
                        {errors.deduction_amount}
                      </div>
                    )}
                  </div>


                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Recommended Bill Amount">
                      HO Recommended Bill Amount<span className="required">(Read-Only)</span>
                    </label>
                    <input
                      title="HO Recommended Bill Amount"
                      type="number"
                      min={0}
                      max={formData.submitted_bill_amount}
                      name="recommended_bill_amount"
                      placeholder="HO Recommended Bill Amount"
                      value={formData.recommended_bill_amount}
                      readOnly
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.recommended_bill_amount ? "is-invalid" : ""
                        }`}
                    />
                    {errors.recommended_bill_amount && (
                      <div className="error-message font-size-text">
                        {errors.recommended_bill_amount}
                      </div>
                    )}
                  </div>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title=" HO Recommended Percentage Amount">
                      HO Recommended Percentage Amount
                    </label>
                    <input
                      title=" HO Recommended Percentage Amount"
                      type="number"
                      min={0}
                      max={formData.submitted_bill_amount}
                      name="ho_percentage_amt"
                      placeholder=" HO Recommended Percentage Amount"
                      value={formData.ho_percentage_amt}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ho_percentage_amt ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ho_percentage_amt && (
                      <div className="error-message font-size-text">
                        {errors.ho_percentage_amt}
                      </div>
                    )}
                  </div>


                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="HO Recommended Bill Percentage">
                      HO Recommended Bill Percentage<span className="required">(Read-Only)</span>
                    </label>
                    <input
                      type="number"
                      title="HO Recommended Bill Percentage"
                      min={0}
                      max={100}
                      name="recommended_percentage"
                      placeholder="HO Recommended Bill Percentage"
                      value={formData.recommended_percentage}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.recommended_percentage ? "is-invalid" : ""
                        }`}
                      readOnly
                    />
                    {errors.recommended_percentage && (
                      <div className="error-message font-size-text">
                        {errors.recommended_percentage}
                      </div>
                    )}
                  </div>



                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title=" LD (Penalty) Amount">
                      LD (Penalty) Amount
                    </label>
                    <input
                      type="number"
                      min={0}
                      name="ld_amount"
                      title=" LD (Penalty) Amount"
                      placeholder="LD (Penalty) Amount"
                      value={formData.ld_amount}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.ld_amount ? "is-invalid" : ""
                        }`}
                    />
                    {errors.ld_amount && (
                      <div className="error-message font-size-text">
                        {errors.ld_amount}
                      </div>
                    )}
                  </div>

                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="Withheld Amount">
                      Withheld Amount
                    </label>
                    <input
                      type="number"
                      title="Withheld Amount"
                      min={0}
                      name="withheld_amt"
                      placeholder="Withheld Amount"
                      value={formData.withheld_amt}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.withheld_amt ? "is-invalid" : ""
                        }`}
                    />
                    {errors.withheld_amt && (
                      <div className="error-message font-size-text">
                        {errors.withheld_amt}
                      </div>
                    )}
                  </div>

                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="Release Withheld Amount">
                      Release Withheld Amount
                    </label>
                    <input
                      type="number"
                      title="Released Withheld Amount"
                      min={0}
                      name="released_withheld_amt"
                      placeholder="Release withheld Amount"
                      value={formData.released_withheld_amt}
                      onChange={handleInputChange}
                      className={`form-input-date ${errors.released_withheld_amt ? "is-invalid" : ""
                        }`}
                    />
                    {errors.released_withheld_amt && (
                      <div className="error-message font-size-text">
                        {errors.released_withheld_amt}
                      </div>
                    )}
                  </div>




                  {/* <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" title="HO Attachment">
                    HO Attachment{" "}
                  </label>
                  <input
                    type="file"
                    title="HO Attachement"
                    min={0}
                    name="ho_attachment "
                    placeholder="HO Attachment"
                    value={formData.ho_attachment}
                    onChange={handleInputChange}
                    className={`form-input-date ${errors.ho_attachment ? "is-invalid" : ""
                      }`}
                  />
                  {errors.ho_attachment && (
                    <div className="error-message font-size-text">
                      {errors.ho_attachment}
                    </div>
                  )}
                </div> */}




                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading" title="Recommended Letter">
                      Recommended Letter
                    </label>
                    <select
                      name="recomended_letter_num"
                      title="Recommended Letter"
                      value={formData.recomended_letter_num}
                      onChange={handleInputChange}
                      className={`form-input-date`}
                    >
                      <option value="">
                        Select an existing letter (Optional)
                      </option>
                      {trackRecordList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.letter_num}
                        </option>
                      ))}
                    </select>
                  </div>

                  {formData.recomended_letter_num ? (
                    ""
                  ) : (
                    <div
                      className="flex-row "
                      style={{
                        width: "23.5vw",
                        justifyContent: "space-between",
                        marginTop: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>OR</div>
                      </div>
                      <div className="button-models">
                        <AddTrackRecordDetails
                          projectId={project}
                          letterfor={formData.workperformedby}
                          lettertype="billing"
                          getLetters={getLetters}
                          title="Recomended Letter Details"
                        />
                      </div>
                    </div>
                  )}


                </>
              )}
            </div>
            <div className="button-models">
              <button
                className="model-button font-weight500 model-button-leave font-size-heading"
                type="submit"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};


const DeleteBillingDetails = ({ i, getbillingDetail }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const ress = await axios.delete(
        `${BASE_URL}/siteproject/billingdetailfield/${i.id}/`
      );
      if (ress.status === 200) {
        await getbillingDetail();
        setshow(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Bill {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Bill {i.title}
            <div className="button-models">
              <button
                className="model-button   font-weight500    model-button-cancel"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};


const AddBOQInvoice = ({ project, getInvoice }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setFormData({
      project: "",
      invoice_number: "",
      month: "",
      year: "",
    });
  };

  const [formData, setFormData] = useState({
    project: "",
    invoice_number: "",
    month: "",
    year: "",
  });

  const [projectList, setProjectList] = useState([]);

  const getProject = async () => {
    try {
      const response = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            // for general(admin)
            : `${BASE_URL}/siteproject/projectfilterbysubcompany/null/`
      );
      const sortedList = sortProjects(response.data, "site_prcode");
      setProjectList(sortedList);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    getProject();
  }, []);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["invoice_number", "month", "year"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value.trim()) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(
          `${BASE_URL}/siteproject/consultant-invoice/`,
          formData
        );

        if (res.status === 200) {
          // await getInvoice()
          handleClose();
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const monthOptions = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

  return (
    <>
      <button title="Add Invoice" className="upload-svg" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "}
        Invoice
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <form>
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading" title="Invoice Number">
                    Invoice Number<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="invoice_number"
                    title="Invoice Number"
                    placeholder="Invoice Number"
                    value={formData.invoice_number}
                    onChange={handleInputChange}
                    className={`form-input ${errors.invoice_number ? "is-invalid" : ""
                      }`}
                  />
                  {errors.invoice_number && (
                    <div className="error-message font-size-text">
                      {errors.invoice_number}
                    </div>
                  )}
                </div>

                <div title="Project" className="flex-column form-group-selectt">
                  <label
                    htmlFor="project"
                    className="form-labels  font-weight500    font-size-subheading" title="Project"
                  >
                    Project
                  </label>
                  <br />
                  <select
                    type="text"
                    id="project"
                    title="Project"
                    name="project"
                    placeholder="project"
                    value={formData.project}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.project
                      ? "error"
                      : inputState.project
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="null" title="Select a Project">Select a Project</option>
                    {projectList?.map((i, index) => (
                      <option key={index} value={i.id}>
                        {i.site_prcode}-{i.project_short_name}
                      </option>
                    ))}
                  </select>

                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.project && (
                    <span className="error-message font-size-text ">
                      {errors.project}
                    </span>
                  )}
                </div>
                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" title="Month">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    title="Month"
                    className={`form-input form-group-selection ${errors.month ? "is-invalid" : ""
                      }`}
                  >
                    <option value="" title="Select Month">Select month</option>
                    {monthOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.month && (
                    <div className="error-message font-size-text">
                      {errors.month}
                    </div>
                  )}
                </div>
                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" title="Year">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    name="year"
                    title="Year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.year ? "is-invalid" : ""
                      }`}
                  >
                    <option value="" title="Select Year">Select year</option>
                    {years.map((year) => (
                      <option key={year} value={year} title="Select Year">
                        {year}
                      </option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.year && (
                    <div className="error-message font-size-text">
                      {errors.year}
                    </div>
                  )}
                </div>

                <div className="button-models">
                  <button
                    className="model-button font-weight500 model-button-submit"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    {loading ? "Adding..." : "Add"}
                  </button>
                </div>
                <p className="error-message font-size-text">
                  {isSubmitted && Object.keys(errors).length > 0 && (
                    Object.keys(errors).length > 5 ? (
                      <h5 className="text-center">Please fill all mandatory fields!</h5>
                    ) : (

                      <h6 className="text-center">
                        {Object.keys(errors).map((field) =>
                          field.charAt(0).toUpperCase() + field.slice(1)
                        ).join(', ') + ' are required!'}
                      </h6>
                    )
                  )}
                </p>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );

};



// ? *************** Project Crud Models End ***************

const AddTrackRecordDetails = ({

  getProjects,
  projectList,
  projectId,
  contractor,
  contractorlist,
  lettertype,
  letterfor,
  title,
}) => {
  const [siteDepartmentList, setSiteDepartmentList] = useState([]);

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState(initialFormData());
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [trackRecordList, setTrackRecordList] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For upload progress
  const [contractorlists, setContractorLists] = useState([]);

  // console.log(contractorlists)

  function initialFormData() {
    return {
      project: projectId ? projectId : "",
      letter_num: "",
      reply_by: "",
      subject: "",
      letter_date: "",
      status: "pending",
      workperformedby: letterfor ? letterfor : "null",
      lettertype: lettertype ? lettertype : "null",
      letterExchange: "null",
      associated_letters: [],
      associated_letters_status: "pending",
      letterdepartment: "null",
      letter_from: "null",
      letter_to: "",
      documents: [{ document_name: "", document_file: "" }],
      contractor: contractor ? contractor : "",
      remarks: '',
    };
  }
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contractor: letterfor === "contractor" ? contractor : "",
    }));
  }, [contractor]);

  useEffect(() => {
    if (formData.project || formData.workperformedby) {
      fetchAssociatedLetters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project, formData.workperformedby, formData.lettertype]);

  const fetchAssociatedLetters = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letterrecordbclosestatus/${formData.project || 'null'
        }/${formData.workperformedby || 'null'}/${formData.lettertype || 'null'}/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error("Error fetching letters.", err);
    }
  };
  const [buffer, setBuffering] = useState(true); //buffering logic


  const getSiteDepartment = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/department/`);
      setSiteDepartmentList(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // Function to Fetch Contractor Details
  const getProjectContractorDetails = async () => {
    try {
      const contractordetails = await axios.get(
        `${BASE_URL}/siteproject/contractorbyproject/${formData.project}/`
      );
      setContractorLists(contractordetails.data);
    } catch (err) {
      // handleErrorToast(err);
    } finally {
    }
  };


  const handleShow = () => {
    setShow(true);
    if (projectId) {
      getProjectContractorDetails();
    }
    getSiteDepartment();

  };

  const handleClose = () => {
    setShow(false);
    setFormData(initialFormData());
    setErrors({});
    setInputState({});
    setFileNames([]);
  };
  const validateForm = () => {
    const requiredFields = [
      'letter_num',
      'project',
      'subject',
      'letter_date',
      'status',
      'lettertype',
      'workperformedby',
      'letterExchange',
      'letter_to',
      'letterdepartment',
      // 'letter_from',
    ];

    const newErrors = requiredFields.reduce((acc, field) => {
      if (
        !formData[field] ||
        formData[field] === "null" ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
      return acc;
    }, {});

    // Conditional validation for 'contractor' field
    if (formData.workperformedby === "contractor" && !formData.contractor) {
      newErrors["contractor"] = "Contractor Name is required!";
    }

    // Conditional validation for 'associated_letters_status'
    if (
      formData.associated_letters &&
      formData.associated_letters.length > 0 &&
      !formData.associated_letters_status
    ) {
      newErrors["associated_letters_status"] =
        "Status for associated letters is required!";
    }

    // Validate documents array
    formData.documents.forEach((doc, index) => {
      if (!doc.document_name || doc.document_name.trim() === "") {
        newErrors[`document_name_${index}`] = `Document Name is required for document ${index + 1}`;
      }
      if (!doc.document_file) {
        newErrors[`document_file_${index}`] = `Document File is required for document ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, options } = e.target;

    let newValue;
    // Handle multiple select inputs
    if (type === 'select-multiple') {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      newValue = value;
    }

    // Update input state for UI feedback
    setInputState((prevState) => ({
      ...prevState,
      [name]:
        newValue && (Array.isArray(newValue) ? newValue.length > 0 : newValue)
          ? "success"
          : "",
    }));

    // Update form data based on the input change
    setFormData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        [name]: newValue,


        ...(name === 'reply_by' && prevFormData.letterExchange === 'send' && {
          letter_from: value,
        }),
      };




      return updatedData;
    });

  };



  const handleDocInputChange = (index, e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      newDocuments[index] = {
        ...newDocuments[index],
        [name]: files ? files[0] : value, // Removed .trim()
      };

      if (files) {
        setFileNames((prevFileNames) => {
          const newFileNames = [...prevFileNames];
          newFileNames[index] = files[0]?.name || "";
          return newFileNames;
        });
      }

      return { ...prevFormData, documents: newDocuments };
    });
  };

  const handleAddDocuments = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: [
        ...prevFormData.documents,
        { document_name: "", document_file: null },
      ],
    }));
  };

  const handleRemoveDocument = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: prevFormData.documents.filter((_, i) => i !== index),
    }));
    setFileNames((prevFileNames) =>
      prevFileNames.filter((_, i) => i !== index)
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        const formDataToSend = new FormData();

        // Append general form fields
        Object.keys(formData).forEach((key) => {
          if (key !== "documents" && formData[key]) {
            if (key === "associated_letters") {
              formData[key].forEach((value) =>
                formDataToSend.append(key, value)
              );
            } else {
              formDataToSend.append(key, formData[key]);
            }
          }
        });

        // Append documents data
        formData.documents.forEach((doc, index) => {
          if (doc.document_file) {
            const customFile = createCustomFile(doc.document_file);
            formDataToSend.append(
              `documents[${index}][document_name]`,
              doc.document_name
            );
            formDataToSend.append(
              `documents[${index}][document_file]`,
              customFile
            );
          }
        });

        const res = await axiosInstance.post(
          `/siteproject/documentbulkwithletter/`,
          formDataToSend,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        );

        if (res.status === 200) {
          await getProjects(); // Refresh project list
          toast.success("Letter Documents uploaded successfully!");
          handleClose();
        } else {
          throw new Error("Document upload failed");
        }
      } catch (err) {
        toast.error("Error uploading documents");
        console.error("Error uploading documents", err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
        setUploadProgress(0);
      }
    }
  };

  const createCustomFile = (originalFile) => {
    const randomCode = generateRandomString();
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
    return new File([originalFile], customFileName, {
      type: originalFile.type,
    });
  };

  const generateRandomString = () => {
    const chars =
      "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from(
      { length: 8 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
  };

  useEffect(() => {
    if (formData.project) {
      getProjectContractorDetails();
    }
  }, [formData.project]); // Dependency array includes formData.project

  const options = trackRecordList
    .sort((a, b) => parseInt(a.letter_num) - parseInt(b.letter_num))
    .map((e) => ({
      value: e.id,
      label: `${e.letter_num} - ${e.letter_date}`,
    }));

  const handleChangecc = (selected) => {
    const associated_letters =
      selected.length > 0 ? selected.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      associated_letters,
    }));
  };


  return (
    <>
      <button
        title={`Add ${title} Details`}
        className="upload-svg equal-hieg"
        onClick={handleShow}
      >
        {/* Replace with your icon component */}
        <AddwithWhiteCircle /> {` ${title}`}
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>Add {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">

              {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? "" :

                ((projectId === 'null' || projectId === undefined || projectId === "") && (
                  <div className="form-group">
                    <RenderSelectField
                      title="Site Project"
                      name="project"
                      required={true}
                      options={projectList?.map((project) => ({
                        value: project.id,
                        label: `${project.site_prcode} - ${project.project_short_name}`,
                      }))}
                      placeholder="Select a Project"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.project && (
                      <div className="error-message col">
                        {errors.project}
                      </div>
                    )}
                  </div>
                ))
              }


              <div className="form-group">
                <RenderSelectField
                  title="Letter Exchange"
                  name="letterExchange"
                  required={true}
                  options={[
                    { value: "send", label: "Send" },
                    { value: "receive", label: "Received" },
                  ]}
                  placeholder="Select an Exchange Type"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letterExchange && (
                  <div className="error-message col">
                    {errors.letterExchange}
                  </div>
                )}
              </div>

              {formData.letterExchange === "send" && (
                <div className="form-group">
                  <RenderSelectField
                    title="Reply By"
                    name="reply_by"
                    required={true}
                    options={[
                      { value: "HO", label: "Head Office" },
                      { value: "SO", label: "Site Office" },
                    ]}
                    placeholder="Select a Reply By"
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.reply_by && (
                    <div className="error-message col">{errors.reply_by}</div>
                  )}
                </div>
              )}

              <div className="form-group">
                <RenderInputField
                  title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter No.`}
                  name="letter_num"
                  required={true}
                  placeholder="Letter Number"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_num && (
                  <div className="error-message col">{errors.letter_num}</div>
                )}
              </div>

              <div className="form-group">
                <RenderInputField
                  title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter Date`}
                  name="letter_date"
                  type="date"
                  required={true}
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_date && (
                  <div className="error-message col">{errors.letter_date}</div>
                )}
              </div>

              <div className="form-group">
                <RenderInputTextareaField
                  title="Subject"
                  name="subject"
                  placeholder="Enter subject here..."
                  rows={5}
                  cols={50}
                  formData={formData}
                  errors={errors}
                  required={true}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.subject && (
                  <div className="error-message col">{errors.subject}</div>
                )}
              </div>
              {lettertype ?
                <></>
                :
                <>
                  <div className="form-group">
                    <RenderSelectField
                      title="Letter Type"
                      name="lettertype"
                      required={true}
                      options={[
                        { value: "show-cause", label: "Show Cause" },
                        { value: "informative", label: "Informative" },
                        { value: "eot", label: "EOT" },
                        { value: "cos", label: "COS" },
                        { value: "billing", label: "Billing" },
                        { value: "design", label: "Design" },
                        { value: "ncr", label: "NCR" },

                        { value: "Design & Drawing", label: "Design & Drawing" },
                        { value: "P&P", label: "P&P" },
                        { value: "QAP", label: "QAP" },
                        { value: "Structure", label: "Structure" },
                        { value: "Q & ME", label: "Q & ME" },
                        { value: "BBS", label: "BBS" },
                        { value: "noc", label: "NOC" },
                        { value: "Road Safety", label: "Road Safety" },
                        { value: "Work Plan", label: "Work Plan" },
                        { value: "Drain", label: "Drain" },
                        { value: "Hydrology", label: "Hydrology" },
                        { value: "Survey", label: "Survey" },
                        { value: "Milestone", label: "Milestone" },
                        { value: "query", label: "Query" },
                        { value: "SiteProgress", label: "SiteProgress" },
                        { value: "other", label: "Others" }
                      ]}
                      placeholder="Select a Letter Type"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.lettertype && (
                      <div className="error-message col">{errors.lettertype}</div>
                    )}
                  </div></>
              }
              <div title="Status" className="flex-column form-group-selectt">
                <label
                  htmlFor="status"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Status<span className="required">*</span>
                </label>
                <br />
                <select id="status"
                  name="status"
                  placeholder="Status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""
                    }`}
                >
                  <option value="">Select a Status</option>
                  <option value="pending">Pending</option>
                  <option value="close">Close</option>

                </select>


                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>

                {/* <div> */}
                {/* {errors.status && (
                    <span className="error-message font-size-text ">
                      {errors.status}
                    </span>
                  )} */}
                {errors.status && (
                  <div className="error-message col">{errors.status}</div>
                )}
                {/* </div> */}
              </div>

              <div className="form-group">
                <RenderSelectField
                  title="Department"
                  name="letterdepartment"
                  required={true}
                  options={siteDepartmentList
                    .map((department) => ({
                      value: department.id,
                      label: `${department.title}`,
                    }))}
                  placeholder="Select a Department"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letterdepartment && (
                  <div className="error-message col">{errors.letterdepartment}</div>
                )}
              </div>

              {letterfor ? <></> :

                (
                  <div className="form-group">
                    <RenderSelectField
                      title="Letter Related To"
                      name="workperformedby"
                      required={true}
                      options={[
                        ...(formData.project &&
                          ((Array.isArray(contractorlist) &&
                            contractorlist.length > 0) ||
                            (Array.isArray(contractorlists) &&
                              contractorlists.length > 0))
                          ? [{ value: "contractor", label: "Contractor" }]
                          : []),
                        { value: "consultant", label: "Consultant" },
                      ]}
                      placeholder="Select Option"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.workperformedby && (
                      <div className="error-message col">
                        {errors.workperformedby}
                      </div>
                    )}
                  </div>
                )}

              {formData.workperformedby === 'contractor' && <div title=" Contractor Name" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Contractor Name<span className="required">*</span>
                </label>
                <br />
                <select
                  id="contractor"
                  name="contractor"
                  value={formData.contractor}
                  onChange={handleInputChange}
                  disabled={!!contractor}

                  className={`form-input form-group-selection ${errors.contractor ? "error" : inputState.contractor ? "success" : ""
                    }`}
                >
                  <option value="null">Select a Contractor</option>
                  {contractorlists?.sort((a, b) => a.name?.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                    <option value={i.id}>{i.name}</option>
                  </>))
                  }
                </select>
                {/* {formData.project !== "" ? "" :  */}
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {/* } */}
                <div>
                  {errors.project && (
                    <span className="error-message font-size-text ">{errors.project}</span>
                  )}
                </div>
              </div>
              }





              {formData.letterExchange === 'receive' &&
                <div className="form-group">
                  <RenderInputField
                    title="Letter From"
                    name="letter_from"
                    placeholder="Letter From"
                    max={100}
                    formData={formData}
                    required={true}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.letter_from && (
                    <div className="error-message col">
                      {errors.letter_from}
                    </div>
                  )}
                </div>}

              <div className="form-group">
                <RenderInputField
                  title="Letter To"
                  name="letter_to"
                  placeholder="Letter To"
                  max={100}
                  required={true}
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_to && (
                  <div className="error-message col">{errors.letter_to}</div>
                )}
              </div>
              <div className="form-group">
                <RenderInputField
                  title="Remarks"
                  name="remarks"
                  placeholder="Remarks"
                  max={100}
                  required={true}
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.remarks && (
                  <div className="error-message col">{errors.remarks}</div>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="names"
                  className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                >
                  Associated Letters
                </label>
                <Select
                  options={options}
                  isMulti
                  onChange={handleChangecc}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '33vw',
                      height: '50px',
                      borderRadius: '10px',
                      border: '1px solid #e3e3e3',
                      margin: '10px 0px 20px 0px',
                      color: '#4a4a4a',
                      backgroundColor: '#f7f8fa',
                      padding: '0px 10px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: '33vw',
                      borderRadius: '10px',
                      marginTop: '0',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                  }}
                />
                {errors.remarks && (
                  <span className="error-message font-size-text">
                    {errors.remarks}
                  </span>
                )}
              </div>

              {/* Show status options if associated letters are selected */}
              {formData.associated_letters &&
                formData.associated_letters.length > 0 && (
                  <>
                    <div className="form-column">
                      <RenderSelectField
                        title="Update Status of Associated Letters"
                        name="associated_letters_status"
                        required={true}
                        options={[
                          { value: "pending", label: "Pending" },
                          { value: "close", label: "Close" },
                        ]}
                        placeholder="Select Status"
                        formData={formData}
                        errors={errors}
                        inputState={inputState}
                        handleInputChange={handleInputChange}
                      />
                      {errors.associated_letters_status && (
                        <div className="error-message col">
                          {errors.associated_letters_status}
                        </div>
                      )}
                    </div>
                  </>
                )}
            </div>

            <DocumentSection
              formData={formData}
              handleDocInputChange={handleDocInputChange}
              handleAddDocuments={handleAddDocuments}
              handleRemoveDocument={handleRemoveDocument}
              errors={errors}
              fileNames={fileNames}
              inputState={inputState}
            />

            {/* Upload Progress Bar */}
            {uploadProgress > 0 && (
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
                <span>{uploadProgress}%</span>
              </div>
            )}

            <div className="button-models">
              <button
                onClick={handleFormSubmit}
                className="model-button font-weight500 model-button-submit"
                disabled={loading}
              >
                Submit
              </button>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};


const AddLetters = ({
  getProjects,
  projectList,
  projectId,
  toggleState,
  contractor,
  contractorlist,
  lettertype,
  letterfor,
}) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState(initialFormData());
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputState, setInputState] = useState({});
  const [trackRecordList, setTrackRecordList] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For upload progress
  const [contractorlists, setContractorLists] = useState([]);

  function initialFormData() {
    return {
      project: projectId || "",
      letter_num: "",
      reply_by: "null",
      subject: "",
      letter_date: "",
      status: "pending",
      workperformedby: letterfor,
      lettertype: lettertype,
      letterExchange: "",
      associated_letters: [], // Changed from 'associated_letter' to 'associated_letters' as an array
      associated_letters_status: "", // Updated field name
      letter_from: "",
      letter_to: "",
      contractor: contractor ? contractor : "",
      documents: [{ document_name: "", document_file: "" }],
    };
  }

  useEffect(() => {
    if (formData.project || formData.workperformedby) {
      fetchAssociatedLetters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project, formData.workperformedby, formData.lettertype]);

  const fetchAssociatedLetters = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letterrecordbclosestatus/${formData.project || 'null'
        }/${formData.workperformedby || 'null'}/${formData.lettertype || 'null'}/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error("Error fetching letters.", err);
    }
  };

  // Function to Fetch Contractor Details
  const getProjectContractorDetails = async () => {
    try {
      const contractordetails = await axios.get(
        `${BASE_URL}/siteproject/contractorbyproject/${formData.project}/`
      );
      setContractorLists(contractordetails.data);
    } catch (err) {
      // handleErrorToast(err);
    } finally {
    }
  };

  const handleShow = () => {
    setShow(true);
    if (projectId) {
      getProjectContractorDetails();
    }
  };

  const handleClose = () => {
    setShow(false);
    setFormData(initialFormData());
    setErrors({});
    setInputState({});
    setFileNames([]);
  };
  const validateForm = () => {
    const requiredFields = [
      "project",
      "letter_num",

      "subject",
      "letter_date",
      "status",
      "workperformedby",
      "lettertype",

      "associated_letters",
      "associated_letters_status",
      "letter_from",
      "letter_to",
      'letterExchange',
    ];
    const newErrors = requiredFields.reduce((acc, field) => {
      if (
        !formData[field] ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
      return acc;
    }, {});

    if (
      formData.associated_letters &&
      formData.associated_letters.length > 0 &&
      !formData.associated_letters_status
    ) {
      newErrors["associated_letters_status"] =
        "Status for associated letters is required!";
    }

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  // Modified handleInputChange to handle multiple selections
  const handleInputChange = (e) => {
    const { name, value, type, options } = e.target;

    let newValue;
    if (type === "select-multiple") {
      // Handle multiple selections
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      // Handle single selection or other input types
      newValue = value;
    }

    // Update input state (e.g., for validation styling)
    setInputState((prevState) => ({
      ...prevState,
      [name]:
        newValue && (Array.isArray(newValue) ? newValue.length > 0 : newValue)
          ? "success"
          : "",
    }));

    // Update form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,


      ...(name === 'reply_by' && prevFormData.letterExchange === 'send' && {
        letter_from: value,
      }),
    }));
  };

  const handleDocInputChange = (index, e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      newDocuments[index] = {
        ...newDocuments[index],
        [name]: files ? files[0] : value.trim(),
      };

      if (files) {
        setFileNames((prevFileNames) => {
          const newFileNames = [...prevFileNames];
          newFileNames[index] = files[0]?.name || "";
          return newFileNames;
        });
      }

      return { ...prevFormData, documents: newDocuments };
    });
  };

  const handleAddDocuments = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: [
        ...prevFormData.documents,
        { document_name: "", document_file: null },
      ],
    }));
  };

  const handleRemoveDocument = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: prevFormData.documents.filter((_, i) => i !== index),
    }));
    setFileNames((prevFileNames) =>
      prevFileNames.filter((_, i) => i !== index)
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        const formDataToSend = new FormData();

        // Append general form fields
        Object.keys(formData).forEach((key) => {
          if (key !== "documents" && formData[key]) {
            if (key === "associated_letters") {
              formData[key].forEach((value) =>
                formDataToSend.append(key, value)
              );
            } else {
              formDataToSend.append(key, formData[key]);
            }
          }
        });

        // Append documents data
        formData.documents.forEach((doc, index) => {
          if (doc.document_file) {
            const customFile = createCustomFile(doc.document_file);
            formDataToSend.append(
              `documents[${index}][document_name]`,
              doc.document_name
            );
            formDataToSend.append(
              `documents[${index}][document_file]`,
              customFile
            );
          }
        });

        const res = await axiosInstance.post(
          `/siteproject/documentbulkwithletter/`,
          formDataToSend,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        );

        if (res.status === 200) {
          handleClose();
          await getProjects(); // Refresh project list
          toast.success("Letter Documents uploaded successfully!");
        } else {
          throw new Error("Document upload failed");
        }
      } catch (err) {
        // toast.error('Error uploading documents');
        console.error("Error uploading documents", err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
        setUploadProgress(0);
      }
    }
  };

  const createCustomFile = (originalFile) => {
    const randomCode = generateRandomString();
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
    return new File([originalFile], customFileName, {
      type: originalFile.type,
    });
  };

  const generateRandomString = () => {
    const chars =
      "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from(
      { length: 8 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
  };

  useEffect(() => {
    if (formData.project) {
      getProjectContractorDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project]); // Dependency array includes formData.project

  // options={trackRecordList.map((option) => ({
  //   value: option.id,
  //   label: option.letter_num,
  // }))}

  const options = trackRecordList
    .sort((a, b) => parseInt(a.letter_num) - parseInt(b.letter_num))
    .map((e) => ({
      value: e.id,
      label: `${e.letter_num} - ${e.letter_date}`,
    }));

  const handleChangecc = (selected) => {
    const associated_letters =
      selected.length > 0 ? selected.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      associated_letters,
    }));
  };

  return (
    <>
      <button
        title="Add Letter Tracking"
        className="upload-svg"
        onClick={handleShow}
      >
        {/* Replace with your icon component */}
        <AddwithWhiteCircle /> Add Letter
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>Add Letter Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">
              {projectId === undefined && (
                <div>
                  <RenderSelectField
                    title="Site Project"
                    name="project"
                    required={true}
                    options={projectList
                      .sort((a, b) =>
                        a.project_short_name.localeCompare(b.project_short_name)
                      )
                      .map((project) => ({
                        value: project.id,
                        label: `${project.site_prcode} - ${project.project_short_name}`,
                      }))}
                    placeholder="Select a Project"
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                </div>
              )}

              <RenderSelectField
                title="Letter Exchange"
                name="letterExchange"
                required={true}
                options={[
                  { value: "send", label: "Send" },
                  { value: "receive", label: "Received" },
                ]}
                placeholder="Select an Exchange Type"
                formData={formData}
                errors={errors}
                inputState={inputState}
                handleInputChange={handleInputChange}
              />

              {formData.letterExchange === "send" && (
                <RenderSelectField
                  title="Reply By"
                  name="reply_by"
                  required={true}
                  options={[
                    { value: "HO", label: "Head Office" },
                    { value: "SO", label: "Site Office" },
                  ]}
                  placeholder="Select a Reply By"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
              )}

              <RenderInputField
                title={`${toTitleCase(formData.letterExchange)} Letter No.`}
                name="letter_num"
                placeholder="Letter Number"
                required={true}
                formData={formData}
                errors={errors}
                inputState={inputState}
                handleInputChange={handleInputChange}
              />

              <RenderInputField
                title={`${toTitleCase(formData.letterExchange)} Letter Date`}
                name="letter_date"
                type="date"
                formData={formData}
                required={true}
                errors={errors}
                inputState={inputState}
                handleInputChange={handleInputChange}
              />

              <RenderInputTextareaField
                title="Subject"
                name="subject"
                placeholder="Enter subject here..."
                rows={5}
                cols={50}
                formData={formData}
                required={true}
                errors={errors}
                inputState={inputState}
                handleInputChange={handleInputChange}
              />


              {formData.workperformedby === 'contractor' && <div title=" Contractor Name" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Contractor Name<span className="required">*</span>
                </label>
                <br />
                <select
                  id="contractor"
                  name="contractor"
                  value={formData.contractor}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.contractor ? "error" : inputState.contractor ? "success" : ""
                    }`}
                >
                  <option value="null">Select a Contractor</option>
                  {contractorlists?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                    <option value={i.id}>{i.name}</option>
                  </>))
                  }
                </select>
                {/* {formData.project !== "" ? "" :  */}
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {/* } */}
                <div>
                  {errors.project && (
                    <span className="error-message font-size-text ">{errors.project}</span>
                  )}
                </div>
              </div>
              }





              {formData.letterExchange === 'receive' &&
                <RenderInputField
                  title="Letter From"
                  name="letter_from"
                  placeholder="Letter From"
                  max={100}
                  formData={formData}
                  required={true}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
              }

              <RenderInputField
                title="Letter To"
                name="letter_to"
                placeholder="Letter To"
                max={100}
                formData={formData}
                required={true}
                errors={errors}
                inputState={inputState}
                handleInputChange={handleInputChange}
              />
              {/* 
              <RenderSelectField
                title="Associated Letters"
                name="associated_letters"
                required={true}
                options={trackRecordList.map((option) => ({
                  value: option.id,
                  label: option.letter_num,
                }))}
                placeholder="Select Letters"
                formData={formData}
                errors={errors}
                inputState={inputState}
                handleInputChange={handleInputChange}
                multiple={true}
              /> */}

              <div className="flex-column">
                <label
                  htmlFor="names"
                  className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                >
                  Associated Letter
                </label>
                <Select
                  options={options}
                  isMulti
                  onChange={handleChangecc}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "34.5vw",
                    }),
                  }}
                />
                {errors.remarks && (
                  <span className="error-message font-size-text">
                    {errors.remarks}
                  </span>
                )}
              </div>


              {/* Show status options if associated letters are selected */}
              {formData.associated_letters &&
                formData.associated_letters.length > 0 && (
                  <RenderSelectField
                    title="Update Status of Associated Letters"
                    name="associated_letters_status"
                    required={true}
                    options={[
                      { value: "partially", label: "Partially" },
                      { value: "close", label: "Close" },
                    ]}
                    placeholder="Select Status"
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                )}
            </div>

            <DocumentSection
              formData={formData}
              handleDocInputChange={handleDocInputChange}
              handleAddDocuments={handleAddDocuments}
              handleRemoveDocument={handleRemoveDocument}
              errors={errors}
              fileNames={fileNames}
              inputState={inputState}
            />

            {/* Upload Progress Bar */}
            {uploadProgress > 0 && (
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
                <span>{uploadProgress}%</span>
              </div>
            )}

            <div className="button-models">
              <button
                onClick={handleFormSubmit}
                className="model-button font-weight500 model-button-submit"
                disabled={loading}
              >
                Submit
              </button>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};


const AddDocuments = ({ vehicle, employee, letter, getDocument }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState([]);



  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData];
      newDocuments[index] = {
        ...newDocuments[index],
        [name]: value, // Updates document_name, vehicledocs, or employeedocs
      };

      return newDocuments;
    });
  };
  const handleFileChange = (e, index) => {
    const file = e.target.files[0];

    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData];
      newDocuments[index] = {
        ...newDocuments[index],
        // document_file: file, // Updates the document_file field with the selected file
        document_file: file,
      };

      return newDocuments;
    });
  };




  const addNewDocument = () => {
    setFormData(
      // [...formprevFormDataata, initialFormData]
      (prevFormData) => ([
        ...prevFormData,
        {
          document_name: "",
          document_file: null,
          vehicledocs: vehicle || "",
          employeedocs: employee || "",
        },
      ])
    );
  };

  const handleRemoveDocument = (index) => {
    setFormData((prevFormData) => (
      prevFormData.filter((_, i) => i !== index)
    ));
  };

  const createCustomFile = (originalFile) => {
    const randomCode = generateRandomString();
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
    return new File([originalFile], customFileName, {
      type: originalFile.type,
    });
  };
  const generateRandomString = () => {
    const chars =
      "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from(
      { length: 8 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
  };




  const handleSubmit = async () => {
    // Create a new FormData object to handle file uploads
    const formDataToSend = new FormData();

    // Prepare an array of documents to send
    formData.forEach((doc, index) => {
      const customFile = doc.document_file ? createCustomFile(doc.document_file) : null;

      // Append the document details
      formDataToSend.append(`documents[${index}].document_name`, doc.document_name);
      formDataToSend.append(`documents[${index}].vehicledocs`, doc.vehicledocs);
      formDataToSend.append(`documents[${index}].employeedocs`, doc.employeedocs);

      // Append the custom file if it exists
      if (customFile) {
        formDataToSend.append(`documents[${index}].document_file`, customFile);
      }
    });

    try {
      const response = await axiosInstance.post(
        `/siteproject/documentbulk/`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log('Success:', response.data);
      getDocument(); // Callback to refresh documents after adding
    } catch (error) {
      console.error('Error uploading documents:', error);
    }
  };



  return (
    <div>
      {formData.map((data, index) => (
        <div key={index}>
          <input
            type="text"
            name="document_name"
            value={data.document_name}
            onChange={(e) => handleInputChange(e, index)}
            placeholder="Document Name"
          />
          <input
            type="file"
            name="document_file"
            onChange={(e) => handleFileChange(e, index)}
          />
          <button onClick={() => handleRemoveDocument(index)}>Close</button>
        </div>
      ))}
      <button onClick={addNewDocument}>Add Document</button>
      <br />
      <button onClick={handleSubmit}>Submit Documents</button>
    </div>
  );
};

const DeleteDocuments = ({ i, getDocument }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setshow(true);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      const ress = await axios.delete(
        `${BASE_URL}/siteproject/documents/${i.id}/`
      );
      if (ress.status === 200) {
        await getDocument();
        handleClose();
        toast.dismiss(loadingToastId);
        toast.success("Document deleted successfully!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Document {i.document_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Document {i.document_name}
            <div className="button-models">
              <button
                className="model-button   font-weight500    model-button-cancel"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};


const DocumentModel = ({ officerent, vehicle, employee, getDocument, siteVehicle, employeeData, rentData, p_id, title }) => {
  const [show, setShow] = useState(false);
  const [toggleState, setToggleState] = useState(1);
  const [formData, setFormData] = useState([
    {
      document_name: "",
      document_file: null,
      vehicledocs: vehicle ? vehicle : "",
      employeedocs: employee ? employee : "",
      miscellaneous_docs: p_id ? p_id : "",
      document_type: "",
      officerent: officerent ? officerent : "",
    },
  ]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const fileInputRefs = useRef([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData([
      {
        document_name: "",
        document_file: null,
        vehicledocs: vehicle || "",
        employeedocs: employee || "",
        miscellaneous_docs: p_id || "",
        document_type: "",
        officerent: officerent || "",

      },
    ]);
  }, [vehicle, employee, p_id, officerent]);

  const refresh = () => {
    setFormData(
      [{
        document_name: "",
        document_file: "",
        vehicledocs: vehicle ? vehicle : "",
        employeedocs: employee ? employee : "",
        miscellaneous_docs: p_id ? p_id : "",
        document_type: "",
        officerent: officerent ? officerent : "",


      },]
    )
  }
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setToggleState(1);
    refresh();

  };


  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData];
      newDocuments[index] = {
        ...newDocuments[index],
        [name]: value,
      };
      return newDocuments;
    });

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim() !== "") {
        delete newErrors[`${index}-${name}`];
      }
      return newErrors;
    });
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData];
      newDocuments[index] = {
        ...newDocuments[index],
        document_file: file,
      };
      return newDocuments;
    });

    // Clear the file error when a file is selected
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (file) {
        delete newErrors[`${index}-document_file`];
      }
      return newErrors;
    });
  };

  const addNewDocument = () => {
    setFormData((prevFormData) => [
      ...prevFormData,
      {
        document_name: "",
        document_file: null,
        vehicledocs: vehicle || "",
        employeedocs: employee || "",
        miscellaneous_docs: p_id || "",
        document_type: "",
        officerent: officerent || "",

      },
    ]);
    fileInputRefs.current.push(null);
  };


  const handleRemoveDocument = (index) => {
    setFormData((prevFormData) => prevFormData.filter((_, i) => i !== index));
    fileInputRefs.current.splice(index, 1);
  };

  const handleClear = (index) => {
    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData];
      newDocuments[index] = {
        ...newDocuments[index],
        document_file: null,
      };
      return newDocuments;
    });

    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].value = "";
    }
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`${index}-document_file`];
      return newErrors;
    });
  };

  const validateForm = () => {
    const newErrors = {};

    formData.forEach((doc, index) => {
      if (!doc.document_name.trim()) {
        newErrors[`${index}-document_name`] = "Document Name is required!";
      }

      if (title !== "Miscellaneous Document" && !doc.document_type.trim()) {
        newErrors[`${index}-document_type`] = "Document Type is required ";
      }

      if (!doc.document_name.trim()) {
        newErrors[`${index}-document_name`] = "Document Name is required!";
      }
      if (!doc.document_file) {
        newErrors[`${index}-document_file`] = "Document file is required!";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  useEffect(() => {
    console.log("FormData updated:", formData);
  }, [formData]);

  useEffect(() => {
    console.log("p_id updated:", p_id);
  }, [p_id]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataToSend = new FormData();

      formData.forEach((doc, index) => {
        formDataToSend.append(`documents[${index}].document_name`, doc.document_name);

        if (doc.employeedocs) {
          formDataToSend.append(`documents[${index}].employeedocs`, doc.employeedocs);
        }
        if (doc.vehicledocs) {
          formDataToSend.append(`documents[${index}].vehicledocs`, doc.vehicledocs);
        }
        if (doc.miscellaneous_docs) {
          formDataToSend.append(`documents[${index}].miscellaneous_docs`, doc.miscellaneous_docs);
        }
        if (doc.officerent) {
          formDataToSend.append(`documents[${index}].officerent`, doc.officerent);
        }
        if (doc.document_file) {
          formDataToSend.append(`documents[${index}].document_file`, doc.document_file);
        }
        if (doc.document_type) {
          formDataToSend.append(`documents[${index}].document_type`, doc.document_type);
        }
      });


      try {
        const response = await axiosInstance.post(
          `/siteproject/documentbulk/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          getDocument();
          { p_id ? handleClose() : setToggleState(2) };
          toast.dismiss(loadingToastId);
          toast.success("Document added successfully!");
          // refresh();

        }
      } catch (error) {
        // handleErrorToast(error);

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  console.log("title");
  console.log(title);


  return (
    <>
      {vehicle || officerent ?
        <>
          <button title="Add/View Document" className="document-download-button  width-5vw  width-5vw" onClick={handleShow}>
            Add/View
          </button>
        </>
        :
        <button title="Add Document" className="upload-svg" onClick={handleShow}>
          <AddwithWhiteCircle />{""} {title}
        </button>
      }
      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>

            {toggleState === 1 ? `Add ${title}` : `View ${title}`}



          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {title !== "Miscellaneous Document" &&
            <div className="bloc-tabss wfm-marginn">
              <button
                className={
                  toggleState === 1
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                }
                onClick={() => setToggleState(1)}
              >
                Add {title}
              </button>

              <button
                className={
                  toggleState === 2
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs "
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                }
                onClick={() => setToggleState(2)}
              >
                View {title}
              </button>



            </div>
          }
          {toggleState === 1 &&
            <div className="flex-row justify-end">
              <button onClick={addNewDocument} className="upload-svg ">
                <AddwithWhiteCircle />
                {" "} Another Document
              </button>
            </div>
          }
          <div className="content-tabs">
            {
              toggleState === 1 ?
                <>
                  {formData.map((data, index) => (
                    <div className="flex-row justify-between" key={index}>
                      <div className="flex-column">
                        <label htmlFor="document_name" className="form-labels font-weight500 font-size-subheading">
                          Document Name<span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          name="document_name"
                          value={data.document_name}
                          onChange={(e) => handleInputChange(e, index)}
                          placeholder="Document Name"
                          className="form-input width-15vw"
                        />
                        {errors[`${index}-document_name`] && (
                          <span className="error-message font-size-text">
                            {errors[`${index}-document_name`]}
                          </span>
                        )}
                      </div>
                      {title !== "Miscellaneous Document" &&
                        <div title="Document Type" className="flex-column">
                          <label htmlFor="document_type" className="form-labels  font-weight500    font-size-subheading">
                            Document Type
                          </label>
                          <select
                            id="document_type"
                            name="document_type"
                            value={formData.document_type}
                            onChange={(e) => handleInputChange(e, index)}
                            className="form-input width-15vw"

                          >
                            <option value="null">Select Document Type </option>
                            <option value="actual">Actual Document</option>
                            <option value="boq">Boq Document</option>
                          </select>

                          {errors[`${index}-document_type`] && (
                            <span className="error-message font-size-text">
                              {errors[`${index}-document_type`]}
                            </span>
                          )}

                        </div>

                      }
                      <div className="upload-button-field">
                        <div title="Upload Document" className="flex-column">
                          <label htmlFor={`document_file_${index}`} className="form-labels font-weight500 font-size-subheading">
                            Upload Document<span className="required">*</span>
                          </label>
                          <div className="flex-row justify-between">
                            <div >
                              <label htmlFor={`document_file_${index}`} className="svg-icon">
                                <div className="svg-field form-input align-center">
                                  <Attachment />
                                </div>

                              </label>
                              <input
                                type="file"
                                id={`document_file_${index}`}
                                name="document_file"
                                accept=".pdf, .doc, .docx"
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(e, index)}
                                ref={(el) => (fileInputRefs.current[index] = el)}
                              />
                            </div>
                            <div style={{ width: "6vw" }}>
                              {data.document_file && (
                                <span className="file-name">
                                  {data.document_file.name}
                                </span>
                              )}
                            </div>
                            <div className="flex-row justify-between" style={{ width: "12vw" }}>
                              <button title="Clear File" onClick={() => handleClear(index)} className="file-clear clear-button">
                                Clear
                              </button>
                              {formData.length > 1 && (
                                <button title="Remove Document" onClick={() => handleRemoveDocument(index)} className="file-clear clear-button">
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                          {errors[`${index}-document_file`] && (
                            <span className="error-message font-size-text">
                              {errors[`${index}-document_file`]}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                  {uploadProgress > 0 && (
                    <div className="progress-bar">
                      <div className="progress-bar-fill" style={{ width: `${uploadProgress}%` }}></div>
                      <span>{uploadProgress}%</span>
                    </div>
                  )}

                  <div className="button-models">
                    <button
                      onClick={handleSubmit}
                      className="model-button font-weight500 model-button-submit"
                      disabled={loading}
                    >
                      Submit
                    </button>
                  </div>
                </> :
                <>
                  <div className='table-overflow'>
                    <table className="table-css">
                      <thead>
                        <tr className="custom-table-head-tr ">
                          <th className="table-heading-text">S.No.</th>
                          <th className="table-heading-text">Document Name</th>
                          <th className="table-heading-text">Document Type</th>
                          <th className="table-heading-text">Document</th>
                          <th className="table-heading-text">Action</th>
                        </tr>
                      </thead>

                      <tbody
                      >
                        {vehicle && (

                          siteVehicle
                            .filter((v) => v.id === vehicle) // Find the vehicle that matches the passed vehicle ID
                            .flatMap((v) => v.documents) // Get the documents for the matched vehicle
                            .filter((doc) => doc.vehicledocs === vehicle) // Ensure the document belongs to the vehicle
                            .map((doc, docIndex) => (
                              <React.Fragment key={docIndex}>
                                <tr className="custom-table-head-td">
                                  <td className="table-body">{docIndex + 1}.</td>
                                  <td className="table-body">{doc.document_name}</td>
                                  <td className="table-body">{doc.document_type}</td>
                                  <td className="table-body">
                                    {doc.document_file ? (
                                      // <a href={i.document_file} target="blank">
                                      //   View
                                      // </a>
                                      <a href={doc.document_file} target="blank">
                                        <DocumentIcon />
                                      </a>
                                    ) : (
                                      "Document Not Attached"
                                    )}
                                  </td>
                                  <td className="table-body">
                                    <DeleteDocuments i={doc} getDocument={getDocument} />
                                  </td>

                                </tr>
                              </React.Fragment>

                            ))
                        )}
                        {employee && (
                          employeeData.document
                            .map((doc, docIndex) => (
                              <React.Fragment key={docIndex}>
                                <tr className="custom-table-head-td">
                                  <td className="align-left">{docIndex + 1}</td>
                                  <td className="align-center">{doc.document_name}</td>
                                  <td className="align-center">{doc.document_type}</td>
                                  <td className="align-center">
                                    {doc.document_file ? (
                                      <a href={doc.document_file} target="_blank" rel="noopener noreferrer">
                                        <DocumentIcon />
                                      </a>
                                    ) : (
                                      "Document Not Attached"
                                    )}
                                  </td>
                                  <td className="align-center">
                                    <DeleteDocuments i={doc} getDocument={getDocument} />
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))
                        )}
                        {officerent && (

                          rentData
                            .filter((v) => v.id === officerent) // Find the vehicle that matches the passed vehicle ID
                            .flatMap((v) => v?.documents) // Get the documents for the matched vehicle
                            .filter((doc) => doc.officerent === officerent) // Ensure the document belongs to the vehicle
                            .map((doc, docIndex) => (
                              <React.Fragment key={docIndex}>
                                <tr className="custom-table-head-td">
                                  <td className="table-body">{docIndex + 1}.</td>
                                  <td className="table-body">{doc.document_name}</td>
                                  <td className="table-body">{doc.document_type}</td>
                                  <td className="table-body">
                                    {doc.document_file ? (
                                      // <a href={i.document_file} target="blank">
                                      //   View
                                      // </a>
                                      <a href={doc.document_file} target="blank">
                                        <DocumentIcon />
                                      </a>
                                    ) : (
                                      "Document Not Attached"
                                    )}
                                  </td>
                                  <td className="table-body">
                                    <DeleteDocuments i={doc} getDocument={getDocument} />
                                  </td>

                                </tr>
                              </React.Fragment>

                            ))
                        )}

                      </tbody>
                    </table>
                  </div>
                </>
            }
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>

    </>
  );
};






const UpdateTrackRecordDetails = ({
  i,
  title,
  getProjects,
  projectList,
  projectId,
  letterType,
  workperformedby,
  associatedletters,
  documents,

}) => {
  const [show, setShow] = useState(false);






  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i)
    setShow(true);
    getAsociatedLetters(i);
  };




  const [formData, setFormData] = useState({
    letter_num: "",
    project: projectId ? projectId : "",
    subject: "",
    letter_date: "",
    reply_by: "",
    status: "pending",
    workperformedby: workperformedby ? workperformedby : "",
    lettertype: letterType ? letterType : "",
    letterExchange: "",
    letter_from: "",
    letterdepartment: "null",

    letter_to: "",
    associated_letters: associatedletters ? associatedletters : [],
    associated_letters_status: "",
    documents: [
      {
        document_name: null,
        document_file: "",
      },
    ],
  });

  const [formDataDoc, setFormDataDoc] = useState({
    documents: [
      {
        document_name: null,
        document_file: "",
      },
    ],
  });


  // console.log(i)





  const [trackRecordList, setTrackRecordList] = useState([]);
  const [optionsList, setoptionsList] = useState([]);
  const getAsociatedLetters = async (i) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letterrecordbclosestatus/${i.project
        }/${i.workperformedby}/${i.lettertype}`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      // toast.error('Error fetching letters.');
      handleErrorToast(err);
    }
  };

  // useEffect(() => {

  // }, [formData.workperformedby, formData.project]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const requiredFields = [
      // "letter_num",
      // "project",
      // "subject",
      // "letter_date",
      // "status",
      // "lettertype",
      // "workperformedby",
      // 'workperformedby',
      // 'letterExchange',
      // 'letter_to',
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    const newErrors = requiredFields.reduce((acc, field) => {
      if (
        !formData[field] ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
      return acc;
    }, {});

    // if (
    //   formData.associated_letters &&
    //   formData.associated_letters.length > 0 &&
    //   !formData.associated_letters_status
    // ) {
    //   newErrors["associated_letters_status"] =
    //     "Status for associated letters is required!";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleInputChange = (e) => {
  //   const {name, value} = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value, type, options } = e.target;

    let newValue;
    // Handle multiple select inputs
    if (type === 'select-multiple') {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      newValue = value;
    }

    // Update input state for UI feedback
    setInputState((prevState) => ({
      ...prevState,
      [name]:
        newValue && (Array.isArray(newValue) ? newValue.length > 0 : newValue)
          ? "success"
          : "",
    }));

    // Update form data based on the input change
    setFormData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        [name]: newValue,
        ...(name === 'lettertype' && {
          status:
            newValue === 'informative' || newValue === 'show-cause'
              ? newValue
              : prevFormData.status,
        }),

        ...(name === 'reply_by' && prevFormData.letterExchange === 'send' && {
          letter_from: value,
        }),
      };




      return updatedData;
    });

  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); // Start loading
      const loadingToastId = toast.loading("Loading: Please wait..."); // Display loading toast

      try {
        const formDataToSend = new FormData();

        // Loop through formData keys, skipping 'documents'
        Object.keys(formData).forEach((key) => {
          if (key !== "documents" && formData[key]) {
            if (key === "associated_letters") {
              // Append each associated letter to the form data
              formData[key].forEach((value) => formDataToSend.append(key, value));
            } else {
              formDataToSend.append(key, formData[key]);
            }
          }
        });

        // Process documents, create custom filenames, and append to formData
        formDataDoc.documents.forEach((doc, index) => {
          const symbols = "!@#$%^&*()_-+=";
          const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
          const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          const numbers = "0123456789";
          const now = new Date();
          const date = now.toLocaleDateString("en-GB").split("/").reverse().join("-");
          const time = now.toLocaleTimeString("en-GB", { hour12: false }).replace(/:/g, "-");
          const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
          let randomCode = "";

          // Generate random code for unique filename
          for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * allChars.length);
            randomCode += allChars[randomIndex];
          }

          const originalFile = doc.document_file;
          const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
          const customFile = new File([originalFile], customFileName, {
            type: originalFile.type,
          });

          // Check if the document has a valid file before appending
          if (doc.document_file) {
            formDataToSend.append(`documents[${index}][document_name]`, doc.document_name);
            formDataToSend.append(`documents[${index}][document_file]`, customFile);
          } else {
            console.warn(`Document file at index ${index} is not valid.`);
          }
        });

        // Logging for debugging (can be removed in production)
        console.log("FormData to send:");
        formDataToSend.forEach((value, key) => {
          if (value instanceof File) {
            console.log(`${key}: [File] ${value.name} - ${value.size} bytes`);
          } else {
            console.log(`${key}: ${value}`);
          }
        });

        // Send the PUT request with the FormData
        let res = await axios.put(
          `${BASE_URL}/siteproject/documentbulkwithletterupdate/${formData.id}/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle response
        if (res.status === 200) {
          setShow(false); // Close modal or update UI
          await getProjects(); // Fetch updated project data
        } else {
          alert("Failed to update the record.");
        }
      } catch (err) {
        handleErrorToast(err); // Handle and show errors using toast
      } finally {
        setLoading(false); // End loading
        toast.dismiss(loadingToastId); // Dismiss loading toast
      }
    }
  };


  const [fileNames, setFileNames] = useState([]);

  const handleDocInputChange = (index, e) => {
    const { name, value, type, files } = e.target;

    setFormDataDoc((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      if (type === "file") {
        newDocuments[index] = {
          ...newDocuments[index],
          [name]: files[0],
        };
        setFileNames((prevFileNames) => {
          const newFileNames = [...prevFileNames];
          newFileNames[index] = files[0]?.name;
          return newFileNames;
        });
      } else {
        newDocuments[index] = {
          ...newDocuments[index],
          [name]: value.trim() ? value : "",
        };
      }
      return {
        ...prevFormData,
        documents: newDocuments,
      };
    });
  };

  const handleAddDocuments = (e) => {
    e.preventDefault();
    setFormDataDoc((prevFormData) => ({
      ...prevFormData,
      documents: [
        ...prevFormData.documents,
        {
          document_name: "",
          document_file: null,
        },
      ],
    }));
  };

  const handleRemoveDocument = (index) => {
    setFormDataDoc((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      newDocuments.splice(index, 1);
      return {
        ...prevFormData,
        documents: newDocuments,
      };
    });
    setFileNames((prevFileNames) => {
      const newFileNames = [...prevFileNames];
      newFileNames.splice(index, 1);
      return newFileNames;
    });
  };

  const handlePicDelete = async (e, i, getProjects) => {
    e.preventDefault();

    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/documents/${i.id}/`
      );

      if (res.status === 200) {
        await getProjects();
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  useEffect(() => {
    if (trackRecordList.length > 0) {
      const valuetoshowinoptions = trackRecordList.filter((option) => option?.letter_num !== formData?.letter_num)
      setoptionsList(valuetoshowinoptions);
    }
  }, [trackRecordList])


  const handleChangecc = (selected) => {
    const associated_letters = selected.length > 0 ? selected.map((item) => item.value) : [];

    // Update the formData with the selected associated letters
    setFormData((prevState) => ({
      ...prevState,
      associated_letters,
    }));

    // Check if any selected associated letter does not exist in trackRecordList
    const missingLetters = associated_letters.filter(
      (id) => !trackRecordList.some((record) => record.id === id)
    );


    // If there are missing letters, set associated_letters_status to 'close'
    if (missingLetters.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        associated_letters_status: 'close', // Update status if missing letters found
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        associated_letters_status: '', // Reset status if all letters are found
      }));
    }
  };



  // ? Document End ===============================

  // useEffect(() => {
  //   getAsociatedLetters();
  // }, [formData.project, formData.workperformedby, formData.lettertype]);

  return (
    <>
      <button onClick={handleShow} className="document-download-button  width-5vw  width-5vw" >
        View/Edit
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title ? title : "Update Track Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">
              {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? "" :

                ((projectId === 'null' || projectId === undefined || projectId === "") && (
                  <div className="form-group">
                    <RenderSelectField
                      title="Site Projects"
                      name="project"
                      required={true}
                      options={projectList?.sort((a, b) =>
                        a.project_short_name.localeCompare(b.project_short_name)
                      )
                        .map((project) => ({
                          value: project.id,
                          label: `${project.site_prcode} - ${project.project_short_name}`,
                        }))}
                      placeholder="Select a Project"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.project && (
                      <div className="error-message col">
                        {errors.project}
                      </div>
                    )}
                  </div>
                ))
              }


              <div className="form-group">
                <RenderSelectField
                  title="Letter Exchange"
                  name="letterExchange"
                  required={true}
                  options={[
                    { value: "send", label: "Send" },
                    { value: "receive", label: "Received" },
                  ]}
                  placeholder="Select an Exchange Type"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letterExchange && (
                  <div className="error-message col">
                    {errors.letterExchange}
                  </div>
                )}
              </div>

              {formData.letterExchange === "send" && (
                <div className="form-group">
                  <RenderSelectField
                    title="Reply By"
                    name="reply_by"
                    required={true}
                    options={[
                      { value: "HO", label: "Head Office" },
                      { value: "SO", label: "Site Office" },
                    ]}
                    placeholder="Select a Reply By"
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.reply_by && (
                    <div className="error-message col">{errors.reply_by}</div>
                  )}
                </div>
              )}

              <div className="form-group">
                <RenderInputField
                  title={`${toTitleCase(formData.letterExchange)} Letter No.`}
                  name="letter_num"
                  placeholder="Letter Number"
                  formData={formData}
                  errors={errors}
                  required={true}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_num && (
                  <div className="error-message col">{errors.letter_num}</div>
                )}
              </div>

              <div className="form-group">
                <RenderInputField
                  title={`${toTitleCase(formData.letterExchange)} Letter Date`}
                  name="letter_date"
                  type="date"
                  formData={formData}
                  errors={errors}
                  required={true}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_date && (
                  <div className="error-message col">{errors.letter_date}</div>
                )}
              </div>

              <div className="form-group">
                <RenderInputTextareaField
                  title="Subject"
                  name="subject"
                  placeholder="Enter subject here..."
                  rows={5}
                  cols={50}
                  formData={formData}
                  required={true}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.subject && (
                  <div className="error-message col">{errors.subject}</div>
                )}
              </div>
              <div title="Status" className="flex-column form-group-selectt">
                <label
                  htmlFor="status"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Status<span className="required">*</span>
                </label>
                <br />
                <select
                  id="status"
                  name="status"
                  placeholder="Status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""
                    }`}

                >
                  <option value="">Select a Status</option>
                  <option value="pending">Pending</option>


                  <option value="close">Close</option>

                </select>


                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>

                <div>
                  {errors.status && (
                    <span className="error-message font-size-text ">
                      {errors.status}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <RenderSelectField
                  title="Letter Type"
                  disabled
                  name="lettertype"
                  required={true}
                  options={[
                    { value: "show-cause", label: "Show Cause" },
                    { value: "informative", label: "Informative" },
                    { value: "eot", label: "EOT" },
                    { value: "cos", label: "COS" },
                    { value: "billing", label: "Billing" },
                    { value: "design", label: "Design" },
                    { value: "ncr", label: "NCR" },

                    { value: "Design & Drawing", label: "Design & Drawing" },
                    { value: "P&P", label: "P&P" },
                    { value: "QAP", label: "QAP" },
                    { value: "Structure", label: "Structure" },
                    { value: "Q & ME", label: "Q & ME" },
                    { value: "BBS", label: "BBS" },
                    { value: "noc", label: "NOC" },
                    { value: "Road Safety", label: "Road Safety" },
                    { value: "Work Plan", label: "Work Plan" },
                    { value: "Drain", label: "Drain" },
                    { value: "Hydrology", label: "Hydrology" },
                    { value: "Survey", label: "Survey" },
                    { value: "Milestone", label: "Milestone" },
                    { value: "query", label: "Query" },
                    { value: "SiteProgress", label: "SiteProgress" },
                    { value: "other", label: "Others" }
                  ]}
                  placeholder="Select a Letter Type"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                  readOnly
                />
                {errors.lettertype && (
                  <div className="error-message col">{errors.lettertype}</div>
                )}
              </div>



              {formData.letterExchange === 'receive' &&
                <div className="form-group">
                  <RenderInputField
                    title="Letter From"
                    name="letter_from"
                    placeholder="Letter From"
                    max={100}
                    formData={formData}
                    errors={errors}
                    required={true}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.letter_from && (
                    <div className="error-message col">
                      {errors.letter_from}
                    </div>
                  )}
                </div>}

              <div className="form-group">
                <RenderInputField
                  title="Letter To"
                  name="letter_to"
                  placeholder="Letter To"
                  max={100}
                  required={true}
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_to && (
                  <div className="error-message col">{errors.letter_to}</div>
                )}
              </div>
              <div className="form-group">
                <RenderInputTextareaField
                  title="Remarks"
                  name="remarks"
                  placeholder="Remarks"
                  rows={5}
                  cols={50}
                  formData={formData}
                  required={true}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.remarks && (
                  <div className="error-message col">{errors.remarks}</div>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="names"
                  className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                >
                  Associated Letters
                </label>

                {/* Select component to show and handle associated letters */}
                <Select
                  options={optionsList.map((e) => ({
                    value: e.id,
                    label: `${e.letter_num}`, // Display the letter number as label
                  }))}
                  isMulti
                  onChange={handleChangecc}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '33vw',
                      height: '50px',
                      borderRadius: '10px',
                      border: '1px solid #e3e3e3',
                      margin: '10px 0px 20px 0px',
                      color: '#4a4a4a',
                      backgroundColor: '#f7f8fa',
                      padding: '0px 10px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: '33vw',
                      borderRadius: '10px',
                      marginTop: '0',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                  }}

                  value={trackRecordList
                    .filter((option) => formData.associated_letters.includes(option.id))
                    .map((e) => ({
                      value: e.id,
                      label: `${e.letter_num}`, // Pre-selected values showing letter_num
                    }))}
                />

              </div>

              {formData.associated_letters &&
                formData.associated_letters.length > 0 && (
                  <RenderSelectField
                    title="Update Status of Associated Letters"
                    name="associated_letters_status"
                    required={true}
                    options={[
                      { value: "pending", label: "pending" },
                      { value: "close", label: "Close" },
                    ]}
                    placeholder="Select Status"
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                )}
            </div>

            <>
              <div className="jd-heading-outer heading-bg-color-white">
                <div className="jd-heading-outer-flex">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">
                    Documents{" "}
                  </h2>
                  <div>
                    <button
                      title="Add Documents"
                      onClick={handleAddDocuments}
                      className="jd-heading-main-right-button font-weight600 "
                      style={{ float: "right" }}
                    >
                      <AddwithBlueCircle />
                    </button>
                    {/* <br /> */}
                  </div>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Name</th>
                      <th className="align-center">File</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {i?.documents?.map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.document_name}</td>
                          <td className="align-center">
                            {i.document_file ? (
                              // <a href={i.document_file} target="blank">
                              //   View
                              // </a>
                              <a href={i.document_file} target="blank">
                                <DocumentIcon />
                              </a>
                            ) : (
                              "Document Not Attached"
                            )}
                          </td>

                          <td className="align-right width-5vw ">
                            <button
                              className="model-delete-button"
                              onClick={(e) =>
                                handlePicDelete(e, i, getProjects)
                              }
                            >
                              <DeleteDustbin />
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />

              <div title="Documents" className="">
                {/* <label className="form-labels font-weight500 font-size-subheading">Documents</label> */}
                {formDataDoc?.documents?.map((doc, index) => (
                  <div key={index} className="expense-req">
                    <div className="flex-column field-cont-div">
                      <label
                        htmlFor={`document_name_${index}`}
                        className="form-labels announce-date font-weight500 font-size-heading"
                      >
                        File Name
                      </label>
                      <input
                        type="text"
                        id={`document_name_${index}`}
                        name={`document_name`}
                        placeholder="Document Name"
                        value={doc.document_name}
                        onChange={(e) => handleDocInputChange(index, e)}
                        className={`form-modal-input ${errors[`document_name_${index}`]
                          ? "error"
                          : inputState[`document_name`]
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors[`document_name_${index}`] && (
                        <span className="error-message font-size-text">
                          {errors[`document_name_${index}`]}
                        </span>
                      )}
                    </div>

                    <div className="flex-column field-cont-div">
                      <label
                        htmlFor=""
                        className="form-labels announce-date font-weight500 font-size-heading"
                      >
                        Upload File
                      </label>
                      <label
                        htmlFor={`document_file_${index}`}
                        className={`form-inputss custom-file-upload ${errors[`document_file_${index}`]
                          ? "error"
                          : inputState[`document_file`]
                            ? "success"
                            : ""
                          }`}
                      >
                        <div className={`align-center pdf-input  ${errors[`document_file_${index}`] ? 'error' : ''}`}>
                          <Attachment />
                        </div>
                      </label>
                      <input
                        id={`document_file_${index}`}
                        name={`document_file`}
                        type="file"
                        onChange={(e) => handleDocInputChange(index, e)}
                        style={{ display: "none", position: "relative" }}
                      />
                      {fileNames[index] && (
                        <p className="file-name align-center">
                          {fileNames[index]}
                        </p>
                      )}

                      {errors[`document_file_${index}`] && (
                        <span className="error-message font-size-text">
                          {errors[`document_file_${index}`]}
                        </span>
                      )}
                    </div>

                    {index === 0 ? null : (
                      <button
                        type="button"
                        className="model-button-cancel"
                        onClick={() => handleRemoveDocument(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
              </div>

              <div className="button-models">
                <button
                  onClick={handleFormSubmit}
                  className="model-button   font-weight500    model-button-submit"
                  disabled={loading}
                >
                  {/* //loading logic */}
                  Update
                </button>
              </div>
            </>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateTrackRecordDetailwithIDonly = ({
  i,
  title,
  getProjects,
  projectList,
  projectId,
  letterType,
  workperformedby,
  associatedletters,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setShow(true);
    setFormData(i);

    // Trigger the API calls when the modal is shown
    await getLettersByID();
    // await getAsociatedLetters();
  };

  const [formData, setFormData] = useState({
    letter_num: "",
    project: projectId ? projectId : "null",
    subject: "",
    letter_date: "",
    status: "",
    workperformedby: workperformedby ? workperformedby : "null",
    lettertype: letterType ? letterType : "null",
    letterExchange: "",
    letter_from: "",
    letter_to: "",
    remarks: '',
    associated_letters: associatedletters ? associatedletters : [],
    associated_letters_status: "pending",
    documents: [
      {
        document_name: null,
        document_file: "",
      },
    ],
  });




  const [formDataDoc, setFormDataDoc] = useState({
    documents: [
      {
        document_name: null,
        document_file: "",
      },
    ],
  });




  const getLettersByID = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/linked-letter-list/${i.id}/`
      );

      const filteredData = res.data.filter(item => item.id === i.id);
      if (filteredData.length > 0) {
        setFormData(prevState => ({
          ...prevState,
          ...filteredData[0], // Merge with existing state
        }));
      }

    } catch (err) {
      handleErrorToast(err);
    }
  };


  const [trackRecordList, setTrackRecordList] = useState([]);
  const getAsociatedLetters = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letterrecordbyfilters/${formData.project
        }/${formData.workperformedby}/${formData.lettertype
        }`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      // toast.error('Error fetching letters.');
      handleErrorToast(err);
    }
  };

  // useEffect(() => {

  // }, [formData.workperformedby, formData.project]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const requiredFields = [
      // "letter_num",
      // "project",
      // "subject",
      // "letter_date",
      // "status",
      // "lettertype",
      // "workperformedby",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    const newErrors = requiredFields.reduce((acc, field) => {
      if (
        !formData[field] ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
      return acc;
    }, {});

    if (
      formData.associated_letters &&
      formData.associated_letters.length > 0 &&
      !formData.associated_letters_status
    ) {
      newErrors["associated_letters_status"] =
        "Status for associated letters is required!";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleInputChange = (e) => {
  //   const {name, value} = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    // if (name === "lettertype") {
    //   if (value === "informative") {
    //     setFormData({
    //       ...formData,
    //       [name]: value,
    //       status: "informative",
    //     });
    //   } else if (value === "show-cause") {
    //     setFormData({
    //       ...formData,
    //       [name]: value,
    //       status: "show-cause",
    //     });
    //   } else {
    //     setFormData({
    //       ...formData,
    //       [name]: value,
    //     });
    //   }
    // } else {
    //   setFormData({
    //     ...formData,
    //     [name]: value,
    //   });
    // }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        const formDataToSend = new FormData();

        formDataToSend.append("letter_num", formData.letter_num);
        formDataToSend.append("project", formData.project);
        formDataToSend.append("subject", formData.subject);
        formDataToSend.append("status", formData.status);
        formDataToSend.append("lettertype", formData.lettertype);
        formDataToSend.append("letter_date", formData.letter_date);
        formDataToSend.append("workperformedby", formData.workperformedby);
        // formDataToSend.append('associated_letters', formData.associated_letters);

        formDataDoc.documents.forEach((doc, index) => {
          const symbols = "!@#$%^&*()_-+=";
          const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
          const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          const numbers = "0123456789";
          const now = new Date();
          const date = now
            .toLocaleDateString("en-GB")
            .split("/")
            .reverse()
            .join("-"); // Format: YYYY-MM-DD
          const time = now
            .toLocaleTimeString("en-GB", { hour12: false })
            .replace(/:/g, "-"); // Format: HH-MM-SS
          const allChars =
            symbols + lowercaseLetters + uppercaseLetters + numbers;
          let randomCode = "";
          for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * allChars.length);
            randomCode += allChars[randomIndex];
          }
          const originalFile = doc.document_file;
          const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
          const customFile = new File([originalFile], customFileName, {
            type: originalFile.type,
          });
          if (doc.document_file) {
            formDataToSend.append(
              `documents[${index}][document_name]`,
              doc.document_name
            );
            formDataToSend.append(
              `documents[${index}][document_file]`,
              customFile
            );
          } else {
            console.warn(`Document file at index ${index} is not valid.`);
          }
        });

        console.log("FormData to send:");
        formDataToSend.forEach((value, key) => {
          if (value instanceof File) {
            console.log(`${key}: [File] ${value.name} - ${value.size} bytes`);
          } else {
            console.log(`${key}: ${value}`);
          }
        });
        let res = await axios.put(
          `${BASE_URL}/siteproject/documentbulkwithletterupdate/${i.id}/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          setShow(false);
          await getProjects();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const [fileNames, setFileNames] = useState([]);

  const handleDocInputChange = (index, e) => {
    const { name, value, type, files } = e.target;

    setFormDataDoc((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      if (type === "file") {
        newDocuments[index] = {
          ...newDocuments[index],
          [name]: files[0],
        };
        setFileNames((prevFileNames) => {
          const newFileNames = [...prevFileNames];
          newFileNames[index] = files[0]?.name;
          return newFileNames;
        });
      } else {
        newDocuments[index] = {
          ...newDocuments[index],
          [name]: value.trim() ? value : "",
        };
      }
      return {
        ...prevFormData,
        documents: newDocuments,
      };
    });
  };

  const handleAddDocuments = (e) => {
    e.preventDefault();
    setFormDataDoc((prevFormData) => ({
      ...prevFormData,
      documents: [
        ...prevFormData.documents,
        {
          document_name: "",
          document_file: null,
        },
      ],
    }));
  };

  const handleRemoveDocument = (index) => {
    setFormDataDoc((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      newDocuments.splice(index, 1);
      return {
        ...prevFormData,
        documents: newDocuments,
      };
    });
    setFileNames((prevFileNames) => {
      const newFileNames = [...prevFileNames];
      newFileNames.splice(index, 1);
      return newFileNames;
    });
  };

  const handlePicDelete = async (e, i, getProjects) => {
    e.preventDefault();

    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/documents/${i.id}/`
      );

      if (res.status === 200) {
        await getProjects();
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  const options = trackRecordList
    .sort((a, b) => parseInt(a.letter_num) - parseInt(b.letter_num))
    .map((e) => ({
      value: e.id,
      label: `${e.letter_num} - ${e.letter_date}`,
    }));

  const handleChangecc = (selected) => {
    const associated_letters =
      selected.length > 0 ? selected.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      associated_letters,
    }));
  };

  // ? Document End ===============================

  useEffect(() => {
    getAsociatedLetters();
  }, [formData.project, formData.workperformedby, formData.lettertype]);

  return (
    <>
      <button onClick={handleShow} className="document-download-button  width-5vw  " style={{ margin: "0px", height: "30px" }}>
        View
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title ? title : "Update Track Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">
              <div title="letter ID" className="flex-column">
                <label
                  htmlFor="letter_num"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Letter No.<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="letter_num"
                  name="letter_num"
                  placeholder="Letter Number"
                  onChange={handleInputChange}
                  value={formData.letter_num}
                  className={`form-input ${errors.letter_num
                    ? "error"
                    : inputState.letter_num
                      ? "success"
                      : ""
                    }`}
                />
                {errors.letter_num && (
                  <span className="error-message font-size-text ">
                    {errors.letter_num}
                  </span>
                )}
              </div>

              {/* <div title="Project" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Project<span className="required">*</span>
                </label>
                <br />
                <select
                  id="project"
                  name="project"
                  placeholder="Project"
                  value={formData.project}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                >
                  <option value="">Select a Project</option>
                  {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                </div>
              </div> */}


              <div title="Subject" className="flex-column">
                <label
                  htmlFor="subject"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Subject<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  max={150}
                  name="subject"
                  placeholder="Subject"
                  onChange={handleInputChange}
                  value={formData.subject}
                  className={`form-input ${errors.subject
                    ? "error"
                    : inputState.subject
                      ? "success"
                      : ""
                    }`}
                />
                {errors.subject && (
                  <span className="error-message font-size-text ">
                    {errors.subject}
                  </span>
                )}
              </div>

              <div title="Letter Date" className="flex-column">
                <label
                  htmlFor="letter_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Letter Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="letter_date"
                  name="letter_date"
                  placeholder="Sent Letter Date"
                  onChange={handleInputChange}
                  value={formData.letter_date}
                  className={`form-input ${errors.letter_date
                    ? "error"
                    : inputState.letter_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.letter_date && (
                  <span className="error-message font-size-text ">
                    {errors.letter_date}
                  </span>
                )}
              </div>

              <div
                title="Letter Type"
                className="flex-column form-group-selectt"
              >
                <label
                  htmlFor="lettertype"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Letter Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="lettertype"
                  name="lettertype"
                  placeholder="lettertype"
                  value={formData.lettertype}
                  onChange={handleInputChange}
                  disabled
                  className={`form-input form-group-selection ${errors.lettertype
                    ? "error"
                    : inputState.lettertype
                      ? "success"
                      : ""
                    }`}

                >
                  <option value="null">Select Letter Type</option>
                  <option value="eot">EOT</option>
                  <option value="cos">COS</option>
                  <option value="ncr">NCR</option>
                  <option value="billing">Billing</option>
                  <option value="design">Design</option>
                  <option value="informative">Informative</option>
                  <option value="show-cause">Show Cause</option>
                  <option value="Design & Drawing">Design & Drawing</option>
                  <option value="P&P">P&P</option>
                  <option value="QAP">QAP</option>
                  <option value="Structure">Structure</option>
                  <option value="billing">Billing</option>
                  <option value="Q & ME">Q & ME</option>
                  <option value="BBS">BBS</option>
                  <option value="noc">NOC</option>
                  <option value="Road Safety">Road Safety</option>
                  <option value="Work Plan">Work Plan</option>
                  <option value="Drain">Drain</option>
                  <option value="Hydrology">Hydrology</option>
                  <option value="Survey">Survey</option>
                  <option value="Milestone">Milestone</option>
                  <option value="SiteProgress">SiteProgress</option>
                  <option value="query">Query</option>
                  <option value="other">Others</option>


                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                <div>
                  {errors.lettertype && (
                    <span className="error-message font-size-text ">
                      {errors.lettertype}
                    </span>
                  )}
                </div>
              </div>

              <div title="Status" className="flex-column form-group-selectt">
                <label
                  htmlFor="status"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Status<span className="required">*</span>
                </label>
                <br />
                <select
                  id="status"
                  name="status"
                  placeholder="Status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""
                    }`}

                >
                  <option value="">Select a Status</option>
                  <option value="pending">Pending</option>


                  <option value="close">Close</option>

                </select>


                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>

                <div>
                  {errors.status && (
                    <span className="error-message font-size-text ">
                      {errors.status}
                    </span>
                  )}
                </div>
              </div>


              <div
                title="Letter Exchange"
                className="flex-column form-group-selectt"
              >
                <label className="form-labels font-weight500 font-size-subheading">
                  Letter Exchange<span className="required">*</span>
                </label>
                <br />
                <select
                  id="letterExchange"
                  name="letterExchange"
                  value={formData.letterExchange}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.letterExchange
                    ? "error"
                    : inputState.letterExchange
                      ? "success"
                      : ""
                    }`}
                >
                  <option value="">Select Letter</option>
                  <option value="send">Send</option>
                  <option value="receive">Receive</option>
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.letterExchange && (
                  <div className="error-message font-size-text">
                    {errors.letterExchange}
                  </div>
                )}
              </div>

              <div title="Letter From" className="flex-column">
                <label
                  htmlFor="letter_from"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Letter From<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="letter_from"
                  max={100}
                  name="letter_from"
                  placeholder="Letter From"
                  onChange={handleInputChange}
                  value={formData.letter_from}
                  className={`form-input ${errors.letter_from
                    ? "error"
                    : inputState.letter_from
                      ? "success"
                      : ""
                    }`}
                />
                {errors.letter_from && (
                  <span className="error-message font-size-text ">
                    {errors.letter_from}
                  </span>
                )}
              </div>
              <div title="Letter To" className="flex-column">
                <label
                  htmlFor="letter_to"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Letter To<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="letter_to"
                  max={100}
                  name="letter_to"
                  placeholder="Letter To"
                  onChange={handleInputChange}
                  value={formData.letter_to}
                  className={`form-input ${errors.letter_to
                    ? "error"
                    : inputState.letter_to
                      ? "success"
                      : ""
                    }`}
                />
                {errors.letter_to && (
                  <span className="error-message font-size-text ">
                    {errors.letter_to}
                  </span>
                )}
              </div>
              <div className="flex-column">
                <label
                  htmlFor="names"
                  className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                >
                  Associated Letters
                </label>
                <Select
                  options={options}
                  isMulti
                  onChange={handleChangecc}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '33vw',
                      height: '50px',
                      borderRadius: '10px',
                      border: '1px solid #e3e3e3',
                      margin: '10px 0px 20px 0px',
                      color: '#4a4a4a',
                      backgroundColor: '#f7f8fa',
                      padding: '0px 10px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: '33vw',
                      borderRadius: '10px',
                      marginTop: '0',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                  }}
                />
                {errors.remarks && (
                  <span className="error-message font-size-text">
                    {errors.remarks}
                  </span>
                )}
              </div>
              {formData.associated_letters &&
                formData.associated_letters.length > 0 && (
                  <RenderSelectField
                    title="Update Status of Associated Letters"
                    name="associated_letters_status"
                    required={true}
                    options={[
                      { value: "pending", label: "Pending" },
                      { value: "close", label: "Close" },
                    ]}
                    placeholder="Select Status"
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                )}
            </div>

            <>
              <div className="jd-heading-outer  heading-bg-color-white">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">
                    Documents{" "}
                  </h2>
                  <div>
                    <button
                      title="Add Documents"
                      onClick={handleAddDocuments}
                      className="jd-heading-main font-weight600 -right-button"
                      style={{ float: "right" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_450_2151)">
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_450_2151">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <br />
                  </div>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Name</th>
                      <th className="align-center">File</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.documents?.map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.document_name}</td>
                          { }
                          <td className="align-center">
                            {i.document_file ? (
                              // <a href={i.document_file} target="blank">
                              //   View
                              // </a>
                              <a href={i.document_file} target="blank">
                                <DocumentIcon />
                              </a>
                            ) : (
                              "Document Not Attached"
                            )}
                          </td>

                          <td className="align-right width-5vw ">
                            <button
                              className="model-delete-button"
                              onClick={(e) =>
                                handlePicDelete(e, i, getProjects)
                              }
                            >
                              <DeleteDustbin />
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />

              <div title="Documents" className="">
                {/* <label className="form-labels font-weight500 font-size-subheading">Documents</label> */}
                {formDataDoc?.documents?.map((doc, index) => (
                  <div key={index} className="expense-req">
                    <div className="flex-column field-cont-div">
                      <label
                        htmlFor={`document_name_${index}`}
                        className="form-labels announce-date font-weight500 font-size-heading"
                      >
                        File Name
                      </label>
                      <input
                        type="text"
                        id={`document_name_${index}`}
                        name={`document_name`}
                        placeholder="Document Name"
                        value={doc.document_name}
                        onChange={(e) => handleDocInputChange(index, e)}
                        className={`form-modal-input ${errors[`document_name_${index}`]
                          ? "error"
                          : inputState[`document_name`]
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors[`document_name_${index}`] && (
                        <span className="error-message font-size-text">
                          {errors[`document_name_${index}`]}
                        </span>
                      )}
                    </div>

                    <div className="flex-column field-cont-div">
                      <label
                        htmlFor=""
                        className="form-labels announce-date font-weight500 font-size-heading"
                      >
                        Upload File
                      </label>
                      <label
                        htmlFor={`document_file_${index}`}
                        className={`form-inputss custom-file-upload ${errors[`document_file_${index}`]
                          ? "error"
                          : inputState[`document_file`]
                            ? "success"
                            : ""
                          }`}
                      >
                        <div className={`align-center pdf-input  ${errors[`document_file_${index}`] ? 'error' : ''}`}>
                          <Attachment />
                        </div>
                      </label>
                      <input
                        id={`document_file_${index}`}
                        name={`document_file`}
                        type="file"
                        onChange={(e) => handleDocInputChange(index, e)}
                        style={{ display: "none", position: "relative" }}
                      />
                      {fileNames[index] && (
                        <p className="file-name align-center">
                          {fileNames[index]}
                        </p>
                      )}

                      {errors[`document_file_${index}`] && (
                        <span className="error-message font-size-text">
                          {errors[`document_file_${index}`]}
                        </span>
                      )}
                    </div>

                    {index === 0 ? null : (
                      <button
                        type="button"
                        className="model-button-cancel"
                        onClick={() => handleRemoveDocument(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
              </div>

              <div className="button-models">
                <button
                  onClick={handleFormSubmit}
                  className="model-button   font-weight500    model-button-submit"
                  disabled={loading}
                >
                  {/* //loading logic */}
                  Update
                </button>
              </div>
            </>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteTrackAndLetterDetails = ({ i, getLetterDetail }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const ress = await axios.delete(
        `${BASE_URL}/siteproject/letterrecord/${i.id}/`
      );
      if (ress.status === 200) {
        await getLetterDetail();
        setshow(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Letter Records {i.letter_num}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Letter Records {i.letter_num}
            <div className="button-models">
              <button
                className="model-button   font-weight500    model-button-cancel"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};





// ! *************** Sector Crud Models Start ***************

const AddCircular = ({ getSiteCirculars }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setFormData({
      title: "",
      file: "",
      description: "",
      publish_date: "",
    });
    setShow(false);
    setErrors({})
  };
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
    file: "",
    description: "",
    publish_date: "",
  });

  // !  ************** Validation start **************  ! //
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title", "description", "file"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    // console.log(newErrors)
    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("title", formData.title);
      formDataa.append("description", formData.description);
      formDataa.append("publish_date", formData.publish_date);

      const symbols = "!@#$%^&*()_-+=";
      const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
      const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numbers = "0123456789";
      const now = new Date();
      const date = now
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-"); // Format: YYYY-MM-DD
      const time = now
        .toLocaleTimeString("en-GB", { hour12: false })
        .replace(/:/g, "-"); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = "";
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.file;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, {
        type: originalFile.type,
      });
      formDataa.append("file", customFile);

      try {
        let res = await axios.post(
          `${BASE_URL}/siteproject/circularupload/`,
          formDataa,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          await getSiteCirculars();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("Circular added successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add circular!");
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value.trim()) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];

    if (file) {
      const validFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (validFileTypes.includes(file.type)) {
        setFormData({
          ...formData,
          [name]: file,
        });
        setErrors({
          ...errors,
          [name]: "", // Clear any previous errors for this field
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF or DOC file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value.trim()) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });

  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <button title="Add Circular" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>{" "}
        Circular
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500    font-size-heading "
        >
          <Modal.Title> Add Circular</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading font-weight500    font-size-heading">Circular</h6> */}

              <form>
                <div className="flex-column">
                  <label
                    htmlFor="title"
                    className="form-labels font-weight500    announce-date font-weight400  font-size-heading" title="Title"
                  >
                    Title<span className="required">*</span>
                  </label>
                  <input
                    id="title"
                    title="Title"
                    type="title"
                    name="title"
                    maxLength={50}
                    placeholder="Title"
                    onChange={handleInputChange}
                    value={formData.title}
                    className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                      }`}
                  />
                  {errors.title && (
                    <span className="error-message font-size-text ">
                      {errors.title}
                    </span>
                  )}
                </div>
                <div className="flex-column">
                  <label
                    htmlFor="date"
                    className="form-labels font-weight500    announce-date font-weight400  font-size-heading" title="Publish Date"
                  >
                    Publish Date
                  </label>
                  <input
                    id="date"
                    title='Publish Date'
                    type="date"
                    name="publish_date"
                    placeholder="date"
                    onChange={handleInputChange}
                    value={formData.publish_date}
                    className={`form-input ${errors.publish_date
                      ? "error"
                      : inputState.publish_date
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.publish_date && (
                    <span className="error-message font-size-text ">
                      {errors.publish_date}
                    </span>
                  )}
                </div>

                <div className="flex-column">
                  <label
                    htmlFor="description"
                    className="form-labels font-weight500    announce-date font-weight400  font-size-heading"
                    title="Description"
                  >
                    Description<span className="required">*</span>
                  </label>
                  <textarea
                    placeholder="Description"
                    title="Description"
                    type="text"
                    name="description"
                    onChange={handleInputChange}
                    value={formData.description}
                    className={`form-input font-weight400  font-size-subheading  ${errors.description
                      ? "error"
                      : inputState.description
                        ? "success"
                        : ""
                      }`}
                  />

                  {errors.description && (
                    <span className="error-message font-size-text ">
                      {errors.description}
                    </span>
                  )}
                </div>

                <div className="flex-column">
                  <label
                    htmlFor="file"
                    className="form-labels font-weight500    announce-date font-weight400  font-size-heading" title="Attachement"
                  >
                    Attachment<span className="required">*</span>
                  </label>
                  <label
                    htmlFor={`file`}
                    className={`form-inputss custom-file-upload ${errors.file
                      ? "error" :
                      ""
                      }`}
                  >
                    <div className={`align-center pdf-input  ${errors.file ? 'error' : ''}`}>
                      <Attachment />
                    </div>
                  </label>
                  <input
                    id="file"
                    type="file"
                    title="Attachment"
                    name="file"
                    accept=".pdf, .doc, .docx, .xls, .xlsx"
                    style={{ display: "none", position: "relative" }}
                    onChange={handleFileChange}
                    className="file-input"
                  />
                  <br />
                  <div>
                    {errors.file && (
                      <span className="error-message font-size-text circular-file-error">
                        {errors.file}
                      </span>
                    )}
                  </div>
                  <div>
                    {formData.file ? (
                      <span
                        className="file-clear"
                        onClick={() => handleFileClear("file")}
                      >
                        Clear
                      </span>
                    ) : null}
                  </div>
                </div>

              </form>
              <div className="button-models">
                <button
                  className="model-button font-weight500 model-button-cancel"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
              <p className="error-message font-size-text">
                {isSubmitted && Object.keys(errors).length > 0 && (
                  Object.keys(errors).length > 5 ? (
                    <h5 className="text-center">Please fill all mandatory fields!</h5>
                  ) : (

                    <h6 className="text-center">
                      {Object.keys(errors).map((field) =>
                        field.charAt(0).toUpperCase() + field.slice(1)
                      ).join(', ') + ' are required!'}
                    </h6>
                  )
                )}
              </p>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ApproveCircular = ({ i, getCircular }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(
        `${BASE_URL}/siteproject/circularuploadupdate/${i.id}/`,
        {
          admin_approval_status: "approved",
        }
      );

      if (res.status === 200) {
        await getCircular();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Approve Circular"
        className="model-edit-button"
        onClick={handleShow}
      >
        <CheckGreen />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Approve Circular {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Approve Circular {i.title}
            <div className="button-models">
              <button
                className="model-button   font-weight500     model-button-cancel  "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-submit"
              >
                Approve
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const RejectCircular = ({ i, getCircular }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(
        `${BASE_URL}/siteproject/circularuploadupdate/${i.id}/`,
        {
          admin_approval_status: "rejected",
        }
      );

      if (res.status === 200) {
        await getCircular();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Rejected Circular"
        className="model-delete-button"
        onClick={handleShow}
      >
        <CrossRed />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Rejected Circular {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to rejected circular {i.title}
            <div className="button-models">
              <button
                className="model-button   font-weight500     model-button-cancel  "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Rejected
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteCircular = ({ i, getCircular }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/circularuploadupdate/${i.id}/`
      );

      if (res.status === 200) {
        await getCircular();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Site Designation"
        className="model-delete-button"
        onClick={handleShow}
      >
        <DeleteDustbin />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Circular {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Circular {i.title}
            <div className="button-models">
              <button
                className="model-button   font-weight500   model-button-cancel  "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const AddInsurance = ({ project, getProjects }) => {
  const [show, setShow] = useState(false);
  const [inputState, setInputState] = useState({});
  const handleClose = () => {
    setShow(false);
    setFormData({
      project: project,
      insurance_policy_num: "",
      insurance_file_upload: "",
      insurance_policy_start_date: null,
      insurance_name: "",
      insurance_company_name: "",
      insurance_amount: "",
      insurance_policy_valid_upto_date: null,
    });
    setDocNames("");
  };
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    project: project,
    insurance_policy_num: "",
    insurance_file_upload: "",
    insurance_policy_start_date: null,
    insurance_name: "",
    insurance_company_name: "",
    insurance_amount: "",
    insurance_policy_valid_upto_date: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "insurance_policy_num",
      "insurance_company_name",
      "insurance_policy_start_date",
      "insurance_name",
      "insurance_policy_valid_upto_date",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [docNames, setDocNames] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const symbols = "!@#$%^&*()_-+=";
      const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
      const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numbers = "0123456789";
      const now = new Date();
      const date = now
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-"); // Format: YYYY-MM-DD
      const time = now
        .toLocaleTimeString("en-GB", { hour12: false })
        .replace(/:/g, "-"); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = "";
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${file.name}`;
      const customFile = new File([file], customFileName, { type: file.type });

      setFormData({
        ...formData,
        insurance_file_upload: customFile,
      });
      setDocNames(customFileName);
    } else {
      setDocNames("");
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      insurance_file_upload: null,
    });
    setDocNames("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSubmit.append(key, formData[key]);
      });

      try {
        const res = await axios.post(
          `${BASE_URL}/siteproject/insurance/`,
          formDataToSubmit,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          handleClose();
          await getProjects();
          toast.dismiss(loadingToastId);
          toast.success("Insurance detail added successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add Insurance detail!");
        }
      } catch (err) {
      } finally {
        setLoading(false);
        // toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Add Insurance" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>{" "}
        Insurance
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500    font-size-heading "
        >
          <Modal.Title> Add Insurance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleFormSubmit}>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Insurance Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="insurance_name"
                    placeholder="Insurance Name"
                    value={formData.insurance_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_name ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_name && (
                    <div className="error-message font-size-text">
                      {errors.insurance_name}
                    </div>
                  )}
                </div>

                <div className="flex-column" title="Insurance Company Name">
                  <label
                    className="form-labels font-weight500 font-size-subheading"
                    htmlFor="insurance_company_name"
                  >
                    Company Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="insurance_company_name"
                    name="insurance_company_name"
                    placeholder="Insurance Company Name"
                    value={formData.insurance_company_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_company_name ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_company_name && (
                    <div className="error-message font-size-text">
                      {errors.insurance_company_name}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Amount <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    name="insurance_amount"
                    placeholder="0"
                    value={formData.insurance_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_amount ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_amount && (
                    <div className="error-message font-size-text">
                      {errors.insurance_amount}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    {" "}
                    Policy Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="insurance_policy_num"
                    placeholder="Insurance Policy Number"
                    value={formData.insurance_policy_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_policy_num ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_policy_num && (
                    <div className="error-message font-size-text">
                      {errors.insurance_policy_num}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Document
                  </label>
                  <div className="flex-row">
                    <label
                      htmlFor={`insurance_file_upload`}
                      className={` custom-file-upload ${errors[`insurance_file_upload`]
                        ? "error"
                        : inputState[`insurance_file_upload`]
                          ? "success"
                          : ""
                        }`}
                    >
                      <div className={`align-center pdf-input  ${errors[`insurance_file_upload`] ? 'error' : ''}`}>
                        <Attachment />
                      </div>
                    </label>
                    <input
                      id="insurance_file_upload"
                      name="insurance_file_upload"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none", position: "relative" }}
                    />
                    {docNames && (
                      <p className="file-name align-center">{docNames}</p>
                    )}
                    {formData.insurance_file_upload ? (
                      <span
                        className="file-clear model-button-cancel"
                        onClick={() => handleFileClear("file")}
                      >
                        Clear
                      </span>
                    ) : null}
                    {errors.insurance_file_upload && (
                      <div className="error-message font-size-text">
                        {errors.insurance_file_upload}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Policy Start Date <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    name="insurance_policy_start_date"
                    placeholder="Insurance Policy Start Date"
                    value={formData.insurance_policy_start_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_policy_start_date ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_policy_start_date && (
                    <div className="error-message font-size-text">
                      {errors.insurance_policy_start_date}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Policy Valid Upto <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    name="insurance_policy_valid_upto_date"
                    placeholder="Insurance Policy Valid Upto"
                    value={formData.insurance_policy_valid_upto_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_policy_valid_upto_date
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  {errors.insurance_policy_valid_upto_date && (
                    <div className="error-message font-size-text">
                      {errors.insurance_policy_valid_upto_date}
                    </div>
                  )}
                </div>
                <div className="button-models">
                  <button
                    className="model-button font-weight500 model-button-leave font-size-heading"
                    type="submit"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    {loading ? "Adding..." : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const UpdateInsurance = ({ project, getProjects, i }) => {
  const [show, setShow] = useState(false);
  const [inputState, setInputState] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setFormData(i);
  };

  const [formData, setFormData] = useState({
    project: project,
    insurance_policy_num: "",
    insurance_file_upload: i.insurance_file_upload,
    insurance_policy_start_date: null,
    insurance_name: "",
    insurance_company_name: "",
    insurance_amount: "",
    insurance_policy_valid_upto_date: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "insurance_policy_num",
      "insurance_company_name",
      "insurance_policy_start_date",
      "insurance_amount",
      "insurance_name",
      "insurance_policy_valid_upto_date",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      const { insurance_file_upload, ...dataWithoutDocument } = formData;

      try {
        const res = await axios.put(
          `${BASE_URL}/siteproject/insurance/${i.id}/`,
          dataWithoutDocument
        );

        if (res.status === 200) {
          setShow(false);
          await getProjects();
          toast.dismiss(loadingToastId);
          toast.success("Insurance detail Updated successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to Update Insurance detail!");
        }
      } catch (err) {
      } finally {
        setLoading(false);
        // toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button
        title="Update Insurance"
        className="model-edit-button"
        onClick={handleShow}
      >
        <UpdatePencil />
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500    font-size-heading "
        >
          <Modal.Title> Update Insurance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleFormSubmit}>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Insurance Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="insurance_name"
                    placeholder="Insurance Name"
                    value={formData.insurance_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_name ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_name && (
                    <div className="error-message font-size-text">
                      {errors.insurance_name}
                    </div>
                  )}
                </div>

                <div className="flex-column" title="Insurance Company Name">
                  <label
                    className="form-labels font-weight500 font-size-subheading"
                    htmlFor="insurance_company_name"
                  >
                    Company Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="insurance_company_name"
                    name="insurance_company_name"
                    placeholder="Insurance Company Name"
                    value={formData.insurance_company_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_company_name ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_company_name && (
                    <div className="error-message font-size-text">
                      {errors.insurance_company_name}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Amount <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    name="insurance_amount"
                    placeholder="0"
                    value={formData.insurance_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_amount ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_amount && (
                    <div className="error-message font-size-text">
                      {errors.insurance_amount}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    {" "}
                    Policy Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="insurance_policy_num"
                    placeholder="Insurance Policy Number"
                    value={formData.insurance_policy_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_policy_num ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_policy_num && (
                    <div className="error-message font-size-text">
                      {errors.insurance_policy_num}
                    </div>
                  )}
                </div>
                {/* <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Document</label>
                  <div className="flex-row">

                    <label htmlFor={`insurance_file_upload`} className={` custom-file-upload ${errors[`insurance_file_upload`] ? "error" : inputState[`insurance_file_upload`] ? "success" : ""}`}>
                      <svg className="pdf-input" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                        <path d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831" stroke="#707070" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </label>
                    <input
                      id="insurance_file_upload"
                      name="insurance_file_upload"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none", position: "relative" }}
                    />
                    {docNames && <p className="file-name align-center">{docNames}</p>}
                    {formData.insurance_file_upload ? (
                      <span
                        className="file-clear model-button-cancel"
                        onClick={() => handleFileClear("file")}
                      >
                        Clear
                      </span>
                    ) : null}
                    {errors.insurance_file_upload && <div className="error-message font-size-text">{errors.insurance_file_upload}</div>}
                  </div>
                </div> */}
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Policy Start Date <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    name="insurance_policy_start_date"
                    placeholder="Insurance Policy Start Date"
                    value={formData.insurance_policy_start_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_policy_start_date ? "is-invalid" : ""
                      }`}
                  />
                  {errors.insurance_policy_start_date && (
                    <div className="error-message font-size-text">
                      {errors.insurance_policy_start_date}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Policy Valid Upto <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    name="insurance_policy_valid_upto_date"
                    placeholder="Insurance Policy Valid Upto"
                    value={formData.insurance_policy_valid_upto_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.insurance_policy_valid_upto_date
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  {errors.insurance_policy_valid_upto_date && (
                    <div className="error-message font-size-text">
                      {errors.insurance_policy_valid_upto_date}
                    </div>
                  )}
                </div>
                <div className="button-models">
                  <button
                    className="model-button font-weight500 model-button-leave font-size-heading"
                    type="submit"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteInsurance = ({ i, getInsuranceData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/insurance/${i.id}/`
      );

      if (res.status === 200) {
        await getInsuranceData();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Insurance"
        className="model-delete-button"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Insurance {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Insurance {i.title}
            <div className="button-models">
              <button
                className="model-button   font-weight500    "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const AddBankGuarantee = ({ getbg, project }) => {
  const [show, setShow] = useState(false);
  const [inputState, setInputState] = useState({});
  const handleClose = () => {
    setShow(false);
    setFormData({
      project: project,
      bank_name: "",
      bg_num: "",
      bg_document: "",
      bg_date: "",
      bg_expiry_date: "",
      bg_release_date: "",
      bg_remark: "",
      bg_amount: "",
    });
    setDocNames("");
  }
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    project: project,
    bank_name: "",
    bg_num: "",
    bg_document: "",
    bg_date: "",
    bg_expiry_date: "",
    bg_release_date: "",
    bg_remark: "",
    bg_amount: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "bank_name",
      "bg_num",
      "bg_document",
      "bg_date",
      "bg_expiry_date",
      "bg_remark",
      "bg_amount",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [docNames, setDocNames] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const symbols = "!@#$%^&*()_-+=";
      const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
      const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numbers = "0123456789";
      const now = new Date();
      const date = now
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-"); // Format: YYYY-MM-DD
      const time = now
        .toLocaleTimeString("en-GB", { hour12: false })
        .replace(/:/g, "-"); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = "";
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${file.name}`;
      const customFile = new File([file], customFileName, { type: file.type });

      setFormData({
        ...formData,
        bg_document: customFile,
      });
      setDocNames(customFileName);
    } else {
      setDocNames("");
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      bg_document: null,
    });
    setDocNames("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSubmit.append(key, formData[key]);
      });

      try {
        const res = await axios.post(
          `${BASE_URL}/siteproject/bgdetails/`,
          formDataToSubmit,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          setShow(false);
          await getbg();
          toast.dismiss(loadingToastId);
          toast.success("BG detail added successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add BG detail!");
        }
      } catch (err) {
      } finally {
        setLoading(false);
        // toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Add BG" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>{" "}
        Bank Guarantee
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500    font-size-heading "
        >
          <Modal.Title> Add Bank Guarantee Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleFormSubmit}>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Bank Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="bank_name"
                    placeholder="Bank Name"
                    value={formData.bank_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bank_name ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bank_name && (
                    <div className="error-message font-size-text">
                      {errors.bank_name}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    {" "}
                    Bank Guarantee Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="bg_num"
                    placeholder="Bank Guarantee Number"
                    value={formData.bg_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_num ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_num && (
                    <div className="error-message font-size-text">
                      {errors.bg_num}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    {" "}
                    Amount <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    name="bg_amount"
                    placeholder="Amount"
                    min={0}
                    value={formData.bg_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_amount ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_amount && (
                    <div className="error-message font-size-text">
                      {errors.bg_amount}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Document<span className="required">*</span>
                  </label>
                  <div className="flex-row">
                    <label
                      htmlFor={`bg_document`}
                      className={` custom-file-upload ${errors[`bg_document`]
                        ? "error"
                        : inputState[`bg_document`]
                          ? "success"
                          : ""
                        }`}
                    >
                      <div className={`align-center pdf-input  ${errors[`bg_document`] ? 'error' : ''}`}>
                        <Attachment />
                      </div>
                    </label>
                    <input
                      id="bg_document"
                      name="bg_document"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none", position: "relative" }}
                    />
                    {docNames && (
                      <p className="file-name align-center">{docNames}</p>
                    )}
                    {formData.bg_document ? (
                      <span
                        className="file-clear model-button-cancel"
                        onClick={() => handleFileClear("file")}
                      >
                        Clear
                      </span>
                    ) : null}
                    {errors.bg_document && (
                      <div className="error-message font-size-text">
                        {errors.bg_document}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Bank Guarantee Date <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    name="bg_date"
                    placeholder="Bank Guarantee Date"
                    value={formData.bg_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_date ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_date && (
                    <div className="error-message font-size-text">
                      {errors.bg_date}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Bank Guarantee Expiry Date{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    name="bg_expiry_date"
                    placeholder="Bank Guarantee Expiry Date"
                    value={formData.bg_expiry_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_expiry_date ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_expiry_date && (
                    <div className="error-message font-size-text">
                      {errors.bg_expiry_date}
                    </div>
                  )}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Release Date
                  </label>
                  <input
                    type="date"
                    name="bg_release_date"
                    placeholder="Bank Release Date"
                    value={formData.bg_release_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_release_date ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_release_date && (
                    <div className="error-message font-size-text">
                      {errors.bg_release_date}
                    </div>
                  )}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Remarks <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="bg_remark"
                    placeholder="Remarks"
                    value={formData.bg_remark}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_remark ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_remark && (
                    <div className="error-message font-size-text">
                      {errors.bg_remark}
                    </div>
                  )}
                </div>
                <div className="button-models">
                  <button
                    className="model-button font-weight500 model-button-leave font-size-heading"
                    type="submit"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    {loading ? "Adding..." : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const UpdateBankGuarantee = ({ getbg, project, i, document }) => {
  const [show, setShow] = useState(false);
  const [inputState, setInputState] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setFormData(i);
  };

  const [formData, setFormData] = useState({
    project: project,
    bank_name: "",
    bg_num: "",
    bg_date: "",
    bg_expiry_date: "",
    bg_remark: "",
    bg_amount: "",
    bg_release_date: "",
    bg_document: i.bg_document, // This is displayed but not editable
  });

  console.log(formData.bg_document);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "bank_name",
      "bg_amount",
      "bg_num",
      "bg_date",
      "bg_expiry_date",
      "bg_remark",
      "bg_release_date",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        // Destructure and remove `bg_document` from `formData`
        const { bg_document, ...dataWithoutDocument } = formData;

        // Make the PUT request without sending `bg_document`
        const res = await axios.put(
          `${BASE_URL}/siteproject/bgdetails/${i.id}/`,
          dataWithoutDocument
        );

        if (res.status === 200) {
          setShow(false);
          await getbg();
          toast.dismiss(loadingToastId);
          toast.success("BG detail updated successfully!");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to update BG detail!");
        }
      } catch (err) {
        // Handle error
        toast.dismiss(loadingToastId);
        toast.error("Error occurred while updating the details!");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading "
        >
          <Modal.Title> Update Bank Guarantee Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handleFormSubmit}>
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    Bank Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="bank_name"
                    placeholder="Bank Name"
                    value={formData.bank_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bank_name ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bank_name && (
                    <div className="error-message font-size-text">
                      {errors.bank_name}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    {" "}
                    Bank Guarantee Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="bg_num"
                    placeholder="Bank Guarantee Number"
                    value={formData.bg_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_num ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_num && (
                    <div className="error-message font-size-text">
                      {errors.bg_num}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    {" "}
                    Amount <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    name="bg_amount"
                    placeholder="Amount"
                    min={0}
                    value={formData.bg_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_amount ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_amount && (
                    <div className="error-message font-size-text">
                      {errors.bg_amount}
                    </div>
                  )}
                </div>

                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    Bank Guarantee Date <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    name="bg_date"
                    placeholder="Bank Guarantee Date"
                    value={formData.bg_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_date ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_date && (
                    <div className="error-message font-size-text">
                      {errors.bg_date}
                    </div>
                  )}
                </div>

                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    Bank Guarantee Expiry Date{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    name="bg_expiry_date"
                    placeholder="Bank Guarantee Expiry Date"
                    value={formData.bg_expiry_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_expiry_date ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_expiry_date && (
                    <div className="error-message font-size-text">
                      {errors.bg_expiry_date}
                    </div>
                  )}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">
                    Release Date
                  </label>
                  <input
                    type="date"
                    name="bg_release_date"
                    placeholder="Bank Release Date"
                    value={formData.bg_release_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_release_date ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_release_date && (
                    <div className="error-message font-size-text">
                      {errors.bg_release_date}
                    </div>
                  )}
                </div>

                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    Remarks <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="bg_remark"
                    placeholder="Remarks"
                    value={formData.bg_remark}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bg_remark ? "is-invalid" : ""
                      }`}
                  />
                  {errors.bg_remark && (
                    <div className="error-message font-size-text">
                      {errors.bg_remark}
                    </div>
                  )}
                </div>
                <div className="button-models">
                  <button
                    className="model-button font-weight500 model-button-leave font-size-heading"
                    type="submit"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteBg = ({ i, getbg }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/bgdetails/${i.id}/`
      );

      if (res.status === 200) {
        await getbg();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Bank Guarantee"
        className="model-delete-button"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Bank Guarantee {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Bank Guarantee {i.title}
            <div className="button-models">
              <button
                className="model-button   font-weight500    "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const AddContractor = ({
  projectId,
  getProjectContractorDetails,
  getProjects,
  getDetails,
  onAddContractor,
  contractor,
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    project: projectId,
    name: "",
    cost: "",
    project_length: "",
    contractor_bid_due_date: null,
    loa_award_date: null,
    agreement_date: null,
    project_duration: "",
    commencement_date: null,
    actual_completion_date: null,
    schedule_completion_date: null,

    actual_construction_completion_date: null,
    schedule_construction_completion_date: null,
    contractor_address: "",
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const handleClose = () => setShow(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEotForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "cost",
      "project_length",
      "project_duration",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAxiosError = (err) => {
    handleErrorToast(err);
  };

  const handlePost = async (e) => {
    e.preventDefault();
    if (validateEotForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        const res = await axios.post(
          `${BASE_URL}/siteproject/contractor/`,
          formData
        );

        if (res.status === 200) {
          if (onAddContractor) {
            onAddContractor(); // Call the callback to notify the parent
          }
          await getProjectContractorDetails();
          await getProjects();
          getDetails();
          handleClose();
        } else {
          toast.error(`Error: ${res.statusText}`);
        }
      } catch (err) {
        handleAxiosError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button
        title="Add Contractor"
        className="upload-svg"
        onClick={() => setShow(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>{" "}
        Contractor
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Contractor Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <form onSubmit={handlePost}>
            <div className="form-flex-wrap">
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Contractor Name">
                  Contractor Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  title="Contractor Name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text">
                    {errors.name}
                  </span>
                )}
              </div>
              <div title="Contractor Address" className="flex-column">
                <label
                  htmlFor="contractor_address"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Contractor Address
                </label>
                <textarea
                  type="text"
                  id="contractor_address"
                  name="contractor_address"
                  placeholder="Contractor Address"
                  onChange={handleInputChange}
                  rows="50"
                  cols="100"
                  value={formData.contractor_address}
                  className={`form-input ${errors.contractor_address
                    ? "error"
                    : inputState.contractor_address
                      ? "success"
                      : ""
                    }`}

                />
                {errors.contractor_address && (
                  <span className="error-message font-size-text ">
                    {errors.contractor_address}
                  </span>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="cost">
                  Cost<span className="required">*</span>
                </label>
                <input
                  type="number"
                  title="Cost"
                  name="cost"
                  placeholder="Cost"
                  value={formData.cost}
                  onChange={handleInputChange}
                  className={`form-input-date width-17vw ${errors.cost ? "error" : inputState.cost ? "success" : ""
                    }`}
                />
                {errors.cost && (
                  <span className="error-message font-size-text">
                    {errors.cost}
                  </span>
                )}
              </div>

              <div title="Project Length" className="flex-column">
                <label
                  htmlFor="project_length"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Length (in kms)<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="project_length"
                  name="project_length"
                  placeholder="10"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input-date width-17vw ${errors.project_length
                    ? "error"
                    : inputState.project_length
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_length && (
                  <span className="error-message font-size-text ">
                    {errors.project_length}
                  </span>
                )}
              </div>


              <div title="Project Duration" className="flex-column">
                <label
                  htmlFor="project_duration"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Duration (in months)
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="project_duration"
                  name="project_duration"
                  placeholder="Project Duration"
                  onChange={handleInputChange}
                  value={formData.project_duration}
                  className={`form-input-date width-17vw ${errors.project_duration
                    ? "error"
                    : inputState.project_duration
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_duration && (
                  <span className="error-message font-size-text ">
                    {errors.project_duration}
                  </span>
                )}
              </div>

              <div title="Contractor Bid Due Date" className="flex-column">
                <label
                  htmlFor="contractor_bid_due_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Contractor Bid Due Date
                </label>
                <input
                  type="date"
                  id="contractor_bid_due_date"
                  name="contractor_bid_due_date"
                  placeholder="Contractor Bid Due Date"
                  onChange={handleInputChange}
                  value={formData.contractor_bid_due_date}
                  className={`form-input-date width-17vw ${errors.contractor_bid_due_date
                    ? "error"
                    : inputState.contractor_bid_due_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.contractor_bid_due_date && (
                  <span className="error-message font-size-text ">
                    {errors.contractor_bid_due_date}
                  </span>
                )}
              </div>

              <div title="LOA Date" className="flex-column">
                <label
                  htmlFor="loa_award_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  LOA Date
                </label>
                <input
                  type="date"
                  id="loa_award_date"
                  name="loa_award_date"
                  min={formData.contractor_bid_due_date}
                  placeholder="Award Date"
                  onChange={handleInputChange}
                  value={formData.loa_award_date}
                  className={`form-input-date width-17vw ${errors.loa_award_date
                    ? "error"
                    : inputState.loa_award_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.loa_award_date && (
                  <span className="error-message font-size-text ">
                    {errors.loa_award_date}
                  </span>
                )}
              </div>

              <div title="Agreement Date" className="flex-column">
                <label
                  htmlFor="agreement_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Agreement Date
                </label>
                <input
                  type="date"
                  id="agreement_date"
                  name="agreement_date"
                  min={formData.contractor_bid_due_date}
                  placeholder="Agreement Date"
                  onChange={handleInputChange}
                  value={formData.agreement_date}
                  className={`form-input-date width-17vw ${errors.agreement_date
                    ? "error"
                    : inputState.agreement_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.agreement_date && (
                  <span className="error-message font-size-text ">
                    {errors.agreement_date}
                  </span>
                )}
              </div>

              <div title="Commencement Date" className="flex-column">
                <label
                  htmlFor="commencement_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Commencement Date
                </label>
                <input
                  type="date"
                  id="commencement_date"
                  name="commencement_date"
                  min={formData.loa_award_date}
                  placeholder="Commencement Date"
                  onChange={handleInputChange}
                  value={formData.commencement_date}
                  className={`form-input-date width-17vw ${errors.commencement_date
                    ? "error"
                    : inputState.commencement_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.commencement_date && (
                  <span className="error-message font-size-text ">
                    {errors.commencement_date}
                  </span>
                )}
              </div>

              <div
                title="Schedule Construction Completion Date"
                className="flex-column"
              >
                <label
                  htmlFor="schedule_construction_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Schedule Const. Completion Date

                </label>
                <input
                  type="date"
                  id="schedule_construction_completion_date"
                  name="schedule_construction_completion_date"
                  min={formData.commencement_date}
                  placeholder="Schedule Construction Completion Date"
                  onChange={handleInputChange}
                  value={formData.schedule_construction_completion_date}
                  className={`form-input-date width-17vw ${errors.schedule_construction_completion_date
                    ? "error"
                    : inputState.schedule_construction_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.schedule_construction_completion_date && (
                  <span className="error-message font-size-text ">
                    {errors.schedule_construction_completion_date}
                  </span>
                )}
              </div>

              <div title="Schedule Completion Date" className="flex-column">
                <label
                  htmlFor="schedule_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Schedule Completion Date
                </label>
                <input
                  type="date"
                  id="schedule_completion_date"
                  name="schedule_completion_date"
                  min={formData.schedule_construction_completion_date}
                  placeholder="Schedule Completion Date"
                  onChange={handleInputChange}
                  value={formData.schedule_completion_date}
                  className={`form-input-date width-17vw ${errors.schedule_completion_date
                    ? "error"
                    : inputState.schedule_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.schedule_completion_date && (
                  <span className="error-message font-size-text ">
                    {errors.schedule_completion_date}
                  </span>
                )}
              </div>

              <div title="Actual Completion Date" className="flex-column">
                <label
                  htmlFor="actual_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Actual Completion Date
                </label>
                <input
                  type="date"
                  id="actual_completion_date"
                  name="actual_completion_date"
                  placeholder="Actual Completion Date"
                  onChange={handleInputChange}
                  value={formData.actual_completion_date}
                  className={`form-input-date width-17vw ${errors.actual_completion_date
                    ? "error"
                    : inputState.actual_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.actual_completion_date && (
                  <span className="error-message font-size-text ">
                    {errors.actual_completion_date}
                  </span>
                )}
              </div>

              <div
                title="Actual Construction Completion Date"
                className="flex-column"
              >
                <label
                  htmlFor="actual_construction_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Actual Const. Completion Date
                </label>
                <input
                  type="date"
                  id="actual_construction_completion_date"
                  name="actual_construction_completion_date"
                  placeholder="Actual Construction Completion Date"
                  onChange={handleInputChange}
                  value={formData.actual_construction_completion_date}
                  className={`form-input-date width-17vw ${errors.actual_construction_completion_date
                    ? "error"
                    : inputState.actual_construction_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.actual_construction_completion_date && (
                  <span className="error-message font-size-text ">
                    {errors.actual_construction_completion_date}
                  </span>
                )}
              </div>


            </div>
            <div className="button-models">
              <button
                className="model-button font-weight500 model-button-leave font-size-heading"
                type="submit"
                disabled={loading}
              >
                {loading ? "Adding..." : "Add"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateContractor = ({
  projectId,
  i,
  contractor,
  getContractorDetails,
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    project: projectId,
    name: "",
    cost: "",
    project_length: "",
    contractor_bid_due_date: "null",
    loa_award_date: "null",
    agreement_date: "null",
    project_duration: "null",
    commencement_date: "null",
    actual_completion_date: "null",
    schedule_completion_date: "null",

    actual_construction_completion_date: "null",
    schedule_construction_completion_date: "null",
    contractor_address: "",
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    setFormData(i);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.type === "date") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value || null, // Set to null if cleared
      }));
    } else {

      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
  };

  const validateEotForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "cost",
      "project_length",
      "project_duration",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAxiosError = (err) => {
    handleErrorToast(err);
  };

  const handlePost = async (e) => {
    e.preventDefault();
    if (validateEotForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        const res = await axios.put(
          `${BASE_URL}/siteproject/contractor/${contractor}/`,
          formData
        );

        if (res.status === 200) {
          handleClose();
          await getContractorDetails();
        } else {
          toast.error(`Error: ${res.statusText}`);
        }
      } catch (err) {
        handleAxiosError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button
        title="Update Contractor"
        className="upload-svg"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="white"
          viewBox="0 0 24 24"
          id="pen-new-square"
        >
          <path
            fill=""
            fill-rule="evenodd"
            d="M11.9426 1.25L13.5 1.25C13.9142 1.25 14.25 1.58579 14.25 2C14.25 2.41421 13.9142 2.75 13.5 2.75H12C9.62177 2.75 7.91356 2.75159 6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.23505 4.75455 2.91245 5.86509 2.80417 8.03734C2.78355 8.45104 2.43146 8.76969 2.01776 8.74907C1.60406 8.72845 1.28541 8.37636 1.30603 7.96266C1.41642 5.74802 1.74641 4.12187 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM22 9.75C22.4142 9.75 22.75 10.0858 22.75 10.5V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574L1.25 12C1.25 11.5858 1.58579 11.25 2 11.25C2.41421 11.25 2.75 11.5858 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12V10.5C21.25 10.0858 21.5858 9.75 22 9.75Z"
            clip-rule="evenodd"
          ></path>
          <path
            fill=""
            fill-rule="evenodd"
            d="M16.7705 2.27592C18.1384 0.908029 20.3562 0.908029 21.7241 2.27592C23.092 3.6438 23.092 5.86158 21.7241 7.22947L18.0928 10.8607C17.7999 11.1536 17.3251 11.1536 17.0322 10.8607C16.7393 10.5678 16.7393 10.093 17.0322 9.80006L19.1051 7.72709C18.5892 7.50519 17.9882 7.14946 17.4193 6.58065C16.8505 6.01185 16.4948 5.41082 16.2729 4.89486L11.2175 9.95026C10.801 10.3668 10.6376 10.532 10.4988 10.71C10.3274 10.9297 10.1804 11.1676 10.0605 11.4192C9.96337 11.623 9.88868 11.8429 9.7024 12.4017L9.27051 13.6974L10.3026 14.7295L11.5983 14.2976C12.1571 14.1113 12.377 14.0366 12.5808 13.9395C12.8324 13.8196 13.0703 13.6726 13.2901 13.5012C13.468 13.3624 13.6332 13.199 14.0497 12.7825C14.3426 12.4896 14.8175 12.4896 15.1104 12.7825C15.4033 13.0754 15.4033 13.5503 15.1104 13.8432L15.076 13.8776C14.7047 14.2489 14.4721 14.4815 14.2126 14.684C13.9069 14.9224 13.5761 15.1268 13.2261 15.2936C12.929 15.4352 12.6169 15.5392 12.1188 15.7052L9.21426 16.6734C8.67801 16.8521 8.0868 16.7126 7.68711 16.3129C7.28742 15.9132 7.14785 15.322 7.3266 14.7857L8.29477 11.8812C8.46079 11.3831 8.56479 11.071 8.7064 10.7739C8.87319 10.4239 9.07761 10.0931 9.31605 9.78742C9.51849 9.52787 9.7511 9.29529 10.1224 8.92401L16.7705 2.27592ZM17.4546 3.7132C17.4773 3.80906 17.509 3.92327 17.5532 4.05066C17.6965 4.46372 17.9677 5.00771 18.48 5.51999C18.9923 6.03227 19.5363 6.30346 19.9493 6.44677C20.0767 6.49097 20.1909 6.52273 20.2868 6.54543L20.6634 6.16881C21.4455 5.38671 21.4455 4.11867 20.6634 3.33658C19.8813 2.55448 18.6133 2.55448 17.8312 3.33658L17.4546 3.7132Z"
            clip-rule="evenodd"
          ></path>
        </svg>{" "}
        Contractor
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Contractor Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <form onSubmit={handlePost}>
            <div className="form-flex-wrap">
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Contractor Name">
                  Contractor Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  title="Contractor Name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text">
                    {errors.name}
                  </span>
                )}
              </div>
              <div title="Contractor Address" className="flex-column">
                <label
                  htmlFor="contractor_address"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Contractor Address
                </label>
                <textarea
                  type="text"
                  id="contractor_address"
                  name="contractor_address"
                  placeholder="Contractor Address"
                  onChange={handleInputChange}
                  rows="50"
                  cols="100"
                  value={formData.contractor_address}
                  className={`form-input ${errors.contractor_address
                    ? "error"
                    : inputState.contractor_address
                      ? "success"
                      : ""
                    }`}

                />
                {errors.contractor_address && (
                  <span className="error-message font-size-text ">
                    {errors.contractor_address}
                  </span>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="cost">
                  Cost<span className="required">*</span>
                </label>
                <input
                  type="number"
                  title="Cost"
                  name="cost"
                  placeholder="Cost"
                  value={formData.cost}
                  onChange={handleInputChange}
                  className={`form-input-date width-17vw ${errors.cost ? "error" : inputState.cost ? "success" : ""
                    }`}
                />
                {errors.cost && (
                  <span className="error-message font-size-text">
                    {errors.cost}
                  </span>
                )}
              </div>

              <div title="Project Length" className="flex-column">
                <label
                  htmlFor="project_length"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Length (in kms)<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="project_length"
                  name="project_length"
                  placeholder="10"
                  onChange={handleInputChange}
                  value={formData.project_length}
                  className={`form-input-date width-17vw ${errors.project_length
                    ? "error"
                    : inputState.project_length
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_length && (
                  <span className="error-message font-size-text ">
                    {errors.project_length}
                  </span>
                )}
              </div>


              <div title="Project Duration" className="flex-column">
                <label
                  htmlFor="project_duration"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Project Duration (in months)
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="project_duration"
                  name="project_duration"
                  placeholder="Project Duration"
                  onChange={handleInputChange}
                  value={formData.project_duration}
                  className={`form-input-date width-17vw ${errors.project_duration
                    ? "error"
                    : inputState.project_duration
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_duration && (
                  <span className="error-message font-size-text ">
                    {errors.project_duration}
                  </span>
                )}
              </div>

              <div title="Contractor Bid Due Date" className="flex-column">
                <label
                  htmlFor="contractor_bid_due_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Contractor Bid Due Date
                </label>
                <input
                  type="date"
                  id="contractor_bid_due_date"
                  name="contractor_bid_due_date"
                  placeholder="Contractor Bid Due Date"
                  onChange={handleInputChange}
                  value={formData.contractor_bid_due_date}
                  className={`form-input-date width-17vw ${errors.contractor_bid_due_date
                    ? "error"
                    : inputState.contractor_bid_due_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.contractor_bid_due_date && (
                  <span className="error-message font-size-text ">
                    {errors.contractor_bid_due_date}
                  </span>
                )}
              </div>

              <div title="LOA Date" className="flex-column">
                <label
                  htmlFor="loa_award_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  LOA Date
                </label>
                <input
                  type="date"
                  id="loa_award_date"
                  name="loa_award_date"
                  min={formData.contractor_bid_due_date}
                  placeholder="Award Date"
                  onChange={handleInputChange}
                  value={formData.loa_award_date}
                  className={`form-input-date width-17vw ${errors.loa_award_date
                    ? "error"
                    : inputState.loa_award_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.loa_award_date && (
                  <span className="error-message font-size-text ">
                    {errors.loa_award_date}
                  </span>
                )}
              </div>

              <div title="Agreement Date" className="flex-column">
                <label
                  htmlFor="agreement_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Agreement Date
                </label>
                <input
                  type="date"
                  id="agreement_date"
                  name="agreement_date"
                  min={formData.contractor_bid_due_date}
                  placeholder="Agreement Date"
                  onChange={handleInputChange}
                  value={formData.agreement_date}
                  className={`form-input-date width-17vw ${errors.agreement_date
                    ? "error"
                    : inputState.agreement_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.agreement_date && (
                  <span className="error-message font-size-text ">
                    {errors.agreement_date}
                  </span>
                )}
              </div>

              <div title="Commencement Date" className="flex-column">
                <label
                  htmlFor="commencement_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Commencement Date
                </label>
                <input
                  type="date"
                  id="commencement_date"
                  name="commencement_date"
                  min={formData.loa_award_date}
                  placeholder="Commencement Date"
                  onChange={handleInputChange}
                  value={formData.commencement_date}
                  className={`form-input-date width-17vw ${errors.commencement_date
                    ? "error"
                    : inputState.commencement_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.commencement_date && (
                  <span className="error-message font-size-text ">
                    {errors.commencement_date}
                  </span>
                )}
              </div>

              <div
                title="Schedule Construction Completion Date"
                className="flex-column"
              >
                <label
                  htmlFor="schedule_construction_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Schedule Const. Completion Date

                </label>
                <input
                  type="date"
                  id="schedule_construction_completion_date"
                  name="schedule_construction_completion_date"
                  min={formData.commencement_date}
                  placeholder="Schedule Construction Completion Date"
                  onChange={handleInputChange}
                  value={formData.schedule_construction_completion_date}
                  className={`form-input-date width-17vw ${errors.schedule_construction_completion_date
                    ? "error"
                    : inputState.schedule_construction_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.schedule_construction_completion_date && (
                  <span className="error-message font-size-text ">
                    {errors.schedule_construction_completion_date}
                  </span>
                )}
              </div>

              <div title="Schedule Completion Date" className="flex-column">
                <label
                  htmlFor="schedule_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Schedule Completion Date
                </label>
                <input
                  type="date"
                  id="schedule_completion_date"
                  name="schedule_completion_date"
                  min={formData.schedule_construction_completion_date}
                  placeholder="Schedule Completion Date"
                  onChange={handleInputChange}
                  value={formData.schedule_completion_date}
                  className={`form-input-date width-17vw ${errors.schedule_completion_date
                    ? "error"
                    : inputState.schedule_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.schedule_completion_date && (
                  <span className="error-message font-size-text ">
                    {errors.schedule_completion_date}
                  </span>
                )}
              </div>

              <div title="Actual Completion Date" className="flex-column">
                <label
                  htmlFor="actual_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Actual Completion Date
                </label>
                <input
                  type="date"
                  id="actual_completion_date"
                  name="actual_completion_date"
                  placeholder="Actual Completion Date"
                  onChange={handleInputChange}
                  value={formData.actual_completion_date}
                  className={`form-input-date width-17vw ${errors.actual_completion_date
                    ? "error"
                    : inputState.actual_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.actual_completion_date && (
                  <span className="error-message font-size-text ">
                    {errors.actual_completion_date}
                  </span>
                )}
              </div>

              <div
                title="Actual Construction Completion Date"
                className="flex-column"
              >
                <label
                  htmlFor="actual_construction_completion_date"
                  className="form-labels  font-weight500    font-size-subheading"
                >
                  Actual Const. Completion Date
                </label>
                <input
                  type="date"
                  id="actual_construction_completion_date"
                  name="actual_construction_completion_date"
                  placeholder="Actual Construction Completion Date"
                  onChange={handleInputChange}
                  value={formData.actual_construction_completion_date}
                  className={`form-input-date width-17vw ${errors.actual_construction_completion_date
                    ? "error"
                    : inputState.actual_construction_completion_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.actual_construction_completion_date && (
                  <span className="error-message font-size-text ">
                    {errors.actual_construction_completion_date}
                  </span>
                )}
              </div>


            </div>
            <div className="button-models">
              <button
                className="model-button font-weight500 model-button-leave font-size-heading"
                type="submit"
                disabled={loading}
              >
                {loading ? "updating..." : "Update"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteContractor = ({ contractor, getProjectContractorDetails, getcontractor, getproject }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/contractor/${contractor}/`
      );

      if (res.status === 200) {
        await getProjectContractorDetails();
        await getproject();
        setShow(false);
        toast.success("Deleted Successfully");
        toast.dismiss(loadingToastId);

      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Contractor"
        className="model-delete-button"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Contractor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Contractor
            <div className="button-models">
              <button
                className="model-button font-weight500"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};









const AddEOTDetails = ({
  project,
  getEot,
  toggleState,
  contractorlist,
  contractor,
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    project: project,
    eot_code: "",
    workperformedby: toggleState,
    letter: "",
    contractor: toggleState === "contractor" ? contractor : "",
    process_initiate_date: "",
    eot_status: "pending"

  });

  const [trackRecordList, setTrackRecordList] = useState([]);
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const handleClose = () => {
    setShow(false);
    setFormData({
      project: project,
      eot_code: "",
      process_initiate_date: "",
      contractor: toggleState === "contractor" ? contractor : "",
      workperformedby: toggleState,
      letter: "",
      eot_status: "pending"

    })
  }



  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contractor: toggleState === "contractor" ? contractor : "",
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor]);



  const handleShow = () => {
    setShow(true)
  };

  const getLetters = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        contractor ?
          `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/eot/${contractor}/` :
          `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/eot/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLetters();
  }, [formData.workperformedby, contractor]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEotForm = () => {
    const newErrors = {};
    const requiredFields = [
      "eot_code",
      "letter",
      "process_initiate_date",

    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAxiosError = (err) => {
    handleErrorToast(err);
  };

  const handleEotPost = async (e) => {
    e.preventDefault();
    if (validateEotForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {


        const res = await axios.post(`${BASE_URL}/siteproject/eot/`, formData);

        if (res.status === 200) {
          await getEot();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("EOT details added successfully")
        } else {
          toast.error(`Error: ${res.statusText}`);
        }
      } catch (err) {
        handleAxiosError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };




  return (
    <>
      <button title='Add EOT' className='upload-svg float-right equal-hi' style={{ margin: 0 }} onClick={handleShow}>

        <AddwithWhiteCircle />
        {" "} EOT
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add EOT Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />


          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading"
              title="EOT Code">EOT Code<span className="required">*</span></label>
            <input
              type="text"
              name="eot_code"
              placeholder="EOT Code"
              title="EOT Code"
              value={formData.eot_code}
              onChange={handleInputChange}
              className={`form-input ${errors.eot_code ? "error" : inputState.eot_code ? "success" : ""}`}
            />
            {errors.eot_code && <span className="error-message font-size-text">{errors.eot_code}</span>}
          </div>

          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading" title="Process initiate Date">Process initiate Date<span className="required">*</span></label>
            <input
              type="date"
              name="process_initiate_date"
              title="Process initiate Date"
              value={formData.process_initiate_date}
              onChange={handleInputChange}
              className={`form-input ${errors.process_initiate_date ? "error" : inputState.process_initiate_date ? "success" : ""}`}
            />
            {errors.process_initiate_date && <span className="error-message font-size-text">{errors.process_initiate_date}</span>}
          </div>
          <div className="flex-column form-group-selectt">
            <label className="form-labels font-weight500 font-size-subheading" title="Initiate Letter">Initiate Letter<span className="required">*</span></label>
            <br />
            <select
              name="letter"
              title="Letter"
              value={formData.letter}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.letter ? "error" : inputState.letter ? "success" : ""}`}
            >
              <option value="" title="Select Option">Select Option</option>
              {trackRecordList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.letter_num}
                </option>
              ))}
            </select>
            <div className="form-group-selection-arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            {errors.letter && <div className="error-message font-size-text">{errors.letter}</div>}
          </div>
          {formData.letter ? (
            ""
          ) : (
            <>
              <div
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <div>OR</div>
              </div>

              <div className="button-models">
                <AddTrackRecordDetails
                  projectId={project}
                  contractor={contractor}
                  letterfor={formData.workperformedby}
                  lettertype="eot"
                  getProjects={getLetters}
                  title="EOT Letter"

                />
              </div>
            </>
          )}








          <div className="button-models">
            <button onClick={handleEotPost} type="button" className="model-button font-weight500 model-button-leave font-size-heading" disabled={loading}>
              Add
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};




const UpdateEOTDetails = ({ i, getEot, toggleState, project, contractor }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({

    eot_code: "",
    approved_date: null,
    approved_days: "",
    process_initiate_date: "",
    workperformedby: toggleState,
    close_letter: "",
    eot_status: "pending",



  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const handleClose = () => {
    setShow(false);

  }

  const handleShow = () => {
    setShow(true)
    setFormData(i);
    getLetters();

  };

  useEffect(() => {
    if (formData.eot_status === "rejected") {
      setFormData((prev) => ({
        ...prev,
        approved_days: "",
        close_letter: "",
      }))
    }
  }, [formData.eot_status]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEotForm = () => {
    const newErrors = {};
    const requiredFields = [
      // "project",
      // "eot_code",
      // "approved_date",
      // "approved_days",
      // "workperformedby",
      // "close_letter",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAxiosError = (err) => {
    handleErrorToast(err);
  };

  const handleEotPost = async (e) => {
    e.preventDefault();
    if (validateEotForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        // Create FormData object to handle file uploads

        const res = await axios.put(`${BASE_URL}/siteproject/eot/${i.id}/`, formData);

        if (res.status === 200) {
          await getEot();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("Eot details updated successfully");

        } else {
          toast.error(`Error: ${res.statusText}`);
        }
      } catch (err) {
        handleAxiosError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };


  const [trackRecordList, setTrackRecordList] = useState([]);
  const getLetters = async () => {
    try {
      const res = await axios.get(
        contractor ?
          `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/eot/${contractor}/`
          :
          `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/eot/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  useEffect(() => {
    getLetters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.workperformedby, contractor]);




  return (
    <>
      <button title='Update EOT' className="model-edit-button" onClick={handleShow}>

        <UpdatePencil />
      </button>


      <Modal show={show} onHide={handleClose} dialogClassName="request-leave ">
        <Modal.Header closeButton>
          <Modal.Title>Update EOT Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-flex-wrap">

            <div className="flex-column">
              <label className="form-labels font-weight500 font-size-subheading" title="EOT Code">
                EOT Code<span className="required">(Read-Only)</span>
              </label>
              <input
                type="text"
                name="eot_code"
                title="EOT Code"
                placeholder="EOT Code"
                value={formData.eot_code}
                onChange={handleInputChange}
                className={`form-input-date width-17vw ${errors.eot_code ? "error" : inputState.eot_code ? "success" : ""
                  }`}
                readOnly
              />
              {errors.eot_code && (
                <span className="error-message font-size-text">
                  {errors.eot_code}
                </span>
              )}
            </div>


            <div className="flex-column">
              <label className="form-labels font-weight500 font-size-subheading" title="Process Initiate Date">Process initiate Date<span className="required">(Read-Only)</span>
              </label>
              <input
                type="date"
                name="process_initiate_date"
                title="Process Initiate Date"
                value={formData.process_initiate_date}
                onChange={handleInputChange}
                className={`form-input-date width-17vw ${errors.process_initiate_date ? "error" : inputState.process_initiate_date ? "success" : ""}`}
                readOnly
              />
              {errors.process_initiate_date && <span className="error-message font-size-text">{errors.process_initiate_date}</span>}
            </div>


            <div className="flex-column">
              <label className="form-labels font-weight500 font-size-subheading" title="Close Date">
                Close Date
              </label>
              <input
                type="date"
                name="approved_date"
                title="Approved Date"
                value={formData.approved_date}
                min={formData.process_initiate_date}
                onChange={handleInputChange}
                className={`form-input-date width-17vw ${errors.approved_date
                  ? "error"
                  : inputState.approved_date
                    ? "success"
                    : ""
                  }`}
              />
              {errors.approved_date && (
                <span className="error-message font-size-text">
                  {errors.approved_date}
                </span>
              )}
            </div>
            <div className="flex-column form-group-selectt">
              <label className="form-labels font-weight500 font-size-subheading" title="EOT Status">EOT Status</label>
              <br />
              <select
                name="eot_status"
                title="EOT status"
                value={formData.eot_status}
                onChange={handleInputChange}
                className={`form-input-date width-17vw form-group-selection`}
              >
                <option value="pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="rejected">Rejected</option>

              </select>
              <div className="form-group-selection-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="25"
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
              {errors.eot_status && <div className="error-message font-size-text">{errors.eot_status}</div>}
            </div>
            {formData.eot_status === "Approved" &&
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Approved Days">
                  Approved Days
                </label>
                <input
                  type="text"
                  name="approved_days"
                  title="Approved Days"
                  placeholder="Approved Days"
                  value={formData.approved_days}
                  onChange={handleInputChange}
                  className={`form-input-date width-17vw ${errors.approved_days
                    ? "error"
                    : inputState.approved_days
                      ? "success"
                      : ""
                    }`}
                />
                {errors.approved_days && (
                  <span className="error-message font-size-text">
                    {errors.approved_days}
                  </span>
                )}
              </div>
            }



            <div className="flex-column form-group-selectt">
              <label className="form-labels font-weight500 font-size-subheading" title="Close Letter">
                Close Letter
              </label>
              <br />
              <select
                name="close_letter"
                title="Close Letter"
                value={formData.close_letter}
                onChange={handleInputChange}
                className={`form-input-date width-17vw form-group-selection ${errors.close_letter ? "error" : inputState.close_letter ? "success" : ""
                  }`}
              >
                <option value="">Select Option</option>
                {trackRecordList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.letter_num}
                  </option>
                ))}
              </select>
              <div className="form-group-selection-arrow">
                <DropdownArrowOption />
              </div>
              {errors.close_letter && (
                <div className="error-message font-size-text">
                  {errors.close_letter}
                </div>
              )}
            </div>



          </div>
          {formData.close_letter ? (
            ""
          ) : (
            <>
              <div
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <div>OR</div>
              </div>

              <div className="button-models">
                <AddTrackRecordDetails
                  projectId={project}
                  contractor={contractor}
                  letterfor={formData.workperformedby}
                  lettertype="eot"
                  getProjects={getLetters}
                  title="EOT Letter"

                />
              </div>
            </>
          )}



          <div className="button-models">
            <button
              onClick={handleEotPost}
              type="button"
              className="model-button font-weight500 model-button-leave font-size-heading"
              disabled={loading}
            >
              Update
            </button>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteEOT = ({ i, getEOT }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/eot/${i.id}/`
      );

      if (res.status === 200) {
        await getEOT();
        setShow(false);
        toast.dismiss(loadingToastId);
        toast.success("EOT Deleted Successfully");
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Insurance"
        className="model-delete-button"
        onClick={handleShow}
      >
        <DeleteDustbin />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete EOT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete EOT Data of {i.eot_code}
            <div className="button-models">
              <button
                className="model-button   font-weight500    "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};







const AddCOSDetails = ({ project, getProjects, toggleState, contractorlist, contractor }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setFormData({
      project: project,
      cos_code: "",
      contractor: toggleState === "contractor" ? contractor : "",
      amount: "",
      workperformedby: toggleState,
      letter: "",

    })

  }
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    project: project,
    cos_code: "",
    approval_date: null,
    contractor: toggleState === "contractor" ? contractor : "",
    process_initiate_date: null,
    cos_status: "pending",
    amount: "",
    workperformedby: toggleState,
    letter: "",
  });


  const [trackRecordList, setTrackRecordList] = useState([]);

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const getLetters = async () => {
    try {
      const res = await axios.get(
        contractor ?
          `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/cos/${contractor}/` :
          `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/cos/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  useEffect(() => {
    getLetters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.workperformedby, contractor]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEotForm = () => {
    const newErrors = {};
    const RequiredFields = [
      "cos_code",
      "amount",
      "process_initiate_date",
      "letter",
    ];

    RequiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  console.log(errors)

  const handleAxiosError = (err) => {
    handleErrorToast(err);
  };

  const handleCOSPost = async (e) => {
    e.preventDefault();

    if (validateEotForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/siteproject/cos/`, formData)

        if (res.status === 200) {
          await getProjects();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("COS data added successfully")
        } else {
          toast.error(`Error: ${res.statusText}`);
        }
      } catch (err) {
        //toast Logic
        handleAxiosError(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Add COS" className="upload-svg" style={{ margin: 0 }} onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "}
        COS
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add COS Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="COS Code">
                  COS Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  title="COS Code"
                  name="cos_code"
                  placeholder="COS Code"
                  value={formData.cos_code}
                  onChange={handleInputChange}
                  className={`form-input ${errors.cos_code
                    ? "error"
                    : inputState.cos_code
                      ? "success"
                      : ""
                    }`}
                />
                {errors.cos_code && (
                  <div className="error-message font-size-text">
                    {errors.cos_code}
                  </div>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Initate Date">
                  Initiate Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  title="Initiate Date"
                  name="process_initiate_date"
                  value={formData.process_initiate_date}
                  onChange={handleInputChange}
                  className={`form-input ${errors.process_initiate_date
                    ? "error"
                    : inputState.process_initiate_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.process_initiate_date && (
                  <div className="error-message font-size-text">
                    {errors.process_initiate_date}
                  </div>
                )}
              </div>


              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Amount">
                  Amount(Write full amount)<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="amount"
                  title="Amount"
                  placeholder="Amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  className={`form-input ${errors.amount ? "error" : inputState.amount ? "success" : ""
                    }`}
                />
                {errors.amount && (
                  <div className="error-message font-size-text">
                    {errors.amount}
                  </div>
                )}
              </div>


              <div className="flex-column form-group-selectt">
                <label className="form-labels font-weight500 font-size-subheading" title="Letter">
                  Letter<span className="required">*</span>
                </label>
                <br />
                <select
                  name="letter"
                  title="Letter"
                  value={formData.letter}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.letter ? "error" : inputState.letter ? "success" : ""
                    }`}
                >
                  <option value="">Select Option</option>
                  {trackRecordList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.letter_num}
                    </option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.letter && (
                  <div className="error-message font-size-text">
                    {errors.letter}
                  </div>
                )}
              </div>
              {formData.letter ? (
                ""
              ) : (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div>OR</div>
                  </div>

                  <div className="button-models">
                    <AddTrackRecordDetails
                      projectId={project}
                      contractor={contractor}
                      letterfor={formData.workperformedby}
                      lettertype="cos"
                      getProjects={getLetters}
                      title="COS Letter"

                    />
                  </div>
                </>
              )}

              <div className="button-models">
                <button
                  className="model-button  font-weight500    model-button-leave font-size-heading"
                  onClick={handleCOSPost}
                  disabled={loading}
                >
                  {loading ? "Adding..." : "Add"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
      />
    </>
  );
};




const UpdateCOSDetails = ({ i, getCos, toggleState, project, contractor }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);

  }
  const handleShow = () => {
    setShow(true);
    setFormData(i)

  }

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({

    cos_code: "",
    approval_date: null,
    cos_status: "pending",
    process_initiate_date: null,
    workperformedby: toggleState,
    amount: "",
    approved_amount: "",
    letter: "",
    close_letter: "",

  });




  const [trackRecordList, setTrackRecordList] = useState([]);

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const getLetters = async () => {
    try {
      const res = await axios.get(
        contractor ?
          `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/cos/${contractor}/` :
          `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/cos/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  useEffect(() => {
    getLetters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.workperformedby, contractor]);





  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });
  };

  const validateCOSForm = () => {
    const newErrors = {};
    const RequiredFields = [
    ];

    RequiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;

  };

  const handleAxiosError = (err) => {
    handleErrorToast(err);
  };


  const handleCOSPost = async (e) => {
    e.preventDefault();


    if (validateCOSForm()) {

      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {





        let res = await axios.put(`${BASE_URL}/siteproject/cos/${i.id}/ `, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        if (res.status === 200) {
          await getCos();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("COS data updated successfully!");
        } else {
          toast.error(`Error: ${res.statusText}`);
        }
      } catch (err) {
        //toast Logic
        handleAxiosError(err);


      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };





  return (
    <>
      <button title="Update COS" className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update COS Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="form-flex-wrap">


              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="COS Code">COS Code<span className="required">(Read-Only)</span></label>
                <input
                  type="text"
                  name="cos_code"
                  title="COS Code"
                  placeholder="COS Code"
                  value={formData.cos_code}
                  onChange={handleInputChange}

                  className={`form-input-date width-20vw ${errors.cos_code ? "error" : inputState.cos_code ? "success" : ""}`}
                  readOnly
                />
                {errors.cos_code && <div className="error-message font-size-text">{errors.cos_code}</div>}
              </div>



              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Initiate Date">Initiate Date<span className="required">(Read-Only)</span></label>
                <input
                  type="date"
                  name="process_initiate_date"
                  title="Initiate Date"
                  value={formData.process_initiate_date}
                  onChange={handleInputChange}
                  className={`form-input-date width-20vw ${errors.process_initiate_date ? "error" : inputState.process_initiate_date ? "success" : ""}`}
                  readOnly
                />
                {errors.process_initiate_date && <div className="error-message font-size-text">{errors.process_initiate_date}</div>}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Approval Date">Approval Date<span className="required">*</span></label>
                <input
                  type="date"
                  name="approval_date"
                  title="Approval Date"
                  // placeholder="Contractor Remarks"
                  value={formData.approval_date}
                  onChange={handleInputChange}
                  min={formData.process_initiate_date}
                  className={`form-input-date width-20vw ${errors.approval_date ? "error" : inputState.approval_date ? "success" : ""}`}
                />
                {errors.approval_date && <div className="error-message font-size-text">{errors.approval_date}</div>}
              </div>


              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Amount(Write full amount)">Amount(Write full amount)<span className="required">(Read-Only)</span></label>
                <input
                  type="text"
                  name="amount"
                  title="Amount(Write full Amount)"
                  placeholder="Amount"
                  value={formData.amount}
                  onChange={handleInputChange}

                  className={`form-input-date width-20vw ${errors.amount ? "error" : inputState.amount ? "success" : ""}`}
                  readOnly
                />
                {errors.amount && <div className="error-message font-size-text">{errors.amount}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Approved Amount(Write full amount)">Approved Amount(Write full amount)<span className="required">*</span></label>
                <input
                  type="text"
                  name="approved_amount"
                  title="Approved Amount(Write full Amount)"
                  placeholder="Approved Amount"
                  value={formData.approved_amount}
                  max={formData.amount}

                  onChange={handleInputChange}

                  className={`form-input-date width-20vw ${errors.approved_amount ? "error" : inputState.approved_amount ? "success" : ""}`}

                />
                {errors.approved_amount && <div className="error-message font-size-text">{errors.approved_amount}</div>}
              </div>
              <div className="flex-column form-group-selectt">
                <label className="form-labels font-weight500 font-size-subheading" title="COS Status">COS Status</label>
                <br />
                <select
                  name="cos_status"
                  title="COS status"
                  value={formData.cos_status}
                  onChange={handleInputChange}
                  className={`form-input-date width-20vw form-group-selection`}
                >
                  <option value="pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="rejected">Rejected</option>

                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.cos_status && <div className="error-message font-size-text">{errors.cos_status}</div>}
              </div>


              <div className="flex-column form-group-selectt">
                <label className="form-labels font-weight500 font-size-subheading" title="Close Letter">
                  Close Letter<span className="required">*</span>
                </label>
                <br />
                <select
                  name="close_letter"
                  title="Close Letter"
                  value={formData.close_letter}
                  onChange={handleInputChange}
                  className={`form-input-date width-20vw form-group-selection ${errors.close_letter ? "error" : inputState.close_letter ? "success" : ""
                    }`}
                >
                  <option value="">Select Option</option>
                  {trackRecordList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.letter_num}
                    </option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.close_letter && (
                  <div className="error-message font-size-text">
                    {errors.close_letter}
                  </div>
                )}
              </div>

              {formData.close_letter ? (
                ""
              ) : (
                <>
                  <div className="flex-column justify-center">
                    <div className="text-center" style={{ marginTop: "30px" }}>OR</div>
                  </div>


                  <div className="flex-column">
                    <div style={{ marginTop: "35px", width: "21vw" }}>
                      <AddTrackRecordDetails
                        projectId={project}
                        contractor={contractor}
                        letterfor={formData.workperformedby}
                        lettertype="cos"
                        getProjects={getLetters}
                        title="COS Letter"

                      />
                    </div>
                  </div>
                </>
              )}
            </div>


            <div className="button-models">
              <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleCOSPost} disabled={loading}>
                {loading ? 'Updating...' : 'Update'}
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};


const DeleteCOS = ({ i, getCOS }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/cos/${i.id}/`
      );

      if (res.status === 200) {
        await getCOS();
        setShow(false);
        toast.dismiss(loadingToastId);
        toast.success("COS Deleted Successfully");
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete COS"
        className="model-delete-button"
        onClick={handleShow}
      >
        <DeleteDustbin />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete COS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete COS Data of {i.cos_code}
            <div className="button-models">
              <button
                className="model-button   font-weight500    "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};


const DeleteMileStone = ({ i, getMilestone }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/milestone/${i.id}/`
      );

      if (res.status === 200) {
        await getMilestone();
        setShow(false);
        toast.dismiss(loadingToastId);
        toast.success("Milestone Deleted Successfully");
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Insurance"
        className="model-delete-button"
        onClick={handleShow}
      >
        <DeleteDustbin />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Milestone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Milestone Data of {i.name}
            <div className="button-models">
              <button
                className="model-button   font-weight500    "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
const UpdateMilestone = ({ i, project, getProjects, appointed_date, contractor }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    project: project,
    name: "",
    milestone_date: "null",
    milestone_as_per_reschedule: "null",
    milestone_as_per_actual: "null",
    day: "",
    rescedule_gap_days: "",
    actual_gap_days: "",
    workperformedby: "contractor",
    letter: "",
    contractor: contractor,
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const handleClose = () => {
    setShow(false);
    setFormData({
      project: project,
      name: "",
      milestone_date: null,
      milestone_as_per_reschedule: null,
      milestone_as_per_actual: null,
      day: "",
      rescedule_gap_days: "",
      actual_gap_days: "",
      workperformedby: "contractor",
      letter: "",
      contractor: contractor,
    });
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contractor: contractor,
    }));
  }, [contractor]);

  const handleShow = () => {
    setShow(true);
    setFormData(i);
  }

  const validateEotForm = () => {
    const newErrors = {};
    const RequiredFields = [


    ];

    RequiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });
    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  console.log(errors);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });

  //   if (name === "day") {
  //     const dayValue = parseInt(value, 10);
  //     if (!isNaN(dayValue) && dayValue > 0) {
  //       const appointedDate = new Date(appointed_date); // Get the appointed date
  //       const calculatedMilestoneDate = new Date(appointedDate); // Create a copy of appointedDate
  //       calculatedMilestoneDate.setDate(appointedDate.getDate() + dayValue); // Calculate milestone date

  //       if (!isNaN(calculatedMilestoneDate.getTime())) {
  //         setFormData({
  //           ...formData,
  //           milestone_date: calculatedMilestoneDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
  //           [name]: value,
  //         });
  //       } else {
  //         setErrors({ ...errors, day: "Invalid day value" });
  //       }
  //     } else {
  //       setErrors({ ...errors, day: "Day must be a positive number" });
  //     }
  //   }

  //   if (name === "milestone_as_per_reschedule") {
  //     const appointedDate = new Date(appointed_date);
  //     const milestoneRescheduleDate = new Date(value);

  //     if (
  //       !isNaN(milestoneRescheduleDate.getTime()) &&
  //       milestoneRescheduleDate >= appointedDate
  //     ) {
  //       const diffInTime =
  //         milestoneRescheduleDate.getTime() - appointedDate.getTime();
  //       const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

  //       setFormData({
  //         ...formData,
  //         rescedule_gap_days: diffInDays,
  //         milestone_as_per_reschedule: value,
  //       });
  //     } else {
  //       setErrors({
  //         ...errors,
  //         milestone_as_per_reschedule:
  //           "Reschedule date must be after the appointed date",
  //       });
  //     }
  //   }
  //   if (name === "milestone_as_per_actual") {
  //     const appointedDate = new Date(appointed_date);
  //     const milestoneActualDate = new Date(value);

  //     if (
  //       !isNaN(milestoneActualDate.getTime()) &&
  //       milestoneActualDate >= appointedDate
  //     ) {
  //       const diffInTime =
  //         milestoneActualDate.getTime() - appointedDate.getTime();
  //       const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

  //       setFormData({
  //         ...formData,
  //         actual_gap_days: diffInDays,
  //         milestone_as_per_actual: value,
  //       });
  //     } else {
  //       setErrors({
  //         ...errors,
  //         milestone_as_per_actual:
  //           "Actual milestone date must be after the appointed date",
  //       });
  //     }
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update input state for feedback like colors
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    const appointedDate = new Date(appointed_date);

    if (name === "milestone_as_per_reschedule") {
      if (value === "") {
        // Reset fields to null and gap days to 0
        setFormData((prev) => ({
          ...prev,
          milestone_as_per_reschedule: null,
          rescedule_gap_days: 0,
        }));
      } else {
        const milestoneRescheduleDate = new Date(value);

        if (
          !isNaN(milestoneRescheduleDate.getTime()) &&
          milestoneRescheduleDate >= appointedDate
        ) {
          const diffInTime =
            milestoneRescheduleDate.getTime() - appointedDate.getTime();
          const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

          setFormData((prev) => ({
            ...prev,
            milestone_as_per_reschedule: value,
            rescedule_gap_days: diffInDays,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            milestone_as_per_reschedule:
              "Reschedule date must be after the appointed date",
          }));
        }
      }
    }

    if (name === "milestone_as_per_actual") {
      if (value === "") {
        // Reset fields to null and gap days to 0
        setFormData((prev) => ({
          ...prev,
          milestone_as_per_actual: null,
          actual_gap_days: 0,
        }));
      } else {
        const milestoneActualDate = new Date(value);

        if (
          !isNaN(milestoneActualDate.getTime()) &&
          milestoneActualDate >= appointedDate
        ) {
          const diffInTime =
            milestoneActualDate.getTime() - appointedDate.getTime();
          const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

          setFormData((prev) => ({
            ...prev,
            milestone_as_per_actual: value,
            actual_gap_days: diffInDays,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            milestone_as_per_actual:
              "Actual milestone date must be after the appointed date",
          }));
        }
      }
    }

    // General case for updating other fields
    if (name !== "milestone_as_per_reschedule" && name !== "milestone_as_per_actual") {
      setFormData((prev) => ({
        ...prev,
        [name]: value === "" ? null : value, // Set null when cleared
      }));
    }
  };





  const [trackRecordList, setTrackRecordList] = useState([]);

  const getLetters = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/${formData.workperformedby}/Milestone/${contractor}/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  useEffect(() => {
    getLetters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.workperformedby, contractor]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateEotForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        // Create the object for submission

        // Log submission data to verify the structure

        let res = await axios.put(
          `${BASE_URL}/siteproject/milestone/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getProjects();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("Milestone Updated Successfully");
        } else {
          alert(res);
        }
      } catch (err) {
        // Handle error and show toast notification
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button
        title="Update Milestones"
        className="model-edit-button"
        onClick={handleShow}
      >
        <UpdatePencil />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Milestone Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <div className="parent-div">
            <div className="form-flex-wrap">

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Name">
                  Name<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="text"
                  title="Name"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="width-17vw form-input-date"
                  readOnly
                />
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Appointed Date">
                  Appointed Date<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="date"
                  name="appointed_date"
                  title="Appointed Date"
                  value={appointed_date}
                  onChange={handleInputChange}
                  className="width-17vw form-input-date"
                  readOnly
                />
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="MileStone Date(Enter Milestone Days)">
                  Milestone Date<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="date"
                  name="milestone_date"
                  title="Milestone Date"
                  min={appointed_date}
                  value={formData.milestone_date}
                  onChange={handleInputChange}
                  className="width-17vw form-input-date"
                  readOnly
                />
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="MileStone Days">
                  MileStone Days<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  name="day"
                  title="MileStone Days"
                  placeholder="Day"
                  value={formData.day}
                  min={0}
                  onChange={handleInputChange}
                  className="width-17vw form-input-date"
                  readOnly
                />
              </div>


              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Actual Milestone Date">
                  Actual Milestone Date
                </label>
                <input
                  type="date"
                  name="milestone_as_per_actual"
                  title="Actual Milestone Date"
                  min={formData.milestone_date}
                  value={formData.milestone_as_per_actual}
                  onChange={handleInputChange}
                  className="width-17vw form-input-date"
                // readOnly
                />
                {errors.milestone_as_per_actual && (
                  <div className="error-message font-size-text">
                    {errors.milestone_as_per_actual}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Actual Gap Days">
                  Actual Gap Days<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  title="Actual Gap Days"
                  name="actual_gap_days"
                  placeholder="Actual Gap Days"
                  value={formData.actual_gap_days}
                  min={0}
                  onChange={handleInputChange}
                  className="width-17vw form-input-date"
                  readOnly
                />
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Reschedule Milestone Date">
                  Reschedule Milestone Date
                </label>
                <input
                  type="date"
                  name="milestone_as_per_reschedule"
                  title="Reschedule Milestone Date"
                  min={formData.milestone_as_per_actual}
                  value={formData.milestone_as_per_reschedule}
                  onChange={handleInputChange}
                  className="width-17vw form-input-date"
                // readOnly
                />
                {errors.milestone_as_per_reschedule && (
                  <div className="error-message font-size-text">
                    {errors.milestone_as_per_reschedule}
                  </div>
                )}
              </div>
              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Reschedule Gap Days">
                  Reschedule Gap Days<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  name="rescedule_gap_days"
                  title="Reschedule Gap Days"
                  placeholder="Reschedule Gap Days"
                  value={formData.rescedule_gap_days}
                  min={0}
                  onChange={handleInputChange}
                  className="width-17vw form-input-date"
                  readOnly
                />
              </div>
              <div className="flex-column form-group-selectt">
                <label className="form-labels font-weight500 font-size-subheading" title="Letter">
                  Reschedule Letter
                </label>
                <br />
                <select
                  name="reschedule_letter"
                  title="Reschedule Letter"
                  value={formData.reschedule_letter}
                  onChange={handleInputChange}
                  className={`width-17vw form-input-date form-group-selection ${errors.reschedule_letter ? "error" : inputState.reschedule_letter ? "success" : ""
                    }`}
                >
                  <option value="">Select Option</option>
                  {trackRecordList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.letter_num}
                    </option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.reschedule_letter && (
                  <div className="error-message font-size-text">
                    {errors.reschedule_letter}
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="flex-column form-group-selectt">
                  <label className="form-labels font-weight500 font-size-subheading" title="Letter">
                    Actual Letter
                  </label>
                  <br />
                  <select
                    name="actual_letter"
                    title="Actual Letter"
                    value={formData.actual_letter}
                    onChange={handleInputChange}
                    className={`width-17vw form-input-date form-group-selection ${errors.actual_letter ? "error" : inputState.actual_letter ? "success" : ""
                      }`}
                  >
                    <option value="">Select Option</option>
                    {trackRecordList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.letter_num}
                      </option>
                    ))}
                  </select>

                  <DropdownArrow />
                </div>
                {errors.actual_letter && (
                  <div className="error-message font-size-text">
                    {errors.actual_letter}
                  </div>
                )}
              </div>
              {formData.reschedule_letter && formData.actual_letter ? (
                ""
              ) : (
                <>
                  <div className="flex-column justify-center">
                    <div className="text-center" style={{ marginTop: "30px", width: "19vw" }}>OR</div>
                  </div>

                  <div className="flex-column">
                    <div style={{ marginTop: "30px", width: "18vw" }}>
                      <AddTrackRecordDetails
                        projectId={project}
                        contractor={contractor}
                        letterfor="contractor"
                        lettertype="Milestone"
                        getProjects={getLetters}
                        title="Milestone Letter"

                      />
                    </div>
                  </div>
                </>
              )}

            </div>





            <div className="button-models">
              <button
                className="model-button  font-weight500    model-button-leave font-size-heading"
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>

          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};





const AddMilstoneDetails = ({ project, getProjects, appointed_date, contractor }) => {
  console.log("project");
  console.log(project);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    project: project,
    name: "",
    milestone_date: null,
    milestone_as_per_reschedule: null,
    milestone_as_per_actual: null,
    day: "",
    rescedule_gap_days: "",
    actual_gap_days: "",
    workperformedby: "contractor",
    letter: "",
    contractor: contractor,
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const handleClose = () => {
    setShow(false);
    setFormData({
      project: project,
      name: "",
      milestone_date: null,
      milestone_as_per_reschedule: null,
      milestone_as_per_actual: null,
      day: "",
      rescedule_gap_days: "",
      actual_gap_days: "",
      workperformedby: "contractor",
      letter: "",
      contractor: contractor,
    });
    setErrors("");
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contractor: contractor,
    }));
  }, [contractor]);

  const handleShow = () => setShow(true);

  const validateEotForm = () => {
    const newErrors = {};
    const RequiredFields = [
      "name",
      "day",
    ];

    RequiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
    });
    setErrors(newErrors);
    toast.warning(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   if (errors[name]) {
  //     setErrors((prevErrors) => {
  //       const { [name]: removedError, ...restErrors } = prevErrors;
  //       return restErrors;
  //     });
  //   }

  //   if (name === "day") {
  //     const dayValue = parseInt(value, 10);
  //     if (!isNaN(dayValue) && dayValue > 0) {
  //       const appointedDate = new Date(appointed_date); // Get the appointed date
  //       const calculatedMilestoneDate = new Date(appointedDate); // Create a copy of appointedDate
  //       calculatedMilestoneDate.setDate(appointedDate.getDate() + dayValue); // Calculate milestone date

  //       if (!isNaN(calculatedMilestoneDate.getTime())) {
  //         setFormData({
  //           ...formData,
  //           milestone_date: calculatedMilestoneDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
  //           [name]: value,
  //         });
  //       } else {
  //         setErrors({ ...errors, day: "Invalid day value" });
  //       }
  //     } else {
  //       setErrors({ ...errors, day: "Day must be a positive number" });
  //     }
  //   }

  //   if (name === "milestone_as_per_reschedule") {
  //     const appointedDate = new Date(appointed_date);
  //     const milestoneRescheduleDate = new Date(value);

  //     if (
  //       !isNaN(milestoneRescheduleDate.getTime()) &&
  //       milestoneRescheduleDate >= appointedDate
  //     ) {
  //       const diffInTime =
  //         milestoneRescheduleDate.getTime() - appointedDate.getTime();
  //       const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

  //       setFormData({
  //         ...formData,
  //         rescedule_gap_days: diffInDays,
  //         milestone_as_per_reschedule: value,
  //       });
  //     } else {
  //       setErrors({
  //         ...errors,
  //         milestone_as_per_reschedule:
  //           "Reschedule date must be after the appointed date",
  //       });
  //     }
  //   }
  //   if (name === "milestone_as_per_actual") {
  //     const appointedDate = new Date(appointed_date);
  //     const milestoneActualDate = new Date(value);

  //     if (
  //       !isNaN(milestoneActualDate.getTime()) &&
  //       milestoneActualDate >= appointedDate
  //     ) {
  //       const diffInTime =
  //         milestoneActualDate.getTime() - appointedDate.getTime();
  //       const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

  //       setFormData({
  //         ...formData,
  //         actual_gap_days: diffInDays,
  //         milestone_as_per_actual: value,
  //       });
  //     } else {
  //       setErrors({
  //         ...errors,
  //         milestone_as_per_actual:
  //           "Actual milestone date must be after the appointed date",
  //       });
  //     }
  //   }
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }

    if (name === "day") {
      const dayValue = parseInt(value, 10);

      if (dayValue === 0) {
        // Reset milestone date when day is zero
        setFormData({
          ...formData,
          milestone_date: null,
          [name]: value,
        });
      } else if (!isNaN(dayValue) && dayValue > 0) {
        const appointedDate = new Date(appointed_date); // Get the appointed date
        const calculatedMilestoneDate = new Date(appointedDate); // Create a copy of appointedDate
        calculatedMilestoneDate.setDate(appointedDate.getDate() + dayValue); // Calculate milestone date

        if (!isNaN(calculatedMilestoneDate.getTime())) {
          setFormData({
            ...formData,
            milestone_date: calculatedMilestoneDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
            [name]: value,
          });
        } else {
          setErrors({ ...errors, day: "Invalid day value" });
        }
      } else {
        setErrors({ ...errors, day: "Day must be a positive number" });
      }
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateEotForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        // Create the object for submission

        // Log submission data to verify the structure

        let res = await axios.post(
          `${BASE_URL}/siteproject/milestone/`,
          formData
        );

        if (res.status === 200) {
          await getProjects();
          handleClose();
          toast.success("Milestone Added Successfully");
        } else {
          alert(res);
        }
      } catch (err) {
        // Handle error and show toast notification
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button
        title="Add Milestones"
        className="upload-svg"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>{" "}
        Milestones
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Milestone Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
          />
          <div className="parent-div">
            <div className="form-flex-wrap">

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Name">
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  title="Name"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="form-input-date form-input-time"
                />
                {errors.name && <span className="error-message font-size-text ">{errors.name}</span>}

              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="Appointed Date">
                  Appointed Date<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="date"
                  name="appointed_date"
                  title="Appointed Date"
                  value={appointed_date}
                  onChange={handleInputChange}
                  className="form-input-date form-input-time"
                  readOnly
                />
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="MileStone Days">
                  MileStone Days<span className="required">*</span>
                </label>
                <input
                  type="number"
                  name="day"
                  title="MileStone Days"
                  placeholder="Day"
                  value={formData.day}
                  min={0}
                  onChange={handleInputChange}
                  className="form-input-date form-input-time"
                // readOnly
                />
                {errors.day && <span className="error-message font-size-text ">{errors.day}</span>}

              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title="MileStone Date(Enter Milestone Days)">
                  Milestone Date<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="date"
                  name="milestone_date"
                  title="Milestone Date"
                  min={appointed_date}
                  value={formData.milestone_date}
                  onChange={handleInputChange}
                  className="form-input-date form-input-time"
                  readOnly
                />
              </div>
            </div>


            <div className="button-models">
              <button
                className="model-button  font-weight500    model-button-leave font-size-heading"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Adding..." : "Add"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const AddProgressdetails = ({
  project,
  progress,
  getprogressDetails,
  contractor,
  progressdata
}) => {
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [previousprogressdata, setPreviousProgressData] = useState([]);


  const months = [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ];



  const [formData, setFormData] = useState({
    project,
    submitted_financial_progress: "0",
    submitted_physical_progress: "0",
    month: "",
    year: "",
    submitted_letter: "",
    contractor,
  });

  const { month, year } = formData;
  const [trackRecordList, setTrackRecordList] = useState([]);
  const validateForm = () => {
    const newErrors = {};

    if (Number(formData.submitted_physical_progress) < previousprogressdata.physical_progress || Number(formData.submitted_physical_progress) > 100) {
      newErrors.submitted_physical_progress = `Value must be between ${previousprogressdata.physical_progress}%- 100%`;
    }

    if (Number(formData.submitted_financial_progress) < previousprogressdata.financial_progress || Number(formData.submitted_financial_progress) > 100) {
      newErrors.submitted_financial_progress = `Value must be between ${previousprogressdata.financial_progress}%- 100%`;
    }

    const requiredFields = ["submitted_financial_progress", "submitted_physical_progress", "month", "year", "submitted_letter"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getLetters = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/contractor/SiteProgress/${contractor}/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!month || !year) {
      console.error("Both month and year are required to fetch progress data.");
      return;
    }

    const fetchProgressData = async () => {
      try {
        let currentMonth = month - 1 === 0 ? 12 : month - 1; // Start with the previous month
        let currentYear = month - 1 === 0 ? year - 1 : year; // Adjust year if previous month is December
        let dataFound = false; // Flag to check if data is found

        while (!dataFound) {
          const response = await axios.get(
            `${BASE_URL}/siteproject/progressbyproject/${project}/${contractor}/${currentMonth}/${currentYear}/`
          );

          const progressData = response.data;

          if (progressData.length > 0) {
            // Assuming progressData is an array
            const { financial_progress, physical_progress } = progressData[0]; // Take the first item

            // Set the values
            setPreviousProgressData({
              financial_progress,
              physical_progress,
            });

            console.log("Financial Progress:", financial_progress);
            console.log("Physical Progress:", physical_progress);

            dataFound = true; // Exit the loop
          } else {
            console.warn(
              `No progress data found for ${currentMonth}/${currentYear}. Checking the previous month.`
            );

            // Move to the previous month
            currentMonth -= 1;
            if (currentMonth === 0) {
              currentMonth = 12;
              currentYear -= 1; // Move to the previous year
            }

            // Stop the loop if we reach an unrealistic limit (e.g., 10 years back)
            if (currentYear < year - 10) {
              console.error("No data found for the past 10 years.");
              break;
            }
          }
        }
      } catch (error) {
        console.error("Error fetching progress data:", error);
      }
    };




    fetchProgressData();
  }, [month, year]);





  const handleShow = async () => {

    setShow(true);
    getLetters();
  };

  const handleClose = () => {
    setShow(false);
    setFormData({
      project,
      submitted_financial_progress: "0",
      submitted_physical_progress: "0",
      month: "",
      year: "",
      submitted_letter: "",
      contractor,
    });
    setErrors("");
    setPreviousProgressData("");
  };


  const [errors, setErrors] = useState({
    submitted_physical_progress: "",
    submitted_financial_progress: "",
  });
  const [inputState, setInputState] = useState({});


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "submitted_physical_progress") {
      if (Number(value) < previousprogressdata.physical_progress || Number(value) > 100) {
        errorMessage = `Value must be between ${previousprogressdata.physical_progress}% - 100%`;
      }
    }

    if (name === "submitted_financial_progress") {
      if (Number(value) < previousprogressdata.financial_progress || Number(value) > 100) {
        errorMessage = `Value must be between ${previousprogressdata.financial_progress}% - 100%`;
      }
    }
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fix the errors before submitting.");
      return;
    }

    const loadingToastId = toast.loading("Loading: Please wait...");

    try {
      let res = await axios.post(
        `${BASE_URL}/siteproject/progressget/`,
        formData
      );
      if (res.status === 200) {
        await getprogressDetails();
        handleClose();
        toast.success("Progress added successfully.");
      } else {
        toast.error(`Error: ${res.statusText}`);
      }
    } catch (error) {
      toast.error("An error occurred while submitting the progress.");
    } finally {
      setLoading(false);
      toast.dismiss(loadingToastId);
    }
  };



  useEffect(() => {
    // Update the formData when previousprogressdata changes
    setFormData((prev) => ({
      ...prev,
      submitted_physical_progress: previousprogressdata.physical_progress || "",
      submitted_financial_progress: previousprogressdata.financial_progress || "",
    }));
  }, [previousprogressdata]);


  return (
    <>
      <button
        title="Add Site Progress"
        className="upload-svg"
        onClick={handleShow}
      >
        <AddwithWhiteCircle /> Progress
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Site Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>



          {/* Frozen Month Field */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Month<span className="required">*</span>
            </label>
            <select
              name="month"
              value={formData.month}
              className="form-input"
              onChange={handleInputChange}
            >
              <option value=''>Select Month</option>
              {months.map((month) => (
                <option
                  key={month.value}
                  value={month.value}
                  disabled={progressdata.some(
                    (data) => data.month === String(month.value) && data.year === String(formData.year)
                  )}
                >
                  {month.text}
                </option>
              ))}
            </select>
            {errors.month && (
              <span className="error-message">{errors.month}</span>
            )}
          </div>

          {/* Frozen Year Field */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Year<span className="required">*</span>
            </label>
            <select
              name="year"
              value={formData.year}
              className="form-input"
              onChange={handleInputChange}
            >
              <option value=''>Select Year</option>

              {[...Array(10)].map((_, index) => {
                const year = new Date().getFullYear() - index;
                return (
                  <option
                    key={year}
                    value={year}
                    disabled={progressdata.some(
                      (data) => data.year === String(year) && data.month === String(formData.month)
                    )}
                  >
                    {year}
                  </option>
                );
              })}
            </select>
            {errors.year && (
              <span className="error-message">{errors.year}</span>
            )}
          </div>
          {/* Submitted Physical Progress */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Submitted Physical Progress (%)<span className="required">*</span>
            </label>
            <input
              type="number"
              min={previousprogressdata.physical_progress}
              placeholder={previousprogressdata.physical_progress || 0}
              name="submitted_physical_progress"
              onChange={handleInputChange}
              className={`form-input ${errors.submitted_physical_progress ? "input-error" : ""}`}
            />
            {errors.submitted_physical_progress && (
              <span className="error-message">{errors.submitted_physical_progress}</span>
            )}
          </div>

          {/* Submitted Financial Progress */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Submitted Financial Progress (%)<span className="required">*</span>
            </label>
            <input
              type="number"
              min={previousprogressdata.financial_progress}
              placeholder={previousprogressdata.financial_progress || 0}
              name="submitted_financial_progress"
              onChange={handleInputChange}
              className={`form-input ${errors.submitted_financial_progress ? "input-error" : ""}`}
            />
            {errors.submitted_financial_progress && (
              <span className="error-message">{errors.submitted_financial_progress}</span>
            )}
          </div>
          <div className="flex-column form-group-selectt">
            <label className="form-labels font-weight500 font-size-subheading" title="Letter">Letter<span className="required">*</span></label>
            <br />
            <select
              name="submitted_letter"
              title="Letter"
              value={formData.submitted_letter}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.submitted_letter ? "error" : inputState.submitted_letter ? "success" : ""}`}
            >
              <option value="" title="Select Option">Select Option</option>
              {trackRecordList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.letter_num}
                </option>
              ))}
            </select>
            <div className="form-group-selection-arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            {errors.submitted_letter && <div className="error-message ">{errors.submitted_letter}</div>}
          </div>
          {formData.submitted_letter ? (
            ""
          ) : (
            <>
              <div
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <div>OR</div>
              </div>

              <div className="button-models">
                <AddTrackRecordDetails
                  projectId={project}
                  contractor={contractor}
                  letterfor="contractor"
                  lettertype="SiteProgress"
                  getProjects={getLetters}
                  title="Site Progress Letter"

                />
              </div>
            </>
          )}


          <div className="button-models">
            <button
              className="model-button font-weight500 model-button-leave font-size-heading"
              onClick={handleSubmit}
              type="button"
              disabled={loading}
            >
              {loading ? "Adding..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateProgressdetails = ({
  i,
  project,
  getprogressDetails,
  progressdata,
  contractor
}) => {

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [previousprogressdata, setPreviousProgressData] = useState([]);


  const months = [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ];



  const [formData, setFormData] = useState({
    project,
    financial_progress: "0",
    physical_progress: "0",
    submitted_physical_progress: "0",
    submitted_financial_progress: "0",
    month: "",
    year: "",
    approved_letter: "",
    contractor,
  });

  const { month, year } = formData;

  const validateForm = () => {
    const newErrors = {};

    if (Number(formData.physical_progress) < previousprogressdata.physical_progress || Number(formData.physical_progress) > 100) {
      newErrors.physical_progress = `Value must be between ${previousprogressdata.physical_progress}%- 100%`;
    }


    if (Number(formData.financial_progress) < previousprogressdata.financial_progress || Number(formData.financial_progress) > 100) {
      newErrors.financial_progress = `Value must be between ${previousprogressdata.financial_progress}%- 100%`;
    }

    const requiredFields = ["financial_progress", "physical_progress", "approved_letter"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const [trackRecordList, setTrackRecordList] = useState([]);
  const handleShow = async () => {

    setShow(true);
    setFormData(i);
    getLetters();
  };

  const getLetters = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letterrecordbyfilters/${project}/contractor/SiteProgress/${contractor}/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    // if (month || !year) {
    //   console.error("Both month and year are required to fetch progress data.");
    //   return;
    // }


    const fetchProgressData = async () => {
      try {
        let currentMonth = month - 1 === 0 ? 12 : month - 1; // Start with the previous month
        let currentYear = month - 1 === 0 ? year - 1 : year; // Adjust year if previous month is December
        let dataFound = false; // Flag to check if data is found

        while (!dataFound) {
          const response = await axios.get(
            `${BASE_URL}/siteproject/progressbyproject/${project}/${contractor}/${currentMonth}/${currentYear}/`
          );

          const progressData = response.data;

          if (progressData.length > 0) {
            // Assuming progressData is an array
            const { financial_progress, physical_progress } = progressData[0]; // Take the first item

            // Set the values
            setPreviousProgressData({
              financial_progress,
              physical_progress,
            });

            console.log("Financial Progress:", financial_progress);
            console.log("Physical Progress:", physical_progress);

            dataFound = true; // Exit the loop
          } else {
            console.warn(
              `No progress data found for ${currentMonth}/${currentYear}. Checking the previous month.`
            );

            // Move to the previous month
            currentMonth -= 1;
            if (currentMonth === 0) {
              currentMonth = 12;
              currentYear -= 1; // Move to the previous year
            }

            // Stop the loop if we reach an unrealistic limit (e.g., 10 years back)
            if (currentYear < year - 10) {
              console.error("No data found for the past 10 years.");
              break;
            }
          }
        }
      } catch (error) {
        console.error("Error fetching progress data:", error);
      }
    };




    fetchProgressData();
  }, [month, year]);







  const handleClose = () => {
    setShow(false);
    setFormData({
      project,
      submitted_financial_progress: "0",
      submitted_physical_progress: "0",
      month: "",
      year: "",
      approved_letter: "",
      contractor,
    });
    setErrors("");
    setPreviousProgressData("");
  };


  const [errors, setErrors] = useState({
    physical_progress: "",
    financial_progress: "",
  });

  const [inputState, setInputState] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "physical_progress") {
      if (Number(value) < previousprogressdata.physical_progress || Number(value) > 100) {
        errorMessage = `Value must be between ${previousprogressdata.physical_progress}% - 100%`;
      }
    }

    if (name === "financial_progress") {
      if (Number(value) < previousprogressdata.financial_progress || Number(value) > 100) {
        errorMessage = `Value must be between ${previousprogressdata.financial_progress}% - 100%`;
      }
    }
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait...");


    if (!validateForm()) {
      toast.error("Please fix the errors before submitting.");
      return;
    }

    try {
      let res = await axios.put(
        `${BASE_URL}/siteproject/progressupdate/${i.id}/`,
        formData
      );
      if (res.status === 200) {
        getprogressDetails();
        handleClose();
        toast.success("Progress updated successfully.");
        toast.dismiss(loadingToastId);

      } else {
        toast.error(`Error: ${res.statusText}`);
      }
    } catch (error) {
      toast.error("An error occurred while submitting the progress.");
    } finally {
      setLoading(false);
      toast.dismiss(loadingToastId);
    }
  };


  useEffect(() => {
    // Update the formData when previousprogressdata changes
    if (!formData.physical_progress || formData.physical_progress === "0" || !formData.financial_progress || formData.financial_progress === "0") {
      setFormData((prev) => ({
        ...prev,
        physical_progress: previousprogressdata.physical_progress || "0",
        financial_progress: previousprogressdata.financial_progress || "0",
      }));
    }
  }, [previousprogressdata]);


  return (
    <>
      <button
        title="Update Site Progress"
        className="model-edit-button"
        onClick={handleShow}
      >
        <UpdatePencil />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Site Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Month<span className="required">*</span>
            </label>
            <select
              name="month"
              value={formData.month}
              className="form-input"
              // onChange={handleInputChange}
              disabled
            >
              <option value=''>Select Month</option>
              {months.map((month) => (
                <option
                  key={month.value}
                  value={month.value}
                  disabled={progressdata.some(
                    (data) => data.month === String(month.value) && data.year === String(formData.year)
                  )}
                >
                  {month.text}
                </option>
              ))}
            </select>
          </div>

          {/* Frozen Year Field */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Year<span className="required">*</span>
            </label>
            <select
              name="year"
              value={formData.year}
              className="form-input"
              // onChange={handleInputChange}
              disabled
            >
              <option value=''>Select Year</option>

              {[...Array(10)].map((_, index) => {
                const year = new Date().getFullYear() - index;
                return (
                  <option
                    key={year}
                    value={year}
                    disabled={progressdata.some(
                      (data) => data.year === String(year) && data.month === String(formData.month)
                    )}
                  >
                    {year}
                  </option>
                );
              })}
            </select>
          </div>



          {/* Actual Physical Progress */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Submitted Physical Progress (%)<span className="required">(Read-Only)</span>
            </label>
            <input
              type="number"
              // min={previousProgress.estimated_physical_progress}
              name="submitted_physical_progress"
              value={formData.submitted_physical_progress}
              onChange={handleInputChange}
              className="form-input"
              readOnly
            />
          </div>

          {/* Actual Financial Progress */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Submitted Financial Progress (%)<span className="required">(Read-Only)</span>
            </label>
            <input
              type="number"
              // min={previousProgress.estimated_financial_progress}
              name="submitted_financial_progress"
              value={formData.submitted_financial_progress}
              onChange={handleInputChange}
              className="form-input"
              readOnly
            />
          </div>

          {/* Submitted Physical Progress */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Actual Physical Progress (%)<span className="required">*</span>
            </label>
            <input
              type="number"
              min={previousprogressdata.physical_progress}
              placeholder={formData.physical_progress || previousprogressdata.physical_progress || 0}
              name="physical_progress"
              onChange={handleInputChange}
              className={`form-input ${errors.physical_progress ? "input-error" : ""}`}
            />
            {errors.physical_progress && (
              <span className="error-message">{errors.physical_progress}</span>
            )}
          </div>

          {/* Submitted Financial Progress */}
          <div className="flex-column">
            <label className="form-labels font-weight500 font-size-subheading">
              Actual Financial Progress (%)<span className="required">*</span>
            </label>
            <input
              type="number"
              min={previousprogressdata.financial_progress}
              placeholder={formData.financial_progress || previousprogressdata.financial_progress || 0}
              name="financial_progress"
              onChange={handleInputChange}
              className={`form-input ${errors.financial_progress ? "input-error" : ""}`}
            />
            {errors.financial_progress && (
              <span className="error-message">{errors.financial_progress}</span>
            )}
          </div>
          <div className="flex-column form-group-selectt">
            <label className="form-labels font-weight500 font-size-subheading" title="Letter">Letter<span className="required">*</span></label>
            <br />
            <select
              name="approved_letter"
              title="Letter"
              value={formData.approved_letter}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.approved_letter ? "error" : inputState.approved_letter ? "success" : ""}`}
            >
              <option value="" title="Select Option">Select Option</option>
              {trackRecordList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.letter_num}
                </option>
              ))}
            </select>
            <div className="form-group-selection-arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            {errors.approved_letter && <div className="error-message ">{errors.approved_letter}</div>}
          </div>
          {formData.approved_letter ? (
            ""
          ) : (
            <>
              <div
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <div>OR</div>
              </div>

              <div className="button-models">
                <AddTrackRecordDetails
                  projectId={project}
                  contractor={contractor}
                  letterfor="contractor"
                  lettertype="SiteProgress"
                  getProjects={getLetters}
                  title="Site Progress Letter"

                />
              </div>
            </>
          )}




          <div className="button-models">
            <button
              className="model-button font-weight500 model-button-leave font-size-heading"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </Modal.Body>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Modal>
    </>
  );
};


const AssociatedLetters = ({ id, workperformedby, letterType, associated_letters }) => {
  const [show, setShow] = useState(false);
  const [letterList, setLetterList] = useState([]); // Main letter data

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const getLetters = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letter-associated/${id}/`
      );
      setLetterList(res.data);
    } catch (error) {
      console.error("Error fetching initial letters:", error);
    }
  };

  const fetchLetterDetails = async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/letter-associated/${id}/`
      );
      return res.data;
    } catch (error) {
      console.error(`Error fetching details for Letter ID ${id}:`, error);
      return null;
    }
  };

  const RenderLetter = ({ letter, level = 0 }) => {
    const { letter_num, associated_letters = [] } = letter;

    const [showNestedLetters, setShowNestedLetters] = useState(false);
    const [nestedLettersData, setNestedLettersData] = useState([]); // Temporarily store nested letter data

    const toggleNestedLetters = async () => {
      if (showNestedLetters) {
        setShowNestedLetters(false); // Collapse
        setNestedLettersData([]); // Remove data when collapsing
      } else {
        setShowNestedLetters(true); // Expand
        const data = await Promise.all(
          associated_letters.map((id) => fetchLetterDetails(id))
        );
        setNestedLettersData(data.filter((item) => item !== null)); // Set nested letter data
      }
    };

    return (
      <div style={{ marginLeft: `${level * 20}px` }}>
        <div style={{ display: "flex" }}>
          <div className="arrow-set">
            <ArrowBullet />
          </div>

          <div className="letter-card">
            <div className="letter-card-header">
              <span style={{ margin: "6px" }}>
                Letter Number: {letter_num}
              </span>
              <div>
                {associated_letters.length > 0 && (
                  <button onClick={toggleNestedLetters}>
                    {showNestedLetters ? <CloseEye /> : <Eye />}
                  </button>
                )}
              </div>
            </div>

            <div style={{ margin: "20px 20px 20px 10px" }}>
              <table>
                <tbody>
                  <tr>
                    <td className="padding-bottom-5px">Reply of </td>
                    <td className="padding-bottom-5px" style={{ padding: "0px 4px" }}>:</td>
                    <td className="padding-bottom-5px">
                      {associated_letters.length > 0 ?
                        <>{letter.associated_letters_get.map(i => i.letter_num).join(",")}</> :
                        "No Associated Letter"}
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-bottom-5px">Letter Date </td>
                    <td className="padding-bottom-5px" style={{ padding: "0px 4px" }}>:</td>
                    <td className="padding-bottom-5px">{letter.letter_date}</td>
                  </tr>
                  <tr>
                    <td className="padding-bottom-5px">View Letter </td>
                    <td className="padding-bottom-5px" style={{ padding: "0px 4px" }}>:</td>
                    <td className="padding-bottom-5px">
                      <UpdateTrackRecordDetailwithIDonly
                        i={letter}
                        projectId={letter.project}
                        workperformedby={letter.workperformedby}
                        letterType={letter.lettertype}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Render child letters only if expanded */}
        {/* {console.log(showNestedLetters, nestedLettersData, 'nested')} */}
        {showNestedLetters &&
          nestedLettersData.map((childLetter, index) => (
            <div className="nested-letters" key={index}>
              <RenderLetter letter={childLetter} level={level + 1} />
            </div>
          ))}
      </div>
    );
  };

  useEffect(() => {
    getLetters();
  }, [id]);

  return (
    <>
      <button onClick={handleShow} className="document-download-button width-5vw " style={{ marginBottom: "10px", height: "30px" }}>
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>View Letters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="letter-list-container scroll-container" style={{ border: "none" }}>
            <div className="scrollbar" style={{ border: "none" }} >
              {letterList?.letter_num ? (
                <RenderLetter letter={letterList} />
              ) : (
                <p className="no-letters-text">No letters found</p>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};




const AddLetterLinked = ({
  getProjects,
  projectList,
  projectId,
  contractor,
  contractorlist,
  lettertype,
  letterfor,
  title,
}) => {

  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputState, setInputState] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [trackRecordList, setTrackRecordList] = useState([]);
  const [buffer, setBuffering] = useState(true);
  const [siteDepartmentList, setSiteDepartmentList] = useState([]);
  const [contractorlists, setContractorLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For upload progress
  const [eotinputState, seteotInputState] = useState({});
  const [cosinputState, setcosInputState] = useState({});
  const [eotList, setEotList] = useState([]);
  const [cosList, setCosList] = useState([]);
  const [eotID, setEotId] = useState("null");
  const [cosID, setCosId] = useState("null");




  const handleShow = () => {
    setShow(true);
    getSiteDepartment();
  };

  const handleClose = () => {
    setShow(false);
    setFormData({
      project: projectId ? projectId : "null",
      letter_num: "",
      reply_by: "",
      subject: "",
      letter_date: null,
      status: "pending",
      workperformedby: letterfor ? letterfor : "null",
      lettertype: lettertype ? lettertype : "null",
      letterExchange: "null",
      associated_letters: [],
      associated_letters_status: "pending",
      letterdepartment: "",
      letter_from: "null",
      letter_to: "",
      documents: [{ document_name: "", document_file: "" }],
      contractor: contractor ? contractor : "",
      remarks: '',
      select: '',
    })

    setEOTFormData({
      project: "",
      eot_code: "",
      workperformedby: "",
      approved_days: "",
      approved_date: "",
      close_letter: "",
      letter: "",
      contractor: "",
      process_initiate_date: "",
      eot_status: "pending"
    });

    setCOSFormData({
      project: "",
      cos_code: "",
      approval_date: "",
      contractor: "",
      process_initiate_date: "",
      cos_status: "pending",
      amount: "",
      workperformedby: "",
      letter: "",
      approved_amount: "",
      close_letter: "",
    })
  };

  const [formData, setFormData] = useState({
    project: projectId ? projectId : "null",
    letter_num: "",
    reply_by: "",
    subject: "",
    letter_date: null,
    status: "pending",
    workperformedby: letterfor ? letterfor : null,
    lettertype: lettertype ? lettertype : "null",
    letterExchange: "null",
    associated_letters: [],
    associated_letters_status: "pending",
    letterdepartment: "",
    letter_from: "null",
    letter_to: "",
    documents: [{ document_name: "", document_file: "" }],
    contractor: contractor ? contractor : "",
    remarks: '',
    select: '',
  });

  // const filteredData = eotList.filter(i => i.id === eotID);
  const [filteredData, setFilteredData] = useState([]);
  const [cosFilteredData, setcosFilteredData] = useState([]);


  console.log("EOTcoDE")
  console.log(filteredData[0]?.eot_code);
  console.log(filteredData)



  const [eotFormData, setEOTFormData] = useState({
    project: "",
    eot_code: "",
    workperformedby: "",
    approved_days: "",
    approved_date: "",
    close_letter: "",
    letter: "",
    contractor: "",
    process_initiate_date: "",
    eot_status: "pending"
  });

  const [cosFormData, setCOSFormData] = useState({
    project: "",
    cos_code: "",
    approval_date: "",
    contractor: "",
    process_initiate_date: "",
    cos_status: "pending",
    amount: "",
    workperformedby: "",
    letter: "",
    approved_amount: "",
    close_letter: "",
  })

  const [progressData, setProgressData] = useState({

  })

  useEffect(() => {

    setEotId("null");
    seteotInputState({});
    setFormData((prevFormData) => ({
      ...prevFormData,
      letter_date: "",
    }));
    setEOTFormData({
      project: formData.project || "",
      eot_code: "",
      workperformedby: "",
      approved_days: "",
      approved_date: "",
      close_letter: "",
      letter: "",
      contractor: "",
      process_initiate_date: "",
      eot_status: "pending",
    });


    setCosId("null");
    setcosInputState({});

    setCOSFormData({
      project: formData.project || "",
      cos_code: "",
      approval_date: "",
      contractor: "",
      process_initiate_date: "",
      cos_status: "pending",
      amount: "",
      workperformedby: "",
      letter: "",
      approved_amount: "",
      close_letter: "",
    })



  }, [formData.workperformedby, formData.contractor, formData.project, formData.select, formData.lettertype]);

  useEffect(() => {
    if ((Array.isArray(contractorlist) && contractorlist.length < 0) ||
      (Array.isArray(contractorlists) && contractorlists.length < 0)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        workperformedby: "consultant",
        contractor: "",
      }))
    }
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        workperformedby: "null",
        contractor: "",
      }))
    }
  }, [formData.project]);

  useEffect(() => {
    if (eotID !== "null") {
      const data = eotList.filter((i) => i.id === eotID);
      setFilteredData(data);
    }
    else if (cosID !== "null") {
      const data = cosList.filter((i) => i.id === cosID);
      setcosFilteredData(data);
    }
    else {
      setFilteredData([]);
      setcosFilteredData([]);
    }
  }, [eotID, eotList, cosID, cosList]);

  console.log("filteredData", filteredData);
  console.log("cosFilteredData", cosFilteredData);


  useEffect(() => {

    if (eotID !== "null") {
      if (filteredData.length > 0) {
        const data = filteredData[0];
        setEOTFormData((prev) => ({
          ...prev,
          project: formData.project,
          workperformedby: formData.workperformedby,
          contractor: formData.contractor,
          approved_date: formData.letter_date,
          process_initiate_date: data?.process_initiate_date,
          eot_code: data?.eot_code,
          letter: data?.letter,
          close_letter: formData?.letter,
        }));
      }
    }
    if (cosID !== "null") {
      if (cosFilteredData.length > 0) {
        const data = cosFilteredData[0];
        setCOSFormData((prev) => ({
          ...prev,
          project: formData.project,
          workperformedby: formData.workperformedby,
          contractor: formData.contractor,
          approval_date: formData.letter_date,
          amount: data?.amount,
          process_initiate_date: data?.process_initiate_date,
          cos_code: data?.cos_code,
          letter: data?.letter,
          close_letter: formData?.letter,
        }));
      }
    }
    if (cosFormData.cos_status === "rejected") {
      setCOSFormData((prev) => ({
        ...prev,
        approved_amount: "",
      }))
    }
    if (formData.lettertype === "eot" && eotID === "null") {
      setEOTFormData((prev) => ({
        ...prev,
        contractor: formData.contractor,
        project: formData.project,
        workperformedby: formData.workperformedby,
        process_initiate_date: formData.letter_date,
        approved_date: null,
        eot_code: "",
        letter: "",
      }));
    }
    if (formData.lettertype === 'cos' && cosID === "null") {
      setCOSFormData((prev) => ({
        ...prev,
        contractor: formData.contractor,
        project: formData.project,
        workperformedby: formData.workperformedby,
        process_initiate_date: formData.letter_date,
        approval_date: null,
        cos_code: "",
        letter: "",
        amount: "",
      }))
    }
  }, [formData.project, formData.workperformedby, formData.contractor, formData.letter_date, formData.select, formData.lettertype, eotID, eotFormData.eot_status, cosID, cosFormData.cos_status]);

  console.log("formData.workperformedby")
  console.log(formData.workperformedby)

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      select: "",
    }));
  }, [formData.lettertype]);





  const validateForm = () => {
    const requiredFields = [
      'letter_num',
      'project',
      'subject',
      'letter_date',
      'status',
      'lettertype',
      'workperformedby',
      'letterExchange',
      'letter_to',
      // 'letterdepartment',
      // 'letter_from',
    ];
    const newErrors = requiredFields.reduce((acc, field) => {
      if (
        !formData[field] ||
        formData[field] === "null" ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
      return acc;
    }, {});
    if (formData.workperformedby === "contractor" && !formData.contractor) {
      newErrors["contractor"] = "Contractor Name is required!";
    }
    if ((formData.lettertype === "eot" || formData.lettertype === "cos") && (!formData.select || formData.select.trim() === "")) {
      newErrors["select"] = `Select ${formData.lettertype} is required`;
    }
    if (formData.select === "new") {
      if (formData.lettertype === "eot" && (!eotFormData.eot_code || eotFormData.eot_code.trim() === "")) {
        newErrors["eot_code"] = `eot_code is required`;
      }
      if (formData.lettertype === "cos" && (!cosFormData.cos_code || cosFormData.cos_code.trim() === "")) {
        newErrors["cos_code"] = `cos_code is required`;
      }
      if (formData.lettertype === "cos" && (!cosFormData.amount || cosFormData.amount.trim() === "")) {
        newErrors["amount"] = `COS Amount is required`;
      }
    }

    if (formData.select === "existing") {
      if (formData.lettertype === "eot" && (eotID === "null")) {
        newErrors["eotID"] = `Existing Eot is required`;
      }
      if (formData.lettertype === "eot" && eotID !== "null" && (!eotFormData.eot_status || eotFormData.eot_status.trim() === "pending")) {
        newErrors["eot_status"] = `Eot Status is required`;
      }
      if (formData.lettertype === "eot" && eotID !== "null" && eotFormData.eot_status === "Approved" && (!eotFormData.approved_days || eotFormData.approved_days.trim() === "")) {
        newErrors["approved_days"] = `Approved Days is required`;
      }
      if (formData.lettertype === "eot" && eotID !== "null" && (!formData.associated_letters || formData.associated_letters.length === 0)) {
        newErrors["associated_letters"] = `Associated Letter  is required`;
      }
      if (formData.lettertype === "cos" && (cosID === "null")) {
        newErrors["cosID"] = `Existing COS is required`;
      }
      if (formData.lettertype === "cos" && cosID !== "null" && (!cosFormData.cos_status || cosFormData.cos_status.trim() === "pending")) {
        newErrors["cos_status"] = `COS Status is required`;
      }
      if (formData.lettertype === "cos" && cosID !== "null" && cosFormData.cos_status === "Approved" && (!cosFormData.approved_amount || cosFormData.approved_amount.trim() === "")) {
        newErrors["approved_amount"] = `Approved Amount is required`;
      }
      if (formData.lettertype === "cos" && cosID !== "null" && (!formData.associated_letters || formData.associated_letters.length === 0)) {
        newErrors["associated_letters"] = `Associated Letter  is required`;
      }
    }


    if (
      formData.associated_letters &&
      formData.associated_letters.length > 0 &&
      !formData.associated_letters_status
    ) {
      newErrors["associated_letters_status"] =
        "Status for associated letters is required!";
    }

    // Validate documents array
    formData.documents.forEach((doc, index) => {
      if (!doc.document_name || doc.document_name.trim() === "") {
        newErrors[`document_name_${index}`] = `Document Name is required for document ${index + 1}`;
      }
      if (!doc.document_file) {
        newErrors[`document_file_${index}`] = `Document File is required for document ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  console.log("errors");
  console.log(errors);

  const handleInputChange = (e) => {
    const { name, value, type, options } = e.target;

    let newValue;
    if (type === "select-multiple") {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      newValue = value;
    }

    // Update the input state for validation feedback
    setInputState((prevState) => ({
      ...prevState,
      [name]:
        newValue && (Array.isArray(newValue) ? newValue.length > 0 : newValue)
          ? "success"
          : "",
    }));

    // Update form data with additional logic for resetting the contractor field
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,

        // Handle the letter_from logic if reply_by and letterExchange conditions are met
        ...(name === "reply_by" &&
          prevFormData.letterExchange === "send" && {
          letter_from: value,
        }),

        // Reset contractor if workperformedby is set to consultant
        ...(name === "workperformedby" &&
          value === "consultant" && {
          contractor: "",
        }),
      };

      return updatedFormData;
    });
  };

  const handleEotInputChange = (e) => {
    const { name, value } = e.target;
    seteotInputState({
      ...eotinputState,
      [name]: value.trim() ? "green" : "",
    });
    setEOTFormData({
      ...eotFormData,
      [name]: value,
    });
  };
  const handleCOSInputChange = (e) => {
    const { name, value } = e.target;
    setcosInputState({
      ...cosinputState,
      [name]: value.trim() ? "green" : "",
    });
    setCOSFormData({
      ...cosFormData,
      [name]: value,
    });
  };

  const handleDocInputChange = (index, e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      newDocuments[index] = {
        ...newDocuments[index],
        [name]: files ? files[0] : value,
      };
      if (files) {
        setFileNames((prevFileNames) => {
          const newFileNames = [...prevFileNames];
          newFileNames[index] = files[0]?.name || "";
          return newFileNames;
        });
      }

      return { ...prevFormData, documents: newDocuments };
    });
  };

  const handleAddDocuments = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: [
        ...prevFormData.documents,
        { document_name: "", document_file: null },
      ],
    }));
  };

  const handleRemoveDocument = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: prevFormData.documents.filter((_, i) => i !== index),
    }));
    setFileNames((prevFileNames) =>
      prevFileNames.filter((_, i) => i !== index)
    );
  };

  const createCustomFile = (originalFile) => {
    const randomCode = generateRandomString();
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
    return new File([originalFile], customFileName, {
      type: originalFile.type,
    });
  };

  const generateRandomString = () => {
    const chars =
      "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from(
      { length: 8 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
  };

  const options = trackRecordList
    .sort((a, b) => parseInt(a.letter_num) - parseInt(b.letter_num))
    .map((e) => ({
      value: e.id,
      label: `${e.letter_num} - ${e.letter_date}`,
    }));


  const handleChangecc = (selected) => {
    const associated_letters =
      selected.length > 0 ? selected.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      associated_letters,
    }));
  };
  const fetchAssociatedLetters = async () => {
    try {
      const res = await axios.get(
        eotFormData.letter !== "" ? `${BASE_URL}/siteproject/letterrecordsbyid/${eotFormData.letter}/` :
          cosFormData.letter !== "" ? `${BASE_URL}/siteproject/letterrecordsbyid/${cosFormData.letter}/` :
            `${BASE_URL}/siteproject/letterrecordbclosestatus/${formData.project || 'null'
            }/${formData.workperformedby || 'null'}/${formData.lettertype || 'null'}/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error("Error fetching letters.", err);
    }
  };
  const getSiteDepartment = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/department/`);
      setSiteDepartmentList(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };
  const getProjectContractorDetails = async () => {
    try {
      const contractordetails = await axios.get(
        `${BASE_URL}/siteproject/contractorbyproject/${formData.project}/`
      );
      setContractorLists(contractordetails.data);
    } catch (err) {
      // handleErrorToast(err);
    } finally {
    }
  };
  const getEot = async () => {

    try {
      // let eot = await axios.get(`${BASE_URL}/siteproject/eot/`);
      let eot = await axios.get(`${BASE_URL}/siteproject/eotfilter/${formData.project}/${formData.workperformedby}/${formData.contractor ? formData.contractor : null}/pending/`);
      setEotList(eot.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {

    }
  };
  const getCOS = async () => {

    try {
      // let eot = await axios.get(`${BASE_URL}/siteproject/eot/`);
      let cos = await axios.get(`${BASE_URL}/siteproject/cosfilter/${formData.project}/${formData.workperformedby}/${formData.contractor ? formData.contractor : null}/pending/`);
      setCosList(cos.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {

    }
  };





  console.log("formData.contractor");
  console.log(formData.contractor);
  console.log("eotFormData.contractor");
  console.log(eotFormData.contractor);
  console.log("eotID");
  console.log(eotID);
  console.log("eotFormData");
  console.log(eotFormData);
  console.log("formData.contractor");
  console.log(formData.contractor);
  console.log("cosFormData.contractor");
  console.log(cosFormData.contractor);
  console.log("cosID");
  console.log(cosID);
  console.log("cosFormData");
  console.log(cosFormData);
  useEffect(() => {
    // Check if conditions are met before calling getEot
    if (formData.project !== "null"
    ) {
      if (formData.select === "existing") {
        if (formData.lettertype === "eot") {
          getEot();
        }
        if (formData.lettertype === "cos") {
          getCOS();
        }
      }
    }
  }, [formData.project, formData.workperformedby, formData.contractor, formData.lettertype, formData.select]);



  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        const formDataToSend = new FormData();

        // Append general form fields
        Object.keys(formData).forEach((key) => {
          if (key !== "documents" && formData[key]) {
            if (key === "associated_letters") {
              formData[key].forEach((value) =>
                formDataToSend.append(key, value)
              );
            } else {
              formDataToSend.append(key, formData[key]);
            }
          }
        });

        // Append documents data
        formData.documents.forEach((doc, index) => {
          if (doc.document_file) {
            const customFile = createCustomFile(doc.document_file);
            formDataToSend.append(
              `documents[${index}][document_name]`,
              doc.document_name
            );
            formDataToSend.append(
              `documents[${index}][document_file]`,
              customFile
            );
          }
        });

        const res = await axiosInstance.post(
          `/siteproject/documentbulkwithletter/`,
          formDataToSend,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        );

        if (res.status === 200) {

          const generatedId = res.data.id;

          if (formData.lettertype === "eot") {
            if (eotID === "null") {

              setEOTFormData((prev) => ({
                ...prev,
                letter: generatedId,
                eot_code: eotFormData.eot_code,
              }));
              const updatedEotData = {
                ...eotFormData,
                letter: generatedId,
                eot_code: eotFormData.eot_code,
              };
              const eotpost = await axios.post(`${BASE_URL}/siteproject/eot/`, updatedEotData);

              if (eotpost.status === 200) {
                await getEot();

              }
            } else if (eotID !== "null") {
              setEOTFormData((prev) => ({
                ...prev,
                close_letter: generatedId,
              }));
              const updatedEotData = {
                ...eotFormData,
                close_letter: generatedId,
              };
              const eotUpdate = await axios.put(`${BASE_URL}/siteproject/eot/${eotID}/`, updatedEotData);

              if (eotUpdate.status === 200) {
                await getEot();
              }
            }


          }
          if (formData.lettertype === "cos") {
            if (cosID === "null") {

              setCOSFormData((prev) => ({
                ...prev,
                letter: generatedId,
                cos_code: cosFormData.cos_code,
              }));
              const updatedCOSData = {
                ...cosFormData,
                letter: generatedId,
                cos_code: cosFormData.cos_code,
              };
              const cospost = await axios.post(`${BASE_URL}/siteproject/cos/`, updatedCOSData);

              if (cospost.status === 200) {
                await getCOS();

              }
            } else if (cosID !== "null") {
              setCOSFormData((prev) => ({
                ...prev,
                close_letter: generatedId,
              }));
              const updatedCOSData = {
                ...cosFormData,
                close_letter: generatedId,
              };
              const cosUpdate = await axios.put(`${BASE_URL}/siteproject/cos/${cosID}/`, updatedCOSData);

              if (cosUpdate.status === 200) {
                await getCOS();
              }
            }


          }

          await getProjects();

          toast.success("Letter Documents uploaded successfully!");
          handleClose();
          toast.dismiss(loadingToastId);

        } else {
          throw new Error("Document upload failed");
        }
      } catch (err) {
        toast.error("Error uploading documents");
        console.error("Error uploading documents", err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
        setUploadProgress(0);
      }
    }
  };



  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contractor: letterfor === "contractor" ? contractor : "",
    }));
  }, [contractor]);
  useEffect(() => {
    if (formData.project !== "null") {
      getProjectContractorDetails();
    }
  }, [formData.project]);

  useEffect(() => {
    if ((formData.project && formData.workperformedby) || eotFormData.letter !== "" || cosFormData.letter !== "") {
      fetchAssociatedLetters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project, formData.workperformedby, formData.lettertype, formData.select, eotFormData.letter, cosFormData.letter]);

  return (
    <>
      <button
        title={`Add ${title} Details`}
        className="upload-svg equal-hieg"
        onClick={handleShow}
      >
        <AddwithWhiteCircle /> {` ${title}`}
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>Add {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">

              {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? "" :

                ((projectId === 'null' || projectId === undefined || projectId === "") && (
                  <div className="form-group">
                    <RenderSelectField
                      title="Site Project"
                      name="project"
                      required={true}
                      options={projectList?.map((project) => ({
                        value: project.id,
                        label: `${project.site_prcode} - ${project.project_short_name}`,
                      }))}
                      placeholder="Select a Project"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.project && (
                      <div className="error-message col">
                        {errors.project}
                      </div>
                    )}
                  </div>
                ))
              }



              <div className="form-group">
                <RenderSelectField
                  title="Letter Exchange"
                  name="letterExchange"
                  required={true}
                  options={[
                    { value: "send", label: "Send" },
                    { value: "receive", label: "Received" },
                  ]}
                  placeholder="Select an Exchange Type"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letterExchange && (
                  <div className="error-message col">
                    {errors.letterExchange}
                  </div>
                )}
              </div>

              {formData.letterExchange === "send" && (
                <div className="form-group">
                  <RenderSelectField
                    title="Reply By"
                    name="reply_by"
                    required={true}
                    options={[
                      { value: "HO", label: "Head Office" },
                      { value: "SO", label: "Site Office" },
                    ]}
                    placeholder="Select a Reply By"
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.reply_by && (
                    <div className="error-message col">{errors.reply_by}</div>
                  )}
                </div>
              )}

              <div className="form-group">
                <RenderInputField
                  title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter No.`}
                  name="letter_num"
                  required={true}
                  placeholder="Letter Number"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_num && (
                  <div className="error-message col">{errors.letter_num}</div>
                )}
              </div>


              {letterfor ? <></> :

                (
                  <div className="form-group">
                    <RenderSelectField
                      title="Letter Related To"
                      name="workperformedby"
                      required={true}
                      options={[
                        ...(formData.project &&
                          ((Array.isArray(contractorlist) &&
                            contractorlist.length > 0) ||
                            (Array.isArray(contractorlists) &&
                              contractorlists.length > 0))
                          ? [{ value: "contractor", label: "Contractor" }]
                          : []),
                        { value: "consultant", label: "Consultant" },
                      ]}
                      placeholder="Select Option"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.workperformedby && (
                      <div className="error-message col">
                        {errors.workperformedby}
                      </div>
                    )}
                  </div>
                )}

              {formData.workperformedby === 'contractor' ?
                <div className="form-group">
                  <div title=" Contractor Name" className="flex-column form-group-selectt">
                    <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                      Contractor Name<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      id="contractor"
                      name="contractor"
                      value={formData.contractor}
                      onChange={handleInputChange}
                      disabled={!!contractor}

                      className={`form-input form-group-selection ${errors.contractor ? "error" : inputState.contractor ? "success" : ""
                        }`}
                    >
                      <option value="">Select a Contractor</option>
                      {contractorlists?.sort((a, b) => a.name?.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                        <option value={i.id}>{i.name}</option>
                      </>))
                      }
                    </select>


                    <DropdownArrow />

                  </div>

                  {errors.contractor && (
                    <div className="error-message col">{errors.contractor}</div>
                  )}
                </div>
                : ""
              }

              {lettertype ?
                <></>
                :
                <>
                  <div className="form-group">
                    <RenderSelectField
                      title="Letter Type"
                      name="lettertype"
                      required={true}
                      options={[
                        { value: "show-cause", label: "Show Cause" },
                        { value: "informative", label: "Informative" },
                        { value: "eot", label: "EOT" },
                        { value: "cos", label: "COS" },
                        { value: "billing", label: "Billing" },
                        { value: "design", label: "Design" },
                        { value: "ncr", label: "NCR" },

                        { value: "Design & Drawing", label: "Design & Drawing" },
                        { value: "P&P", label: "P&P" },
                        { value: "QAP", label: "QAP" },
                        { value: "Structure", label: "Structure" },
                        { value: "Q & ME", label: "Q & ME" },
                        { value: "BBS", label: "BBS" },
                        { value: "noc", label: "NOC" },
                        { value: "Road Safety", label: "Road Safety" },
                        { value: "Work Plan", label: "Work Plan" },
                        { value: "Drain", label: "Drain" },
                        { value: "Hydrology", label: "Hydrology" },
                        { value: "Survey", label: "Survey" },
                        { value: "Milestone", label: "Milestone" },
                        { value: "query", label: "Query" },
                        { value: "SiteProgress", label: "SiteProgress" },
                        { value: "other", label: "Others" }
                      ]}
                      placeholder="Select a Letter Type"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.lettertype && (
                      <div className="error-message col">{errors.lettertype}</div>
                    )}
                  </div></>
              }
              {(formData.lettertype === "eot" || formData.lettertype === "cos") && formData.workperformedby !== "null" ?
                <div className="form-group">
                  <div className="flex-column form-group-selectt">
                    <label
                      htmlFor="select"
                      className="form-labels  font-weight500    font-size-subheading"
                    >
                      New/Existing {formData.lettertype}<span className="required">*</span>
                    </label>
                    <br />
                    <select id="select"
                      name="select"
                      placeholder="select"
                      value={formData.select}

                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.select ? "error" : inputState.select ? "success" : ""
                        }`}
                    >
                      <option value="">Select</option>
                      <option value="new">New {formData.lettertype}</option>
                      <option value="existing">Existing {formData.lettertype}</option>

                    </select>





                    <DropdownArrow />

                  </div>
                  {errors.select && (
                    <div className="error-message col">{errors.select}</div>
                  )}
                </div> : ""
              }


              {formData.select === "new" ?
                <>
                  {formData.lettertype === "eot" ?
                    <>
                      <div className="form-group">
                        <RenderInputField
                          title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter Date`}
                          name="letter_date"
                          type="date"
                          required={true}
                          formData={formData}
                          errors={errors}
                          inputState={inputState}
                          handleInputChange={handleInputChange}
                        />
                        {errors.letter_date && (
                          <div className="error-message col">{errors.letter_date}</div>
                        )}
                      </div>
                      <div className="flex-column">
                        <label className="form-labels font-weight500 font-size-subheading"
                          title="EOT Code">EOT Code<span className="required">*</span></label>
                        <input
                          type="text"
                          name="eot_code"
                          placeholder="EOT Code"
                          title="EOT Code"
                          value={eotFormData.eot_code}
                          onChange={handleEotInputChange}
                          className={`form-input ${errors.eot_code ? "error" : eotinputState.eot_code ? "success" : ""}`}
                        />
                        {errors.eot_code && <span className="error-message font-size-text">{errors.eot_code}</span>}
                      </div>

                    </> : ""
                  }
                  {formData.lettertype === "cos" ?
                    <>
                      <div className="form-group">
                        <RenderInputField
                          title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter Date`}
                          name="letter_date"
                          type="date"
                          required={true}
                          formData={formData}
                          errors={errors}
                          inputState={inputState}
                          handleInputChange={handleInputChange}
                        />
                        {errors.letter_date && (
                          <div className="error-message col">{errors.letter_date}</div>
                        )}
                      </div>
                      <div className="flex-column">
                        <label className="form-labels font-weight500 font-size-subheading"
                          title="COS Code">COS Code<span className="required">*</span></label>
                        <input
                          type="text"
                          name="cos_code"
                          placeholder="COS Code"
                          title="COS Code"
                          value={cosFormData.cos_code}
                          onChange={handleCOSInputChange}
                          className={`form-input ${errors.cos_code ? "error" : eotinputState.cos_code ? "success" : ""}`}
                        />
                        {errors.cos_code && <span className="error-message font-size-text">{errors.cos_code}</span>}
                      </div>
                      <div className="flex-column">
                        <label className="form-labels font-weight500 font-size-subheading"
                          title="COS Amount">COS Amount(Write Full Amount)<span className="required">*</span></label>
                        <input
                          type="number"
                          name="amount"
                          placeholder="COS Amount"
                          title="COS Amount"
                          value={cosFormData.amount}
                          onChange={handleCOSInputChange}
                          className={`form-input ${errors.amount ? "error" : eotinputState.amount ? "success" : ""}`}
                        />
                        {errors.amount && <span className="error-message font-size-text">{errors.amount}</span>}
                      </div>

                    </> : ""
                  }


                </> :
                formData.select === "existing" ?
                  <>
                    {formData.lettertype === "eot" ?
                      <div className="form-group">
                        <div className="flex-column form-group-selectt">
                          <label
                            htmlFor="select"
                            className="form-labels  font-weight500    font-size-subheading"
                          >
                            Existing EOT<span className="required">*</span>
                          </label>
                          <br />
                          <select

                            value={eotID}
                            onChange={(e) => setEotId(e.target.value)}


                            className={`form-input form-group-selection`}
                          >
                            <option value="">Select Existing Eot</option>
                            {eotList?.sort((a, b) => a.eot_code?.localeCompare(b.eot_code, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                              <option value={i.id}>{i.eot_code}</option>
                            </>))
                            }
                          </select>
                          <DropdownArrow />
                        </div>
                        {errors.eotID && (
                          <div className="error-message col">{errors.eotID}</div>
                        )}
                      </div>
                      : ""
                    }
                    {formData.lettertype === "cos" ?
                      <div className="form-group">
                        <div className="flex-column form-group-selectt">
                          <label
                            htmlFor="select"
                            className="form-labels  font-weight500    font-size-subheading"
                          >
                            Existing COS<span className="required">*</span>
                          </label>
                          <br />
                          <select

                            value={cosID}
                            onChange={(e) => setCosId(e.target.value)}


                            className={`form-input form-group-selection`}
                          >
                            <option value="">Select Existing Cos</option>
                            {cosList?.sort((a, b) => a.cos_code?.localeCompare(b.cos_code, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                              <option value={i.id}>{i.cos_code}</option>
                            </>))
                            }
                          </select>
                          <DropdownArrow />
                        </div>
                        {errors.cosID && (
                          <div className="error-message col">{errors.cosID}</div>
                        )}
                      </div>
                      : ""
                    }
                  </> : ""
              }
              {formData.select !== "new" &&
                <div className="form-group">
                  <RenderInputField
                    title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter Date`}
                    name="letter_date"
                    type="date"
                    required={true}
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.letter_date && (
                    <div className="error-message col">{errors.letter_date}</div>
                  )}
                </div>
              }


              {eotID !== "null" ?
                <>
                  <div className="form-group">
                    <div className="flex-column form-group-selectt">
                      <label className="form-labels font-weight500 font-size-subheading" title="EOT Status">EOT Status</label>
                      <br />
                      <select
                        name="eot_status"
                        title="EOT status"
                        value={eotFormData.eot_status}
                        onChange={handleEotInputChange}
                        className={`form-input form-group-selection`}
                      >
                        <option value="pending">Select Status</option>
                        <option value="Approved">Approved</option>
                        <option value="rejected">Rejected</option>

                      </select>
                      <DropdownArrow />
                    </div>
                    {errors.eot_status && <div className="error-message font-size-text">{errors.eot_status}</div>}
                  </div>

                  {
                    eotFormData.eot_status === "Approved" &&
                    <div className="flex-column">
                      <label className="form-labels font-weight500 font-size-subheading" title="Approved Days">
                        Approved Days
                      </label>
                      <input
                        type="number"
                        name="approved_days"
                        title="Approved Days"
                        placeholder="Approved Days"
                        value={eotFormData.approved_days}
                        onChange={handleEotInputChange}
                        className={`form-input ${errors.approved_days
                          ? "error"
                          : inputState.approved_days
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.approved_days && (
                        <span className="error-message font-size-text">
                          {errors.approved_days}
                        </span>
                      )}
                    </div>
                  }




                </> : <></>

              }
              {cosID !== "null" ?
                <>
                  <div className="form-group">
                    <div className="flex-column form-group-selectt">
                      <label className="form-labels font-weight500 font-size-subheading" title="Cos Status">Cos Status</label>
                      <br />
                      <select
                        name="cos_status"
                        title="Cos status"
                        value={cosFormData.cos_status}
                        onChange={handleCOSInputChange}
                        className={`form-input form-group-selection`}
                      >
                        <option value="pending">Select Status</option>
                        <option value="Approved">Approved</option>
                        <option value="rejected">Rejected</option>

                      </select>
                      <DropdownArrow />
                    </div>
                    {errors.cos_status && <div className="error-message font-size-text">{errors.cos_status}</div>}
                  </div>

                  {
                    cosFormData.cos_status === "Approved" &&
                    <div className="flex-column">
                      <label className="form-labels font-weight500 font-size-subheading" title="Approved Amount">
                        Approved Amount
                      </label>
                      <input
                        type="number"
                        name="approved_amount"
                        title="Approved Amount"
                        placeholder="Approved Amount"
                        value={cosFormData.approved_amount}
                        onChange={handleCOSInputChange}
                        className={`form-input ${errors.approved_amount
                          ? "error"
                          : cosinputState.approved_amount
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.approved_amount && (
                        <span className="error-message font-size-text">
                          {errors.approved_amount}
                        </span>
                      )}
                    </div>
                  }




                </> : <></>

              }




              <div className="form-group">
                <RenderInputTextareaField
                  title="Subject"
                  name="subject"
                  placeholder="Enter subject here..."
                  rows={5}
                  cols={50}
                  formData={formData}
                  errors={errors}
                  required={true}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.subject && (
                  <div className="error-message col">{errors.subject}</div>
                )}
              </div>
              <div className="form-group">
                <div title="Status" className="flex-column form-group-selectt">
                  <label
                    htmlFor="status"
                    className="form-labels  font-weight500    font-size-subheading"
                  >
                    Status<span className="required">*</span>
                  </label>
                  <br />
                  <select id="status"
                    name="status"
                    placeholder="Status"
                    value={formData.status}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Status</option>
                    <option value="pending">Pending</option>
                    <option value="close">Close</option>

                  </select>
                  <DropdownArrow />
                </div>
                {errors.status && (
                  <div className="error-message col">{errors.status}</div>
                )}
              </div>

              <div className="form-group">
                <RenderSelectField
                  title="Department"
                  name="letterdepartment"
                  required={true}
                  options={siteDepartmentList
                    .map((department) => ({
                      value: department.id,
                      label: `${department.title}`,
                    }))}
                  placeholder="Select a Department"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letterdepartment && (
                  <div className="error-message col">{errors.letterdepartment}</div>
                )}
              </div>







              {formData.letterExchange === 'receive' &&
                <div className="form-group">
                  <RenderInputField
                    title="Letter From"
                    name="letter_from"
                    placeholder="Letter From"
                    max={100}
                    formData={formData}
                    required={true}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.letter_from && (
                    <div className="error-message col">
                      {errors.letter_from}
                    </div>
                  )}
                </div>}

              <div className="form-group">
                <RenderInputField
                  title="Letter To"
                  name="letter_to"
                  placeholder="Letter To"
                  max={100}
                  required={true}
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_to && (
                  <div className="error-message col">{errors.letter_to}</div>
                )}
              </div>
              <div className="form-group">
                <RenderInputField
                  title="Remarks"
                  name="remarks"
                  placeholder="Remarks"
                  max={100}
                  required={true}
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.remarks && (
                  <div className="error-message col">{errors.remarks}</div>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="names"
                  className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                >
                  Associated Letters
                </label>
                <Select
                  options={options}
                  isMulti
                  onChange={handleChangecc}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '33vw',
                      height: '50px',
                      borderRadius: '10px',
                      border: '1px solid #e3e3e3',
                      margin: '10px 0px 20px 0px',
                      color: '#4a4a4a',
                      backgroundColor: '#f7f8fa',
                      padding: '0px 10px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: '33vw',
                      borderRadius: '10px',
                      marginTop: '0',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                  }}
                />
                {errors.associated_letters && (
                  <span className="error-message font-size-text">
                    {errors.associated_letters}
                  </span>
                )}
              </div>

              {/* Show status options if associated letters are selected */}
              {formData.associated_letters &&
                formData.associated_letters.length > 0 && (
                  <>
                    <div className="form-column">
                      <RenderSelectField
                        title="Update Status of Associated Letters"
                        name="associated_letters_status"
                        required={true}
                        options={[
                          { value: "pending", label: "Pending" },
                          { value: "close", label: "Close" },
                        ]}
                        placeholder="Select Status"
                        formData={formData}
                        errors={errors}
                        inputState={inputState}
                        handleInputChange={handleInputChange}
                      />
                      {errors.associated_letters_status && (
                        <div className="error-message col">
                          {errors.associated_letters_status}
                        </div>
                      )}
                    </div>
                  </>
                )}
            </div>

            <DocumentSection
              formData={formData}
              handleDocInputChange={handleDocInputChange}
              handleAddDocuments={handleAddDocuments}
              handleRemoveDocument={handleRemoveDocument}
              errors={errors}
              fileNames={fileNames}
              inputState={inputState}
            />

            {/* Upload Progress Bar */}
            {uploadProgress > 0 && (
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
                <span>{uploadProgress}%</span>
              </div>
            )}

            <div className="button-models">
              <button
                onClick={handleFormSubmit}
                className="model-button font-weight500 model-button-submit"
                disabled={loading}
              >
                Submit
              </button>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>

      </Modal>
    </>
  )

};


































export {
  AddProjectDetails,

  UpdateProjectAllDetails,
  AddProjectBill,
  UpdateProjectBill,
  AssociatedLetters,
  DeleteBillingDetails,

  AddTrackRecordDetails,
  UpdateTrackRecordDetails,
  UpdateTrackRecordDetailwithIDonly,
  DeleteTrackAndLetterDetails,

  AddCircular,
  ApproveCircular,
  RejectCircular,
  DeleteCircular,

  AddContractor,
  UpdateContractor,
  DeleteContractor,
  UpdateInsurance,
  AddInsurance,
  DeleteInsurance,
  AddBankGuarantee,
  DeleteBg,

  AddDocuments,

  DocumentModel,


  UpdateBankGuarantee,
  AddEOTDetails,
  UpdateEOTDetails,

  AddCOSDetails,
  UpdateCOSDetails,
  AddMilstoneDetails,
  UpdateMilestone,
  AddProgressdetails,
  UpdateProgressdetails,
  AddLetters,
  AddBOQInvoice,
  UpdateConsultantDetails,
  DeleteDocuments,
  DeleteMileStone,
  DeleteEOT,
  DeleteCOS,
  AddLetterLinked,
};
