import { Routes, Route } from "react-router-dom";
import "./App.css";
import Auth from "./routes/Auth";
import React, { useState, useEffect } from "react";
import Internet from "./assets/gif/internet-down.gif";
import Server from "./assets/gif/server-down.gif";
import "bootstrap/dist/css/bootstrap.min.css";

import PageNotFound from "./pages/PageNotFound";
import { routingData } from "./routes/routing";
import RedirectRouter from "./routes/RedirectRouter";

function App() {
  const recursiveRouter = (props) => {
    const { nestedRoutes, routes, isProtected, role } = props;
    const authenticatedRouted = () => {
      return (
        <>
          {routes &&
            routes.map((e, index) => (
              <Route key={index} path={e.path} element={e.component} />
            ))}
          {nestedRoutes &&
            nestedRoutes.map((e, index) => (
              <Route path={e.path} element={e.component} key={index}>
                {recursiveRouter(e)}
              </Route>
            ))}
        </>
      );
    };

    return isProtected ? (
      <Route element={<Auth allowedRoles={role} />}>
        {authenticatedRouted()}
      </Route>
    ) : role === "" ? (
      <Route element={<RedirectRouter />}>{authenticatedRouted()}</Route>
    ) : (
      <>{authenticatedRouted()}</>
    );
  };

  // 1. Set session start time when session begins
  useEffect(() => {
    const sessionStartTime = localStorage.getItem("session_start_time");

    // If no session_start_time is set, set it now
    if (!sessionStartTime) {
      localStorage.setItem("session_start_time", Date.now());
    }

    // Clean up session token and time on visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        localStorage.removeItem("session_token");
        localStorage.removeItem("session_start_time");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // 2. Check if session exceeds 3 hours (10800000 ms)
  useEffect(() => {
    const checkSessionDuration = () => {
      const sessionStartTime = localStorage.getItem("session_start_time");
      if (sessionStartTime) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - parseInt(sessionStartTime, 10);

        // If elapsed time is more than 3 hours (10800000 ms), remove session token and reload
        if (elapsedTime >= 10800000) {
          localStorage.removeItem("session_token");
          localStorage.removeItem("session_start_time");
          window.location.reload(); // Refresh the page
        }
      }
    };

    // Check session duration every minute
    const interval = setInterval(checkSessionDuration, 60000);

    return () => clearInterval(interval);
  }, []);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const serverOnline = true;

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);
  

  return (
    <>
      {isOnline ? (
        serverOnline ? (
          <Routes>
            {routingData.map((e, index) => {
              return (
                <React.Fragment key={index.toString()}>
                  {recursiveRouter(e)}
                </React.Fragment>
              );
            })}

            {/* Extra Routes */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        ) : (
          <center>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>
              You Are Online but, the Server is Offline. <br />
              <b>Please be Patient. We are working hard to fix it.</b> <br />
              <br />
              <img
                style={{
                  borderRadius: "20px",
                  boxShadow: "0px 1px 38px 0px rgba(0, 0, 0, 0.10)",
                }}
                src={Server}
                alt=""
              />
            </p>
          </center>
        )
      ) : (
        <center>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <p>
            You are offline. Because Internet connection Is Not Working. <br />
            <b>Our Software is working Fine :)</b> <br />
            <br />
            <img
              style={{
                borderRadius: "20px",
                boxShadow: "0px 1px 38px 0px rgba(0, 0, 0, 0.10)",
              }}
              src={Internet}
              alt=""
            />
          </p>
        </center>
      )}
    </>
  );
}

//demo demo
export default App;
