 import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import Logo from "../../assets/images/loginimage.png"
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import { EyeBottonInput, Eyebuttonwithline } from "../../components/AllSvg";
import NewYearGif from "../../assets/gif/555.gif"

const Login = () => {

  const navigate = useNavigate();
  const { updatePermissions } = usePermission(); // Use the hook

  const [errMsg, setErrMsg] = useState("");


  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });



  const [errors, setErrors] = useState({});

  const [inputState, setInputState] = useState({});

  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    // Check if the fields have been auto-filled by the browser
    const emailInput = document.getElementById('email');
    const passwordInput = document.getElementById('password');
    if (emailInput && passwordInput) {
      setFormData({
        email: emailInput.value,
        password: passwordInput.value
      });
      setInputState({
        email: emailInput.value ? 'green' : '',
        password: passwordInput.value ? 'green' : '',
      });
    }
  }, []);



  const validateForm = () => {
    const newErrors = {};


    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    const requiredFields = [
      'password',
    ];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };



  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (validateForm()) {
      setLoading(true); //loading logic
      // const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      // toast.success(`Logging In...`);
      const logingToastId = toast.loading(`Logging In...`); //toast Logic

      try {
        let res = await axios.post(`${BASE_URL}/user/login/`, formData);

        if (res.status === 200) {
          toast.success(`Logging In...`);

          const { access, refresh, payload, employeecode, payload_a, letter,sitemanagement_role,sitemanagement_project, sitemanagement_emp_id} = res.data;

          const sessionData = {
            access_token: access || "",
            refresh_token: refresh || "",
            role: payload?.role || "",
            email: payload?.email || "",
            emp_code: employeecode || "",
            department: payload_a?.department || "",
            rh: payload_a?.reporting_head || "",
            profilepic: payload_a?.profilepic || "",
            letter_type: letter || "",
            name: payload_a?.name || "",
            company: payload_a?.division_name || "",
            company_id: payload_a?.sub_company_id || "",
            sitemanagement_role: sitemanagement_role || "",
            sitemanagement_emp_id: sitemanagement_emp_id || "",

            sitemanagement_project: sitemanagement_project?.id || "",
            sitemanagement_project_short_name: sitemanagement_project?.project_short_name || "",
            sitemanagement_project_site_prcode: sitemanagement_project?.site_prcode || "",
            sitemanagement_project_sub_company: sitemanagement_project?.sub_company || "",
         

        };

          console.log(sessionData);

          Object.entries(sessionData).forEach(([key, value]) => {
            sessionStorage.setItem(key, value);
          });

          updatePermissions(); // Update permissions after setting session storage


          

          // Optionally, you can log updated sessionStorage
          console.log("Updated sessionStorage:", sessionStorage);

          // Navigate to the desired location after setting session data
          navigate("/");
        } else {
          setErrMsg("Invalid Email Or Password");
        }
      } catch (err) {
        console.log(err);
        setErrMsg(err.response.data.error);
        // setErrMsg("Invalid Email Or Password");

        //toast Logic
        if (err.response) {
          toast.dismiss(logingToastId);
          const errorData = err.response.data;
          if (typeof errorData.error === 'string') { // Single error message   
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false);  //loading logic
        // toast.dismiss(loadingToastId);
      }
    } else {
      console.log('Form contains errors:', errors);

    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });


    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const forgotpass = () => {
    navigate("/forgotpassword");
  }


  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent the default form submission
      handleSubmit(event);
    }
  };
  ;






  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className='login-page'>
        <div>
          <Link to="/">
            <img src={NewYearGif} alt='logo' className=' login-page-img width-25vw ' /></Link>
        </div>
        <div className='login-page-right width-35vw'>
          <div className='login-page-first-heading   font-weight300'>Hello!</div>
          <div className='login-page-second-heading   font-weight600'>Welcome Back👋</div>
          <div className='login-page-third-heading   font-weight300 font-size-heading'>The world is just a step away from here </div>

          <form onSubmit={handleSubmit} onKeyDown={handleKeyPress} className="register-form">
            <div className="flex-column">
              <label htmlFor="email" className='form-labels'>Email</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="example@gmail.com"
                autoComplete="off"
                onChange={handleInputChange}
                value={formData.email}
                onInput={(e) => {
                  e.target.value = e.target.value.toLowerCase().trim();// Convert input to lowercase
                }}
                className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
              />
              {errors.email && (
                <span className="error-message font-size-text ">{errors.email}</span>
              )}</div>
            <div className="flex-column">

              <label htmlFor="password" className='form-labels'>Password:</label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  id="password"
                  name="password"
                  onChange={handleInputChange
                  }
                  value={formData.password}

                  className={`form-input ${errors.password ? 'error' : inputState.password ? 'success' : ''}`}
                />
                {errors.password && (
                  <span className="error-message font-size-text ">{errors.password}</span>
                )}
                <button
                  type="button"
                  style={{ position: 'absolute', right: 20, top: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeBottonInput /> : <Eyebuttonwithline/>}
                </button>
              </div>
            </div>
            <div>
              <button type="submit" className="login-button font-weight600  font-size-heading" disabled={loading}>
                Sign In
              </button></div>
            <button className="forgotbutton  font-weight600  font-size-text " onClick={forgotpass}>Forgot Password ?</button>
            <br></br>
            <p
              style={{ color: "red" }}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

          </form>

        </div>
      </div>
    </>
  )
}

export default Login
