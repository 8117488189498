import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import { downloadAsExcel, handleErrorToast } from "../CustomFunctions";
import { Calender, CircularGrid, DownloadIcon, Employee, Office, SearchBig, SearchSmall, UpdatePencil, Eye } from "../AllSvg";
import { RunPayRoll, SalaryDeductionPolicyAdd, SalaryDeductionPolicyDelete, SalaryDeductionPolicyUpdate, SalaryStructureHeadingAdd, SalaryStructureHeadingDelete, SalaryStructureHeadingUpdate } from "./PayrollComponents";


const PayRun = () => {
  const [designationname, setDesignationname] = useState([]);

  const [buffer, setBuffering] = useState(false); //buffering logic

  const getDesignation = async () => {
    // setBuffering(true); //buffering logic

    try {
      const des = await axios.get(`${BASE_URL}/siteproject/designation/`);


      setDesignationname(des.data);

    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [yearr, setYearr] = useState(`${year}`);
  const [monthlylist, setMonthlylist] = useState([]);
  const [monthlylistfilter, setMonthlylistfilter] = useState([]);

  const [toggleState, setToggleState] = useState("pending/");
  // const [searchQuery, setSearchQuery] = useState("");

  const getSalarylist = async () => {
    setBuffering(true); //buffering logic

    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/salaryslipall/${yearr}/${monthh}`
      );
      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: "", numericPart: 0 };
        }
      };
      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } = getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } = getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setMonthlylist([]);
    } catch (err) {
      // toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  // const [selectedRH, setRh] = useState('');
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );

  const applyFilters = () => {
    let filteredData = monthlylist;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.user_details.name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return nameMatch;
      });
    }

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.user_details.department_name === selectedDep
      );
    }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.user_details.designation_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }

    // if (selectedRH) {

    //     filteredData = filteredData.filter(employee => employee.rh_assigned === selectedRH);
    // }

    if (selectedDivision) {
      filteredData = filteredData.filter(
        (employee) => employee.user_details.division_name === selectedDivision
      );
    }

    setMonthlylistfilter(filteredData);
  };

  useEffect(() => {
    // getSalarylist();
    getSubCompany();
  }, [monthh, yearr]);

  useEffect(() => {
    applyFilters();
  }, [selectedName, selectedDep, selectedDesi, monthlylist, selectedDivision]);

  // ?  ************** Put Request Start **************  ! //

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/salarySlip`;
  const salarySlip = (emp_code, month, year) => {
    navigate(baseUrl + `/${year}` + `/${month}` + `/${emp_code}/`);
  };

  const OneSalarySlip = (i, monthh, yearr) => {
    navigate(baseUrl, { state: { i, monthh, yearr } });
  };
  const tableRef = useRef(null);
  const handleDownloadPayRunSheet = () => {
    downloadAsExcel(tableRef, "users", selectedDivision === "" ? `PayRunSheet-${monthh}-${yearr}` : `${selectedDivision}-PayRunSheet-${monthh}-${yearr}`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Calender />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>



            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Designation"
                type="text"
                value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
            </div>
          </div>
          {/* <DownloadTableExcel
            filename={
              selectedDivision === ""
                ? `PayRunSheet-${monthh}-${yearr}`
                : `${selectedDivision}-PayRunSheet-${monthh}-${yearr}`
            }
            // filename={`BankFormatSheet-${monthh}-${yearr}`}
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className=" model-button-print">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="22"
                id="download"
                fill="#fff"
              >
                <g
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                </g>
              </svg>
            </button>
          </DownloadTableExcel> */}
          <button className=" model-button-print" onClick={handleDownloadPayRunSheet}>
            <DownloadIcon />
          </button>
        </div>
      </div>
      <div className="table-css-white-background scroll-container-table">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Pay Run
            {/* <p className='career-para font-size-subheading">Based your performance</p>*/}
          </div>
          <RunPayRoll year={yearr} month={monthh} />
        </div>
        <table ref={tableRef} className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              {/* <th className="align-left">S.No.</th> */}
              <th className="table-heading-text">Emp Code</th>
              <th className="table-heading-text text-center">Name</th>
              <th className="table-heading-text text-center">Department</th>
              <th className="table-heading-text text-center">Position</th>
              <th className="table-heading-text text-center">Total Days</th>
              <th className="table-heading-text text-center">TDS</th>
              <th className="table-heading-text text-center">Advance</th>
              <th className="table-heading-text text-center">Arrear</th>
              <th className="table-heading-text text-center">InHand Salary</th>
              <th className="table-heading-text text-center">Gross Salary</th>
              <th className="table-heading-text text-center">Basic Salary</th>
              <th className="table-heading-text text-center">HRA</th>
              <th className="table-heading-text text-center">Transport</th>
              <th className="table-heading-text text-center">Special Allowance</th>
              <th className="table-heading-text text-center">ESI</th>
              <th className="table-heading-text text-center">PF</th>
              <th className="table-heading-text text-center">Employer ESI</th>
              <th className="table-heading-text text-center">Employer PF</th>
              <th className="table-heading-text text-center">Gratuity</th>
              <th className="table-heading-text text-center">Statutory Bonus</th>
              <th className="table-heading-text text-center">CTC Monthly</th>
              <th className="table-heading-text text-center">CTC Yearly</th>
              <th className="table-heading-text text-center">Account No.</th>
              <th className="table-heading-text text-center">IFSC Code</th>
              <th className="table-heading-text text-center">Bank Name</th>
              <th className="table-heading-text text-center">View</th>
              {/* <th className="text-center">Action</th> */}
            </tr>
          </thead>

          {buffer ? <div className="spinner"></div> : //buffering logic

            <tbody>
              {monthlylistfilter
                // .filter((e) =>
                //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
                // )
                // .sort((a, b) => Date(a.date) - Date(b.date))
                .map((i, index) => {
                  return (
                    <React.Fragment key={index}>

                      <tr className="custom-table-head-tdd">
                        {/* <td className="align-left">{index + 1}</td> */}
                        <td className="table-body">{i.emp_code}</td>
                        <td className="table-body text-center">{i.user_details.name}</td>
                        <td className="table-body text-center">
                          {i.user_details.department_name}
                        </td>
                        <td className="table-body text-center">
                          {i.user_details.designation_name}
                        </td>
                        <td className="table-body text-center" title="Total Working Days">{i.calculation}</td>
                        <td className="table-body text-center" title="TDS">{i.tds}</td>
                        <td className="table-body text-center" title="Advance">{i.advance}</td>
                        <td className="table-body text-center" title="Arreal">{i.arial}</td>
                        <td className="table-body text-center" title="Inhand Salary">{i.salarypay}</td>
                        <td className="table-body text-center" title="Gross">{i.totalearnings}</td>
                        <td className="table-body text-center" title="Basic">{i.basicsalary}</td>
                        <td className="table-body text-center" title="HRA">{i.hra}</td>
                        <td className="table-body text-center" title="Transport">{i.transport}</td>
                        <td className="table-body text-center" title="Special Allowance">{i.specialallowance}</td>
                        <td className="table-body text-center" title="ESI">{i.esideduction}</td>
                        <td className="table-body text-center" title="PF">{i.pfdeduction}</td>
                        <td className="table-body text-center" title="EESI">{i.esi}</td>
                        <td className="table-body text-center" title="EPF">{i.epf}</td>
                        <td className="table-body text-center" title="Gratuity">{i.gratuity}</td>
                        <td className="table-body text-center" title="bonus">{i.bonus}</td>
                        <td className="table-body text-center" title="CTC Monthly">{i.ctcofmonth}</td>
                        <td className="table-body text-center" title="CTC Yearly">{i.ctcofyear}</td>
                        <td className="table-body text-center" title="Account Number">{i.bank_details?.account_number}</td>
                        <td className="table-body text-center" title="IFSC Code">{i.bank_details?.ifsc_code}</td>
                        <td className="table-body text-center" title="Bank Name">{i.bank_details?.bank_name}</td>
                        <td className="table-body text-center">


                          {/* <a
                            href={`${FRONTEND_URL}/salarySlip/${yearr}/${monthh}/${i.emp_code}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="View using href"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                              <path
                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                fill="#2576BC"
                              />
                            </svg>
                          </a> */}

                          {/* <button className="allproject-dashboard" title="View using Navigate" onClick={(e) => salarySlip(i.emp_code, monthh, yearr)}> */}
                          <button
                            className="allproject-dashboard"
                            title="View using Navigate"
                            onClick={(e) => OneSalarySlip(i, monthh, yearr)}
                          >
                            <Eye />
                          </button>
                        </td>
                        {/* <td className="text-center">
                                                Action
                                            </td> */}
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

const MonthlySalary = () => {
  const [designationname, setDesignationname] = useState([]);

  const [buffer, setBuffering] = useState(false); //buffering logic

  const getDesignation = async () => {
    // setBuffering(true); //buffering logic
    try {
      const des = await axios.get(`${BASE_URL}/siteproject/designation/`);


      setDesignationname(des.data);

    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [yearr, setYearr] = useState(`${year}`);
  const [monthlylist, setMonthlylist] = useState([]);
  const [monthlylistfilter, setMonthlylistfilter] = useState([]);

  const [toggleState, setToggleState] = useState("pending/");
  // const [searchQuery, setSearchQuery] = useState("");

  const getSalarylist = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/monthlyformat/${yearr}/${monthh}`
      );
      setMonthlylist(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };
  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );

  const applyFilters = () => {
    let filteredData = monthlylist;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.salary.employee.name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return nameMatch;
      });
    }

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.salary.employee.department_name === selectedDep
      );
    }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();
      console.log("filteredData");
      console.log(filteredData);
      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.salary.employee.designation_name
          .toLowerCase()
          .includes(lowercaseSelectedName);

        // const desiMatch = employee.position_name.toLowerCase().includes(lowercaseSelectedName);
        return desiMatch;
      });
    }
    if (selectedDivision) {
      filteredData = filteredData.filter(
        (employee) =>
          employee.salary.employee.division_name === selectedDivision
      );
    }

    setMonthlylistfilter(filteredData);
  };

  useEffect(() => {
    getSubCompany();
    getSalarylist();
  }, [monthh, yearr]);

  useEffect(() => {
    applyFilters();
  }, [selectedName, selectedDep, selectedDesi, monthlylist, selectedDivision]);

  // ?  ************** Put Request Start **************  ! //

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/salarySlip`;
  const salarySlip = (emp_code, month, year) => {
    navigate(baseUrl + `/${year}` + `/${month}` + `/${emp_code}/`);
  };

  const OneSalarySlip = (i, monthh, yearr) => {
    navigate(baseUrl, { state: { i, monthh, yearr } });
  };

  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>


        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw   date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Designation"
                type="text"
                value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Monthly Salary List
            {/* <p className='career-para font-size-subheading">Based your performance</p>          */}
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text text-lefttt">S.No.</th>
              <th className="table-heading-text text-center">Emp Code</th>
              <th className="table-heading-text text-center">Name</th>
              <th className="table-heading-text text-center">Department</th>
              <th className="table-heading-text text-center">Position</th>
              <th className="table-heading-text text-center">Total Days</th>
              <th className="table-heading-text text-end">Total Salary</th>
              {/* <th className="text-center">View</th> */}
              {/* <th className="text-center">Action</th> */}
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic

            <tbody>
              {monthlylistfilter
                // .filter((e) =>
                //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
                // )
                // .sort((a, b) => Date(a.date) - Date(b.date))
                .map((i, index) => {
                  return (
                    <React.Fragment key={index}>

                      <tr className="custom-table-head-td">
                        <td className="table-body text-leftt">{index + 1}</td>
                        <td className="table-body text-center">
                          {i.salary.bank_details?.user}
                        </td>
                        <td className="table-body text-center">{i.salary.name}</td>
                        <td className="table-body text-center">
                          {i.salary.employee.department_name}
                        </td>
                        <td className="table-body text-center">
                          {i.salary.employee.designation_name}
                        </td>
                        <td className="table-body text-center">{i.calculation}</td>
                        <td className="table-body text-end">{i.salary.salarypay}</td>
                        <td className="table-body text-center">
                          {/* <a
                                                    href={`${FRONTEND_URL}/salarySlip/${yearr}/${monthh}/${i.user_detail}/`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="View using href"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />
                                                    </svg>
                                                </a> */}

                          {/* <button className="allproject-dashboard" title="View using Navigate" onClick={(e) => OneSalarySlip(i, monthh, yearr)}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none">

                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />

                                                    </svg>
                                                </button> */}
                        </td>
                        {/* <td className="text-center">
                                                Action
                                            </td> */}
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

const EmployeeDetails = () => {
  const [designationname, setDesignationname] = useState([]);


  const [selectedRemark, setRemark] = useState("");
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [selectedDivision, setDivision] = useState('null');

  const [emp_codes, setEmp_codes] = useState([]);
  const getDesignation = async () => {
    // setBuffering(true); //buffering logic
    try {
      const des = await axios.get(`${BASE_URL}/siteproject/designation/`);

      const emp = await axios.get(`${BASE_URL}/siteproject/employeefilterbysubcompany/${selectedDivision}/`);

      setDesignationname(des.data);


      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: '', numericPart: 0 };
        }
      };

      const sortedList = emp.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } = getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } = getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setEmp_codes(sortedList);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, [selectedDivision]);

  const [employeeDetailed, setemployeeDetailed] = useState([]);
  const [employeeDetailedfilter, setemployeeDetailedfilter] = useState([]);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const month1 = today.getMonth() - 6;
  const month2 = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  const [monthStart, setStartMonth] = useState(month1 > 0 ? `${month1}` : `1`);
  const [monthEnd, setEndMonth] = useState(`${month2}`);
  const [yearr, setYearr] = useState(`${year}`);
  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [monthlylist, setMonthlylist] = useState([]);
  const [monthlylistfilter, setMonthlylistfilter] = useState([]);

  const [selectedEmpcode, setSelectedEmpcode] = useState(
    // `${sessionStorage.getItem("emp_code")}`
    `null`
  );

  const [toggleState, setToggleState] = useState("pending/");
  // const [searchQuery, setSearchQuery] = useState("");
  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };
  const getSalarylist = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/employeewiseformat/${selectedEmpcode}/`
      );
      setemployeeDetailed(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };



  const applyFilters = () => {
    let filteredData = employeeDetailed;

    if (yearr) {
      const selectedYear = yearr.toString();

      filteredData = filteredData.filter((employee) => {
        return employee.year === selectedYear;
      });
    }

    if (monthStart && monthEnd) {
      const startMonth = monthStart;
      const endMonth = monthEnd;

      filteredData = filteredData.filter((employee) => {
        const empMonth = Number(employee.month);
        return empMonth >= startMonth && empMonth <= endMonth;
      });
    }

    setemployeeDetailedfilter(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [monthStart, monthEnd, yearr, employeeDetailed]);

  useEffect(() => {
    getSubCompany();
    getSalarylist();
  }, [selectedEmpcode]);


  // ?  ************** Navigate Function **************  ! //

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/salarySlipEmployee`;

  const OneSalarySlip = (i) => {
    navigate(baseUrl, { state: { i } });
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div title="Year Filter" className="field-cont-div">

              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw   date-field"
              />

              <hr className="field-cont-hr" />
            </div>

            <div title="Month Start Filter" className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                value={monthStart}
                max={monthEnd}
                onChange={(e) => setStartMonth(e.target.value)}
              >
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div title="Month End Filter" className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                min={monthStart}
                value={monthEnd}
                onChange={(e) => setEndMonth(e.target.value)}
              >
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div title="Employee Filter" className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={selectedEmpcode}
                onChange={(e) => setSelectedEmpcode(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {emp_codes
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
            </div>

          </div>
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Employee Details</div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text text-lefttt">Sr. No.</th>
              <th className="table-heading-text text-center">Month</th>
              <th className="table-heading-text text-center">Year</th>
              <th className="table-heading-text text-center">Total Pay days</th>
              <th className="table-heading-text text-center">Net Pay</th>
              <th className="table-heading-text text-end ">Salary Receipt</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {employeeDetailedfilter
                // .filter((e) =>
                //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
                // )
                // .sort((a, b) => Date(a.date) - Date(b.date))
                .map((item, index) => {
                  return (
                    <React.Fragment key={index}>

                      <tr className="custom-table-head-td">
                        <td className="table-body text-lefttt">{index + 1}</td>
                        <td className="table-body text-center">{item.month}</td>
                        <td className="table-body text-center">{item.year}</td>
                        <td className="table-body text-center">{item.calculation}</td>
                        <td className="table-body text-center">{item.salary.salarypay}</td>
                        {/* <td className="text-center">{item.standard_status.stdbasicsalary}</td> */}
                        {/* <td className="text-center">{item.salary.esideduction + item.salary.pfdeduction}</td> */}
                        {/* <td className="text-center">{item.month}</td> */}
                        {/* <td className="text-center">{item.month}</td> */}
                        <td className="table-body text-end">
                          <button
                            className="allproject-dashboard"
                            title="View using Navigate"
                            onClick={(e) => OneSalarySlip(item)}
                          >
                            <Eye />
                          </button>
                          {/* <img src="" alt="Salary Receipt" /> */}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

const BankFormat = () => {
  const [designationname, setDesignationname] = useState([]);


  const [selectedRemark, setRemark] = useState("");
  const [buffer, setBuffering] = useState(false); //buffering logic

  const getDesignation = async () => {
    // setBuffering(true); //buffering logic
    try {
      const des = await axios.get(`${BASE_URL}/siteproject/designation/`);


      setDesignationname(des.data);

    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // console.log(newdate)

  const [monthh, setMonthh] = useState(`${month}`);
  const monthName = monthNames[monthh - 1];

  const [yearr, setYearr] = useState(`${year}`);

  const [bankFormat, setbankFormat] = useState([]);
  const [bankFormatfilter, setbankFormatfilter] = useState([]);

  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [selectedFromexp, setFromexp] = useState("");
  const [selectedToexp, setToexp] = useState("");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );

  // const [searchQuery, setSearchQuery] = useState("");

  const getSalarylist = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/bankformat/${yearr}/${monthh}/`
      );
      setbankFormat(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  const applyFilters = () => {
    let filteredData = bankFormat;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        return nameMatch;
      });
    }

    if (selectedDep) {
      filteredData = filteredData.filter(
        (employee) => employee.department_name === selectedDep
      );
    }

    // if (selectedFromexp) {
    //     const fromExpNumber = parseInt(selectedFromexp);
    //     filteredData = filteredData.filter(employee => parseInt(employee.yearofExp) >= fromExpNumber);
    // }

    // if (selectedToexp) {
    //     const toExpNumber = parseInt(selectedToexp);
    //     filteredData = filteredData.filter(employee => parseInt(employee.yearofExp) <= toExpNumber);
    // }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const desiMatch = employee.position_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        return desiMatch;
      });
    }
    // if (selectedRemark) {
    //     const lowercaseSelectedRemark = selectedRemark.toLowerCase();

    //     filteredData = filteredData.filter(employee => {
    //         const matchRemark = employee.remarks?.toLowerCase().includes(lowercaseSelectedRemark);

    //         return matchRemark;
    //     });
    // }

    if (selectedDivision) {
      filteredData = filteredData.filter(
        (employee) => employee.employee.division_name === selectedDivision
      );
    }

    setbankFormatfilter(filteredData);
  };

  useEffect(() => {
    getSubCompany();
    getSalarylist();
  }, [monthh, yearr]);

  useEffect(() => {
    applyFilters();
  }, [
    bankFormat,
    bankFormatfilter,
    selectedName,
    selectedDep,
    selectedDesi,
    selectedFromexp,
    selectedToexp,
    selectedRemark,
    selectedDivision,
  ]);

  // ?  ************** Put Request Start **************  ! //
  const tableRef = useRef(null);
  const handleDownloadBankSheet = () => {
    downloadAsExcel(tableRef, "users", selectedDivision === "" ? `BankFormatSheet-${monthh}-${yearr}` : `${selectedDivision}-BankFormatSheet-${monthh}-${yearr}`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-5vw   date-field"
              />
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Calender />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-15vw  "
                placeholder="Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

          </div>
          {/* <DownloadTableExcel
            filename={
              selectedDivision === ""
                ? `BankFormatSheet-${monthh}-${yearr}`
                : `${selectedDivision}-BankFormatSheet-${monthh}-${yearr}`
            }
            // filename={`BankFormatSheet-${monthh}-${yearr}`}
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className=" model-button-print">
              <DownloadIcon />
            </button>
          </DownloadTableExcel> */}
          <button className=" model-button-print" onClick={handleDownloadBankSheet}>
            <DownloadIcon />
          </button>
        </div>
      </div>
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">
            Bank Format
            {/* <p className='career-para font-size-subheading">Based your performance</p>          */}
          </div>
        </div>
        <table ref={tableRef} className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text text-lefttt">S.No.</th>
              <th className="table-heading-text text-center">Emp Code</th>
              <th className="table-heading-text text-center">Name</th>
              <th className="table-heading-text text-center">Bank</th>
              <th className="table-heading-text text-center">Account No.</th>
              <th className="table-heading-text text-center">IFSC</th>
              <th className="table-heading-text text-end ">Basic Salary</th>
              {/* <th className="text-center">View</th> */}
              {/* <th className="text-center">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {bankFormatfilter
              // .filter((e) =>
              //   e.name.toLowerCase().includes(searchQuery.toLowerCase())
              // )
              // .sort((a, b) => Date(a.date) - Date(b.date))
              .map((i, index) => {
                return (
                  <React.Fragment key={index}>

                    <tr className="custom-table-head-tdd">
                      <td className="table-body text-lefttt">{index + 1}</td>
                      <td className="table-body text-center">{i.bank_details?.user}</td>
                      <td className="table-body text-center">{i.name}</td>
                      <td className="table-body text-center">
                        {i.bank_details?.bank_name}-
                        {i.bank_details?.branch_address}
                      </td>
                      <td className="table-body text-center">
                        {i.bank_details?.account_number}
                      </td>
                      <td className="table-body text-center">
                        {i.bank_details?.ifsc_code}
                      </td>
                      <td className="table-body text-end">{i.salarypay}</td>
                      {/* Check */}
                      {/* <td className="text-center">
                        {formatDate(i.applied_date)}
                      </td>

                      <td className="text-center">
                        <a
                          href={`${FRONTEND_URL}/salarySlip/${i.user_detail}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="View "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 30 30"
                            fill="none"
                          >
                            <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                            <path
                              d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                              fill="#2576BC"
                            />
                          </svg>
                        </a>
                      </td> */}
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const SalaryStructureHeadingTable = () => {
  const [salaryStructureHeadings, setSalaryStructureHeadings] = useState([]);
  const [toggleCompany, setToggleCompany] = useState('null');
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSalaryStructureHeadings = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/salaryHeadingbysubcompany/${toggleCompany}/`);
      setSalaryStructureHeadings(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getSalaryStructureHeadings();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background scroll-container-table">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Salary Structure Headings</div>
        <div className="field-cont">
          <div className="table-searchh">
            <div>
              <SearchSmall />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <SalaryStructureHeadingAdd salaryStructureHeadings={salaryStructureHeadings} getSalaryStructureHeadings={getSalaryStructureHeadings} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="table-heading-text">Sr. No.</th>
            <th className="table-heading-text text-center">Position No.</th>
            <th className="table-heading-text text-center">Name</th>
            <th className="table-heading-text text-center">Type</th>
            <th className="table-heading-text text-center">Company</th>
            <th className="table-heading-text text-end width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {salaryStructureHeadings?.filter((e) =>
              e.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
              .sort((a, b) =>
                a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>

                  <tr className="custom-table-head-td">
                    <td className="table-body">{index + 1}</td>
                    <td className="table-body text-center">{i.sorting_var}</td>
                    <td className="table-body text-center">{i.title}</td>
                    <td className="table-body text-center form-text-trasformation-uppercase">{i.salary_type}</td>
                    <td className="table-body text-end">{i.sub_company_title}</td>

                    <td className="table-body text-end width-5vw ">
                      <SalaryStructureHeadingUpdate
                        i={i}
                        salaryStructureHeadings={salaryStructureHeadings}
                        getSalaryStructureHeadings={getSalaryStructureHeadings}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const SalaryDeductionPolicyTable = () => {
  const [salaryDeductionPolicies, setSalaryDeductionPolicy] = useState([]);
  const [toggleCompany, setToggleCompany] = useState('null');
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSalaryDeductionPolicyList = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/salarypolicybysubcompany/${toggleCompany}/`);
      setSalaryDeductionPolicy(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getSalaryDeductionPolicyList();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Salary Deduction Policies</div>
        <div className="field-cont">
          <div className="table-searchh">
            <div>
              <SearchSmall />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <SalaryDeductionPolicyAdd getSalaryDeductionPolicyList={getSalaryDeductionPolicyList} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="table-heading-text">Sr. No.</th>
            <th className="table-heading-text text-center">Company</th>
            <th className="table-heading-text text-center">Name</th>
            {/* <th className="text-center">Employee Count</th> */}
            {/* <th className="text-center">Employee View</th> */}
            <th className="table-heading-text text-end width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {salaryDeductionPolicies?.filter((e) =>
              e.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
              .sort((a, b) =>
                a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
              )
              .map((i, index) => (
                <React.Fragment key={index}>

                  <tr className="custom-table-head-td">
                    <td className="table-body">{index + 1}</td>
                    <td className="table-body text-center">{i.sub_company_title}</td>
                    <td className="table-body text-center">{i.name}</td>
                    {/* <td className="text-center">{i?.employee_count}</td> */}
                    {/* <td className="text-center">
                      <EmployeebySalaryCalculationPolicyTable policy={i} />
                    </td> */}
                    <td className="table-body text-end width-5vw ">
                      <SalaryDeductionPolicyUpdate
                        i={i}
                        getSalaryDeductionPolicyList={getSalaryDeductionPolicyList}
                      />
                      <SalaryDeductionPolicyDelete
                        i={i}
                        getSalaryDeductionPolicyList={getSalaryDeductionPolicyList}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export { PayRun, MonthlySalary, EmployeeDetails, BankFormat, SalaryStructureHeadingTable, SalaryDeductionPolicyTable };
