import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Modal, ToastContainer } from 'react-bootstrap';
import { BASE_URL } from '../../../config/axios';
import { handleErrorToast, sortProjects } from '../../CustomFunctions';
import { toast } from 'react-toastify';
import { formattedDateLong } from '../../Date';
import Dashboardnavbar from '../../../layout/Dashboardnavbar';
import AdminInventoryitemlist from '../AdminSiteManagement/AdminInventoryitemlist';
import usePermission from '../../../config/permissions';
import { SearchSmall, SearchBig, UpdatePencil, AddwithWhiteCircle, DropdownArrow } from '../../AllSvg';
import { Projects } from '../../FilterDropdown';
import { UploadInventory, UploadItemsBulk } from '../BulkModals';

// !  **************  Inventory Components Start **************  ! //

const AddSiteInventory = ({ getInventory, p_id }) => {
    const [show, setShow] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const { projectID } = usePermission();
    const role = sessionStorage.getItem('sitemanagement_role');

    const handleClose = () => {
        setShow(false);
        setErrors({});
        setFormData({
            item_id: "",
            emp_code: "",
            project: projectID ? projectID : p_id ? p_id : "null",
            description: "",
            quantity: "",
            rate_per_item: "",
            purchase_date: null,
            // remaning_quantity: "",
            // distributed_quantity: "",
            // sub_company: sessionStorage.getItem('company_id')
        })
    }

    const handleShow = () => {
        getProjects();
        getEmployee();
        getItem();
        setShow(true)
    };

    const [formData, setFormData] = useState({
        item_id: "",
        emp_code: "",
        project: projectID ? projectID : p_id ? p_id : "null",
        description: "",
        quantity: "",
        rate_per_item: "",
        purchase_date: null,
        // remaning_quantity: "",
        // distributed_quantity: "",
        // sub_company: sessionStorage.getItem('company_id')
    });
    const getProjects = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
            const pro = await axios.get(
                sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
                    `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                    : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
                        `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                        : `${BASE_URL}/siteproject/project/`
            );
            const sortedProjects = sortProjects(pro.data, 'site_prcode');
            setProjectList(sortedProjects);

        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };



    const [itemlist, setItemList] = useState([]);

    const getItem = async () => {

        setBuffering(true); //buffering logic // Start Buffering
        try {
            const pro = await axios.get(`${BASE_URL}/siteproject/siteinventoryitem/`);
            setItemList(pro.data);

        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }

    }




    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);


    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "item_id",
            "emp_code",
            "description",
            "quantity",
            "rate_per_item",
            "project",
            // "remaning_quantity",
            // "distributed_quantity",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field] || formData[field] === "null") {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: value,
            };

            if (name === "emp_code") {
                const selectedprojectList = projectList.find(emp => emp.emp_code === value);
                if (selectedprojectList) {
                    updatedFormData.name = selectedprojectList.name;
                } else {
                    updatedFormData.name = "";
                }
            }

            return updatedFormData;
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value.trim()) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };

    const [employeeList, setEmployeeList] = useState([]);
    const [siteEmployeeList, setSiteEmployeeList] = useState([]);

    const getEmployee = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const pro = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${formData.project}/null/`);
            const site = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${formData.project}/null/`);
            setEmployeeList(pro.data);
            setSiteEmployeeList(site.data);

        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };
    useEffect(() => {
        getEmployee(formData.project);
    }, [formData.project]);


    const [buffer, setBuffering] = useState(true); //buffering logic



    //******************Valedation End*********************** */

    const [loading, setLoading] = useState(false);

    const handleFormSubmit = async (e) => {

        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                let res = await axios.post(`${BASE_URL}/siteproject/siteinventory/`, formData);

                if (res.status === 200) {
                    await getInventory();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button title="Site Inventory" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Site Inventory
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
                    <Modal.Title> Add Site Inventory</Modal.Title>
                </Modal.Header>
                {/* <Modal.Header closeButton>
                <Modal.Title>Request Leave</Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h1 className="task-Tab-heading text-center  font-weight500    font-size-heading"><Modal.Title>Add Inventory Item</Modal.Title> </h1> */}
                            {role === 'SiteManagement' ?
                                "" : <>
                                    {p_id ?
                                        <></>
                                        :
                                        <div title="Project" className="flex-column form-group-selectt">
                                            <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                                                Project<span className="required">*</span>
                                            </label>
                                            <br />
                                            <select
                                                id="project"
                                                name="project"
                                                placeholder="Project"
                                                value={formData.project}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                                            >
                                                <option value="null">Select a Project</option>
                                                {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                                    <option key={index} value={i.id}>{i.site_prcode}{i.project_short_name}</option>
                                                ))}
                                            </select>
                                            <DropdownArrow />
                                            {console.log(errors, 'errors')}
                                            {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                                        </div>
                                    }
                                </>}

                            <div title="List of Items" className="flex-column form-group-selectt">
                                <label htmlFor="item_id" className="form-labels  font-weight500    font-size-subheading">
                                    List of Items<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="item_id"
                                    title="List of Items"
                                    name="item_id"
                                    placeholder="item_id"
                                    value={formData.item_id}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.item_id ? "error" : inputState.item_id ? "success" : ""}`}
                                >
                                    <option value="">Select Items</option>
                                    {itemlist?.sort((a, b) => a.item_name.localeCompare(b.item_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.item_name}</option>
                                    ))}
                                </select>
                                <DropdownArrow />
                                {errors.item_id && <span className="error-message font-size-text ">{errors.item_id}</span>}
                            </div>

                            <div title="Employees List" className="flex-column form-group-selectt">
                                <label htmlFor="emp_code" className="form-labels  font-weight500    font-size-subheading">
                                    {role === "SiteManagement" ? "Employee" : "Employee(Add Project First)"}<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="emp_code"
                                    name="emp_code"
                                    placeholder="emp_code"
                                    value={formData.emp_code}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.emp_code ? "error" : inputState.emp_code ? "success" : ""}`}
                                >
                                    <option value="" title="Select an Employee">Select an Employee</option>
                                    {(role === "SiteManagement" ? siteEmployeeList : employeeList)?.sort((a, b) => a.emp_code.localeCompare(b.emp_code, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.emp_code}>{i.emp_code}-{i.name}</option>
                                    ))}
                                </select>
                                <DropdownArrow />
                                {errors.emp_code && <span className="error-message font-size-text ">{errors.emp_code}</span>}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="description" className="form-labels  font-weight500    font-size-subheading" title='Description'>
                                    Description<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="description"
                                    name="description"
                                    placeholder="Name of the description"
                                    title='Description'
                                    onChange={handleInputChange}
                                    value={formData.description}
                                    className={`form-input ${errors.description ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.description && (
                                    <span className="error-message font-size-text ">{errors.description}</span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading" title="Quantity">
                                    Quantity<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    id="quantity"
                                    name="quantity"
                                    title="Quantity"
                                    placeholder="Quantity"
                                    onChange={handleInputChange}
                                    value={formData.quantity}
                                    className={`form-input ${errors.quantity ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.quantity && (
                                    <span className="error-message font-size-text ">{errors.quantity}</span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="rate_per_item" className="form-labels  font-weight500    font-size-subheading" title='Rate per Item'>
                                    Rate per Item<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    id="rate_per_item"
                                    name="rate_per_item"
                                    title="Rate per Item"
                                    placeholder=" Rate per Item"
                                    onChange={handleInputChange}
                                    value={formData.rate_per_item}
                                    className={`form-input ${errors.rate_per_item ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.rate_per_item && (
                                    <span className="error-message font-size-text ">{errors.rate_per_item}</span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="purchase_date" className="form-labels  font-weight500    font-size-subheading" title="Purchase Date">
                                    Purchase date
                                    {/* <span className="required">*</span> */}
                                </label>
                                <input
                                    type="date"
                                    id="purchase_date"
                                    title="Purchase Date"
                                    name="purchase_date"
                                    placeholder="purchase_date"
                                    onChange={handleInputChange}
                                    value={formData.purchase_date}
                                    className={`form-input ${errors.purchase_date ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.purchase_date && (
                                    <span className="error-message font-size-text ">{errors.purchase_date}</span>
                                )}
                            </div>
                            <div className="button-models">
                                <button className="model-button-cancel model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleFormSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                        <p className="error-message font-size-text">
                            {isSubmitted && Object.keys(errors).length > 0 && (
                                Object.keys(errors).length > 5 ? (
                                    <h5 className="text-center">Please fill all mandatory fields!</h5>
                                ) : (

                                    <h6 className="text-center">
                                        {Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'}
                                    </h6>
                                )
                            )}
                        </p>
                    </div>
                    {/* <ToastContainer position='top-center' autoClose={3000} hideProgressBar /> */}
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateSiteInventory = ({ i, getInventory }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const handleShow = () => {
        // getProjects();
        // getEmployee();
        getItem();
        setShow(true)
    };


    const [itemlist, setItemList] = useState([]);
    const getItem = async () => {

        try {
            const pro = await axios.get(`${BASE_URL}/siteproject/siteinventoryitem/`);
            setItemList(pro.data);

        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {

        }

    }
    const [formData, setFormData] = useState({
        item_name: "",
        // emp_code: "",
        // project_short_name:"",
        description: "",
        quantity: "",
        rate_per_item: "",
        purchase_date: "",
        // remaning_quantity: "",
        // distributed_quantity: "",
    });

    useEffect(() => {
        setFormData(i);
    }, [i]);

    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "item_name",
            // "emp_code",
            // "project",
            "description",
            "quantity",
            "rate_per_item",
            "purchase_date",
            // "remaning_quantity",
            // "distributed_quantity",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);

        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: value,
            };

            // if (name === "emp_code") {
            //   const selectedEmployee = employeeList.find(emp => emp.emp_code === value);
            //   if (selectedEmployee) {
            //     updatedFormData.name = selectedEmployee.name;
            //   } else {
            //     updatedFormData.name = "";
            //   }
            // }

            return updatedFormData;
        });
    };

    //******************Valedation End*********************** */

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.put(`${BASE_URL}/siteproject/siteinventory/${i.id}/`, formData);

                if (res.status === 200) {
                    await getInventory();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button className="model-edit-button" onClick={handleShow}>
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Update Inventory Item Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className="parent-div">
                        <div className="bdy-div">


                            <div>
                                <h5>Employee :{i.emp_code}</h5>
                                <br />
                                <h5>Project Name : {i.project_short_name}</h5>
                            </div>
                            <div className="flex-column form-group-selectt" title="List of Item">
                                <label htmlFor="item_name" className="form-labels  font-weight500    font-size-subheading">
                                    List of Item<span className="required">*</span>
                                </label>
                                <br />
                                {/* <inputt
                    type="text"
                    id="item_name"
                    name="item_name"
                    placeholder="Name of the item_name"
                    onChange={handleInputChange}
                    value={formData.item_name}
                    className={`form-input ${errors.item_name ? "error" : inputState.tile ? "success" : ""
                      }`}
                  /> */}
                                <select
                                    id="item_id"
                                    name="item_id"
                                    placeholder="item_id"
                                    value={formData.item_id}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.item_id ? "error" : inputState.item_id ? "success" : ""}`}
                                >
                                    <option value="">Select Items</option>
                                    {itemlist?.sort((a, b) => a.item_name.localeCompare(b.item_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.item_name}</option>
                                    ))}
                                </select>
                                <DropdownArrow />

                                {errors.item_id && (
                                    <span className="error-message font-size-text ">{errors.item_id}</span>
                                )}
                            </div>

                            <div className="flex-column" title="Description">
                                <label htmlFor="description" className="form-labels  font-weight500    font-size-subheading">
                                    Description<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="description"
                                    name="description"
                                    placeholder="Name of the description"
                                    onChange={handleInputChange}
                                    value={formData.description}
                                    className={`form-input ${errors.description ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.description && (
                                    <span className="error-message font-size-text ">{errors.description}</span>
                                )}
                            </div>

                            <div className="flex-column" title="Quantity">
                                <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                                    Quantity<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    id="quantity"
                                    name="quantity"
                                    placeholder="Name of the quantity"
                                    onChange={handleInputChange}
                                    value={formData.quantity}
                                    className={`form-input ${errors.quantity ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.quantity && (
                                    <span className="error-message font-size-text ">{errors.quantity}</span>
                                )}
                            </div>

                            <div className="flex-column" title="Rate Per Item">
                                <label htmlFor="rate_per_item" className="form-labels  font-weight500    font-size-subheading">
                                    Rate Per Item<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    id="rate_per_item"
                                    name="rate_per_item"
                                    placeholder="Name of the rate_per_item"
                                    onChange={handleInputChange}
                                    value={formData.rate_per_item}
                                    className={`form-input ${errors.rate_per_item ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.rate_per_item && (
                                    <span className="error-message font-size-text ">{errors.rate_per_item}</span>
                                )}
                            </div>

                            <div className="flex-column" title="Purchase Date">
                                <label htmlFor="purchase_date" className="form-labels  font-weight500    font-size-subheading">
                                    Purchase date
                                    {/* <span className="required">*</span> */}
                                </label>
                                <input
                                    type="date"
                                    id="purchase_date"
                                    name="purchase_date"
                                    placeholder="purchase_date"
                                    onChange={handleInputChange}
                                    value={formData.purchase_date}
                                    className={`form-input ${errors.purchase_date ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.purchase_date && (
                                    <span className="error-message font-size-text ">{errors.purchase_date}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button-cancel model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    //   type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleFormSubmit}
                                >
                                    Update
                                </button>
                            </div>
                            <p className="error-message font-size-text">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 5 ? (
                                        <h5 className="text-center">Please fill all mandatory fields!</h5>
                                    ) : (

                                        <h6 className="text-center">
                                            {Object.keys(errors).map((field) =>
                                                field.charAt(0).toUpperCase() + field.slice(1)
                                            ).join(', ') + ' are required!'}
                                        </h6>
                                    )
                                )}
                            </p>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};


const SiteInventoryDelete = ({ i, getInventory }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();

        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(`${BASE_URL}/siteproject/siteinventory/${i.id}/`);

            if (res.status === 200) {
                await getInventory();
                handleClose();
            } else {
                alert(res);
            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className="parent-div">
                        <h1 className="task-Tab-heading text-center  font-weight500    font-size-heading"><Modal.Title>Delete Inventory Item</Modal.Title> </h1>

                        Are You Sure You Want to delete
                        <br />Item: {i.item_name}
                        <br />given to : {i.emp_code}
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// !  **************  Inventory Components End **************  ! //

const AddItemList = ({ getItemsList }) => {
    const [show, setShow] = useState(false);
    const [subCompanyList, setSubCompanyList] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getSubCompany();
    }

    const [formData, setFormData] = useState({
        item_name: "",
        sub_company: "",
        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //
    const getSubCompany = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompanyList(res.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
        }
    };

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_name"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/siteinventoryitem/`,
                    formData,
                );

                if (res.status === 200) {
                    await getItemsList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value.trim()) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };

    return (
        <>
            <button title="Add Items" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Items
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
                    <Modal.Title> Add Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading"></h6> */}

                            <form>
                                <div title="Site Sub Company" className="flex-column form-group-selectt">
                                    <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                                        Site Sub Company
                                    </label>
                                    <br />
                                    <select
                                        id="sub_company"
                                        name="sub_company"
                                        value={formData.sub_company}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Site SubCompany</option>
                                        {subCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true }))?.map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.sub_company && (
                                            <span className="error-message font-size-text ">{errors.sub_company}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <label htmlFor="item_name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading" title="Add Item Name">
                                        Add Item Name<span className="required">*</span>
                                    </label>
                                    <input
                                        id="item_name"
                                        type="item_name"
                                        name="item_name"
                                        title="Add Item Name"
                                        maxLength={50}
                                        placeholder="Item Name"
                                        onChange={handleInputChange}
                                        value={formData.item_name}
                                        className={`form-input ${errors.item_name ? "error" : inputState.item_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.item_name && (
                                        <span className="error-message font-size-text ">{errors.item_name}</span>
                                    )}
                                </div>

                            </form>
                            <div className="button-models">
                                <button className="model-button-cancel model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateItemList = ({ i, getItemsList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i)
        setShow(true)
    };

    const [formData, setFormData] = useState({
        item_name: "",
    });


    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_name"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/siteinventoryitem/${i.id}/`,
                    formData,

                );

                if (res.status === 200) {
                    await getItemsList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);

            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Update Item" className="model-edit-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Update Item Name
                            </h6>

                            <form>
                                <div className="flex-column">

                                    <input
                                        id="item_name"
                                        type="item_name"
                                        name="item_name"
                                        placeholder="Item Name"
                                        onChange={handleInputChange}
                                        value={formData.item_name}
                                        className={`form-input ${errors.item_name ? "error" : inputState.item_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.item_name && (
                                        <span className="error-message font-size-text ">{errors.item_name}</span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button className="model-button-cancel model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteItemList = ({ i, getItemsList }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/siteinventoryitem/${i.id}/`
            );

            if (res.status === 200) {
                await getItemsList();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Item" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading text-center  font-weight500    font-size-heading"><Modal.Title>Delete Item</Modal.Title> </h1>

                        Are You Sure You Want to Delete Item "{i.item_name}"
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ************************Item Component Component Start*************************************/

const SiteInventory = ({ p_id, site_prcode, siteData, getSiteInventoryData }) => {
    const [siteInventory, setSiteInventory] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedSearch, setSearch] = useState("");

    const { projectID } = usePermission();
    const [selectedProject, setSelectedProject] = useState(projectID ? projectID : "null");
    const [projectList, setProjectList] = useState([]);
    const role = sessionStorage.getItem("sitemanagement_role");

    const [buffer, setBuffering] = useState(true); //buffering logic


    const getProjects = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
            const pro = await axios.get(
                sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
                    `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                    : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
                        `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                        : `${BASE_URL}/siteproject/project/`
            );
            const sortedProjects = sortProjects(pro.data, 'site_prcode');
            setProjectList(sortedProjects);


        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false);// End Buffering
        }
    };



    const getInventory = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/filterbyproj-site-inventory/${p_id ? p_id : selectedProject}/`);
            setSiteInventory(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);
        }
    };

    useEffect(() => {
        getProjects();
    }, [selectedProject, p_id]);

    useEffect(() => {
        getInventory();
    }, [selectedProject, p_id]);




    const applyFilters = () => {
        let filteredData = siteInventory;
        if (selectedSearch !== "") {
            const lowercaseSelectedName = selectedSearch.toLowerCase();
            filteredData = filteredData.filter(project => {
                const item_name = project.item_name.toString().toLowerCase().includes(lowercaseSelectedName);

                return item_name;
            });
        }
        if (selectedProject !== 'null') {
            filteredData = filteredData.filter(project => project.project === selectedProject);
        }

        setFilteredData(filteredData);
    };


    useEffect(() => {
        applyFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSearch, siteInventory]);



    return (
        <>



            <div className="attendance-heading  font-weight500    font-size-heading"> </div>
            {p_id ?
                <>
                    <div className="table-css-white-background table-border-heading">
                        <div>
                            <div className='flex-row justify-between'>
                                <div className="jd-heading-outer-flex ">
                                    <h2 className="jd-heading-main font-weight600 font-size-heading">Site Inventory</h2>
                                </div>
                                <div>
                                    <AddSiteInventory getInventory={getInventory} p_id={p_id} projectID={projectID} />

                                    {role === "Admin" &&
                                        <UploadInventory getInventory={getInventory} site_prcode={site_prcode} />
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="jd-heading-bottom-bold"></div>
                                <div className="jd-heading-bottom-light"></div>
                            </div>
                        </div>
                    </div>
                </> :
                <>
                    {role === "SiteManagement" ? ("") : (
                        <div className="attendance-subcont">
                            <div className="field-cont">
                                <div title='Search Filter' className="field-cont-div">
                                    <SearchBig />
                                    <input className="attendance-input-field width-20vw"
                                        placeholder='Search Item'
                                        type="text"
                                        value={selectedSearch}
                                        onChange={(e) => setSearch(e.target.value)} />

                                    <hr className="field-cont-hr" />
                                </div>

                                <Projects toggleProject={selectedProject} setToggleProject={setSelectedProject} />

                            </div>
                            <div>

                                <AddSiteInventory getInventory={getInventory} projectID={projectID} />
                                {role === "Admin" &&
                                    <UploadInventory getInventory={getInventory} />
                                }

                            </div>

                        </div>
                    )}
                </>
            }




            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="table-css-white-background table-border  table-box paddingTop-0px">

                <div className="scroll-container-table child-table-scroll">


                    <table className="table-css">
                        <thead className="table-heading">
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-lefttt">S.No.</th>
                                {p_id ? <></> : <th className="table-heading-text text-center">Project</th>}
                                <th className="table-heading-text">Item Name</th>
                                <th className="table-heading-text">Employee</th>
                                <th className="table-heading-text text-center">Description</th>
                                <th className="table-heading-text text-center">Quantity</th>
                                <th className="table-heading-text text-center">Rate per Item</th>
                                <th className="table-heading-text text-center">Purchase Date</th>
                                <th className="table-heading-text text-end width-10vw">Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {(role === "SiteManagement" ? siteData : filteredData)?.map((i, index) => (
                                    <React.Fragment key={index}>

                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}</td>
                                            {p_id ? <></> : <td className="table-body text-center">{i.project_short_name}</td>}

                                            <td className="table-body ">{i.item_name}</td>
                                            <td className="table-body">{i.emp_code}</td>
                                            <td className="table-body text-center">{i.description}</td>
                                            <td className="table-body text-center">{i.quantity}</td>
                                            <td className="table-body text-center">{i.rate_per_item}</td>
                                            <td className="table-body text-center">{i.purchase_date ? formattedDateLong(i.purchase_date) : "-"}</td>
                                            <td className="table-body text-end width-10vw ">
                                                <UpdateSiteInventory i={i} getInventory={role === "SiteManagement" ? getSiteInventoryData : getInventory} />
                                                {role === "Admin" || role === "AuthoritySupervisor" ? <SiteInventoryDelete i={i} getInventory={getInventory} /> : ""}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </>
    );
};

const ItemList = ({ item }) => {
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [empItemList, setEmpItemList] = useState([]);

    const getItemsList = async () => {
        setBuffering(true); //buf   fering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/siteinventoryitem/`
            );



            setEmpItemList(res.data);
        } catch (err) {
            console.log(err)
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getItemsList();
    }, []);

    const role = sessionStorage.getItem('role')
    return (
        <>

            <div className="table-css-white-background">
                <div className="table-heading-flex field-cont-between">
                    <div className="repo-heading font-weight500    font-size-heading">
                        Items List <br />
                    </div>
                    <div className="field-cont">
                        {/* {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
              <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
                <option value="null">Select Sub Company</option>
                {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

              </select>
            </div> : null
            } */}
                        <div className="table-searchh">
                            <div>
                                <SearchSmall />
                            </div>

                            <div>
                                <input
                                    placeholder="Search"
                                    className="dropdown font-size-subheading   navbar-inputt"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                        {role === "Admin" &&
                            <>
                                <AddItemList getItemsList={getItemsList} />
                                <UploadItemsBulk />
                            </>
                        }
                    </div>
                </div>
                <div className='table-box height-73vh scroll-container-table'>
                    <table className="table-css ">
                        <thead className='table-heading'>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text">S.No.</th>
                                <th className="table-heading-text text-center">Item Name</th>
                                {role === "Admin" ? <>
                                    <th className="table-heading-text text-center"> View </th>
                                </> : ""}
                                <th className="table-heading-text text-center width-10vw"> Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {empItemList
                                    ?.filter(
                                        (e) =>
                                            e.item_name
                                                .toLowerCase()
                                                .includes(searchQuery.toLowerCase()))
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <React.Fragment key={index}>

                                            <tr className="custom-table-head-td">
                                                <td className="table-body">{index + 1}</td>
                                                <td className="table-body text-center">{i.item_name}</td>
                                                {role === 'Admin' ?
                                                    <>
                                                        <td className="table-body text-center">
                                                            <AdminInventoryitemlist i={i} />
                                                        </td>
                                                    </> : ""
                                                }

                                                <td className="table-body text-center">
                                                    <>
                                                        <UpdateItemList i={i} getItemsList={getItemsList} />

                                                        <DeleteItemList i={i} getItemsList={getItemsList} />

                                                    </>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        }
                    </table>
                </div>
            </div>

            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </>
    );
};


const InventoryManagementComponent = () => {

    const [toggleState, setToggleState] = useState(1);
    const role = sessionStorage.getItem("sitemanagement_role");
    const projectID = sessionStorage.getItem("sitemanagement_project");
    const [sitedata, setSiteData] = useState([]);


    const getInventory = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/filterbyproj-site-inventory/${projectID}/`);
            setSiteData(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
        }
    };

    useEffect(() => {
        if (role === "SiteManagement") {
            getInventory();
        }
    }, [projectID]);

    return (
        <div>
            <Dashboardnavbar name={toggleState === 1 ? "Site Inventory" : "Item List"} url="Inventory Management" />
            <div className="content-tabs">
                <div className='toggle-button-area flex-row justify-between' style={{ padding: "15px 30px" }}>
                    <div>
                        <button
                            className={
                                toggleState === 1
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                            }
                            onClick={() => setToggleState(1)}
                        >
                            Site Inventory
                        </button>
                        <button
                            className={
                                toggleState === 2
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                            }
                            onClick={() => setToggleState(2)}
                        >
                            Item List
                        </button>
                    </div>
                    {role === "SiteManagement" ?
                        <div>
                            {toggleState === 1 ?
                                <AddSiteInventory projectID={projectID} getInventory={getInventory} /> :
                                <AddItemList />
                            }
                        </div>
                        : ""}
                </div>

            </div>
            <div className="content-tabs">
                {toggleState === 1 ? <SiteInventory siteData={sitedata} getSiteInventoryData={getInventory} /> : null}
                {toggleState === 2 ? <ItemList /> : null}
            </div>
        </div>
    )
}





export { InventoryManagementComponent, AddSiteInventory, SiteInventory }