import React, { useEffect, useState, useRef } from 'react';
import { BASE_URL } from '../../config/axios';
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { handleErrorToast, sortProjects } from '../CustomFunctions';
import { saveAs } from 'file-saver';
import Dashboardnavbar from '../../layout/Dashboardnavbar';

import "jspdf-autotable";

import axiosInstance from "../../config/axiosInstance";
import {
  AddwithWhiteCircle,
  DropdownArrow,
  DocumentIcon,
  DownloadIcon,
  Filter,
  Office,
  DeleteDustbin,
  Attachment,
} from "../AllSvg";

import {
  DocumentSection,
  RenderSelectField,

} from "../FormdataComponent";
import Select from "react-select";
import JSZip from 'jszip';
import { use } from 'react';


const AddCertificationAccess = ({ getcertification }) => {

  const [show, setShow] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const handleShow = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
    setFormData(initialFormData);
    setFileNames("")
    setErrors("");
  }

  function initialFormData() {
    return {
      company_name: "",
      service_type: "",
      project_short_name: "",
      project_full_name: "",
      structure_details: "",
      client: "",
      lane_type: "",
      project: "",
      total_length_km: "",
      total_cost_cr: "",
      is_jv: false,
      lead_partner: "",
      sectors: "",
      project_cost: "",
      jv_partner: "",
      associate_partner: "",
      our_share_percent: "",
      our_length_share_km: "",
      our_cost_share_cr: "",
      our_share_4_6_lane_length: "",
      our_share_2_lane_length: "",
      start_date: null,
      end_date: null,
      remarks: "",
      documents: [{ document_name: "", document_file: "" }],

    };
  }

  const [formData, setFormData] = useState(initialFormData());
  const [projectbyId, setProjectbyID] = useState("null");

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const requiredFields = [
      "start_date",
      "end_date",
      // "project",
      // "total_length_km",
      // "total_cost_cr",
    ];

    const newErrors = requiredFields.reduce((acc, field) => {
      if (
        !formData[field] ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
      return acc;
    }, {});

    // Validate documents array
    formData.documents.forEach((doc, index) => {
      if (!doc.document_name || doc.document_name.trim() === "") {
        newErrors[`document_name_${index}`] = `Document Name is required for document ${index + 1}`;
      }
      if (!doc.document_file) {
        newErrors[`document_file_${index}`] = `Document File is required for document ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };


  useEffect(() => {
    if (formData.project && projectbyId) {
      setFormData((prevData) => ({
        ...prevData,
        project_short_name: projectbyId.project_short_name,
        project_full_name: projectbyId.project_name,
      }));
    }
  }, [formData.project, projectbyId]);


  const handleInputChange = (e, index) => {
    const { name, value, type, checked, options } = e.target;

    let newValue;
    if (type === "select-multiple") {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else if (type === "checkbox") {
      newValue = checked;
    } else {
      newValue = value;
    }

    // Update input state with feedback
    setInputState((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
            ? "green"
            : ""
          : newValue
            ? "green"
            : "",
    }));

    // Update form data with dynamic calculations
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      // Set lane_type to "null" if sectors is not "Road & Highway"
      if (updatedFormData.sectors !== "Road & Highway") {
        updatedFormData.lane_type = "";
      }

      if (
        [
          // contractor
          "our_share_4_6_lane_length",
          "our_share_2_lane_length",
          "our_share_percent",
          "our_length_share_km",
          "our_cost_share_cr",
          "total_cost_cr",
          "total_length_km",
        ].includes(name)
      ) {
        // Contractor calculations
        const total_cost_cr = parseFloat(updatedFormData.total_cost_cr || 0).toFixed(2);
        const our_share_percent = parseFloat(updatedFormData.our_share_percent || 0).toFixed(2);
        const total_length_km = parseFloat(updatedFormData.total_length_km || 0).toFixed(2);




        // You can extend this logic to compute additional fields

        if (updatedFormData.lane_type === "2" && updatedFormData.total_cost_cr > 1) {
          updatedFormData.our_share_4_6_lane_length =
            (total_length_km * 0.4 * (our_share_percent / 100)).toFixed(2);
        }
        else {
          updatedFormData.our_share_4_6_lane_length = 0
        }
        if (updatedFormData.lane_type === "4" || updatedFormData.lane_type === "6") {
          updatedFormData.our_share_2_lane_length =
            (total_length_km * 1.5 * (our_share_percent / 100)).toFixed(2);
        }
        else {
          updatedFormData.our_share_2_lane_length = 0
        }
        updatedFormData.our_length_share_km =
          (total_length_km * (our_share_percent / 100)).toFixed(2);
        updatedFormData.our_cost_share_cr =
          (total_cost_cr * (our_share_percent / 100)).toFixed(2);
      }

      return updatedFormData;
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value.trim()) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });


  };


  const getProjectbyID = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/projectbyid/${formData.project}/`);
      setProjectbyID(res.data);


    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProjectbyID();
  }, [formData.project]);



  const handleDocInputChange = (index, e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      newDocuments[index] = {
        ...newDocuments[index],
        [name]: files ? files[0] : value, // Removed .trim()
      };

      if (files) {
        setFileNames((prevFileNames) => {
          const newFileNames = [...prevFileNames];
          newFileNames[index] = files[0]?.name || "";
          return newFileNames;
        });
      }

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (name === "document_name" && value.trim()) {
          delete updatedErrors[`document_name_${index}`];
        }
        if (name === "document_file" && files?.[0]) {
          delete updatedErrors[`document_file_${index}`];
        }
        return updatedErrors;
      });

      return { ...prevFormData, documents: newDocuments };
    });
  };

  const handleAddDocuments = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: [
        ...prevFormData.documents,
        { document_name: "", document_file: null },
      ],
    }));
  };

  const handleRemoveDocument = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: prevFormData.documents.filter((_, i) => i !== index),
    }));
    setFileNames((prevFileNames) =>
      prevFileNames.filter((_, i) => i !== index)
    );
  };

  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For upload progress
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [toggleCompany, setToggleCompany] = useState("null");



  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);
      // const pro = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${toggleCompany}/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/projectfilterbysubcompany/${formData.company_name ? formData.company_name : 'null'}/`
      );
      const sortedList = sortProjects(pro.data, "site_prcode");
      setProjectList(sortedList);

      // const res = await axios.get(`${BASE_URL}/siteproject/letterrecord/`);
      // setTrackRecordList(res.data);


    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  useEffect(() => {
    getProjects();
  }, [formData.company_name]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
          if (key !== "documents" && formData[key]) {


            formDataToSend.append(key, formData[key]);

          }
        });

        // Append documents data
        formData.documents.forEach((doc, index) => {
          if (doc.document_file) {
            const customFile = createCustomFile(doc.document_file);
            formDataToSend.append(
              `documents[${index}][document_name]`,
              doc.document_name
            );
            formDataToSend.append(
              `documents[${index}][document_file]`,
              customFile
            );
          }
        });

        const res = await axiosInstance.post(
          `/siteproject/docs-Bulkwith-Certifications/`,
          formDataToSend,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        );

        if (res.status === 200) {

          handleClose();
          await getcertification();
          toast.success("Data submitted successfully!");

          toast.success("Data submitted successfully!");


        } else {
          throw new Error("Document upload failed");
        }
      } catch (err) {
        // toast.error("Error uploading documents");
        console.error("Error uploading documents", err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
        setUploadProgress(0);
      }
    }
  };
  const createCustomFile = (originalFile) => {
    const randomCode = generateRandomString();
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
    return new File([originalFile], customFileName, {
      type: originalFile.type,
    });
  };
  const generateRandomString = () => {
    const chars =
      "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from(
      { length: 8 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
  };

  return (
    <>
      <button
        title="Add Certificate"
        className="upload-svg"
        onClick={handleShow}
      >
        {/* Replace with your icon component */}
        <AddwithWhiteCircle /> Certification
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>Add Certification Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-flex-wrap">

            <div title="Site Sub Company" className="flex-column form-group-selectt">
              <label htmlFor="company_name" className="form-labels  font-weight500    font-size-subheading">
                Site Sub Company
              </label>
              <br />
              <select
                id="company_name"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
                className={`form-input form-group-selection ${errors.company_name ? "error" : inputState.company_name ? "success" : ""
                  }`}
              >
                <option value="">Select a Site SubCompany</option>
                {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                  <option value={i.id}>{i.title}</option>
                </>))
                }
              </select>
              <DropdownArrow />
              <div>
                {errors.company_name && (
                  <span className="error-message font-size-text ">{errors.company_name}</span>
                )}
              </div>
            </div>
            <div title="Project" className="flex-column form-group-selectt">
              <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                Project
              </label>
              <br />
              <select
                id="project"
                name="project"
                value={formData.project}
                onChange={handleInputChange}
                className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                  }`}
              >
                <option value="">Select Project</option>
                {projectList?.sort((a, b) => a.site_prcode.localeCompare(b.site_prcode, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                  <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                </>))
                }
              </select>
              <DropdownArrow />
              <div>
                {errors.project && (
                  <span className="error-message font-size-text ">{errors.project}</span>
                )}
              </div>
            </div>
            <div title="project_short_name" className="flex-column">
              <label htmlFor="project_short_name" className="form-labels  font-weight500    font-size-subheading">
                Project Short Name
              </label>
              <input
                type="text"
                maxLength={100}
                id="project_short_name"
                name="project_short_name"
                placeholder="Project Short Name"
                onChange={handleInputChange}
                value={formData.project_short_name}
                className={`form-input ${errors.project_short_name
                  ? "error"
                  : inputState.project_short_name
                    ? "success"
                    : ""
                  }`}
              />
              {errors.project_short_name && (
                <span className="error-message font-size-text ">{errors.project_short_name}</span>
              )}
            </div>

            <div title="project_full_name" className="flex-column">
              <label htmlFor="project_full_name" className="form-labels  font-weight500    font-size-subheading">
                Project Full Name
              </label>
              <input
                type="text"
                maxLength={100}
                id="project_full_name"
                name="project_full_name"
                placeholder="Project Full Name"
                onChange={handleInputChange}
                value={formData.project_full_name}
                className={`form-input ${errors.project_full_name
                  ? "error"
                  : inputState.project_full_name
                    ? "success"
                    : ""
                  }`}
              />
              {errors.project_full_name && (
                <span className="error-message font-size-text ">{errors.project_full_name}</span>
              )}
            </div>


            <div title="service_type" className="flex-column form-group-selectt">
              <label htmlFor="service_type" className="form-labels  font-weight500    font-size-subheading">
                Service Type
              </label>
              <br />
              <select
                id="service_type"
                name="service_type"
                value={formData.service_type}
                onChange={handleInputChange}
                className={`form-input form-group-selection ${errors.service_type ? "error" : inputState.service_type ? "success" : ""
                  }`}
              >
                <option value="">Select a Service Type</option>
                <option value="DPR">DPR</option>
                <option value="AE">AE</option>
                <option value="IE">IE</option>
                <option value="O&M">O&M</option>
                <option value="Safety">Safety</option>

              </select>
              <DropdownArrow />
              {errors.service_type && (
                <span className="error-message font-size-text ">{errors.service_type}</span>
              )}
            </div>
            <div title="sectors" className="flex-column form-group-selectt">
              <label htmlFor="sectors" className="form-labels  font-weight500    font-size-subheading">
                Sector Type
              </label>
              <br />
              <select
                id="sectors"
                name="sectors"
                value={formData.sectors}
                onChange={handleInputChange}
                className={`form-input form-group-selection ${errors.sectors ? "error" : inputState.sectors ? "success" : ""
                  }`}
              >
                <option value="">Select a Service Type</option>
                <option value="Railway">Railway</option>
                <option value="Road & Highway">Road & Highway</option>
                <option value="Water">Water</option>
                <option value="Metro">Metro</option>
              </select>
              <DropdownArrow />
              {errors.sectors && (
                <span className="error-message font-size-text ">{errors.sectors}</span>
              )}
            </div>

            <div title="structure_details" className="flex-column form-group-selectt">
              <label htmlFor="structure_details" className="form-labels  font-weight500    font-size-subheading">
                Structure Type
              </label>
              <br />
              <select
                id="structure_details"
                name="structure_details"
                value={formData.structure_details}
                onChange={handleInputChange}
                className={`form-input form-group-selection ${errors.structure_details ? "error" : inputState.structure_details ? "success" : ""
                  }`}
              >
                <option value="">Select a Structure Type</option>
                <option value="Tunnel">Tunnel</option>
                <option value="Bridge - Minor">Bridge - Minor</option>
                <option value="Bridge - Major">Bridge - Major</option>
                <option value="Flyover">Flyover</option>
              </select>
              <DropdownArrow />
              {errors.structure_details && (
                <span className="error-message font-size-text ">{errors.structure_details}</span>
              )}
            </div>



            <div title="client" className="flex-column">
              <label htmlFor="client" className="form-labels  font-weight500    font-size-subheading">
                Client
              </label>
              <input
                type="text"
                maxLength={100}
                id="client"
                name="client"
                placeholder="Client"
                onChange={handleInputChange}
                value={formData.client}
                className={`form-input ${errors.client
                  ? "error"
                  : inputState.client
                    ? "success"
                    : ""
                  }`}
              />
              {errors.client && (
                <span className="error-message font-size-text ">{errors.client}</span>
              )}
            </div>

            {formData.sectors === "Road & Highway" ?
              <div title="Lane Type" className="flex-column form-group-selectt">
                <label htmlFor="lane_type" className="form-labels  font-weight500    font-size-subheading">
                  Lane Type
                </label>
                <br />
                <select
                  id="lane_type"
                  name="lane_type"
                  value={formData.lane_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.lane_type ? "error" : inputState.lane_type ? "success" : ""
                    }`}
                >
                  <option value="">Select a Lane Type</option>
                  <option value="2">2 Lane</option>
                  <option value="4">4 Lane</option>
                  <option value="6">6 Lane</option>
                </select>
                <DropdownArrow />
                {errors.lane_type && (
                  <span className="error-message font-size-text ">{errors.lane_type}</span>
                )}
              </div>

              : ""}

            <div title="total_length_km" className="flex-column">
              <label htmlFor="total_length_km" className="form-labels  font-weight500    font-size-subheading">
                Total Length (KM)
              </label>
              <input
                type="text"
                maxLength={100}
                id="total_length_km"
                name="total_length_km"
                placeholder="Total Length"
                onChange={handleInputChange}
                value={formData.total_length_km}
                className={`form-input ${errors.total_length_km
                  ? "error"
                  : inputState.total_length_km
                    ? "success"
                    : ""
                  }`}
              />
              {errors.total_length_km && (
                <span className="error-message font-size-text ">{errors.total_length_km}</span>
              )}
            </div>

            <div title="project_cost" className="flex-column">
              <label htmlFor="project_cost" className="form-labels font-weight500 font-size-subheading">
                Project Cost (in Cr.)
              </label>
              <input
                type="text"
                maxLength={100}
                id="project_cost"
                name="project_cost"
                placeholder="Total Cost"
                onChange={handleInputChange}
                value={formData.project_cost}
                className={`form-input ${errors.project_cost
                  ? "error"
                  : inputState.project_cost
                    ? "success"
                    : ""
                  }`}
              />
              {errors.project_cost && (
                <span className="error-message font-size-text ">{errors.project_cost}</span>
              )}
            </div>

            <div title="total_cost_cr" className="flex-column">
              <label htmlFor="total_cost_cr" className="form-labels  font-weight500    font-size-subheading">
                Total Cost (in Cr.)
              </label>
              <input
                type="text"
                maxLength={100}
                id="total_cost_cr"
                name="total_cost_cr"
                placeholder="Total Cost"
                onChange={handleInputChange}
                value={formData.total_cost_cr}
                className={`form-input ${errors.total_cost_cr
                  ? "error"
                  : inputState.total_cost_cr
                    ? "success"
                    : ""
                  }`}
              />
              {errors.total_cost_cr && (
                <span className="error-message font-size-text ">{errors.total_cost_cr}</span>
              )}
            </div>

            <div title="Start Date" className="flex-column">
              <label htmlFor="start_date" className="form-labels  font-weight500    font-size-subheading">
                Start Date<span className="required">*</span>
              </label>
              <input
                type="date"
                id="start_date"
                name="start_date"
                onChange={handleInputChange}
                value={formData.start_date}
                className={`form-input ${errors.start_date ? "error" : inputState.start_date ? "success" : ""
                  }`}
              />
              {errors.start_date && <span className="error-message font-size-text ">{errors.start_date}</span>}
            </div>
            <div title="End Date" className="flex-column">
              <label htmlFor="end_date" className="form-labels  font-weight500    font-size-subheading">
                End Date<span className="required">*</span>
              </label>
              <input
                type="date"
                id="end_date"
                name="end_date"
                min={formData.start_date}
                onChange={handleInputChange}
                value={formData.end_date}
                className={`form-input ${errors.end_date ? "error" : inputState.end_date ? "success" : ""
                  }`}
              />
              {errors.end_date && <span className="error-message font-size-text ">{errors.end_date}</span>}
            </div>

            <>
              <div title="jv_partner" className="flex-column">
                <div className="flex-row">
                  <label htmlFor="jv_partner" className="form-labels  font-weight500    font-size-subheading">
                    JV Partner  {" "}
                  </label>
                  <hr className="field-cont-hr" />
                  <div className="checkbox-toggle">
                    <input
                      id="toggle1"
                      name="is_jv"
                      type="checkbox"
                      value={formData.is_jv}
                      className={`form-checkbox ${errors.is_jv ? 'error' : inputState.is_jv ? 'success' : ''}`}
                      onChange={handleInputChange}
                      checked={formData.is_jv}
                    />
                    <label htmlFor="toggle1"></label>
                  </div>
                </div>
                <input
                  type="text"
                  maxLength={100}
                  id="jv_partner"
                  name="jv_partner"
                  placeholder="JV Partner "
                  onChange={handleInputChange}
                  value={formData.jv_partner}
                  className={`form-input ${errors.jv_partner
                    ? "error"
                    : inputState.jv_partner
                      ? "success"
                      : ""
                    }`}
                />
                {errors.jv_partner && (
                  <span className="error-message font-size-text ">{errors.jv_partner}</span>
                )}
              </div>
            </>
            <>
              <div title="lead_partner" className="flex-column">
                <label htmlFor="lead_partner" className="form-labels  font-weight500    font-size-subheading">
                  Lead Partner
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="lead_partner"
                  name="lead_partner"
                  placeholder="Lead Partner"
                  onChange={handleInputChange}
                  value={formData.lead_partner}
                  className={`form-input ${errors.lead_partner
                    ? "error"
                    : inputState.lead_partner
                      ? "success"
                      : ""
                    }`}
                />
                {errors.lead_partner && (
                  <span className="error-message font-size-text ">{errors.lead_partner}</span>
                )}
              </div>
            </>
            <div title="associate_partner" className="flex-column">
              <label htmlFor="associate_partner" className="form-labels  font-weight500    font-size-subheading">
                Associate Partner
              </label>
              <input
                type="text"
                maxLength={100}
                id="associate_partner"
                name="associate_partner"
                placeholder="Associate Partner"
                onChange={handleInputChange}
                value={formData.associate_partner}
                className={`form-input ${errors.associate_partner
                  ? "error"
                  : inputState.associate_partner
                    ? "success"
                    : ""
                  }`}
              />
              {errors.associate_partner && (
                <span className="error-message font-size-text ">{errors.associate_partner}</span>
              )}
            </div>
            <div title="our_share_percent" className="flex-column">
              <label htmlFor="our_share_percent" className="form-labels  font-weight500    font-size-subheading">
                Our Share Percent
              </label>
              <input
                type="number"
                min={0}
                max={100}
                id="our_share_percent"
                name="our_share_percent"
                placeholder="Our Share Percent"
                onChange={handleInputChange}
                value={formData.our_share_percent}
                className={`form-input ${errors.our_share_percent
                  ? "error"
                  : inputState.our_share_percent
                    ? "success"
                    : ""
                  }`}
              />
              {errors.our_share_percent && (
                <span className="error-message font-size-text ">{errors.our_share_percent}</span>
              )}
            </div>
            <div title="our_length_share_km" className="flex-column">
              <label htmlFor="our_length_share_km" className="form-labels  font-weight500    font-size-subheading">
                Our Share Length KM<span className="required">(Read-Only)</span>
              </label>
              <input
                type="number"
                min={0}
                id="our_length_share_km"
                name="our_length_share_km"
                placeholder="Our Share Length KM"
                onChange={handleInputChange}
                value={formData.our_length_share_km}
                readOnly
                className={`form-input ${errors.our_length_share_km
                  ? "error"
                  : inputState.our_length_share_km
                    ? "success"
                    : ""
                  }`}
              />
              {errors.our_length_share_km && (
                <span className="error-message font-size-text ">{errors.our_length_share_km}</span>
              )}
            </div>
            <div title="our_cost_share_cr" className="flex-column">
              <label htmlFor="our_cost_share_cr" className="form-labels  font-weight500    font-size-subheading">
                Our Cost Share (in crores)<span className="required">(Read-Only)</span>
              </label>
              <input
                type="number"
                min={0}
                id="our_cost_share_cr"
                name="our_cost_share_cr"
                placeholder="Our Cost Share "
                onChange={handleInputChange}
                value={formData.our_cost_share_cr}
                readOnly
                className={`form-input ${errors.our_cost_share_cr
                  ? "error"
                  : inputState.our_cost_share_cr
                    ? "success"
                    : ""
                  }`}
              />
              {errors.our_cost_share_cr && (
                <span className="error-message font-size-text ">{errors.our_cost_share_cr}</span>
              )}
            </div>
            <div title="our_share_4_6_lane_length" className="flex-column">
              <label htmlFor="our_share_4_6_lane_length" className="form-labels  font-weight500    font-size-subheading">
                Our Share 4-6 Lane Length<span className="required">(Read-Only)</span>
              </label>
              <input
                type="number"
                min={0}
                id="our_share_4_6_lane_length"
                name="our_share_4_6_lane_length"
                placeholder="Our Share 4-6 lane length "
                onChange={handleInputChange}
                readOnly
                value={formData.our_share_4_6_lane_length}
                className={`form-input ${errors.our_share_4_6_lane_length
                  ? "error"
                  : inputState.our_share_4_6_lane_length
                    ? "success"
                    : ""
                  }`}
              />
              {errors.our_share_4_6_lane_length && (
                <span className="error-message font-size-text ">{errors.our_share_4_6_lane_length}</span>
              )}
            </div>
            <div title="our_share_2_lane_length" className="flex-column">
              <label htmlFor="our_share_2_lane_length" className="form-labels  font-weight500    font-size-subheading">
                Our Share 2 Lane Length<span className="required">(Read-Only)</span>
              </label>
              <input
                type="number"
                min={0}
                id="our_share_2_lane_length"
                name="our_share_2_lane_length"
                placeholder="Our Share 2 Lane Length "
                readOnly
                onChange={handleInputChange}
                value={formData.our_share_2_lane_length}
                className={`form-input ${errors.our_share_2_lane_length
                  ? "error"
                  : inputState.our_share_2_lane_length
                    ? "success"
                    : ""
                  }`}
              />
              {errors.our_share_2_lane_length && (
                <span className="error-message font-size-text ">{errors.our_share_2_lane_length}</span>
              )}
            </div>
            <div title="remarks" className="flex-column">
              <label htmlFor="remarks" className="form-labels  font-weight500    font-size-subheading">
                Remarks
              </label>
              <input
                type="text"
                id="remarks"
                name="remarks"
                placeholder="Remarks "
                onChange={handleInputChange}
                value={formData.remarks}
                className={`form-input ${errors.remarks
                  ? "error"
                  : inputState.remarks
                    ? "success"
                    : ""
                  }`}
              />
              {errors.remarks && (
                <span className="error-message font-size-text ">{errors.remarks}</span>
              )}
            </div>

          </div>



          <DocumentSection
            formData={formData}
            handleDocInputChange={handleDocInputChange}
            handleAddDocuments={handleAddDocuments}
            handleRemoveDocument={handleRemoveDocument}
            errors={errors}
            fileNames={fileNames}
            inputState={inputState}
          />

          {/* Upload Progress Bar */}
          {uploadProgress > 0 && (
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${uploadProgress}%` }}
              ></div>
              <span>{uploadProgress}%</span>
            </div>
          )}



          <div className="button-models">
            <button
              onClick={handleFormSubmit}
              className="model-button font-weight500 model-button-submit"
              disabled={loading}
              type='button'
            >
              Submit
            </button>
          </div>
          <p className="error-message font-size-text">
            {isSubmitted && Object.keys(errors).length > 0 && (
              Object.keys(errors).length > 5 ? (
                <h5 className="text-center">Please fill all mandatory fields!</h5>
              ) : (

                <h6 className="text-center">
                  {Object.keys(errors).map((field) =>
                    field.charAt(0).toUpperCase() + field.slice(1)
                  ).join(', ') + ' are required!'}
                </h6>
              )
            )}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}



const UpdateCertificate = ({
  i,
  title,
  projectId,
  letterType,
  workperformedby,
  associatedletters,
  getcertification,
}) => {
  const [projectbyId, setProjectbyID] = useState();
  const [show, setShow] = useState(false);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
  const [projectList, setProjectList] = useState([])
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setFormData(i);
  };



  const [formData, setFormData] = useState({
    company_name: "",
    service_type: "",
    project_short_name: "",
    project_full_name: "",
    structure_details: "",
    client: "",
    lane_type: "",
    total_length_km: "",
    total_cost_cr: "",
    is_jv: false,
    lead_partner: "",
    sectors: "",
    project_cost: "",
    jv_partner: "",
    associate_partner: "",
    our_share_percent: "",
    our_length_share_km: "",
    our_cost_share_cr: "",
    our_share_4_6_lane_length: "",
    our_share_2_lane_length: "",
    start_date: "null",
    end_date: "null",
    remarks: "",
    documents: [{ document_name: "", document_file: "" }],

  });






  const [formDataDoc, setFormDataDoc] = useState({
    documents: [
      {
        document_name: null,
        document_file: "",
      },
    ],
  });



  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const requiredFields = [
      // "letter_num",
      // "project",
      // "subject",
      // "letter_date",
      // "status",
      // "lettertype",
      // "workperformedby",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    const newErrors = requiredFields.reduce((acc, field) => {
      if (
        !formData[field] ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
      return acc;
    }, {});


    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };



  const getProjects = async () => {
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);
      // const pro = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${toggleCompany}/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/projectfilterbysubcompany/${formData.company_name ? formData.company_name : 'null'}/`
      );
      const sortedList = sortProjects(pro.data, "site_prcode");
      setProjectList(sortedList);

      // const res = await axios.get(`${BASE_URL}/siteproject/letterrecord/`);
      // setTrackRecordList(res.data);


    } catch (err) {
      handleErrorToast(err);
    } finally {
    }
  };
  useEffect(() => {
    getProjects();
  }, [formData.company_name]);


  useEffect(() => {
    if (formData.project && projectbyId) {
      setFormData((prevData) => ({
        ...prevData,
        project_short_name: projectbyId.project_short_name,
        project_full_name: projectbyId.project_name,
      }));
    }
  }, [formData.project, projectbyId]);


  // const handleInputChange = (e) => {
  //   const { name, value, type, checked, options } = e.target;

  //   let newValue;
  //   if (type === "select-multiple") {
  //     newValue = Array.from(options)
  //       .filter((option) => option.selected)
  //       .map((option) => option.value);
  //   } else if (type === "checkbox") {
  //     newValue = checked;
  //   } else {
  //     newValue = value;
  //   }

  //   // Update input state with feedback
  //   setInputState((prevState) => ({
  //     ...prevState,
  //     [name]:
  //       type === "checkbox"
  //         ? checked
  //           ? "green"
  //           : ""
  //         : newValue
  //           ? "green"
  //           : "",
  //   }));

  //   // Update form data with dynamic calculations
  //   setFormData((prevFormData) => {
  //     const updatedFormData = {
  //       ...prevFormData,
  //       [name]: newValue,
  //     };

  //     // Set lane_type to "null" if sectors is not "Road & Highway"
  //     if (updatedFormData.sectors !== "Road & Highway") {
  //       updatedFormData.lane_type = "null";
  //     }

  //     if (
  //       [
  //         // contractor
  //         "our_share_4_6_lane_length",
  //         "our_share_2_lane_length",
  //         "our_share_percent",
  //         "our_length_share_km",
  //         "our_cost_share_cr",
  //         "total_cost_cr",
  //         "total_length_km",
  //       ].includes(name)
  //     ) {
  //       // Contractor calculations
  //       const total_cost_cr = parseFloat(updatedFormData.total_cost_cr || 0).toFixed(2);
  //       const our_share_percent = parseFloat(updatedFormData.our_share_percent || 0).toFixed(2);
  //       const total_length_km = parseFloat(updatedFormData.total_length_km || 0).toFixed(2);




  //       // You can extend this logic to compute additional fields

  //       if (updatedFormData.lane_type === "2" && updatedFormData.total_cost_cr > 1) {
  //         updatedFormData.our_share_4_6_lane_length =
  //           (total_length_km * 0.4 * (our_share_percent / 100)).toFixed(2);
  //       }
  //       else {
  //         updatedFormData.our_share_4_6_lane_length = 0
  //       }
  //       updatedFormData.our_share_2_lane_length =
  //         (total_length_km * 1.5 * (our_share_percent / 100)).toFixed(2);
  //       updatedFormData.our_length_share_km =
  //         (total_length_km * (our_share_percent / 100)).toFixed(2);
  //       updatedFormData.our_cost_share_cr =
  //         (total_cost_cr * (our_share_percent / 100)).toFixed(2);
  //     }

  //     return updatedFormData;
  //   });
  // };


  // ?  ************** Validation End **************  ! //




  const handleInputChange = (e) => {
    const { name, value, type, checked, options } = e.target;

    let newValue;
    if (type === "select-multiple") {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else if (type === "checkbox") {
      newValue = checked;
    } else {
      newValue = value;
    }

    // Update input state with feedback
    setInputState((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
            ? "green"
            : ""
          : newValue
            ? "green"
            : "",
    }));

    // Update form data with dynamic calculations
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      // Set lane_type to "null" if sectors is not "Road & Highway"
      if (updatedFormData.sectors !== "Road & Highway") {
        updatedFormData.lane_type = "";
      }

      if (
        [
          // contractor
          "our_share_4_6_lane_length",
          "our_share_2_lane_length",
          "our_share_percent",
          "our_length_share_km",
          "our_cost_share_cr",
          "total_cost_cr",
          "total_length_km",
        ].includes(name)
      ) {
        // Contractor calculations
        const total_cost_cr = parseFloat(updatedFormData.total_cost_cr || 0).toFixed(2);
        const our_share_percent = parseFloat(updatedFormData.our_share_percent || 0).toFixed(2);
        const total_length_km = parseFloat(updatedFormData.total_length_km || 0).toFixed(2);




        // You can extend this logic to compute additional fields

        if (updatedFormData.lane_type === "2" && updatedFormData.total_cost_cr > 1) {
          updatedFormData.our_share_4_6_lane_length =
            (total_length_km * 0.4 * (our_share_percent / 100)).toFixed(2);
        }
        else {
          updatedFormData.our_share_4_6_lane_length = 0
        }
        updatedFormData.our_share_2_lane_length =
          (total_length_km * 1.5 * (our_share_percent / 100)).toFixed(2);
        updatedFormData.our_length_share_km =
          (total_length_km * (our_share_percent / 100)).toFixed(2);
        updatedFormData.our_cost_share_cr =
          (total_cost_cr * (our_share_percent / 100)).toFixed(2);
      }

      return updatedFormData;
    });
  };


  const getProjectbyID = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/projectbyid/${formData.project}/`);
      setProjectbyID(res.data);


    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProjectbyID();
  }, [formData.project]);

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        const formDataToSend = new FormData();

        Object.keys(formData).forEach((key) => {

          formDataToSend.append(key, formData[key]);


        });
        formDataDoc.documents.forEach((doc, index) => {
          const symbols = "!@#$%^&*()_-+=";
          const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
          const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          const numbers = "0123456789";
          const now = new Date();
          const date = now
            .toLocaleDateString("en-GB")
            .split("/")
            .reverse()
            .join("-"); // Format: YYYY-MM-DD
          const time = now
            .toLocaleTimeString("en-GB", { hour12: false })
            .replace(/:/g, "-"); // Format: HH-MM-SS
          const allChars =
            symbols + lowercaseLetters + uppercaseLetters + numbers;
          let randomCode = "";
          for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * allChars.length);
            randomCode += allChars[randomIndex];
          }
          const originalFile = doc.document_file;
          const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
          const customFile = new File([originalFile], customFileName, {
            type: originalFile.type,
          });
          if (doc.document_file) {
            formDataToSend.append(
              `documents[${index}][document_name]`,
              doc.document_name
            );
            formDataToSend.append(
              `documents[${index}][document_file]`,
              doc.document_file
            );
          } else {
            console.warn(`Document file at index ${index} is not valid.`);
          }
        });

        console.log("FormData to send:");
        formDataToSend.forEach((value, key) => {
          if (value instanceof File) {
            console.log(`${key}: [File] ${value.name} - ${value.size} bytes`);
          } else {
            console.log(`${key}: ${value}`);
          }
        });
        let res = await axios.put(
          `${BASE_URL}/siteproject/docs-Bulk-with-Certifications-update/${formData.id}/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          setShow(false);
          await getcertification();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };



  const [fileNames, setFileNames] = useState([]);

  const handleDocInputChange = (index, e) => {
    const { name, value, type, files } = e.target;

    setFormDataDoc((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      if (type === "file") {
        newDocuments[index] = {
          ...newDocuments[index],
          [name]: files[0],
        };
        setFileNames((prevFileNames) => {
          const newFileNames = [...prevFileNames];
          newFileNames[index] = files[0]?.name;
          return newFileNames;
        });
      } else {
        newDocuments[index] = {
          ...newDocuments[index],
          [name]: value.trim() ? value : "",
        };
      }
      return {
        ...prevFormData,
        documents: newDocuments,
      };
    });
  };

  const handleAddDocuments = (e) => {
    e.preventDefault();
    setFormDataDoc((prevFormData) => ({
      ...prevFormData,
      documents: [
        ...prevFormData.documents,
        {
          document_name: "",
          document_file: null,
        },
      ],
    }));
  };



  const handleRemoveDocument = (index) => {
    setFormDataDoc((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      newDocuments.splice(index, 1);
      return {
        ...prevFormData,
        documents: newDocuments,
      };
    });
    setFileNames((prevFileNames) => {
      const newFileNames = [...prevFileNames];
      newFileNames.splice(index, 1);
      return newFileNames;
    });
  };

  const handlePicDelete = async (e, i, getProjects) => {
    e.preventDefault();

    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/documents/${i.id}/`
      );

      if (res.status === 200) {
        await getProjects();
        toast.success("Document deleted Successfully");
        toast.dismiss(loadingToastId);

      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };



  // ? Document End ===============================


  return (
    <>
      <button onClick={handleShow} className="document-download-button  width-5vw  " style={{ marginBottom: "10px", height: "30px" }}>
        View
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title ? title : "Update Track Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">

              <div title="Site Sub Company" className="flex-column form-group-selectt">
                <label htmlFor="company_name" className="form-labels  font-weight500    font-size-subheading">
                  Site Sub Company
                </label>
                <br />
                <select
                  id="company_name"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.company_name ? "error" : inputState.company_name ? "success" : ""
                    }`}
                >
                  <option value="">Select a Site SubCompany</option>
                  {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                    <option value={i.id}>{i.title}</option>
                  </>))
                  }
                </select>
                <DropdownArrow />
                <div>
                  {errors.company_name && (
                    <span className="error-message font-size-text ">{errors.company_name}</span>
                  )}
                </div>
              </div>
              <div title="Project" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Project
                </label>
                <br />
                <select
                  id="project"
                  name="project"
                  value={formData.project}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                    }`}
                >
                  <option value="">Select Project</option>
                  {projectList?.sort((a, b) => a.site_prcode.localeCompare(b.site_prcode, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                    <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                  </>))
                  }
                </select>
                <DropdownArrow />
                <div>
                  {errors.project && (
                    <span className="error-message font-size-text ">{errors.project}</span>
                  )}
                </div>
              </div>

              <div title="project_short_name" className="flex-column">
                <label htmlFor="project_short_name" className="form-labels  font-weight500    font-size-subheading">
                  Project Short Name
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="project_short_name"
                  name="project_short_name"
                  placeholder="Project Short Name"
                  onChange={handleInputChange}
                  value={formData.project_short_name}
                  className={`form-input ${errors.project_short_name
                    ? "error"
                    : inputState.project_short_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_short_name && (
                  <span className="error-message font-size-text ">{errors.project_short_name}</span>
                )}
              </div>

              <div title="project_full_name" className="flex-column">
                <label htmlFor="project_full_name" className="form-labels  font-weight500    font-size-subheading">
                  Project Full Name
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="project_full_name"
                  name="project_full_name"
                  placeholder="Project Full Name"
                  onChange={handleInputChange}
                  value={formData.project_full_name}
                  className={`form-input ${errors.project_full_name
                    ? "error"
                    : inputState.project_full_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_full_name && (
                  <span className="error-message font-size-text ">{errors.project_full_name}</span>
                )}
              </div>


              <div title="service_type" className="flex-column form-group-selectt">
                <label htmlFor="service_type" className="form-labels  font-weight500    font-size-subheading">
                  Service Type
                </label>
                <br />
                <select
                  id="service_type"
                  name="service_type"
                  value={formData.service_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.service_type ? "error" : inputState.service_type ? "success" : ""
                    }`}
                >
                  <option value="">Select a Service Type</option>
                  <option value="DPR">DPR</option>
                  <option value="AE">AE</option>
                  <option value="IE">IE</option>
                  <option value="O&M">O&M</option>
                  <option value="Safety">Safety</option>

                </select>
                <DropdownArrow />
                {errors.service_type && (
                  <span className="error-message font-size-text ">{errors.service_type}</span>
                )}
              </div>
              <div title="sectors" className="flex-column form-group-selectt">
                <label htmlFor="sectors" className="form-labels  font-weight500    font-size-subheading">
                  Sector Type
                </label>
                <br />
                <select
                  id="sectors"
                  name="sectors"
                  value={formData.sectors}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sectors ? "error" : inputState.sectors ? "success" : ""
                    }`}
                >
                  <option value="">Select a Service Type</option>
                  <option value="Railway">Railway</option>
                  <option value="Road & Highway">Road & Highway</option>
                  <option value="Water">Water</option>
                  <option value="Metro">Metro</option>
                </select>
                <DropdownArrow />
                {errors.sectors && (
                  <span className="error-message font-size-text ">{errors.sectors}</span>
                )}
              </div>

              <div title="structure_details" className="flex-column form-group-selectt">
                <label htmlFor="structure_details" className="form-labels  font-weight500    font-size-subheading">
                  Structure Type
                </label>
                <br />
                <select
                  id="structure_details"
                  name="structure_details"
                  value={formData.structure_details}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.structure_details ? "error" : inputState.structure_details ? "success" : ""
                    }`}
                >
                  <option value="">Select a Structure Type</option>
                  <option value="Tunnel">Tunnel</option>
                  <option value="Bridge - Minor">Bridge - Minor</option>
                  <option value="Bridge - Major">Bridge - Major</option>
                  <option value="Flyover">Flyover</option>
                </select>
                <DropdownArrow />
                {errors.structure_details && (
                  <span className="error-message font-size-text ">{errors.structure_details}</span>
                )}
              </div>



              <div title="client" className="flex-column">
                <label htmlFor="client" className="form-labels  font-weight500    font-size-subheading">
                  Client
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="client"
                  name="client"
                  placeholder="Client"
                  onChange={handleInputChange}
                  value={formData.client}
                  className={`form-input ${errors.client
                    ? "error"
                    : inputState.client
                      ? "success"
                      : ""
                    }`}
                />
                {errors.client && (
                  <span className="error-message font-size-text ">{errors.client}</span>
                )}
              </div>

              {formData.sectors === "Road & Highway" ?
                <div title="Lane Type" className="flex-column form-group-selectt">
                  <label htmlFor="lane_type" className="form-labels  font-weight500    font-size-subheading">
                    Lane Type
                  </label>
                  <br />
                  <select
                    id="lane_type"
                    name="lane_type"
                    value={formData.lane_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.lane_type ? "error" : inputState.lane_type ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Lane Type</option>
                    <option value="2">2 Lane</option>
                    <option value="4">4 Lane</option>
                    <option value="6">6 Lane</option>
                  </select>
                  <DropdownArrow />
                  {errors.lane_type && (
                    <span className="error-message font-size-text ">{errors.lane_type}</span>
                  )}
                </div>
                : null}

              <div title="total_length_km" className="flex-column">
                <label htmlFor="total_length_km" className="form-labels  font-weight500    font-size-subheading">
                  Total Length (KM)
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="total_length_km"
                  name="total_length_km"
                  placeholder="Total Length"
                  onChange={handleInputChange}
                  value={formData.total_length_km}
                  className={`form-input ${errors.total_length_km
                    ? "error"
                    : inputState.total_length_km
                      ? "success"
                      : ""
                    }`}
                />
                {errors.total_length_km && (
                  <span className="error-message font-size-text ">{errors.total_length_km}</span>
                )}
              </div>

              <div title="project_cost" className="flex-column">
                <label htmlFor="project_cost" className="form-labels font-weight500 font-size-subheading">
                  Project Cost (in Cr.)
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="project_cost"
                  name="project_cost"
                  placeholder="Total Cost"
                  onChange={handleInputChange}
                  value={formData.project_cost}
                  className={`form-input ${errors.project_cost
                    ? "error"
                    : inputState.project_cost
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_cost && (
                  <span className="error-message font-size-text ">{errors.project_cost}</span>
                )}
              </div>

              <div title="total_cost_cr" className="flex-column">
                <label htmlFor="total_cost_cr" className="form-labels  font-weight500    font-size-subheading">
                  Total Cost (in Cr.)
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="total_cost_cr"
                  name="total_cost_cr"
                  placeholder="Total Cost"
                  onChange={handleInputChange}
                  value={formData.total_cost_cr}
                  className={`form-input ${errors.total_cost_cr
                    ? "error"
                    : inputState.total_cost_cr
                      ? "success"
                      : ""
                    }`}
                />
                {errors.total_cost_cr && (
                  <span className="error-message font-size-text ">{errors.total_cost_cr}</span>
                )}
              </div>

              <div title="Start Date" className="flex-column">
                <label htmlFor="start_date" className="form-labels  font-weight500    font-size-subheading">
                  Start Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  onChange={handleInputChange}
                  value={formData.start_date}
                  className={`form-input ${errors.start_date ? "error" : inputState.start_date ? "success" : ""
                    }`}
                />
                {errors.start_date && <span className="error-message font-size-text ">{errors.start_date}</span>}
              </div>
              <div title="End Date" className="flex-column">
                <label htmlFor="end_date" className="form-labels  font-weight500    font-size-subheading">
                  End Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  min={formData.start_date}
                  onChange={handleInputChange}
                  value={formData.end_date}
                  className={`form-input ${errors.end_date ? "error" : inputState.end_date ? "success" : ""
                    }`}
                />
                {errors.end_date && <span className="error-message font-size-text ">{errors.end_date}</span>}
              </div>

              <>
                <div title="jv_partner" className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="jv_partner" className="form-labels  font-weight500    font-size-subheading">
                      JV Partner  {" "}
                    </label>
                    <hr className="field-cont-hr" />
                    <div className="checkbox-toggle">
                      <input
                        id="toggle1"
                        name="is_jv"
                        type="checkbox"
                        value={formData.is_jv}
                        className={`form-checkbox ${errors.is_jv ? 'error' : inputState.is_jv ? 'success' : ''}`}
                        onChange={handleInputChange}
                        checked={formData.is_jv}
                      />
                      <label htmlFor="toggle1"></label>
                    </div>
                  </div>
                  <input
                    type="text"
                    maxLength={100}
                    id="jv_partner"
                    name="jv_partner"
                    placeholder="JV Partner "
                    onChange={handleInputChange}
                    value={formData.jv_partner}
                    className={`form-input ${errors.jv_partner
                      ? "error"
                      : inputState.jv_partner
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.jv_partner && (
                    <span className="error-message font-size-text ">{errors.jv_partner}</span>
                  )}
                </div>
              </>
              <>
                <div title="lead_partner" className="flex-column">
                  <label htmlFor="lead_partner" className="form-labels  font-weight500    font-size-subheading">
                    Lead Partner
                  </label>
                  <input
                    type="text"
                    maxLength={100}
                    id="lead_partner"
                    name="lead_partner"
                    placeholder="Lead Partner"
                    onChange={handleInputChange}
                    value={formData.lead_partner}
                    className={`form-input ${errors.lead_partner
                      ? "error"
                      : inputState.lead_partner
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.lead_partner && (
                    <span className="error-message font-size-text ">{errors.lead_partner}</span>
                  )}
                </div>
              </>
              <div title="associate_partner" className="flex-column">
                <label htmlFor="associate_partner" className="form-labels  font-weight500    font-size-subheading">
                  Associate Partner
                </label>
                <input
                  type="text"
                  maxLength={100}
                  id="associate_partner"
                  name="associate_partner"
                  placeholder="Associate Partner"
                  onChange={handleInputChange}
                  value={formData.associate_partner}
                  className={`form-input ${errors.associate_partner
                    ? "error"
                    : inputState.associate_partner
                      ? "success"
                      : ""
                    }`}
                />
                {errors.associate_partner && (
                  <span className="error-message font-size-text ">{errors.associate_partner}</span>
                )}
              </div>
              <div title="our_share_percent" className="flex-column">
                <label htmlFor="our_share_percent" className="form-labels  font-weight500    font-size-subheading">
                  Our Share Percent
                </label>
                <input
                  type="number"
                  min={0}
                  max={100}
                  id="our_share_percent"
                  name="our_share_percent"
                  placeholder="Our Share Percent"
                  onChange={handleInputChange}
                  value={formData.our_share_percent}
                  className={`form-input ${errors.our_share_percent
                    ? "error"
                    : inputState.our_share_percent
                      ? "success"
                      : ""
                    }`}
                />
                {errors.our_share_percent && (
                  <span className="error-message font-size-text ">{errors.our_share_percent}</span>
                )}
              </div>
              <div title="our_length_share_km" className="flex-column">
                <label htmlFor="our_length_share_km" className="form-labels  font-weight500    font-size-subheading">
                  Our Share Length KM<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  min={0}
                  id="our_length_share_km"
                  name="our_length_share_km"
                  placeholder="Our Share Length KM"
                  onChange={handleInputChange}
                  value={formData.our_length_share_km}
                  readOnly
                  className={`form-input ${errors.our_length_share_km
                    ? "error"
                    : inputState.our_length_share_km
                      ? "success"
                      : ""
                    }`}
                />
                {errors.our_length_share_km && (
                  <span className="error-message font-size-text ">{errors.our_length_share_km}</span>
                )}
              </div>
              <div title="our_cost_share_cr" className="flex-column">
                <label htmlFor="our_cost_share_cr" className="form-labels  font-weight500    font-size-subheading">
                  Our Cost Share (in crores)<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  min={0}
                  id="our_cost_share_cr"
                  name="our_cost_share_cr"
                  placeholder="Our Cost Share "
                  onChange={handleInputChange}
                  value={formData.our_cost_share_cr}
                  readOnly
                  className={`form-input ${errors.our_cost_share_cr
                    ? "error"
                    : inputState.our_cost_share_cr
                      ? "success"
                      : ""
                    }`}
                />
                {errors.our_cost_share_cr && (
                  <span className="error-message font-size-text ">{errors.our_cost_share_cr}</span>
                )}
              </div>
              <div title="our_share_4_6_lane_length" className="flex-column">
                <label htmlFor="our_share_4_6_lane_length" className="form-labels  font-weight500    font-size-subheading">
                  Our Share 4-6 Lane Length<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  min={0}
                  id="our_share_4_6_lane_length"
                  name="our_share_4_6_lane_length"
                  placeholder="Our Share 4-6 lane length "
                  onChange={handleInputChange}
                  readOnly
                  value={formData.our_share_4_6_lane_length}
                  className={`form-input ${errors.our_share_4_6_lane_length
                    ? "error"
                    : inputState.our_share_4_6_lane_length
                      ? "success"
                      : ""
                    }`}
                />
                {errors.our_share_4_6_lane_length && (
                  <span className="error-message font-size-text ">{errors.our_share_4_6_lane_length}</span>
                )}
              </div>
              <div title="our_share_2_lane_length" className="flex-column">
                <label htmlFor="our_share_2_lane_length" className="form-labels  font-weight500    font-size-subheading">
                  Our Share 2 Lane Length<span className="required">(Read-Only)</span>
                </label>
                <input
                  type="number"
                  min={0}
                  id="our_share_2_lane_length"
                  name="our_share_2_lane_length"
                  placeholder="Our Share 2 Lane Length "
                  readOnly
                  onChange={handleInputChange}
                  value={formData.our_share_2_lane_length}
                  className={`form-input ${errors.our_share_2_lane_length
                    ? "error"
                    : inputState.our_share_2_lane_length
                      ? "success"
                      : ""
                    }`}
                />
                {errors.our_share_2_lane_length && (
                  <span className="error-message font-size-text ">{errors.our_share_2_lane_length}</span>
                )}
              </div>
              <div title="remarks" className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    font-size-subheading">
                  Remarks
                </label>
                <input
                  type="text"
                  id="remarks"
                  name="remarks"
                  placeholder="Remarks "
                  onChange={handleInputChange}
                  value={formData.remarks}
                  className={`form-input ${errors.remarks
                    ? "error"
                    : inputState.remarks
                      ? "success"
                      : ""
                    }`}
                />
                {errors.remarks && (
                  <span className="error-message font-size-text ">{errors.remarks}</span>
                )}
              </div>








            </div>

            <>
              <div className="jd-heading-outer  heading-bg-color-white">
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600  font-size-heading">
                    Documents{" "}
                  </h2>
                  <div>
                    <button
                      title="Add Documents"
                      onClick={handleAddDocuments}
                      className="jd-heading-main font-weight600 -right-button"
                      style={{ float: "right" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_450_2151)">
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_450_2151">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <br />
                  </div>
                </div>
                <div>
                  <div className="jd-heading-bottom-bold"></div>
                  <div className="jd-heading-bottom-light"></div>
                </div>
              </div>

              <div className="form-flex-wrap">
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Name</th>
                      <th className="align-center">File</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.documents?.map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.document_name}</td>
                          { }
                          <td className="align-center">
                            {i.document_file ? (
                              // <a href={i.document_file} target="blank">
                              //   View
                              // </a>
                              <a href={i.document_file} target="blank">
                                <DocumentIcon />
                              </a>
                            ) : (
                              "Document Not Attached"
                            )}
                          </td>

                          <td className="align-right width-5vw ">
                            <button
                              className="model-delete-button"
                              onClick={(e) =>
                                handlePicDelete(e, i, getcertification)
                              }
                            >
                              <DeleteDustbin />
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="line" />

              <div title="Documents" className="">
                {/* <label className="form-labels font-weight500 font-size-subheading">Documents</label> */}
                {formDataDoc?.documents?.map((doc, index) => (
                  <div key={index} className="expense-req">
                    <div className="flex-column field-cont-div">
                      <label
                        htmlFor={`document_name_${index}`}
                        className="form-labels announce-date font-weight500 font-size-heading"
                      >
                        File Name
                      </label>
                      <input
                        type="text"
                        id={`document_name_${index}`}
                        name={`document_name`}
                        placeholder="Document Name"
                        value={doc.document_name}
                        onChange={(e) => handleDocInputChange(index, e)}
                        className={`form-modal-input ${errors[`document_name_${index}`]
                          ? "error"
                          : inputState[`document_name`]
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors[`document_name_${index}`] && (
                        <span className="error-message font-size-text">
                          {errors[`document_name_${index}`]}
                        </span>
                      )}
                    </div>

                    <div className="flex-column field-cont-div">
                      <label
                        htmlFor=""
                        className="form-labels announce-date font-weight500 font-size-heading"
                      >
                        Upload File
                      </label>
                      <label
                        htmlFor={`document_file_${index}`}
                        className={`form-inputss custom-file-upload ${errors[`document_file_${index}`]
                          ? "error"
                          : inputState[`document_file`]
                            ? "success"
                            : ""
                          }`}
                      >
                        <div className={`align-center pdf-input  ${errors[`document_file_${index}`] ? 'error' : ''}`}>
                          <Attachment />
                        </div>
                      </label>
                      <input
                        id={`document_file_${index}`}
                        name={`document_file`}
                        type="file"
                        onChange={(e) => handleDocInputChange(index, e)}
                        style={{ display: "none", position: "relative" }}
                      />
                      {fileNames[index] && (
                        <p className="file-name align-center">
                          {fileNames[index]}
                        </p>
                      )}

                      {errors[`document_file_${index}`] && (
                        <span className="error-message font-size-text">
                          {errors[`document_file_${index}`]}
                        </span>
                      )}
                    </div>

                    {index === 0 ? null : (
                      <button
                        type="button"
                        className="model-button-cancel"
                        onClick={() => handleRemoveDocument(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
              </div>

              <div className="button-models">
                <button
                  onClick={handleFormSubmit}
                  className="model-button   font-weight500    model-button-submit"
                  disabled={loading}
                >
                  {/* //loading logic */}
                  Update
                </button>
              </div>
            </>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};


const DeleteCertificate = ({ i, getcertification }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/certifications/${i.id}/`
      );

      if (res.status === 200) {
        await getcertification();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Insurance"
        className="model-delete-button"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Certificate of {i.project_short_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Certificate of {i.project_short_name}
            <div className="button-models">
              <button
                className="model-button   font-weight500   model-button-cancel "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};




const CertficateAccess = ({ projectId, letterBy, contractor, contractorlist }) => {
  const tableRef = useRef(null);
  const [toggleCompany, setToggleCompany] = useState("null");
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);


  const [workperformedby, setWorkperformedby] = useState("null");

  const [lettertype, setLetterType] = useState("null");







  const [filteredData, setFilteredData] = useState([]);

  const [error, setError] = useState(null);

  const [selectedNo, setNo] = useState("");
  const [selectedFromDate, setFromDate] = useState("null");
  const [selectedToDate, setToDate] = useState("null");
  const [structure, setStructure] = useState("null");
  const [sector, setSector] = useState("null");
  const [service, setService] = useState("null");


  const [trackRecordList, setTrackRecordList] = useState([]);


  const [buffer, setBuffering] = useState(true); //buffering logic


  const getcompany = async () => {
    setBuffering(true); // Start Buffering
    try {

      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);



    } catch (err) {
      // handleErrorToast(err); // You can handle any general error here if needed
    } finally {
      setBuffering(false); // End Buffering
    }
  };
  const getProjects = async () => {
    setBuffering(true); // Start Buffering
    try {

      const res = await axios.get(`${BASE_URL}/siteproject/certifications/`);
      setTrackRecordList(res.data);


    } catch (err) {
      // handleErrorToast(err); // You can handle any general error here if needed
    } finally {
      setBuffering(false); // End Buffering
    }
  };

  useEffect(() => {
    getcompany();
  }, []);


  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sector, selectedFromDate, selectedToDate, structure]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNo, trackRecordList]);

  const applyFilters = () => {
    let filteredData = trackRecordList;
    if (selectedNo !== "") {
      const lowercaseSelectedName = selectedNo.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const lid = employee.letter_num.toString().toLowerCase().includes(lowercaseSelectedName);
        return lid;
      });
    }


    setFilteredData(filteredData);
  };
  const [toggleState, setToggleState] = useState(1);

  return (
    <>

      <div >



        <div className='content-tabs'>
          <Dashboardnavbar name={toggleState === 1 ? "Certification" : ""} url="Certification Access" />
          <div className="attendance-history-cont">
            <div className="attendance-heading  font-weight500    font-size-heading"> </div>
            <div className="field-cont">

              <div className="attendance-subcontainer" style={{ width: "100%" }}>

                <div className='flex-row ' style={{ width: "80%", flexWrap: "wrap" }}>
                  <div title="Company Filter" className="field-cont-div">
                    <Office />
                    <select
                      onChange={(e) => setToggleCompany(e.target.value)}
                      value={toggleCompany}
                      className="attendance-input-field width-10vw   date-field"
                    >
                      <option value="null">Sub Company</option>
                      {siteSubCompanyList.map((company) => (
                        <option value={company.id}>{company.title}</option>
                      ))}
                      ;
                    </select>

                    <hr className="field-cont-hr" />
                  </div>
                  <div title="Sector Filter" className="field-cont-div">
                    <Filter />
                    <select
                      id="sector"
                      name="sector"
                      placeholder="sector"
                      value={sector}
                      onChange={(e) => setSector(e.target.value)}
                      className={`attendance-input-field width-10vw `}
                    >
                      <option value="null">Sector Type</option>
                      <option value="Railway">Railway</option>
                      <option value="Road & Highway">Road & Highway</option>
                      <option value="Water">Water</option>
                      <option value="Metro">Metro</option>
                    </select>
                    <hr className="field-cont-hr" />
                  </div>

                  <div title="Structure Filter" className="field-cont-div">
                    <Filter />
                    <select
                      id="structure"
                      name="structure"
                      placeholder="structure"
                      value={structure}
                      onChange={(e) => setStructure(e.target.value)}
                      className={`attendance-input-field width-10vw `}
                    >
                      <option value="null">Select Structure Type</option>

                      <option value="Tunnel">Tunnel</option>
                      <option value="Bridge - Minor">Bridge - Minor</option>
                      <option value="Bridge - Major">Bridge - Major</option>
                      <option value="Flyover">Flyover</option>
                    </select>
                    <hr className="field-cont-hr" />
                  </div>




                  <div title="Start Date Filter" className="field-cont-div">
                    <input
                      className="attendance-input-field width-10vw   date-field"
                      placeholder="Start Date"
                      type="date"
                      value={selectedFromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <hr className="field-cont-hr" />
                  </div>

                  <div title="End Date Filter" className="field-cont-div ">
                    <input
                      className="attendance-input-field width-10vw   date-field"
                      placeholder="End Date"
                      type="date"
                      min={selectedFromDate}
                      value={selectedToDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                    <hr className="field-cont-hr" />
                  </div>
                  <div title="Service Filter" className="field-cont-div">
                    <Filter />
                    <select
                      id="service"
                      name="service"
                      placeholder="service"
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                      className={`attendance-input-field width-10vw `}
                    >
                      <option value="null">Service Type</option>
                      <option value="DPR">DPR</option>
                      <option value="AE">AE</option>
                      <option value="IE">IE</option>
                      <option value="O&M">O&M</option>
                      <option value="Safety">Safety</option>
                    </select>
                    <hr className="field-cont-hr" />
                  </div>
                </div>
                <div className='flex-row'>
                  <DownloadAndZip projectData={trackRecordList} sector={sector} structure={structure} fromDate={selectedFromDate} toDate={selectedToDate} />
                  <AddCertificationAccess getcertification={getProjects} />
                </div>
              </div>

            </div>
            <div className='filter-showing ' style={{ justifyContent: "end" }}>
              {/* <div>Letter Records:</div> */}

              <div >Showing {trackRecordList.length} of {trackRecordList.length} </div>
            </div>

            <div className="table-css-white-background  table-box paddingTop-0px scroll-container-table" >
              <table ref={tableRef} className="table-css">
                <thead className='table-heading'>
                  <tr className='custom-table-head-tr'>
                    <th className='table-heading-text'>S.no.</th>
                    <th className='table-heading-text'>Service Type</th>
                    <th className='table-heading-text'>Project Short Name </th>
                    <th className='table-heading-text'>Project Full Name </th>
                    <th className='table-heading-text'>Structure details</th>
                    <th className='table-heading-text'>Client</th>
                    <th className='table-heading-text'>Lane Type</th>
                    <th className='table-heading-text'>Total Length</th>
                    <th className='table-heading-text'>Total Cost</th>
                    <th className='table-heading-text'>Lead Partner</th>
                    <th className='table-heading-text'>JV Partner</th>
                    <th className='table-heading-text'>Associate Partner</th>
                    <th className='table-heading-text'>Share Percent</th>
                    <th className='table-heading-text'>Share Length</th>
                    <th className='table-heading-text'>Share Cost</th>
                    <th className='table-heading-text'>Our share 4/6 lane Length </th>
                    <th className='table-heading-text'>Our share 2 lane Length</th>
                    <th className='table-heading-text'>Start Date</th>
                    <th className='table-heading-text'>End Date</th>
                    <th className='table-heading-text'>Remarks</th>
                    {sessionStorage.getItem('sitemanagement_role') === "BD" ? "" :
                      <>
                        <th className='table-heading-text'>View/Edit</th>
                        <th className='table-heading-text'>Delete</th>
                      </>
                    }
                  </tr>
                </thead>
                {buffer ? (
                  <div className="spinner-small"> </div> //buffering logic 
                ) : error ? (
                  <p>Error fetching data: {error.message}</p>
                ) :
                  <tbody>
                    {filteredData.map((i, index) => (
                      <tr className="custom-table-head-td">
                        <td className='table-body'>{index + 1}.</td>
                        <td className='table-body'>{i.service_type}</td>
                        <td className='table-body'>{i.project_short_name}</td>
                        <td className='table-body'><div style={{ width: "300px", whiteSpace: "normal" }}>{i.project_full_name}</div></td>
                        <td className='table-body'>{i.structure_details}</td>
                        <td className='table-body'>{i.client}</td>
                        <td className='table-body'>{i.lane_type}</td>
                        <td className='table-body'>{i.total_length_km}</td>
                        <td className='table-body'>{i.total_cost_cr}</td>
                        <td className='table-body'>{i.lead_partner}</td>
                        <td className='table-body'>{i.jv_partner}</td>
                        <td className='table-body'>{i.associate_partner}</td>
                        <td className='table-body'>{i.our_share_percent}</td>
                        <td className='table-body'>{i.our_length_share_km}</td>
                        <td className='table-body'>{i.our_cost_share_cr}</td>
                        <td className='table-body'>{i.our_share_4_6_lane_length}</td>
                        <td className='table-body'>{i.our_share_2_lane_length}</td>
                        <td className='table-body'>{i.start_date}</td>
                        <td className='table-body'>{i.end_date}</td>
                        <td className='table-body'>{i.remarks}</td>
                        <td className='table-body'>
                          <UpdateCertificate
                            i={i}
                            getcertification={getProjects}

                          />


                        </td>
                        <td className='table-body'>
                          <DeleteCertificate
                            i={i}
                            getcertification={getProjects}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


const DownloadAndZip = ({ projectData, fromDate, toDate, sector, structure }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const handleShow = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
  }

  console.log({ fromDate, toDate, sector, structure });

  const getDocument = async () => {
    const res = await axios.get(`${BASE_URL}/siteproject/certificationdownlaodfilter/${fromDate}/${toDate}/${sector}/${structure}/null/`);
    setDocumentList(res.data);
  }

  useEffect(() => {
    getDocument();
  }, [fromDate, toDate, sector, structure]);

  const fetchAndZipFiles = async () => {
    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic
    setError("");

    try {
      // Step 1: Fetch the API response



      const apiResponse = documentList; // Assuming API response is JSON
      const files = apiResponse.flatMap((project) => project.documents);

      if (files.length === 0) {
        setError("No documents found in the API response.");
        setLoading(false);
        return;
      }

      // Step 3: Initialize JSZip
      const zip = new JSZip();

      // Step 4: Fetch each document and add it to the ZIP
      const filePromises = files.map(async (file) => {
        try {
          const fileResponse = await axios.get(file.document_file, {
            responseType: "blob",
          });
          const fileName = `${file.document_name}.pdf`;
          zip.file(fileName, fileResponse.data);
        } catch (err) {
          console.error(`Failed to download: ${file.document_name}`, err);
        }
      });

      await Promise.all(filePromises);

      // Step 5: Generate and save the ZIP file
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "documents.zip");

      // Close the modal and show success toast
      handleClose();
      toast.dismiss(loadingToastId);
      toast.success("Documents downloaded successfully!");


    } catch (err) {
      console.error("Error fetching data or downloading files:", err);
      setError("Failed to process the request. Please try again.");
    } finally {
      setLoading(false);
      toast.dismiss(loadingToastId);

    }

  };



  return (
    <>
      <button
        title="Download"
        className="upload-svg"
        onClick={handleShow}
      >
        <DownloadIcon />
      </button>
      <div>

        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="request-leave"
        >
          <Modal.Header closeButton>
            <Modal.Title>Download Certificates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {/* <ol>
                  {documentList.map((project, index) => (
                    // <li key={index}>
                    //   {project.project_short_name}

                    project.documents.map((document, docIndex) => (
                      <li key={docIndex}>{document.document_name}</li>
                    ))

                    // </li>
                  ))}
                </ol> */}
                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      {/* <th className="align-left">S. No.</th> */}
                      <th className="align-center">Name</th>
                      <th className="align-center">File</th>

                    </tr>
                  </thead>
                  <tbody>
                    {documentList.map((project, i) => (
                      project.documents?.map((i, index) => (
                        <React.Fragment key={index}>

                          <tr className="custom-table-head-td">
                            {/* <td className="align-left">{index + 1}</td> */}
                            <td className="align-center">{i.document_name}</td>
                            { }
                            <td className="align-center">
                              {i.document_file ? (
                                // <a href={i.document_file} target="blank">
                                //   View
                                // </a>
                                <a href={i.document_file} target="blank">
                                  <DocumentIcon />
                                </a>
                              ) : (
                                "Document Not Attached"
                              )}
                            </td>


                          </tr>
                        </React.Fragment>
                      ))))}
                  </tbody>
                </table>
              </div>
              <div className='button-models' >
                <button
                  className="model-button   model-button-cancel font-weight500"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button onClick={fetchAndZipFiles} disabled={loading} className="model-button   font-weight500    model-button-submit">
                  {loading ? "Downloading..." :
                    // "Download Certificate"
                    <> <DownloadIcon /> Certificates</>
                  }
                </button>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};






export default CertficateAccess;








