import { useEffect, useState } from "react"
import { CircularGrid } from "./AllSvg"
import axios from "axios"
import { BASE_URL } from "../config/axios"
import usePermission from "../config/permissions"
import { sortProjects } from "./CustomFunctions"


const LetterStatus = ({ selectedLetterStatus, setLetterStatus }) => {
    return (
        <div title="Status Filter" className="field-cont-div">
            <CircularGrid />
            <select
                id="status"
                name="status"
                placeholder="Status"
                value={selectedLetterStatus}
                onChange={(e) => setLetterStatus(e.target.value)}
                className={`attendance-input-field width-10vw `}
            >



                <option value="null">Select a Status</option>
                <option value="pending">Pending</option>
                <option value="in-progress">InProgress</option>
                <option value="partially-close">Partially Close</option>
                <option value="close">Close</option>



            </select>
            <hr className="field-cont-hr" />
        </div>
    )
}



const LetterType = ({ setLetterType, lettertype }) => {
    return (
        <>
            <div title="Letter Type" className="field-cont-div">
                <CircularGrid />
                <select
                    onChange={(e) => setLetterType(e.target.value)}
                    value={lettertype}
                    className="attendance-input-field width-10vw   date-field"
                >

                    <option value="null"> Letter Type</option>
                    <option value="eot">EOT</option>
                    <option value="cos">COS</option>
                    <option value="ncr">NCR</option>
                    <option value="billing">Billing</option>
                    <option value="design">Design</option>
                    <option value="informative">Informative</option>
                    <option value="show-cause">Show Cause</option>
                    <option value="Design & Drawing">Design & Drawing</option>
                    <option value="P&P">P&P</option>
                    <option value="QAP">QAP</option>
                    <option value="Structure">Structure</option>
                    <option value="billing">Billing</option>
                    <option value="Q & ME">Q & ME</option>
                    <option value="BBS">BBS</option>
                    <option value="noc">NOC</option>
                    <option value="Road Safety">Road Safety</option>
                    <option value="Work Plan">Work Plan</option>
                    <option value="Drain">Drain</option>
                    <option value="Hydrology">Hydrology</option>
                    <option value="Survey">Survey</option>
                    <option value="Milestone">Milestone</option>
                    <option value="SiteProgress">SiteProgress</option>
                    <option value="query">Query</option>
                    <option value="other">Others</option>
                </select>

                <hr className="field-cont-hr" />
            </div>
        </>
    )
}


const SubCompany = ({ toggleCompany, setToggleCompany }) => {

    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

    // Fetch sub-companies when the component mounts
    useEffect(() => {
        const fetchSubCompanies = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
                setSiteSubCompanyList(response.data);
            } catch (error) {
                console.error('Error fetching sub-companies:', error);
            }
        };

        fetchSubCompanies();
    }, []);


    // Event handlers
    const handleCompanyChange = (e) => setToggleCompany(e.target.value);


    return (
        <>

            <div title="Company Filter" className="field-cont-div">
                <CircularGrid />
                <select
                    onChange={handleCompanyChange}
                    value={toggleCompany || 'null'}
                    className="attendance-input-field width-15vw date-field"
                >
                    <option value="null">Select Sub Company</option>
                    {siteSubCompanyList.map((company) => (
                        <option key={company.id} value={company.id}>
                            {company.title}
                        </option>
                    ))}
                </select>
                <hr className="field-cont-hr" />
            </div>
        </>
    )
}



const Projects = ({ toggleProject, setToggleProject }) => {

    const [projectList, setProjectList] = useState([]);

    // Fetch projects list when the component mounts
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                // const response = await axios.get(
                //     sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
                //         `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                //         : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
                //             `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                //             : `${BASE_URL}/siteproject/project/`
                // );

                const response = await axios.get(
                    sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
                        `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                        : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
                            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                            : `${BASE_URL}/siteproject/projectfilterbysubcompany/null/`
                );
                const sortedProjects = sortProjects(response.data, 'site_prcode');
                setProjectList(sortedProjects);
            } catch (error) {
                console.error('Error fetching sub-companies:', error);
            }
        };

        fetchProjects();
    }, []);


    // Event handlers
    const handleProjectChange = (e) => setToggleProject(e.target.value);


    return (
        <>
            <div title="Project Dropdown " className="field-cont-div">
                <CircularGrid />
                <select
                    onChange={handleProjectChange}
                    value={toggleProject || 'null'}
                    className={`attendance-input-field width-10vw`}
                >
                    <option value="null">Project</option>
                    {projectList
                        // ?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true }))
                        .map((i, index) => (
                            <option key={index} value={i.id}>
                                {i.site_prcode}-{i.project_short_name}
                            </option>
                        ))}
                </select>
                <hr className="field-cont-hr" />
            </div>
        </>
    )
}



export { LetterStatus, LetterType, SubCompany, Projects }