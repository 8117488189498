import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FRONTEND_URL } from "../config/axios";
import Applymodels from "../pages/Applymodels";
import { useNavigate } from "react-router-dom";
import { SearchBig } from "../components/AllSvg";
import { routingData } from "../routes/routing";

const Dashboardnavbar = ({ name, url }) => {
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);



  const flattenRoutes = (routes) => {
    let flatRoutes = [];

    const flatten = (routeList, parentPath = "") => {
      routeList.forEach((route) => {
        const fullPath = parentPath ? `${parentPath}/${route.path}` : route.path;
        // Remove dynamic segments (e.g., /:id or /:email)
        if (!fullPath.includes("/:")) {  // Exclude routes with dynamic segments
          // Clean the path by removing the 'admin/' prefix
          const cleanedPath = fullPath.replace(/^admin\//, '');  // Remove "admin/" from the beginning
          flatRoutes.push({ ...route, path: cleanedPath });
        }

        // Recursively flatten nested routes
        if (route.routes && Array.isArray(route.routes)) {
          flatten(route.routes, fullPath); // Handle nested routes recursively
        }
      });
    };

    flatten(routes);
    return flatRoutes;
  };


  // Effect to fetch available routes based on user role
  useEffect(() => {
    const userRole = sessionStorage.getItem("role") || "";
    const roleData = routingData.find((route) => route.role === userRole);

    if (roleData) {
      // Flatten nested routes if they exist
      if (roleData.nestedRoutes && Array.isArray(roleData.nestedRoutes)) {
        const flattenedRoutes = flattenRoutes(roleData.nestedRoutes);
        setAvailableRoutes(flattenedRoutes);
      } else {
        setAvailableRoutes([]); // No routes available if nestedRoutes is empty
      }
    } else {
      setAvailableRoutes([]); // No routes available if roleData not found
    }
  }, []);


  console.log(availableRoutes)

  // Handle input change for search
  const handleSearchChange = (e) => {
    const input = e.target.value.trim();
    setSearchInput(input);

    if (input) {
      // Filter the routes by the user input
      const filtered = availableRoutes.filter((route) =>
        (route.label || route.path)
          .toLowerCase()
          .includes(input.toLowerCase())
      );

      console.log(filtered)

      setSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };


  console.log(suggestions)

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");

  console.log(urlParts[3])

  // Handle suggestion click (navigate to selected route)
  const handleSuggestionClick = (path) => {
    setSearchInput("");
    setSuggestions([]);
    setShowSuggestions(false); // Close suggestions after selecting one
    const staticPath = path.split("/:")[0]; // Remove dynamic parameters
    navigate(`/${urlParts[3]}/${staticPath}`); // Navigate to the selected route
  };

  // Close suggestions if clicked outside
  const handleClickOutside = (e) => {
    if (!e.target.closest(".navbar-search")) {
      setShowSuggestions(false);
    }
  };

  // Listen for click outside to close suggestions
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const role = sessionStorage.getItem('sitemanagement_role');

  return (
    <div className="navbar-section-main">
      <div className="navbar-margin-left">
        <div className="navbar-subheading font-weight500 font-size-text">
          Pages / {url}
        </div>
        <div className="navbar-heading font-weight700">{name}</div>
      </div>
      <div className="navbar-subsection navbar-margin-right">
        {role !== "CertificationAccess" &&
          (
            <div className="applymodel">
              <Applymodels />
            </div>
          )
        }


        <div className="navbar-subsection-b">
          <div className="navbar-search">
            <div>
              <SearchBig />
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400 font-size-text"
                value={searchInput}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          {showSuggestions && (
            <div className="search-suggestions-popup">
              {suggestions.length > 0 ? (
                suggestions.map((route) => (
                  <div
                    key={route.path}
                    className="search-suggestion-item"
                    onClick={() => handleSuggestionClick(route.path)}
                  >
                    {route.label || route.path}
                  </div>
                ))
              ) : (
                <div className="search-suggestion-item no-results">No results found</div>
              )}
            </div>
          )}
          <div className="navbar-logo-mobile">
            <button
              title="logout"
              className="navbar-svg"
              onClick={() => {
                toast.info("Logging Out...");
                sessionStorage.clear();
                window.location = FRONTEND_URL;
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi  bi-box-arrow-right" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" fill="#A3AED0" />
                <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" fill="#A3AED0" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Dashboardnavbar;
