import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";
import { ToastContainer, toast } from 'react-toastify';
import { DeleteDustbin, Attachment } from "../../AllSvg";
import { handleErrorToast } from "../../CustomFunctions";
import { UpdateBill, ViewBill } from "../../ImprestManagement/ExpenseSheet/ExpenseSlipComponents";

const ExpenseDetailDelete = ({
  id,
  getExpenses,
  date,
  getSpecificExpenses,
}) => {
  const [show, setShow] = useState(false);

  // console.log(id)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (item_name) => {
    item_name.preventDefault();

    try {
      console.log("Delete Expense Detail Api");
      let res = await axios.delete(
        `${BASE_URL}/siteproject/expensedetailsdelete/${id.id}/`
      );

      if (res.status === 200) {
        await getExpenses(date);
        getSpecificExpenses();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitDelete}>
        <DeleteDustbin />
      </button>

      {/* <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          
            <div className="button-models">
              
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
         
        </Modal.Body>
      </Modal> */}
    </>
  );
};

const ExpenseDetailsAdd = ({ i, totall, expansee, status, utilize, remain }) => {
  // ***********Model Code Api Start ***********

  const [initiated, setInitiated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      item_name: "",
      expense_type: "",
      item_amount: "",
      bill: "",
    })
    setShow(false)
  };
  const handleShow = () => {
    setShow(true);
    getExpenses(formdate);
    getSpecificExpenses();
    getInitiation(i);
  };

  // **********Table Visibility Api Start ***********

  const [showAdd, setShowAdd] = useState(false);
  const handleClosee = () => {
    setFormData({
      item_name: "",
      expense_type: "",
      item_amount: "",
      bill: "",
    });
    handleClear();
    setShowAdd(false)
  };
  const handleShoww = () => setShowAdd(true);

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formatttedDatee = `${day.toString().padStart(2, "0")}`;

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const [monthh, setMonthh] = useState(`${month}`);

  const formatedmonth = `${monthh.toString().padStart(2, "0")}`;
  const [yearr, setYearr] = useState(`${year}`);

  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

  const [selecteddate, setSelecteddate] = useState(`${formatttedDatee}`);

  const handleselectvalue = (date) => {
    setSelecteddate(date);
    const formatdate = `${yearr}-${monthh}-${date}`;
    getExpenses(formatdate);
  };

  const formdate = `${yearr}-${monthh}-${selecteddate}`;

  // ! **********Expense Detail by Id Get Api Start ***********

  const [updatedlist, setUpdatedList] = useState([]);

  const [sum, setSum] = useState(0);
  const [appsum, setAppSum] = useState(0);

  const getSpecificExpenses = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/expensedetailsbyexpense/${i.id}/`
      );

      const summ = res.data.reduce(
        (acc, item) => acc + parseFloat(item.item_amount),
        0
      );
      const appsumm = res.data.reduce(
        (acc, item) => acc + parseFloat(item.bill_approved_amt),
        0
      );
      setSum(summ);
      setAppSum(appsumm);
      const uniqueDatesSet = new Set();
      res.data.forEach((item) => {
        uniqueDatesSet.add(item.date);
      });
      setUpdatedList(Array.from(uniqueDatesSet));
    } catch (err) { }
  };

  // ! **********Expense Detail by Date Get Api Start ***********

  const [expensenames, setExpensenames] = useState([]);
  const getExpenses = async (formdate) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/expensedetailsbydate/${formdate}/${i.id}/`
      );
      setExpensenames(res.data);
    } catch (err) { }
  };

  const getInitiation = async (i) => {
    try {
      if (i.submit_for_approval || i.rh_leave_status === "approved") {
        setInitiated(true);
      }
    } catch (err) { }
  };

  // ! **********Expense Detail Add Api Start ***********

  const [formData, setFormData] = useState({
    item_name: "",
    expense_type: "",
    item_amount: "",
    bill: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["item_name", "item_amount", "expense_type", "bill"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setInputState({

  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "expense_type") {
      let maxAmount = 0;

      // Set max value based on selected expense_type
      switch (value) {
        case "Stationary":
          maxAmount = i.approved_stationary_item;
          break;
        case "Electronic":
          maxAmount = i.approved_electronic_item;
          break;
        case "Grocery":
          maxAmount = i.approved_grocery;
          break;
        default:
          // For "other" details
          const selectedOther = i.other_details?.find(other => other.other_name === value);
          if (selectedOther) {
            maxAmount = selectedOther.approved_other;
          }
          break;
      }

      setFormData({
        ...formData,
        [name]: value,  // Update the selected expense_type
        max_amount: maxAmount,  // Store the max value based on the selected type
      });
    } else if (name === "item_amount") {
      const maxAmount = formData.max_amount || 0;

      // Always allow the user to input any value, just provide feedback if it's too high
      setInputState({
        ...inputState,
        [name]: value.trim() ? "success" : "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });

      if (parseFloat(value) > maxAmount) {
        setErrors({
          ...errors,
          [name]: `Amount should be ≤ ${maxAmount} (imprest limit)`,
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });
      }

    } else {
      setInputState({
        ...inputState,
        [name]: value.trim() ? "success" : "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };


  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "expense_type") {
  //     let maxAmount = 0;

  //     // Set max value based on selected expense_type
  //     switch (value) {
  //       case "transportation":
  //         maxAmount = i.approved_stationary_item;
  //         break;
  //       case "accomodation":
  //         maxAmount = i.approved_electronic_item;
  //         break;
  //       case "fooding":
  //         maxAmount = i.approved_grocery;
  //         break;
  //       default:
  //         // For "other" details
  //         const selectedOther = i.other_details?.find(other => other.other_name === value);
  //         if (selectedOther) {
  //           maxAmount = selectedOther.approved_other;
  //         }
  //         break;
  //     }

  //     setFormData({
  //       ...formData,
  //       [name]: value,  // Update the selected expense_type
  //       max_amount: maxAmount,  // Store the max value based on the selected type
  //     });
  //   } else if (name === "item_amount") {
  //     // Check if the value is less than or equal to the max amount
  //     const maxAmount = formData.max_amount || 0;

  //     if (parseFloat(value) <= maxAmount) {
  //       setInputState({
  //         ...inputState,
  //         [name]: value.trim() ? "success" : "",
  //       });

  //       setFormData({
  //         ...formData,
  //         [name]: value,
  //       });

  //       setErrors({
  //         ...errors,
  //         [name]: "",
  //       });
  //     } else {
  //       // Handle case where the input value exceeds the allowed max amount
  //       setErrors({
  //         ...errors,
  //         [name]: `Amount must be less than or equal to ${maxAmount}`,
  //       });
  //     }
  //   } else {
  //     // For textarea or other fields without validation
  //     setInputState({
  //       ...inputState,
  //       [name]: value.trim() ? "success" : "",
  //     });

  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //     setErrors({
  //       ...errors,
  //       [name]: "",
  //     });
  //   }
  // };


  const [fileName, setFileName] = useState("");
  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      setFormData({ ...formData, [name]: file, });
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const handleClear = () => {
    setFormData(prevState => ({
      ...prevState,
      bill: ""
    }));
    setFileName("");
  }

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleExpenseSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataa = new FormData();
      formDataa.append("item_name", formData.item_name);
      formDataa.append("item_amount", formData.item_amount);
      formDataa.append("expense_type", formData.expense_type);
      formDataa.append("date", `${yearr}-${monthh}-${selecteddate}`);

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.bill;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });
      formDataa.append("bill", customFile);
      formDataa.append("expense", i.id);

      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/siteproject/expensedetailsadd/`,
          formDataa,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          await getExpenses(formdate);
          await getSpecificExpenses();
          await handleClosee();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" title={`Amount:-\nUtilized: ${utilize !== null ? utilize : sum} \nRemaining: ${remain !== null ? remain : Number(totall) - Number(sum)} `} onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      {/* <Modal show={show} onHide={handleClose} dialogClassName="half-modal width-50vw"> */}
      <Modal show={show} dialogClassName="half-modal width-50vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="task-Tab-heading   font-weight500    font-size-heading">Expense Details: </h6>
              <div className="flex-row" style={{ justifyContent: "center" }}>
                <div>
                  <select
                    className="attendance-input-field width-10vw   date-field"
                    type="text"
                    value={monthh}
                    onChange={(e) => setMonthh(e.target.value)}
                  >
                    {/* <option value="">All</option> */}
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div>
                  <input
                    type="number"
                    maxLength={4}
                    value={yearr}
                    onChange={(e) => setYearr(e.target.value)}
                    className="attendance-input-field width-5vw   date-field"
                  />
                </div>
              </div>

              <div className="expense-circle-outer">
                <div className="expense-circle-outer">
                  {datees.map((data) => {
                    const date = `${yearr}-${formatedmonth}-${data.date}`;
                    const selectedformatdate = `${yearr}-${formatedmonth}-${selecteddate}`;
                    return (
                      <button
                        className={`expsense-circle ${date === formattedDate
                          ? "expsense-circle-today"
                          : date === selectedformatdate
                            ? "expsense-circle-selected"
                            : updatedlist?.includes(date)
                              ? "expsense-circle-used"
                              : "expsense-circle-general"
                          }`}
                        key={data.date}
                        onClick={() => handleselectvalue(data.date)}
                      >
                        {data.date}
                      </button>
                    );
                  })}
                </div>
                <hr className="line" />
                <div className="amount-status">
                  <div className="align-center">
                    <div className="flex-row">
                      <div>
                        <h6 className="utilize   font-weight500    font-size-text ">Spent Amount</h6>
                        <p className="utilize font-weight500 align-center" style={{ color: "red" }}>
                          {sum}
                        </p>
                      </div>
                      <div>
                        <hr className="field-cont-hr" />
                      </div>
                      <div>
                        <h6 className="utilize   font-weight500    font-size-text ">Approved Amount</h6>
                        <p className="utilize font-weight500 align-center" style={{ color: "red" }}>
                          {appsum ? appsum : '-'}
                        </p>
                      </div>
                    </div>
                    <h6 className="utilize   font-weight500    font-size-text">Utilize</h6>
                  </div>
                  <div className="align-center">
                    <div className="flex-row">
                      <div>
                        <h6 className="remaining  font-weight500    font-size-text ">After Spent</h6>
                        <p className=" remaining font-weight500 align-center" style={{ color: "#2576bc" }}>
                          {Number(totall) - Number(sum)}
                        </p>
                      </div>
                      <div>
                        <hr className="field-cont-hr" />
                      </div>
                      <div>
                        <h6 className="utilize   font-weight500  font-size-text ">After Approval</h6>
                        <p className=" utilize font-weight500 align-center" style={{ color: "#2576bc" }}>
                          {appsum ? (Number(totall) - Number(appsum)) : '-'}
                        </p>
                      </div>
                    </div>
                    <h6 className="remaining  font-weight500 font-size-text ">Remaining</h6>
                  </div>
                </div>
                <hr className="line" />

                <table className="table-css">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Item</th>
                      <th className="align-center">Type</th>
                      <th className="align-center">Amount</th>
                      <th className="align-center">Attached Bill</th>
                      <th className="align-center">Approved Amount</th>
                      <th className="align-right width-5vw">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expensenames.map((j, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{j.item_name}</td>
                          <td className="align-center form-text-trasformation-uppercase">
                            {j.expense_type ? j.expense_type : "-"}
                          </td>
                          <td className="align-center" title={`TL Approved Amount:${j.authority_approved_amt}`}>{j.item_amount}</td>
                          <td className="align-center">
                            {j.bill ? (
                              i.partially_change ? <>
                                Last Submitted:<ViewBill i={j} />
                              </> :
                                <>
                                  <ViewBill i={j} />
                                </>
                            ) : (
                              "Bill Not Attached"
                            )}
                          </td>
                          <td className="align-center"> {
                            j.bill_approved_amt === "0" && j.bill_approved === "rejected" ?
                              <span title={`${j.bill_amount_change_reason ? `Amount Change Reason: \n${j.bill_amount_change_reason}\n` : ""}${j.bill_approved === "rejected" ? `Bill Reject Reason: \n${j.bill_rejection_reason}` : ""}`} style={{ textDecoration: 'line-through' }}>{j.authority_approved_amt}</span>
                              : j.bill_approved_amt
                          }</td>
                          {/* <td className="align-center">{j.authority_approved_amt}</td> */}
                          <td className="align-center width-5vw">
                            {
                              i.submit_for_approval ? (
                                i.partially_change && j.bill_approved === "rejected" && j.authority_approved_amt !== "0" ?
                                  <>Bill:<span request-status={j.bill_approved} className="request-status align-center form-text-trasformation-uppercase">
                                    {j.bill_approved}</span>
                                    <br />
                                    Upload New:
                                    <UpdateBill
                                      i={j}
                                      date={formdate}
                                      getSpecificExpenses={getSpecificExpenses}
                                      getExpenses={getExpenses}
                                    />
                                  </>
                                  :
                                  <>Bill Check:<span request-status={j.bill_approved} className="request-status align-center form-text-trasformation-uppercase">
                                    <br />{j.bill_approved}</span>
                                  </>
                              ) : (
                                <>
                                  <ExpenseDetailDelete
                                    id={j}
                                    getExpenses={getExpenses}
                                    date={formdate}
                                    getSpecificExpenses={getSpecificExpenses}
                                  />
                                  <br />
                                  Bill:<span request-status={j.bill_approved} className="request-status align-center form-text-trasformation-uppercase">
                                    {j.bill_approved}</span>
                                </>
                              )
                            }
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              {i.submit_for_approval ? null : showAdd === true ? (
                <>
                  <div className="expense-req">
                    <div className="flex-column">
                      <label
                        htmlFor="item_name"
                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                      >
                        Item
                      </label>
                      <input
                        id="item_name"
                        type="text"
                        name="item_name"
                        placeholder="Item"
                        onChange={handleInputChange}
                        value={formData.item_name}
                        className={`form-inputs ${errors.item_name
                          ? "error"
                          : inputState.item_name
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.item_name && (
                        <span className="error-message font-size-text ">{errors.item_name}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label
                        htmlFor="expense_type"
                        className="form-labels  font-weight500    announce-date font-size-heading"
                      >
                        Type
                      </label>

                      <select
                        id="expense_type"
                        name="expense_type"
                        // className="attendance-input-field width-5vw   date-field"
                        // type="text"
                        value={formData.expense_type}
                        onChange={handleInputChange}
                        className={` form-inputs ${errors.expense_type
                          ? "error"
                          : inputState.expense_type
                            ? "success"
                            : ""
                          }`}
                      >
                        <option value="">Type</option>
                        <option value="Stationary">Stationary</option>
                        <option value="Electronic">Electronic</option>
                        <option value="Grocery">Grocery</option>
                        {i.other_details?.length > 0
                          ?
                          i.other_details?.map((other, index) => (
                            <option key={index} value={`${other.other_name}`} className='form-text-trasformation-uppercase'>{other.other_name}</option>
                          ))
                          : null
                        }
                      </select>
                      {errors.expense_type && (
                        <span className="error-message font-size-text ">{errors.expense_type}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label
                        htmlFor="item_amount"
                        className="form-labels justify-center font-weight500 announce-date font-weight400  font-size-heading"
                      >
                        Amount
                      </label>
                      <input
                        id="item_amount"
                        min={0}
                        title={
                          formData.expense_type === "transportation"
                            ? `Max amount for transportation is ${i.approved_stationary_item}`
                            : formData.expense_type === "accomodation"
                              ? `Max amount for accommodation is ${i.approved_electronic_item}`
                              : formData.expense_type === "fooding"
                                ? `Max amount for fooding is ${i.approved_grocery}`
                                : `Max amount for ${formData.expense_type} is ${i.other_details?.find(other => other.other_name === formData.expense_type)?.approved_other || 0}`
                        }
                        max={
                          formData.expense_type === "transportation"
                            ? i.approved_stationary_item
                            : formData.expense_type === "accomodation"
                              ? i.approved_electronic_item
                              : formData.expense_type === "fooding"
                                ? i.approved_grocery
                                : i.other_details?.find(other => other.other_name === formData.expense_type)?.approved_other || 0
                        }
                        type="number"
                        name="item_amount"
                        placeholder="Amount"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleInputChange}
                        value={formData.item_amount}
                        className={`form-inputs ${errors.item_amount
                          ? "error"
                          : inputState.item_amount
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.item_amount && (
                        <span className="error-message font-size-text ">
                          {errors.item_amount}
                        </span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label
                        htmlFor="bill"
                        // className="form-labels announce-date font-weight500  form-inputss"
                        className="form-labels   font-weight500    announce-date font-size-heading"
                        style={{ width: '8vw' }}
                      >
                        Attach Bill
                      </label>
                      <label
                        title={`${fileName && `${fileName}`}`}
                        htmlFor="file-upload"
                        className={`custom-doc-upload ${errors.bill
                          ? "error"
                          : inputState.bill
                            ? "success"
                            : ""
                          }`}
                      >
                        <div htmlFor="file-upload" className="">
                          <div className={`align-center pdf-input  ${errors.bill ? 'error' : ''}`}>
                            <Attachment />
                          </div>
                        </div>
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        name="bill"
                        onChange={handleFileChange}
                        style={{ display: "none", position: "relative" }}
                        className="width-5vw"
                      />
                      <div className="flex-row justify-evenly">
                        <div>
                          <br />
                          {fileName && <p className="width-10vw">{fileName}</p>}
                        </div>
                      </div>
                      {errors.bill && (
                        <span className="error-message font-size-text ">{errors.bill}</span>
                      )}
                    </div>


                    <div className="flex-column">
                      <label
                        htmlFor="bill"
                        className="announce-date font-weight400  form-inputss"
                      ></label>
                      <div className="flex-row action-div">
                        <button className=""
                          onClick={() => {
                            handleClosee();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15 9L9 15"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 9L15 15"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button
                          className="form-inputs-submit width-5vw"
                          onClick={handleExpenseSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <button
                    title="Add Expense Details"
                    onClick={handleShoww}
                    className="jd-heading-main font-weight600 -right-button"
                    style={{ float: "right" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_450_2151)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                          fill="#2576BC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_450_2151">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <br />
                </div>
              )}

              {/* {selecteddate} */}
              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

export default ExpenseDetailsAdd;

