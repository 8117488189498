import React, { useState, useEffect } from "react";


const SkeletonLoader = ({ currentIndex, filteredDataLength }) => {
    const [animationStates, setAnimationStates] = useState([true, true, true, true, true]);

    useEffect(() => {
        const timeoutIds = [];

        // Fade out rows progressively based on currentIndex
        for (let i = 0; i < 5; i++) {
            if (currentIndex > i) {
                timeoutIds.push(
                    setTimeout(() => {
                        setAnimationStates((prev) => {
                            const newStates = [...prev];
                            newStates[i] = false;
                            return newStates;
                        });
                    }, 1000 * (i + 1))
                );
            }
        }

        // Cleanup timeouts on unmount or when currentIndex changes
        return () => timeoutIds.forEach((id) => clearTimeout(id));
    }, [currentIndex]);

    const skeletonRows = Array(5).fill(
        <div className="space-wrapper">
            <div className="loader-wrapper" style={{ width: "90%", height: 6 }}></div>
            <div className="loader-wrapper" style={{ width: "65%", height: 6 }}></div>
            <div className="loader-wrapper" style={{ width: "50%", height: 6 }}></div>
            <div className="loader-wrapper" style={{ width: "35%", height: 6 }}></div>
        </div>
    );

    return (
        <div className="skeleton-wrapper">
            {skeletonRows.map((row, index) => (
                <div
                    key={index}
                    className={`skeleton-row ${animationStates[index] ? "loading" : "loaded"}`}
                >
                    {row}
                </div>
            ))}
        </div>
    );
};

export default SkeletonLoader;
