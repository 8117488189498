import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import axios from "axios";

import { BASE_URL } from "../config/axios";


import { handleErrorToast, sortProjects } from "../components/CustomFunctions";
import { AddCircular, AddProjectDetails, AddTrackRecordDetails } from "../components/SiteManagement/ProjectDetailsCrudButtons";
import { AddSiteEmployee, AddSiteDepartment, AddSiteSubcompanys } from "../components/Wfm/WorkforceCrudButton";
import { AddVehicleBillingDetails, AddVehicleModel } from "../components/SiteManagement/VehicleManagement/VehicleManagementComponenets";
import { AddSiteInventory } from "../components/SiteManagement/InventoryManagement/InventoryManagementComponent";
import usePermission from "../config/permissions";



const AddModel = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    // await getData()
    getData()
    setShow(true)
  };

  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const siteRole = sessionStorage.getItem("sitemanagement_role");
  const { projectID } = usePermission();
  const [project, setProject] = useState(projectID ? projectID : "null");

  const [clientList, setClientList] = useState([]);
  const [siteDepartmentList, setSiteDepartmentList] = useState([]);
  const [siteProjectList, setSiteProjectList] = useState([]);
  const [sectorData, setSectorData] = useState([]);



  const getData = async () => {


    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      // const res = await axios.get(`${BASE_URL}/siteproject/projectdashboard/`);
      const res = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' || sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
          `${BASE_URL}/siteproject/projectdashboard/contractor/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : `${BASE_URL}/siteproject/projectdashboard/contractor/`
      );
      const cli = await axios.get(`${BASE_URL}/siteproject/project-clients/`);

      const des = await axios.get(`${BASE_URL}/siteproject/designation/`);
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );

      const desi = await axios.get(
        `${BASE_URL}/siteproject/designationfilterbysubcompanycount/null/active/`
      );

      const sortedProjectDashboard = sortProjects(res.data, 'site_prcode');
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      const circular = await axios.get(`${BASE_URL}/siteproject/circularupload/`);





      setProjectsList(sortedProjectDashboard);
      setSiteSubCompanyList(sub.data);
      setClientList(cli.data);
      setSiteDepartmentList(des.data);
      setSiteProjectList(sortedProjects);
      setSectorData(circular.data);
      setSiteDepartmentList(desi.data);




    } catch (err) {
      // handleErrorToast(err);
    } finally {

    }
  }



  return (
    <>
      <button
        className="model-button btn-trans  font-weight500    model-button-leave  font-weight500    font-size-heading"
        onClick={handleShow}
        style={{ padding: "10px 20px" }}
      >
        Add
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Body>
          <div>
            <div>
              <div className="bloc-tabs-apply4">
                <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Add Options:</Modal.Title> </h1>
              </div>
            </div>

            <div className="content-tabs ">
              <div className="align-center">
                {siteRole === "SiteManagement" || siteRole === "Hr" || siteRole === "Account" ?
                  "" :
                  <AddProjectDetails
                    clientList={clientList}
                    projectList={projectsList}
                    getProjects={getData}
                    siteSubCompanyList={siteSubCompanyList}
                  />
                }

                <AddCircular
                  getSiteCirculars={getData}
                  sectorList={sectorData} />

                {siteRole === "Account" ? "" :

                  <AddSiteEmployee
                    getSiteEmployee={getData}
                    siteProjectList={siteProjectList}
                    siteSubCompanyList={siteSubCompanyList}
                    siteDepartmentList={siteDepartmentList}
                  />
                }
                {
                  siteRole === "Hr" || siteRole === "BD" || siteRole === "Account" ? "" :
                    <AddTrackRecordDetails
                      getProjects={getData}
                      projectList={projectsList} projectId={project} title={"Letters"} />

                }

                {siteRole === "Hr" || siteRole === "BD" ? "" :

                  <>
                    {siteRole === "SiteManagement" ? (<AddVehicleModel getVehicle={getData} projectid={projectID} />)
                      : (<AddVehicleModel getVehicle={getData} />)
                    }
                  </>
                }
                {siteRole === "BD" || siteRole === "Account" ? "" :
                  <>
                    {siteRole === "SiteManagement" ? (<AddSiteInventory getInventory={getData} />)
                      : (<AddSiteInventory getInventory={getData} />)
                    }
                  </>
                }

                {siteRole === "SiteManagement" ?
                  "" :
                  <AddSiteSubcompanys getSiteSubcompanies={getData} />
                }
                {siteRole === "SiteManagement" || siteRole === "Hr" || siteRole === "BD" ?
                  "" :
                  <AddVehicleBillingDetails getVehicleBillingDetail={getData} />

                }





                <AddSiteDepartment
                  getSiteDepartment={getData}
                  siteDepartmentList={siteDepartmentList}
                  siteSubCompanyList={siteSubCompanyList}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddModel;
