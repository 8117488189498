import React from 'react'

const SearchSmall = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
            >
                <circle
                    cx="5"
                    cy="5"
                    r="4.3"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                />
                <line
                    x1="10.0101"
                    y1="11"
                    x2="8"
                    y2="8.98995"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                />
            </svg>
        </>
    )
}

const SearchBig = ({ color }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
            >
                <circle
                    cx="5"
                    cy="5"
                    r="4.3"
                    stroke={color ? color : "#707070"}
                    stroke-width="1.4"
                />
                <line
                    x1="10.0101"
                    y1="11"
                    x2="8"
                    y2="8.98995"
                    stroke={color ? color : "#707070"}
                    stroke-width="1.4"
                    stroke-linecap="round"
                />
            </svg>
        </>
    )
}

const Arrow = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 48 48"
                id="arrow"
            >
                <path
                    fill="none"
                    d="M0 0h48v48H0z"
                />
                <path
                    d="m24 8-2.83 2.83L32.34 22H8v4h24.34L21.17 37.17 24 40l16-16z"
                />
            </svg>
        </>
    )
}

const ArrowBullet = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 18 14" id="Arrow">
                <g fill="none" fill-rule="evenodd" stroke="#2476bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                    <path d="M1 7h16M11 1l6 6-6 6" fill="#2476bd" />
                </g>
            </svg>
        </>
    )
}

const DropdownArrow = () => {
    return (
        <>
            <div className="form-group-selection-arrow">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
            </div>
        </>
    )
}

const DropdownArrowOption = () => {
    return (
        <>
            <div >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
            </div>
        </>
    )
}

const DropdownArrowClosed = () => {
    return (
        <>
            <div >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    id="right-arrow"
                >
                    <g>
                        <g>
                            <rect
                                opacity="0" transform="rotate(180 12 12)"
                            />
                            <path
                                d="M10.46 18a2.23 2.23 0 0 1-.91-.2 1.76 1.76 0 0 1-1.05-1.59V7.79A1.76 1.76 0 0 1 9.55 6.2a2.1 2.1 0 0 1 2.21.26l5.1 4.21a1.7 1.7 0 0 1 0 2.66l-5.1 4.21a2.06 2.06 0 0 1-1.3.46z"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </>
    )
}

const Collapse = ({ color }) => {
    return (
        <>
            <div >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    viewBox="0 0 24 24"
                    id="collapse">
                    <path d="M18,11H14a1,1,0,0,1-1-1V6a1,1,0,0,1,2,0V9h3a1,1,0,0,1,0,2Z" />
                    <path d="M14 11a1 1 0 0 1-.71-1.71l5-5a1 1 0 0 1 1.41 1.41l-5 5A1 1 0 0 1 14 11zM10 11H6A1 1 0 0 1 6 9H9V6a1 1 0 0 1 2 0v4A1 1 0 0 1 10 11z" />
                    <path d="M10 11a1 1 0 0 1-.71-.29l-5-5A1 1 0 0 1 5.71 4.29l5 5A1 1 0 0 1 10 11zM10 19a1 1 0 0 1-1-1V15H6a1 1 0 0 1 0-2h4a1 1 0 0 1 1 1v4A1 1 0 0 1 10 19z" />
                    <path d="M5 20a1 1 0 0 1-.71-1.71l5-5a1 1 0 0 1 1.41 1.41l-5 5A1 1 0 0 1 5 20zM14 19a1 1 0 0 1-1-1V14a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2H15v3A1 1 0 0 1 14 19z" />
                    <path d="M19,20a1,1,0,0,1-.71-.29l-5-5a1,1,0,0,1,1.41-1.41l5,5A1,1,0,0,1,19,20Z" />
                </svg>
            </div>
        </>
    )
}

const Expand = ({ color }) => {
    return (
        <>
            <div >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    viewBox="0 0 24 24"
                    id="expand"
                    fill={color ? color : "black"}
                >
                    <path d="M9 20H5a1 1 0 0 1-1-1V15a1 1 0 0 1 2 0v3H9a1 1 0 0 1 0 2zM14 11a1 1 0 0 1-.71-1.71l5-5a1 1 0 0 1 1.41 1.41l-5 5A1 1 0 0 1 14 11z"></path>
                    <path d="M19 10a1 1 0 0 1-1-1V6H15a1 1 0 0 1 0-2h4a1 1 0 0 1 1 1V9A1 1 0 0 1 19 10zM5 20a1 1 0 0 1-.71-1.71l5-5a1 1 0 0 1 1.41 1.41l-5 5A1 1 0 0 1 5 20zM5 10A1 1 0 0 1 4 9V5A1 1 0 0 1 5 4H9A1 1 0 0 1 9 6H6V9A1 1 0 0 1 5 10zM19 20a1 1 0 0 1-.71-.29l-5-5a1 1 0 0 1 1.41-1.41l5 5A1 1 0 0 1 19 20z"></path>
                    <path d="M19 20H15a1 1 0 0 1 0-2h3V15a1 1 0 0 1 2 0v4A1 1 0 0 1 19 20zM10 11a1 1 0 0 1-.71-.29l-5-5A1 1 0 0 1 5.71 4.29l5 5A1 1 0 0 1 10 11z"></path>
                </svg>
            </div>
        </>
    )
}

const AddwithWhiteCircle = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <g clip-path="url(#clip0_1415_3177)">
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1415_3177">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

const AddwithBlueCircle = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <g clip-path="url(#clip0_1415_3177)">
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                        fill="#2576BC"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1415_3177">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

const CrosswithGreyCircle = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#707070"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15 9L9 15"
                    stroke="#707070"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9 9L15 15"
                    stroke="#707070"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}

const CheckGreen = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
            >
                <path
                    d="M15 4.5L6.75 12.75L3 9"
                    stroke="#145650"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}

const WarnYellow = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 48 48"
                d="exclamation">
                <path
                    fill="#F7941E"
                    fill-rule="evenodd"
                    d="M24 10C25.1046 10 26 10.8954 26 12L26 30C26 31.1046 25.1046 32 24 32 22.8954 32 22 31.1046 22 30L22 12C22 10.8954 22.8954 10 24 10zM24 38C25.1046 38 26 37.1046 26 36 26 34.8954 25.1046 34 24 34 22.8954 34 22 34.8954 22 36 22 37.1046 22.8954 38 24 38z"
                    clip-rule="evenodd">
                </path>
            </svg>
        </>
    )
}

const CrossRed = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
            >
                <path
                    d="M13.5 4.5L4.5 13.5"
                    stroke="#F72B50"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M4.5 4.5L13.5 13.5"
                    stroke="#F72B50"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}

const UpdatePencil = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
            >
                <path
                    d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                    fill="#145650"
                />
            </svg>
        </>
    )
}

const UpdatePencilWhite = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                fill='white'
                id="edit">
                <path
                    d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z" />
                <path
                    d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z" />
            </svg>
        </>
    )
}

const DeleteDustbin = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
            >
                <path
                    d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                    fill="#F72B50"
                />
            </svg>
        </>
    )
}

const Location = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 17 17" fill="none">
                <g clip-path="url(#clip0_650_3351)">
                    <mask id="mask0_650_3351" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
                        <path d="M17 0H0V17H17V0Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_650_3351)">
                        <path d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z" fill="#707070" />
                        <path d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z" fill="#707070" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_650_3351">
                        <rect width="17" height="17" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

const Eye = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 30 30"
                fill="none"
            >
                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                <path
                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                    fill="#2576BC"
                />
            </svg>
        </>
    )
}

const BlueEye = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 30 30"
                fill="none"
            >
                {/* <circle cx="15" cy="15" r="15" fill="#F6F7F9" /> */}
                <path
                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                    fill="#2576BC"
                />
            </svg>
        </>
    )
}

const CloseEye = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="-8 -5 30 30"
                fill="none"
            >
                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />

                <path d="M18.521 1.478a1 1 0 0 0-1.414 0L1.48 17.107a1 1 0 1 0 1.414 1.414L18.52 2.892a1 1 0 0 0 0-1.414zM3.108 13.498l2.56-2.56A4.18 4.18 0 0 1 5.555 10c0-2.379 1.99-4.309 4.445-4.309.286 0 .564.032.835.082l1.203-1.202A12.645 12.645 0 0 0 10 4.401C3.44 4.4 0 9.231 0 10c0 .423 1.057 2.09 3.108 3.497zm13.787-6.993l-2.562 2.56c.069.302.111.613.111.935 0 2.379-1.989 4.307-4.444 4.307-.284 0-.56-.032-.829-.081l-1.204 1.203c.642.104 1.316.17 2.033.17 6.56 0 10-4.833 10-5.599 0-.424-1.056-2.09-3.105-3.495z"
                    fill="#2576BC"
                ></path>
            </svg>
        </>
    )
}

const EyeBottonInput = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 10" fill="none">
                <path d="M15.4569 4.7975C15.435 4.74813 14.9056 3.57375 13.7287 2.39687C12.1606 0.82875 10.18 0 7.99999 0C5.81999 0 3.83937 0.82875 2.27124 2.39687C1.09437 3.57375 0.562494 4.75 0.543119 4.7975C0.51469 4.86144 0.5 4.93064 0.5 5.00062C0.5 5.0706 0.51469 5.1398 0.543119 5.20375C0.564994 5.25312 1.09437 6.42688 2.27124 7.60375C3.83937 9.17125 5.81999 10 7.99999 10C10.18 10 12.1606 9.17125 13.7287 7.60375C14.9056 6.42688 15.435 5.25312 15.4569 5.20375C15.4853 5.1398 15.5 5.0706 15.5 5.00062C15.5 4.93064 15.4853 4.86144 15.4569 4.7975ZM7.99999 9C6.07624 9 4.39562 8.30062 3.00437 6.92188C2.43352 6.35418 1.94786 5.70685 1.56249 5C1.94776 4.29309 2.43343 3.64574 3.00437 3.07812C4.39562 1.69938 6.07624 1 7.99999 1C9.92374 1 11.6044 1.69938 12.9956 3.07812C13.5676 3.6456 14.0543 4.29295 14.4406 5C13.99 5.84125 12.0269 9 7.99999 9ZM7.99999 2C7.40665 2 6.82663 2.17595 6.33328 2.50559C5.83994 2.83524 5.45542 3.30377 5.22836 3.85195C5.00129 4.40013 4.94188 5.00333 5.05764 5.58527C5.17339 6.16721 5.45912 6.70176 5.87867 7.12132C6.29823 7.54088 6.83278 7.8266 7.41472 7.94236C7.99667 8.05811 8.59987 7.9987 9.14804 7.77164C9.69622 7.54458 10.1648 7.16006 10.4944 6.66671C10.824 6.17336 11 5.59334 11 5C10.9992 4.2046 10.6828 3.44202 10.1204 2.87959C9.55797 2.31716 8.79539 2.00083 7.99999 2ZM7.99999 7C7.60443 7 7.21775 6.8827 6.88885 6.66294C6.55996 6.44318 6.30361 6.13082 6.15224 5.76537C6.00086 5.39991 5.96125 4.99778 6.03842 4.60982C6.11559 4.22186 6.30608 3.86549 6.58578 3.58579C6.86549 3.30608 7.22185 3.1156 7.60981 3.03843C7.99778 2.96126 8.39991 3.00087 8.76536 3.15224C9.13081 3.30362 9.44317 3.55996 9.66293 3.88886C9.8827 4.21776 9.99999 4.60444 9.99999 5C9.99999 5.53043 9.78928 6.03914 9.41421 6.41421C9.03913 6.78929 8.53043 7 7.99999 7Z" fill="#707070" />
            </svg>
        </>
    )
}

const Eyebuttonwithline = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                <path d="M4.28016 11.82C2.7935 10.8467 1.8335 9.37999 1.8335 8.09332C1.8335 5.90665 4.5935 3.22665 8.00016 3.22665C9.3935 3.22665 10.6868 3.67332 11.7268 4.36665" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                <path d="M13.2332 5.74033C13.8272 6.49366 14.1732 7.32699 14.1732 8.09366C14.1732 10.2803 11.4066 12.9603 7.99992 12.9603C7.39325 12.9603 6.80058 12.8737 6.24658 12.7203" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                <path d="M6.51072 9.57821C6.11406 9.18554 5.89206 8.65021 5.89406 8.09221C5.89139 6.92888 6.83272 5.98354 7.99672 5.98154C8.55672 5.98021 9.09406 6.20221 9.49006 6.59821" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                <path d="M10.0732 8.46631C9.91717 9.32764 9.24317 10.003 8.38184 10.161" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                <path d="M13.2611 2.8335L2.74512 13.3495" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
            </svg>
        </>
    )
}

const Tripledot = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='employee-edit-svg'>
                <path d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </>
    )
}

const CircularGrid = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
            >
                <g clip-path="url(#clip0_650_3324)">
                    <path
                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                        fill="#707070"
                    />
                    <path
                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                        fill="#707070"
                    />
                    <path
                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                        fill="#707070"
                    />
                    <path
                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                        fill="#707070"
                    />
                    <path
                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                        fill="#707070"
                    />
                    <path
                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                        fill="#707070"
                    />
                    <path
                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                        fill="#707070"
                    />
                    <path
                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                        fill="#707070"
                    />
                    <path
                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                        fill="#707070"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_650_3324">
                        <rect width="14" height="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

const Sort = ({ color }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                id="sorting">
                <path
                    fill={color ? color : "#707070"}
                    d="M3 5.5A1.5 1.5 0 0 1 4.5 4h15a1.5 1.5 0 0 1 0 3h-15A1.5 1.5 0 0 1 3 5.5zm0 6A1.5 1.5 0 0 1 4.5 10h11a1.5 1.5 0 0 1 0 3h-11A1.5 1.5 0 0 1 3 11.5zM4.5 16a1.5 1.5 0 0 0 0 3h7a1.5 1.5 0 0 0 0-3h-7z"
                />
            </svg>
        </>
    )
}

const Filter = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 32 32"
                id="filter">
                <path
                    d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
                    stroke="#707070"
                />
            </svg>
        </>
    )
}

const Office = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 64 64"
                id="office">
                <g>
                    <path
                        d="M38.37,15H25.63a9.21,9.21,0,0,0-9.2,9.2V46a3,3,0,0,0,3,3H44.57a3,3,0,0,0,3-3V24.2A9.21,9.21,0,0,0,38.37,15ZM35.58,47H27.36V42.26a3.48,3.48,0,0,1,3.49-3.48H32.1a3.48,3.48,0,0,1,3.48,3.48Zm10-1a1,1,0,0,1-1,1h-7V42.26a5.49,5.49,0,0,0-5.48-5.48H30.85a5.49,5.49,0,0,0-5.49,5.48V47H19.43a1,1,0,0,1-1-1V24.2a7.21,7.21,0,0,1,7.2-7.2H38.37a7.21,7.21,0,0,1,7.2,7.2Z"
                        stroke="#707070"
                    />
                    <path
                        d="M25 31.65H24a1 1 0 000 2h1a1 1 0 000-2zM25 27.13H24a1 1 0 000 2h1a1 1 0 000-2zM25 22.56H24a1 1 0 000 2h1a1 1 0 000-2zM39.39 31.65h-1a1 1 0 000 2h1a1 1 0 000-2zM39.4 27.13h-1a1 1 0 000 2h1a1 1 0 000-2zM39.4 22.56h-1a1 1 0 000 2h1a1 1 0 000-2zM32.49 31.68h-1a1 1 0 000 2h1a1 1 0 000-2zM32.5 27.16h-1a1 1 0 000 2h1a1 1 0 000-2zM32.5 22.59h-1a1 1 0 000 2h1a1 1 0 000-2z"
                        stroke="#707070"
                    />
                </g>
            </svg>
        </>
    )
}

const HourGlass = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                id="hourglass">
                <path
                    d="M19,5.292V2h1a1,1,0,0,0,0-2H4A1,1,0,0,0,4,2H5V5.292a6.963,6.963,0,0,0,3.87,6.261L9.764,12l-.894.447A6.963,6.963,0,0,0,5,18.708V22H4a1,1,0,0,0,0,2H20a1,1,0,0,0,0-2H19V18.708a6.963,6.963,0,0,0-3.87-6.261L14.236,12l.894-.447A6.963,6.963,0,0,0,19,5.292Zm-12,0V2H17V5.292a4.972,4.972,0,0,1-2.764,4.472L12,10.882,9.764,9.764A4.972,4.972,0,0,1,7,5.292ZM17,18.708V22H7V18.708a4.972,4.972,0,0,1,2.764-4.472L12,13.118l2.236,1.118A4.972,4.972,0,0,1,17,18.708Z"
                    stroke="#707070"
                />
                <path
                    d="M10.659 7.975a1 1 0 0 0 .894-1.789A.994.994 0 0 1 11 5.292a1 1 0 0 0-2 0A2.984 2.984 0 0 0 10.659 7.975zM13.341 16.025a1 1 0 0 0-.894 1.789.994.994 0 0 1 .553.894 1 1 0 0 0 2 0A2.984 2.984 0 0 0 13.341 16.025z"
                    stroke="#707070"
                />
            </svg>
        </>
    )
}

const Calender = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                id="calendar">
                <path
                    fill="#707070"
                    d="M7 10a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm0 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm5-5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm3 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
                />
                <path
                    fill="#707070"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 4a1 1 0 1 1 2 0v1h2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2V4a1 1 0 0 1 2 0v1h6V4ZM5 8a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V8Z"
                />
            </svg>
        </>
    )
}

const Active = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                id="activity">
                <path
                    fill="#707070"
                    d="M17.6734 9.47316C17.8558 9.10127 17.7022 8.65193 17.3303 8.46951C16.9584 8.28709 16.5091 8.44068 16.3266 8.81256L14.8663 11.7896C14.4137 12.7124 13.0833 12.6673 12.6942 11.716C11.815 9.56698 8.80955 9.46517 7.78698 11.5498L6.32665 14.5268C6.14423 14.8987 6.29782 15.3481 6.6697 15.5305C7.04158 15.7129 7.49093 15.5593 7.67335 15.1874L9.13369 12.2104C9.58632 11.2876 10.9167 11.3327 11.3058 12.284C12.185 14.433 15.1904 14.5348 16.213 12.4502L17.6734 9.47316Z"
                />
                <path
                    fill="#707070"
                    fill-rule="evenodd"
                    d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z" clip-rule="evenodd"
                />
            </svg>
        </>
    )
}

const Employee = ({ color }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 48 48"
                id="employee">
                <g>
                    <path
                        fill={color ? color : "#707070"}
                        d="M33 34H25a1 1 0 0 1-1-1V32H21a1 1 0 0 1-1-1V24a7 7 0 0 1 7-7 7 7 0 0 1 7 7v9A1 1 0 0 1 33 34zm-7-2h6V24a5 5 0 0 0-10 0v6h3a1 1 0 0 1 1 1zM21 45H19a1 1 0 0 1 0-2h1V41a1 1 0 0 1 2 0v3A1 1 0 0 1 21 45z"
                    />
                    <path
                        fill={color ? color : "#707070"}
                        d="M35 45H33a1 1 0 0 1-1-1V33a1 1 0 0 1 2 0V43h1a1 1 0 0 1 0 2zM27 28a1 1 0 0 1-1-1V21a1 1 0 0 1 2 0v6A1 1 0 0 1 27 28z"
                    />
                    <path
                        fill={color ? color : "#707070"}
                        d="M28 22H26a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zM27 19a7 7 0 0 1-7-7V10a7 7 0 0 1 14 0v2A7 7 0 0 1 27 19zM27 5a5 5 0 0 0-5 5v2a5 5 0 0 0 10 0V10A5 5 0 0 0 27 5zM25 42H11a1 1 0 0 1-1-1V31a1 1 0 0 1 1-1H25a1 1 0 0 1 1 1V41A1 1 0 0 1 25 42zM12 40H24V32H12z"
                    />
                    <path
                        fill={color ? color : "#707070"}
                        d="M21,32H15a1,1,0,0,1-1-1V28a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v3A1,1,0,0,1,21,32Zm-5-2h4V29H16Z"
                    />
                    <path
                        fill={color ? color : "#707070"}
                        d="M36 35a1 1 0 0 1-1-1V27a8 8 0 0 0-16 0v1a1 1 0 0 1-2 0V27a10 10 0 0 1 20 0v7A1 1 0 0 1 36 35zM25 36H11a1 1 0 0 1 0-2H25a1 1 0 0 1 0 2z"
                    />
                    <path
                        fill={color ? color : "#707070"}
                        d="M18,37a1,1,0,0,1-1-1V34a1,1,0,0,1,2,0v2A1,1,0,0,1,18,37Z"
                    />
                </g>
            </svg>
        </>
    )
}

const DownloadIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="22"
                id="download"
                fill="#fff"
            >
                <g
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                >
                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                </g>
            </svg>
        </>
    )
}

const UploadIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                </g>
            </svg>
        </>
    )
}

const Attachment = () => {
    return (
        <>
            <svg
                className=""
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                    stroke="#707070"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}

const Reciept = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 16.933 16.933"
                id="bill">
                <path
                    d="M1.854 0C.819 0 0 .83 0 1.852c-.002 4.94.002 9.878.002 14.816 0 .221.256.345.429.207l1.488-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.488-1.19 1.49 1.19a.265.265 0 0 0 .33 0l1.489-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.487-1.19 1.488 1.19c.174.139.43.015.43-.207l.002-8.733h2.91a.26.26 0 0 0 .263-.263V1.854A1.85 1.85 0 0 0 15.083 0Zm0 .53h11.932c-.35.337-.556.8-.556 1.324l-.002 14.265-1.222-.98a.265.265 0 0 0-.33 0l-1.49 1.191-1.488-1.191a.264.264 0 0 0-.33 0L6.878 16.33 5.391 15.14a.265.265 0 0 0-.33 0L3.575 16.33 2.085 15.14a.264.264 0 0 0-.33 0l-1.224.98L.53 1.852c0-.77.634-1.322 1.324-1.322zm13.228 0c.73 0 1.324.586 1.324 1.324v5.554H13.76V1.854c0-.738.592-1.324 1.322-1.324zM3.707 3.439c-.133 0-.265.089-.265.266v.287c-.599.116-1.058.63-1.058 1.252 0 .42.217.672.48.799.264.127.552.147.816.174.265.027.506.06.639.125.133.064.181.15.181.32 0 .406-.34.743-.782.746-.384-.014-.658-.23-.784-.623-.106-.339-.614-.175-.503.162.152.475.48.844 1.011.96v.294c0 .354.53.354.53 0v-.282c.598-.116 1.058-.635 1.058-1.257 0-.388-.18-.654-.48-.799-.264-.126-.552-.146-.817-.173-.264-.028-.502-.056-.639-.126-.122-.062-.181-.142-.181-.32 0-.41.34-.742.783-.745.421.016.666.26.783.622.107.339.615.175.504-.162-.152-.474-.5-.857-1.012-.959v-.295c0-.177-.132-.266-.264-.266zm2.645.265c-.355 0-.355.527 0 .53h4.763c.353 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.53 0 .53h4.763c.352 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.527 0 .527h4.763c.352 0 .352-.527 0-.527zM2.649 9.26c-.356 0-.356.537 0 .529h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.53 0 .53h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.529 0 .53h8.466c.352 0 .352-.53 0-.53z"
                    fill="#2576BC"
                >
                </path>
            </svg>
        </>
    )
}

const PDFIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                className="bi bi-file-pdf"
                viewBox="0 0 16 16"
                color="#FF747C"
            >
                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
            </svg>
        </>
    )
}

const DocumentIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" enable-background="new 0 0 512 512" viewBox="0 0 512 512" id="documents">
                <path fill="#2576BC" d="M357.52,396.6v50.27c0,16.569-13.431,30-30,30l0,0H94.48c-16.569,0-30-13.431-30-30
			l0,0V135.4c0-16.569,13.431-30,30-30h70v261.2c0,16.569,13.431,30,30,30l0,0H357.52z"></path>
                <path fill="#00acea" d="M447.52,124.8v251.8c0,16.569-13.431,30-30,30H184.48c-16.569,0-30-13.431-30-30
			V65.13c0-16.569,13.431-30,30-30h176l1,1.03l-1,0.97v71.37c0,9.002,7.298,16.3,16.3,16.3h68.74l1.03-1L447.52,124.8z"></path>
                <path fill="#2576BC" d="M446.55,123.8l-1.03,1h-68.74c-9.002,0-16.3-7.298-16.3-16.3V37.13l1-0.97
			L446.55,123.8z"></path>
                <path fill="#ffffff" d="M392.3 248.31H204.68c-5.523 0-10-4.477-10-10s4.477-10 10-10H392.3c5.523 0 10 4.477 10 10S397.823 248.31 392.3 248.31zM317 192.37H204.68c-5.523 0-10-4.477-10-10s4.477-10 10-10H317c5.523 0 10 4.477 10 10S322.523 192.37 317 192.37zM392.3 308.31H204.68c-5.523 0-10-4.477-10-10s4.477-10 10-10H392.3c5.523 0 10 4.477 10 10S397.823 308.31 392.3 308.31z"></path>
            </svg>

        </>
    )
}

const Refresh = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="refresh-icon"
                fill="#fff"
                viewBox="0 0 24 24"
                id="refresh"
            >
                <path d="M21 21a1 1 0 0 1-1-1V16H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5A1 1 0 0 1 21 21zM8 10H3A1 1 0 0 1 2 9V4A1 1 0 0 1 4 4V8H8a1 1 0 0 1 0 2z"></path>
                <path d="M12 22a10 10 0 0 1-9.94-8.89 1 1 0 0 1 2-.22 8 8 0 0 0 15.5 1.78 1 1 0 1 1 1.88.67A10 10 0 0 1 12 22zM20.94 12a1 1 0 0 1-1-.89A8 8 0 0 0 4.46 9.33a1 1 0 1 1-1.88-.67 10 10 0 0 1 19.37 2.22 1 1 0 0 1-.88 1.1z"></path>
            </svg>
        </>
    )
}

export { SearchSmall, SearchBig, Arrow, ArrowBullet, DropdownArrow, DropdownArrowOption, DropdownArrowClosed, Collapse, Expand, AddwithWhiteCircle, AddwithBlueCircle, CrosswithGreyCircle, CheckGreen, WarnYellow, CrossRed, UpdatePencil, UpdatePencilWhite, DeleteDustbin, Location, Eye, BlueEye, CloseEye, EyeBottonInput, Eyebuttonwithline, Tripledot, CircularGrid, Sort, Filter, Office, HourGlass, Calender, Active, Employee, DownloadIcon, UploadIcon, Attachment, Reciept, PDFIcon, DocumentIcon, Refresh, }