import React, { useEffect, useState } from 'react'
import { formatRoundoff, handleErrorToast, sortProjects, formatCurrencyIndian } from '../../CustomFunctions';
import axios from 'axios';
import { BASE_URL } from '../../../config/axios';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Dashboardnavbar from '../../../layout/Dashboardnavbar';
import { datearray, formattedDateLong } from '../../Date';
import { AddwithWhiteCircle, CircularGrid, Reciept, UpdatePencil, SearchBig, Employee } from '../../AllSvg';
import { Projects } from '../../FilterDropdown';
import usePermission from '../../../config/permissions';
import { DocumentModel } from '../ProjectDetailsCrudButtons';
import { height } from '@mui/system';
import Select from 'react-select';

import { VehicleModelBulk } from '../BulkModals';
// !  ************** Vehicle Model Components Start **************  ! //

const AddVehicleModel = ({ projectId, getVehicle }) => {

  const [show, setShow] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const siteRole = sessionStorage.getItem("sitemanagement_role");

  const handleShow = () => {
    setShow(true);
    getProjects();
    setErrors({});
  }



  const initialFormData = {
    project: projectId ? projectId : "",
    name: "",
    vehicle_num: "",
    fixed_km: "",
    fixed_bill_amt: "",
    extra_amt_above_fix_km: "",

    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
    km_per_lit: "",
    fuel_price: ""
  };


  // const handleClose = () => {
  //   setShow(false);
  //   setFormData({
  //     project: projectId ? projectId : "",
  //     name: "",
  //     vehicle_num: "",
  //     fixed_km: "",
  //     fixed_bill_amt: "",
  //     extra_amt_above_fix_km: "",

  //     bank_name: "",
  //     account_holder_name: "",
  //     account_num: "",
  //     ifsc_code: "",
  //     pan_card: "",
  //     gst_num: "",
  //     address: "",
  //     mobile: "",
  //   })
  // }

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(initialFormData);


  }


  const getProjects = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  };
  console.log(projectId)


  const [formData, setFormData] = useState({
    project: projectId ? projectId : "",
    name: "",
    vehicle_num: "",
    fixed_km: "",
    fixed_bill_amt: "",
    extra_amt_above_fix_km: "",

    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
    km_per_lit: "",
    fuel_price: ""
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "project",
      "vehicle_num",
      "fixed_km",
      "fixed_bill_amt",
      "bank_name",
      "account_holder_name",
      "account_num",
      "ifsc_code",
      "pan_card",
      "mobile",
      "extra_amt_above_fix_km"
    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  console.log(projectId)

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let ress = await axios.post(
          `${BASE_URL}/siteproject/vehicle/`,
          formData
        );

        if (ress.status === 200) {
          { getVehicle ? getVehicle() : window.location.reload(); }
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };




  return (
    <>
      <button title="Add Vechile Model" className="upload-svg equal-hieght" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "}Vehicle
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Add Vehicle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="form-flex-wrap">


              {siteRole === "SiteManagement" ? "" : <>
                {projectId ? <></> :
                  <div title="Site Projects" className="flex-column form-group-selectt">

                    <label htmlFor="project" className="form-labels font-weight500 font-size-subheading" title='Project'>
                      Project<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      id="project"
                      name="project"
                      title='Project'
                      value={formData.project}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                    >
                      <option value="" title='Select a Project'>Select a Project</option>
                      {projectList?.map((i, index) => (
                        <option key={index} value={i.id} title='Select a Project'>
                          {i.site_prcode} - {i.project_short_name}
                        </option>
                      ))
                      }
                    </select>

                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    <div>
                      {errors.project && (
                        <span className="error-message font-size-text">{errors.project}</span>
                      )}
                    </div>
                  </div>
                }
              </>}

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Vehicle Name'>Vehicle Name<span className="required">*</span></label>
                <input
                  type="text"
                  name="name"
                  title='Vehicle Name'
                  placeholder="Vehicle Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={`form-input ${errors.name ? 'error' : ''}`}
                />
                {errors.name && <div className="error-message font-size-text">{errors.name}</div>}
              </div>
              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Vehicle Number'>Vehicle Number<span className="required">*</span></label>
                <input
                  type="text"
                  name="vehicle_num"
                  title='Vehicle Number'
                  placeholder="Vehicle Number"
                  value={formData.vehicle_num}
                  onChange={handleInputChange}
                  className={`form-input ${errors.vehicle_num ? 'error' : ''}`}
                />
                {errors.vehicle_num && <div className="error-message font-size-text">{errors.vehicle_num}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Fixed Kilometers'>Fixed Kilometer<span className="required">*</span></label>
                <input
                  type="number"
                  name="fixed_km"
                  title='Fixed Kilometers'
                  placeholder="Fixed Kilometers"
                  min={0}
                  value={formData.fixed_km}
                  onChange={handleInputChange}
                  className={`form-input ${errors.fixed_km ? 'error' : ''}`}
                />
                {errors.fixed_km && <div className="error-message font-size-text">{errors.fixed_km}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Kilometer Per Liter'>Kilometer per Liter</label>
                <input
                  type="number"
                  name="km_per_lit"
                  title='Kilometer Per Liter'
                  placeholder="Kilometer per Liter"
                  min={0}
                  value={formData.km_per_lit}
                  onChange={handleInputChange}
                  className={`form-input ${errors.km_per_lit ? 'is-invalid' : ''}`}
                />
                {errors.km_per_lit && <div className="error-message font-size-text">{errors.km_per_lit}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Fuel Price'>Fuel Price</label>
                <input
                  type="number"
                  name="fuel_price"
                  title="Fuel Price"
                  placeholder="Fuel Price"
                  min={0}
                  value={formData.fuel_price}
                  onChange={handleInputChange}
                  className={`form-input ${errors.fuel_price ? 'is-invalid' : ''}`}
                />
                {errors.fuel_price && <div className="error-message font-size-text">{errors.fuel_price}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Fixed Bill Amount'>Fixed Bill Amount<span className="required">*</span></label>
                <input
                  type="number"
                  min={0}
                  name="fixed_bill_amt"
                  placeholder="Fixed Bill Amount"
                  title="Fixed Bill Amount"
                  value={formData.fixed_bill_amt}
                  onChange={handleInputChange}
                  className={`form-input ${errors.fixed_bill_amt ? 'error' : ''}`}
                />
                {errors.fixed_bill_amt && <div className="error-message font-size-text">{errors.fixed_bill_amt}</div>}
              </div>
              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Extra Amount Per KM After Fixed KM'>Extra Amount Per KM After Fixed KM<span className="required">*</span></label>
                <input
                  type="number"
                  min={0}
                  name="extra_amt_above_fix_km"
                  title='Extra Amount Per KM After Fixed KM'
                  placeholder="Extra Amount Per KM After Fixed KM"
                  value={formData.extra_amt_above_fix_km}
                  onChange={handleInputChange}
                  className={`form-input ${errors.extra_amt_above_fix_km ? 'error' : ''}`}
                />
                {errors.extra_amt_above_fix_km && <div className="error-message font-size-text">{errors.extra_amt_above_fix_km}</div>}
              </div>


              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Bank Name'>Bank Name<span className="required">*</span></label>
                <input
                  type="text"
                  name="bank_name"
                  title='Bank Name'
                  placeholder="Bank Name"
                  value={formData.bank_name}
                  onChange={handleInputChange}
                  className={`form-input ${errors.bank_name ? 'error' : ''}`}
                />
                {errors.bank_name && <div className="error-message font-size-text">{errors.bank_name}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Account Holder Name'>Account Holder Name <span className="required">*</span></label>
                <input
                  type="text"
                  name="account_holder_name"
                  title='Account Holder Name'
                  placeholder="Account Holder Name"
                  value={formData.account_holder_name}
                  onChange={handleInputChange}
                  className={`form-input ${errors.account_holder_name ? 'error' : ''}`}
                />
                {errors.account_holder_name && <div className="error-message font-size-text">{errors.account_holder_name}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Account Number'>Account Number<span className="required">*</span></label>
                <input
                  type="number"
                  min={0}
                  name="account_num"
                  title='Account Number'
                  placeholder="Account Number"
                  value={formData.account_num}
                  onChange={handleInputChange}
                  className={`form-input ${errors.account_num ? 'error' : ''}`}
                />
                {errors.account_num && <div className="error-message font-size-text">{errors.account_num}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Mobile Number'>Mobile Number<span className="required">*</span></label>
                <input
                  type="number"
                  name="mobile"
                  title='Mobile Number'
                  min={0}
                  placeholder="Mobile Number"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  className={`form-input ${errors.mobile ? 'error' : ''}`}
                />
                {errors.mobile && <div className="error-message font-size-text">{errors.mobile}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='IFSC Code'>IFSC Code<span className="required">*</span></label>
                <input
                  type="text"
                  name="ifsc_code"
                  title='IFSC Code'
                  placeholder="IFSC Code"
                  value={formData.ifsc_code}
                  onChange={handleInputChange}
                  className={`form-input ${errors.ifsc_code ? 'error' : ''}`}
                />
                {errors.ifsc_code && <div className="error-message font-size-text">{errors.ifsc_code}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='Pan Card'>PAN Card<span className="required">*</span></label>
                <input
                  type="text"
                  name="pan_card"
                  title='Pan Card'
                  placeholder="PAN Card"
                  value={formData.pan_card}
                  onChange={handleInputChange}
                  className={`form-input ${errors.pan_card ? 'error' : ''}`}
                />
                {errors.pan_card && <div className="error-message font-size-text">{errors.pan_card}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading" title='GST Number'>GST Number</label>
                <input
                  type="text"
                  title='GST Number'
                  name="gst_num"
                  placeholder="GST Number"
                  value={formData.gst_num}
                  onChange={handleInputChange}
                  className={`form-input ${errors.gst_num ? 'is-invalid' : ''}`}
                />
                {errors.gst_num && <div className="error-message font-size-text">{errors.gst_num}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading" title='Address'>
                  Address
                </label>
                <textarea
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleInputChange}
                  title='Address'
                  rows="50"
                  cols="50"
                  className={`form-textarea textarea  ${errors.address ? 'is-invalid' : ''}`}
                  style={{ height: "50vh" }}
                />
                {errors.address && <div className="error-message font-size-text">{errors.address}</div>}
              </div>



            </div>



            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose} title='cancle'>
                Cancel
              </button>

              <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                {loading ? 'Adding...' : 'Add'}
              </button>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>

          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

const UpdateVehicle = ({ i, projectId, getVehicle }) => {
  const [projectList, setProjectList] = useState([]);
  const [show, setShow] = useState(false);


  const handleShow = () => {
    getProjects();
    setShow(true);
    setFormData(i);
  }
  const getProjects = async () => {

    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);
    } catch (err) {
      handleErrorToast(err)
    } finally {

    }
  };



  const [formData, setFormData] = useState({
    project: projectId ? projectId : "",
    name: "",
    vehicle_num: "",
    fixed_km: "",
    fixed_bill_amt: "",
    extra_amt_above_fix_km: "",

    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
    km_per_lit: "",
    fuel_price: ""
  });


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});


  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "project",
      "vehicle_num",
      "fixed_km",
      "fixed_bill_amt",
      "extra_amt_above_fix_km"
    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //


  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.put(
          `${BASE_URL}/siteproject/vehicle/${i.id}/`,
          formData
        );

        if (ress.status === 200) {
          await getVehicle();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(i);
  }


  return (


    <div>
      <>
        <button title='Update Vehicle Details' className='model-edit-button' onClick={handleShow}>
          <UpdatePencil />
        </button>

        <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
          <Modal.Header closeButton>
            <Modal.Title>Update Vehicle Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <form className="register-form">
              <div className="form-flex-wrap">


                {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? "" : <>
                  <div title="Site Projects" className="flex-column form-group-selectt">
                    <label htmlFor="project" className="form-labels font-weight500 font-size-subheading">
                      Project<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      id="project"
                      name="project"
                      value={formData.project}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                    >
                      <option value="">Select a Project</option>
                      {projectList?.map((i, index) => (
                        <option key={index} value={i.id}>
                          {i.site_prcode} - {i.project_short_name}
                        </option>
                      ))
                      }
                    </select>

                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    <div>
                      {errors.project && (
                        <span className="error-message font-size-text">{errors.project}</span>
                      )}
                    </div>
                  </div></>}

                <div className="flex-column" title="Vehicle Name">
                  <label className="form-labels  font-weight500 font-size-subheading">Vehicle Name<span className="required">*</span></label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Vehicle Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.name ? 'is-invalid' : ''}`}
                  />
                  {errors.name && <div className="error-message font-size-text">{errors.name}</div>}
                </div>
                <div className="flex-column" title="Vehicle Number">
                  <label className="form-labels  font-weight500 font-size-subheading">Vehicle Number<span className="required">*</span></label>
                  <input
                    type="text"
                    name="vehicle_num"
                    placeholder="Vehicle Number"
                    value={formData.vehicle_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.vehicle_num ? 'is-invalid' : ''}`}
                  />
                  {errors.vehicle_num && <div className="error-message font-size-text">{errors.vehicle_num}</div>}
                </div>

                <div className="flex-column" title="Fixed Kilometer">
                  <label className="form-labels  font-weight500 font-size-subheading">Fixed Kilometer<span className="required">*</span></label>
                  <input
                    type="number"
                    name="fixed_km"
                    placeholder="Fixed Kilometer"
                    min={0}
                    value={formData.fixed_km}
                    onChange={handleInputChange}
                    className={`form-input ${errors.fixed_km ? 'is-invalid' : ''}`}
                  />
                  {errors.fixed_km && <div className="error-message font-size-text">{errors.fixed_km}</div>}
                </div>

                <div className="flex-column" title="Kilometer Per Liter">
                  <label className="form-labels  font-weight500 font-size-subheading">Kilometer per Liter</label>
                  <input
                    type="number"
                    name="km_per_lit"
                    placeholder="Kilometer per Liter"
                    min={0}
                    value={formData.km_per_lit}
                    onChange={handleInputChange}
                    className={`form-input ${errors.km_per_lit ? 'is-invalid' : ''}`}
                  />
                  {errors.km_per_lit && <div className="error-message font-size-text">{errors.km_per_lit}</div>}
                </div>

                <div className="flex-column" title="Fuel Price">
                  <label className="form-labels  font-weight500 font-size-subheading">Fuel Price</label>
                  <input
                    type="number"
                    name=" fuel_price"
                    placeholder="Fuel Price"
                    min={0}
                    value={formData.fuel_price}
                    onChange={handleInputChange}
                    className={`form-input ${errors.fuel_price ? 'is-invalid' : ''}`}
                  />
                  {errors.fuel_price && <div className="error-message font-size-text">{errors.fuel_price}</div>}
                </div>
                <div className="flex-column" title="Fixed Bill Amount">
                  <label className="form-labels  font-weight500 font-size-subheading">Fixed Bill Amount<span className="required">*</span></label>
                  <input
                    type="number"
                    min={0}
                    name="fixed_bill_amt"
                    placeholder="Fixed Bill Amount"
                    value={formData.fixed_bill_amt}
                    onChange={handleInputChange}
                    className={`form-input ${errors.fixed_bill_amt ? 'is-invalid' : ''}`}
                  />
                  {errors.fixed_bill_amt && <div className="error-message font-size-text">{errors.fixed_bill_amt}</div>}
                </div>
                <div className="flex-column" title="Extra Amount Per KM After Fixed KM">
                  <label className="form-labels  font-weight500 font-size-subheading">Extra Amount Per KM After Fixed KM<span className="required">*</span></label>
                  <input
                    type="number"
                    min={0}
                    name="extra_amt_above_fix_km"
                    placeholder="Extra Amount Per KM After Fixed KM"
                    value={formData.extra_amt_above_fix_km}
                    onChange={handleInputChange}
                    className={`form-input ${errors.extra_amt_above_fix_km ? 'is-invalid' : ''}`}
                  />
                  {errors.extra_amt_above_fix_km && <div className="error-message font-size-text">{errors.extra_amt_above_fix_km}</div>}
                </div>


                <div className="flex-column" title="Bank Name">
                  <label className="form-labels  font-weight500 font-size-subheading">Bank Name<span className="required">*</span></label>
                  <input
                    type="text"
                    name="bank_name"
                    placeholder="Bank Name"
                    value={formData.bank_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bank_name ? 'is-invalid' : ''}`}
                  />
                  {errors.bank_name && <div className="error-message font-size-text">{errors.bank_name}</div>}
                </div>

                <div className="flex-column" title="Account Holder Name">
                  <label className="form-labels  font-weight500 font-size-subheading">Account Holder Name <span className="required">*</span></label>
                  <input
                    type="text"
                    name="account_holder_name"
                    placeholder="Account Holder Name"
                    value={formData.account_holder_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.account_holder_name ? 'is-invalid' : ''}`}
                  />
                  {errors.account_holder_name && <div className="error-message font-size-text">{errors.account_holder_name}</div>}
                </div>

                <div className="flex-column" title="Account Number">
                  <label className="form-labels  font-weight500 font-size-subheading">Account Number<span className="required">*</span></label>
                  <input
                    type="number"
                    min={0}
                    name="account_num"
                    placeholder="Account Number"
                    value={formData.account_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.account_num ? 'is-invalid' : ''}`}
                  />
                  {errors.account_num && <div className="error-message font-size-text">{errors.account_num}</div>}
                </div>

                <div className="flex-column" Title="Mobile Number">
                  <label className="form-labels  font-weight500 font-size-subheading">Mobile Number<span className="required">*</span></label>
                  <input
                    type="number"
                    name="mobile"
                    min={0}
                    placeholder="Mobile Number"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    className={`form-input ${errors.mobile ? 'is-invalid' : ''}`}
                  />
                  {errors.mobile && <div className="error-message font-size-text">{errors.mobile}</div>}
                </div>

                <div className="flex-column" title="IFSC Code">
                  <label className="form-labels  font-weight500 font-size-subheading">IFSC Code<span className="required">*</span></label>
                  <input
                    type="text"
                    name="ifsc_code"
                    placeholder="IFSC Code"
                    value={formData.ifsc_code}
                    onChange={handleInputChange}
                    className={`form-input ${errors.ifsc_code ? 'is-invalid' : ''}`}
                  />
                  {errors.ifsc_code && <div className="error-message font-size-text">{errors.ifsc_code}</div>}
                </div>

                <div className="flex-column" title="Pan Card">
                  <label className="form-labels  font-weight500 font-size-subheading">PAN Card<span className="required">*</span></label>
                  <input
                    type="text"
                    name="pan_card"
                    placeholder="PAN Card"
                    value={formData.pan_card}
                    onChange={handleInputChange}
                    className={`form-input ${errors.pan_card ? 'is-invalid' : ''}`}
                  />
                  {errors.pan_card && <div className="error-message font-size-text">{errors.pan_card}</div>}
                </div>

                <div className="flex-column" title="GST Number">
                  <label className="form-labels  font-weight500 font-size-subheading">GST Number<span className="required">*</span></label>
                  <input
                    type="text"
                    name="gst_num"
                    placeholder="GST Number"
                    value={formData.gst_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.gst_num ? 'is-invalid' : ''}`}
                  />
                  {errors.gst_num && <div className="error-message font-size-text">{errors.gst_num}</div>}
                </div>

                <div className="flex-column" title="Address">
                  <label className="form-labels font-weight500 font-size-subheading">
                    Address<span className="required">*</span>
                  </label>
                  <textarea
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleInputChange}
                    rows="50"
                    cols="50"
                    className={`form-textarea width-10vw ${errors.address ? 'is-invalid' : ''}`}
                  />
                  {errors.address && <div className="error-message font-size-text">{errors.address}</div>}
                </div>

              </div>
            </form>


            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                {loading ? 'Updating...' : 'Update'}
              </button>
            </div>

          </Modal.Body>
        </Modal>
      </>

    </div>


  )
}

const DeleteVehicle = ({ i, getVehicle }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);

  };
  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/vehicle/${i.id}/`);

      if (res.status === 200) {
        await getVehicle();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      console.log("Error")
      console.log(err)
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Vehicle  Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Vehicle Details "{i.name}"

            <div className="button-models">
              <button className="model-button-cancel  model-button font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
}

// !  ************** Vehicle Model Components End**************  ! //


// !  ************** Vehicle Log Components Start **************  ! //

const AddVehicleLogDetails = ({ getVehicleLogDetail, projectid }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [show, setShow] = useState(false);
  const siteRole = sessionStorage.getItem("sitemanagement_role");


  const [projectList, setProjectList] = useState([]);

  const handleShow = () => {

    setShow(true);
  }


  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(initialFormData);
    setErrors({})
    // setProject(initialProject);

  }



  const [formData, setFormData] = useState({
    vehicle: "",
    date: "",
    from_time: "10:00:00",
    to_time: "17:30:00",
    commencement_of_trip: "",
    conclusion_of_trip: "",
    person_using_conveyance: "",
    purpose_and_place: "",
    employee: [],
    project: projectid ? projectid : null,
  });

  const getProjects = async () => {
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      handleErrorToast(err);
    } finally {

    }
  };

  const getEmployee = async () => {

    try {
      const emp = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${formData.project ? formData.project : "null"}/null/`);
      setEmployeeList(emp.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {

    }
  }
  const getVehicle = async () => {
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapi/${formData.project ? formData.project : "null"}/`);
      setVehicleList(veh.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {
    }
  }
  const initialFormData = {
    vehicle: "",
    date: "",
    from_time: "10:00:00",
    to_time: "17:30:00",
    commencement_of_trip: "",
    conclusion_of_trip: "",
    person_using_conveyance: "",
    purpose_and_place: "",
    employee: [],
    project: projectid ? projectid : null,
  };


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle",
      "date",
      "from_time",
      "to_time",
      "commencement_of_trip",
      "conclusion_of_trip",
      "purpose_and_place",
      // "person_using_conveyance",
      'employee',
      "project",

    ]

    if (!formData.to_time) {
      newErrors.to_time = "To time is required.";
    } else if (formData.to_time <= formData.from_time) {
      newErrors.to_time = "To time must be later than From time.";
    }

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }

  // ?  ************** Validation End **************  ! //


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));

    // Validation logic
    if (name === 'conclusion_of_trip') {
      if (Number(value) <= Number(formData.commencement_of_trip)) {
        setErrors(prevState => ({
          ...prevState,
          conclusion_of_trip: 'Conclusion of Trip must be greater than Commencement of Trip',
        }));
      } else {
        setErrors(prevState => ({
          ...prevState,
          conclusion_of_trip: '',
        }));
      }
    }

    if (name === 'commencement_of_trip') {
      setErrors(prevState => ({
        ...prevState,
        conclusion_of_trip: '',
      }));
    }
  };


  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.post(
          `${BASE_URL}/siteproject/vehiclelogdetail/
`, formData
        );

        if (ress.status === 200) {
          { getVehicleLogDetail ? getVehicleLogDetail() : window.location.reload() }
          await getVehicleLogDetail();
          handleClose();
          // setFormData("");
        } else {
          alert(ress);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  useEffect(() => {
    getProjects();
    if (formData.project !== "null") {
      getVehicle();
      getEmployee();
    }
    else {
      setVehicleList([]);
      setEmployeeList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project]);

  const generateTimeOptions = (startTime = "00:00") => {
    const times = [];
    let current = new Date(`2024-01-01T${startTime}:00`);
    const end = new Date(`2024-01-01T23:59:00`);

    while (current <= end) {
      times.push(current.toTimeString().slice(0, 5)); // Get HH:MM format
      current.setMinutes(current.getMinutes() + 30); // Increment by 30 minutes
    }
    return times;
  };


  const options = [
    {
      value: 'Select Employee',
      label: 'Select Employee',
    },
    ...employeeList
      .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
      .map((e) => ({
        value: e.id,
        label: `${e.emp_code} - ${e.name}`,
      })),
  ];

  const handleChangecc = (selected) => {
    const employee =
      selected.length > 0 ? selected.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      employee,
    }));
  };



  const timeOptions = generateTimeOptions(formData.from_time || "00:00");


  return (



    <>
      <button title="Add Vechile Log Details" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {" "} Vehicle Log Details
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Vehicle Log Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form >

                {siteRole === 'SiteManagement' ? "" : <>
                  <div title="Project" className="flex-column form-group-selectt">
                    <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                      Project<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="project"
                      name="project"
                      placeholder="project"
                      value={formData.project}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                    >

                      <option value="null">Select a Project</option>
                      {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                        <option key={index} value={i.id}>{i.project_short_name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                  </div>
                </>
                }
                <div title="Vehicle" className="flex-column form-group-selectt">
                  <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                    {siteRole === "SiteManagement" ? ("Vehicle") : ("Vehicle (Select Project First)")} <span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="vehicle"
                    name="vehicle"
                    placeholder="vehicle"
                    value={formData.vehicle}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                  >

                    <option value="">Select a vehicle</option>
                    {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                </div>

                <div title="person_using_conveyance" className="flex-column form-group-selectt">
                  <label htmlFor="person_using_conveyance" className="form-labels  font-weight500    font-size-subheading">
                    {siteRole === "SiteManagement" ? ("Person Using Conveyance") : ("Person Using Conveyance (Select Project First)")} <span className="required">*</span>

                  </label>
                  <br />
                  <Select
                    options={options}
                    isMulti
                    placeholder="Select Name"
                    onChange={handleChangecc}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '33vw',
                        minHeight: '50px',
                        borderRadius: '10px',
                        border: '1px solid #e3e3e3',
                        margin: '10px 0px 20px 0px',
                        color: '#4a4a4a',
                        backgroundColor: '#f7f8fa',
                        padding: '0px 10px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: '33vw',
                        borderRadius: '10px',
                        marginTop: '0',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                    }}
                  />
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.employee && <span className="error-message font-size-text ">{errors.employee}</span>}
                </div>

                <div className="flex-column">
                  <label htmlFor='date' className="form-labels  font-weight500 font-size-subheading" title="Date">Date<span className="required">*</span></label>
                  <input
                    type="date"
                    name="date"
                    title="Date"
                    placeholder="Date"
                    value={formData.date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.date ? 'error' : ''}`}
                  />
                  {errors.date && <div className="error-message font-size-text">{errors.date}</div>}
                </div>
                <div className='flex-row width-35vw justify-between'>
                  <div className="flex-column ">
                    <label htmlFor='from_time' className="form-labels  font-weight500 font-size-subheading" title="From Time">From Time<span className="required">*</span></label>
                    <input
                      id='from_time'
                      type="time"
                      name="from_time"
                      title='From Time'
                      placeholder="Time"
                      value={formData.from_time}
                      onChange={handleInputChange}
                      className={`form-input form-input-time ${errors.from_time ? 'error' : ''}`}
                    />
                    {errors.from_time && <div className="error-message font-size-text">{errors.from_time}</div>}
                  </div>
                  <div className="flex-column">
                    <label htmlFor='to_time' className="form-labels   font-weight500 font-size-subheading" title="To Time">To Time<span className="required">*</span></label>
                    <input
                      id='to_time'
                      type="time"
                      name="to_time"
                      title="To Time"
                      placeholder="Time"
                      value={formData.to_time}
                      min={formData.from_time}
                      onChange={handleInputChange}
                      className={`form-input form-input-time ${errors.to_time ? 'error' : ''}`}
                    />
                    {errors.to_time && <div className="error-message  font-size-text">{errors.to_time}</div>}
                  </div>
                </div>



                <div className="flex-column">
                  <label htmlFor='commencement_of_trip' className="form-labels  font-weight500 font-size-subheading" title="Commencement of Trip">Commencement of Trip (Initial Reading) <span className="required">*</span></label>
                  <input
                    id='commencement_of_trip'
                    type="number"
                    title="Commencement of Trip"
                    min={0}
                    name="commencement_of_trip"
                    placeholder="Commencement of Trip"
                    value={formData.commencement_of_trip}
                    onChange={handleInputChange}
                    className={`form-input ${errors.commencement_of_trip ? 'error' : ''}`}
                  />
                  {errors.commencement_of_trip && <div className="error-message font-size-text">{errors.commencement_of_trip}</div>}
                </div>
                {formData.commencement_of_trip &&
                  <div className="flex-column">
                    <label htmlFor='conclusion_of_trip' className="form-labels  font-weight500 font-size-subheading" title="Conclusion of Trip">Conclusion of Trip (Final Reading)<span className="required">*</span></label>
                    <input
                      id='conclusion_of_trip'
                      title='Conclusion of Trip'
                      type="number"
                      min={formData.commencement_of_trip}
                      name="conclusion_of_trip"
                      placeholder={formData.commencement_of_trip}
                      value={formData.conclusion_of_trip}
                      onChange={handleInputChange}
                      className={`form-input ${errors.conclusion_of_trip ? 'error' : ''}`}
                    />
                    {errors.conclusion_of_trip && <div className="error-message font-size-text">{errors.conclusion_of_trip}</div>}
                  </div>
                }

                <div className="flex-column">
                  <label htmlFor='purpose_and_place' className="form-labels  font-weight500 font-size-subheading" title='Purpose And Place'>Purpose And Place<span className="required">*</span></label>
                  <input
                    id='purpose_and_place'
                    title='Purpose And Place'
                    type="text"
                    name="purpose_and_place"
                    placeholder="Purpose And Place"
                    value={formData.purpose_and_place}
                    onChange={handleInputChange}
                    className={`form-input ${errors.purpose_and_place ? 'error' : ''}`}
                  />
                  {errors.purpose_and_place && <div className="error-message font-size-text">{errors.purpose_and_place}</div>}
                </div>
                <div className="button-models">
                  <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                    Cancel
                  </button>
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>




  )
}

const UpdateVehicleLogDetail = ({ getVehicleLogDetail, i }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [show, setShow] = useState(false);
  const [project, setProject] = useState("null");
  const [projectList, setProjectList] = useState([]);
  const siteRole = sessionStorage.getItem("sitemanagement_role");

  const handleShow = () => {
    getEmployee();
    setShow(true);
    getVehicle();
    setFormData(i);
    getProjects();
  }
  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(i);
    setProject(i);
  }


  const getProjects = async () => {
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      console.log("err2")
      console.log(err)
      handleErrorToast(err);
    } finally {

    }
  };

  const getEmployee = async () => {

    try {
      const emp = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${i.project_id}/null/`);
      setEmployeeList(emp.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {

    }
  }
  const getVehicle = async () => {

    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapibyproj/${i.project_id}`);
      setVehicleList(veh.data);

    } catch (err) {
      console.log("err1")
      console.log(err)
      handleErrorToast(err);

    } finally {

    }
  }


  const [formData, setFormData] = useState({
    vehicle: "",
    date: "",
    from_time: "",
    to_time: "",
    commencement_of_trip: "",
    conclusion_of_trip: "",
    person_using_conveyance: "",
    employee: [],
    purpose_and_place: "",
  });

  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle",
      "date",
      "from_time",
      "to_time",
      "commencement_of_trip",
      "conclusion_of_trip",
      "purpose_and_place",
      "person_using_conveyance",
      "employee",

    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.put(
          `${BASE_URL}/siteproject/vehiclelogdetail/${i.id}/
`, formData
        );

        if (ress.status === 200) {
          await getVehicleLogDetail();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  // useEffect(() => {
  //   getProjects();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);



  useEffect(() => {

    if (project !== "null") {
      getVehicle();
    }
    else {
      setVehicleList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);


  const options = [
    {
      value: 'Select Employee',
      label: 'Select Employee',
    },
    ...employeeList
      .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
      .map((e) => ({
        value: e.id,
        label: `${e.emp_code} - ${e.name}`,
      })),
  ];

  const handleChangecc = (selected) => {
    const employee =
      selected.length > 0 ? selected.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      employee,
    }));
  };



  return (


    <div>
      <>
        <button title='Update Vehicle Log Details' className='model-edit-button' onClick={handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
              fill="#145650"
            />
          </svg>
        </button>

        <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
          <Modal.Header closeButton>
            <Modal.Title>Update Vehicle Log Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <div className="parent-div">
              <div className="bdy-div">
                <form >
                  <div title="Vehicle" className="flex-column form-group-selectt">
                    <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                      Vehicle<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="vehicle"
                      name="vehicle"
                      placeholder="vehicle"
                      value={formData.vehicle}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                    >

                      <option value="">Select a vehicle</option>
                      {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                        <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                  </div>

                  <div title="person_using_conveyance" className="flex-column form-group-selectt">
                    <label htmlFor="employee" className="form-labels  font-weight500    font-size-subheading">
                      {siteRole === "SiteManagement" ? ("Person Using Conveyance") : ("Person Using Conveyance (Select Project First)")} <span className="required">*</span>

                    </label>
                    <br />
                    <select
                      options={options}
                      isMulti
                      placeholder="Select Name"
                      onChange={handleChangecc}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '33vw',
                          minHeight: '50px',
                          borderRadius: '10px',
                          border: '1px solid #e3e3e3',
                          margin: '10px 0px 20px 0px',
                          color: '#4a4a4a',
                          backgroundColor: '#f7f8fa',
                          padding: '0px 10px',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: '33vw',
                          borderRadius: '10px',
                          marginTop: '0',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                      }}
                    />

                    {errors.employee && <span className="error-message font-size-text ">{errors.employee}</span>}
                  </div>

                  <div className="flex-column" title="Date">
                    <label htmlFor='date' className="form-labels  font-weight500 font-size-subheading">Date<span className="required">*</span></label>
                    <input
                      type="date"
                      name="date"
                      placeholder="Date"
                      value={formData.date}
                      onChange={handleInputChange}
                      className={`form-input ${errors.date ? 'error' : ''}`}
                    />
                    {errors.date && <div className="error-message font-size-text">{errors.date}</div>}
                  </div>
                  <div className='flex-row width-35vw justify-between'>
                    <div className="flex-column" title="From Time">
                      <label htmlFor='from_time' className="form-labels  font-weight500 font-size-subheading">From Time<span className="required">*</span></label>
                      <input
                        id='from_time'
                        type="time"
                        name="from_time"
                        placeholder="Time"
                        value={formData.from_time}
                        onChange={handleInputChange}
                        className={`form-input form-input-time${errors.from_time ? 'error' : ''}`}
                      />
                      {errors.from_time && <div className="error-message font-size-text">{errors.from_time}</div>}
                    </div>
                    <div className="flex-column" title="To Time">
                      <label htmlFor='to_time' className="form-labels  font-weight500 font-size-subheading">To Time<span className="required">*</span></label>
                      <input
                        id='to_time'
                        type="time"
                        name="to_time"
                        placeholder="Time"
                        value={formData.to_time}
                        onChange={handleInputChange}
                        className={`form-input form-input-time${errors.to_time ? 'is-invalid' : ''}`}
                      />
                      {errors.to_time && <div className="error-message font-size-text">{errors.to_time}</div>}
                    </div>
                  </div>



                  <div className="flex-column" title="Commencement of Trip(Initial Reading)">
                    <label htmlFor='commencement_of_trip' className="form-labels  font-weight500 font-size-subheading">Commencement of Trip (Initial Reading) <span className="required">*</span></label>
                    <input
                      id='commencement_of_trip'
                      type="number"
                      min={0}
                      name="commencement_of_trip"
                      placeholder="Commencement of Trip"
                      value={formData.commencement_of_trip}
                      onChange={handleInputChange}
                      className={`form-input ${errors.commencement_of_trip ? 'is-invalid' : ''}`}
                    />
                    {errors.commencement_of_trip && <div className="error-message font-size-text">{errors.commencement_of_trip}</div>}
                  </div>

                  <div className="flex-column" title="Conclusion of Trip(Final Reading)">
                    <label htmlFor='conclusion_of_trip' className="form-labels  font-weight500 font-size-subheading">Conclusion of Trip (Final Reading)<span className="required">*</span></label>
                    <input
                      id='conclusion_of_trip'
                      type="number"
                      min={0}
                      name="conclusion_of_trip"
                      placeholder="Conclusion of Trip"
                      value={formData.conclusion_of_trip}
                      onChange={handleInputChange}
                      className={`form-input ${errors.conclusion_of_trip ? 'is-invalid' : ''}`}
                    />
                    {errors.conclusion_of_trip && <div className="error-message font-size-text">{errors.conclusion_of_trip}</div>}
                  </div>

                  <div className="flex-column" title="Purpose And Place">
                    <label htmlFor='purpose_and_place' className="form-labels  font-weight500 font-size-subheading">Purpose And Place<span className="required">*</span></label>
                    <input
                      id='purpose_and_place'
                      type="text"
                      name="purpose_and_place"
                      placeholder="Purpose And Place"
                      value={formData.purpose_and_place}
                      onChange={handleInputChange}
                      className={`form-input ${errors.purpose_and_place ? 'is-invalid' : ''}`}
                    />
                    {errors.purpose_and_place && <div className="error-message font-size-text">{errors.purpose_and_place}</div>}
                  </div>
                  <div className="button-models">
                    <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                      Cancel
                    </button>
                    <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                      {loading ? 'Updating...' : 'Update'}
                    </button>
                  </div>
                </form>
              </div></div>
          </Modal.Body>
        </Modal>
      </>

    </div>


  )
}

const DeleteVehicleLogDetail = ({ getVehicleLogDetail, i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);

  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/vehiclelogdetail/${i.id}/`);

      if (res.status === 200) {
        await getVehicleLogDetail();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      console.log("Error")
      console.log(err)
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Vehicle Log Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Vehicle Log Detail of  "{i.personusingconveyance}"

            <div className="button-models">
              <button className="model-button model-button-cancel   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
}
// !  ************** Vehicle Log Components End **************  ! //

//?  ********* Vehicle Billing Details Components Start******** ?//

const AddVehicleBillingDetails = ({ getVehicleBillingDetail, projectid }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [show, setShow] = useState(false);


  const [setBuffering] = useState(false); //buffering logic
  const initialProject = "null";
  const [setProject] = useState(initialProject);
  const [projectList, setProjectList] = useState([]);

  const handleShow = () => {
    setShow(true);
  }
  const role = sessionStorage.getItem('role');


  const [formData, setFormData] = useState({
    vehicle_owner_details: "",
    vehicle: "",
    month: "",
    year: "",
    extra_amount: "",
    final_total_amount: "",
    less_tds: "",
    payable_amount: "",
    fixed_km: "",
    fixed_bill_amt: "",
    extra_km: "",
    extra_amt_above_fix_km: "",
    total_km: "",
    extra_charges: "",
    project: projectid ? projectid : null,
    account_holder_name: "",

  });




  const getProjects = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  };


  const getVehicle = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapibyproj/${formData.project ? formData.project : "null"}/`);
      setVehicleList(veh.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  }

  const initialFormData = {
    vehicle_owner_details: "",
    vehicle: "",
    month: "",
    year: "",
    extra_amount: "",
    final_total_amount: "",
    less_tds: "",
    payable_amount: "",
    fixed_km: "",
    fixed_bill_amt: "",
    extra_km: "",
    extra_amt_above_fix_km: "",
    total_km: "",
    extra_charges: "",
    project: projectid ? projectid : null,
    account_holder_name: "",
  };



  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setErrors({});
    setShow(false);
    setFormData(initialFormData);

  }


  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle",
      "month",
      "year",
      "extra_amount",
      "final_total_amount",
      "fixed_bill_amt",


    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);

    return Object.keys(newErrors).length === 0;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update input state (for validation or UI purposes)
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    // Update form data and calculate total_km
    if (name === "vehicle") {
      const selectedVehicle = vehicleList.find((vehicle) => vehicle.id === value);

      const updatedFormData = {
        ...formData,
        [name]: value,
        fixed_km: selectedVehicle ? selectedVehicle.fixed_km : "",
        fixed_bill_amt: selectedVehicle ? selectedVehicle.fixed_bill_amt : 0,
        account_holder_name: selectedVehicle ? selectedVehicle.account_holder_name : "",
        extra_amt_above_fix_km: selectedVehicle ? selectedVehicle.extra_amt_above_fix_km : 0,
      };

      // Calculate extra amount
      updatedFormData.extra_amount =
        (updatedFormData.extra_km || 0) * (updatedFormData.extra_amt_above_fix_km || 0);


      // Calculate total_km
      updatedFormData.total_km =
        Number(updatedFormData.fixed_km || 0) + Number(updatedFormData.extra_km || 0);

      // Calculate final total amount
      updatedFormData.final_total_amount =
        updatedFormData.fixed_bill_amt +
        updatedFormData.extra_charges +
        updatedFormData.extra_amount;

      setFormData(updatedFormData);
    } else {
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      // Calculate extra amount if extra_km or extra_amt_above_fix_km is updated
      if (name === "extra_km" || name === "extra_amt_above_fix_km") {
        updatedFormData.extra_amount =
          (updatedFormData.extra_km || 0) * (updatedFormData.extra_amt_above_fix_km || 0);
      }

      // Calculate total_km if fixed_km or extra_km is updated
      if (name === "fixed_km" || name === "extra_km") {
        updatedFormData.total_km =
          Number(updatedFormData.fixed_km || 0) + Number(updatedFormData.extra_km || 0);
      }

      // Calculate final total amount if relevant fields are updated
      if (
        name === "fixed_bill_amt" ||
        name === "extra_km" ||
        name === "extra_amt_above_fix_km"
      ) {
        updatedFormData.final_total_amount =
          Number(updatedFormData.fixed_bill_amt || 0) +
          Number(updatedFormData.extra_amount || 0);
      }

      setFormData(updatedFormData);
    }
  };





  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.post(
          `${BASE_URL}/siteproject/vehiclebillingdetails/
`, formData
        );

        if (ress.status === 200) {
          await getVehicleBillingDetail();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  useEffect(() => {
    getProjects();
    if (formData.project !== "null") {
      getVehicle();
    }
    else {
      setVehicleList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project]);

  console.log(formData);

  return (



    <>
      <button title="Add Vechile Billing Details" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {" "} Vehicle Billing Details
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Vehicle Billing Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form>
                {
                  role === "SiteManagement" ? "" : <>
                    <div title="Project" className="flex-column form-group-selectt">
                      <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                        Project
                      </label>
                      <br />
                      <select
                        type="text"
                        id="project"
                        name="project"
                        placeholder="project"
                        value={formData.project}
                        onChange={handleInputChange}
                        className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                      >

                        <option value="null">Select a Project</option>
                        {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                          <option key={index} value={i.id}>{i.project_short_name}</option>
                        ))}
                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                      {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                    </div>
                  </>
                }
                <div title="Vehicle" className="flex-column form-group-selectt">
                  <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                    {role === "SiteManagement" ? ("Vehicle") : ("Vehicle (Select Project First)")} <span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="vehicle"
                    name="vehicle"
                    placeholder="vehicle"
                    value={formData.vehicle}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                  >

                    <option value="">Select a vehicle</option>
                    {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                </div>
                <div className="flex-column">
                  <label htmlFor='account_holder_name' className="form-labels  font-weight500 font-size-subheading" title="Vehicle Owner Name">Owner Name </label>
                  <input
                    id='account_holder_name'
                    type="text"
                    title="Final Total Amount"
                    min={0}
                    name="account_holder_name"
                    placeholder="Owner Name"
                    value={formData.account_holder_name}
                    // onChange={handleInputChange}

                    className={`form-input ${errors.account_holder_name ? 'error' : ''}`}
                  />
                  {errors.account_holder_name && <div className="error-message font-size-text">{errors.account_holder_name}</div>}
                </div>

                {/* <div title="vehicle_owner_details" className="flex-column form-group-selectt">
                  <label htmlFor="vehicle_owner_details" className="form-labels  font-weight500    font-size-subheading">
                    Vehicle's Owner Detail<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="vehicle_owner_details"
                    name="vehicle_owner_details"
                    placeholder="Vehicle's Owner Detail"
                    value={formData.vehicle_owner_details}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.vehicle_owner_details ? "error" : inputState.vehicle_owner_details ? "success" : ""}`}
                  >

                    <option value="">Owner Detail</option>
                    {vehicleList?.sort((a, b) => a.account_holder_name.localeCompare(b.account_holder_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}> {i.account_holder_name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg

                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.vehicle_owner_details && <span className="error-message font-size-text ">{errors.vehicle_owner_details}</span>}
                </div> */}
                <div title="Month" className="flex-column form-group-selectt">
                  <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="month"
                    name="month"
                    placeholder="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.month ? "error" : inputState.month ? "success" : ""}`}
                  >

                    <option value="">Select a month</option>
                    {monthOptions.map((month) => (
                      <option key={month.id} value={month.id}>{month.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.month && <span className="error-message font-size-text ">{errors.month}</span>}
                </div>
                <div title="Year" className="flex-column form-group-selectt">
                  <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="year"
                    name="year"
                    placeholder="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.year ? "error" : inputState.year ? "success" : ""}`}
                  >

                    <option value="">Select a Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.year && <span className="error-message font-size-text ">{errors.year}</span>}
                </div>
                <div className="flex-column">
                  <label htmlFor='fixed_km' className="form-labels  font-weight500 font-size-subheading" title="Fixed KM">Fixed KM</label>
                  <input
                    id='fixed_km'
                    type="number"
                    title="Fixed KM"
                    min={0}
                    name="fixed_km"
                    placeholder="Fixed KM"
                    value={formData.fixed_km}
                    // onChange={handleInputChange}
                    disabled
                    className={`form-input ${errors.fixed_km ? 'error' : ''}`}
                  />
                  {errors.fixed_km && <div className="error-message font-size-text">{errors.fixed_km}</div>}
                </div>
                <div className="flex-column">
                  <label htmlFor='extra_km' className="form-labels  font-weight500 font-size-subheading" title="Extra Amount">Extra KM</label>
                  <input
                    id='extra_km'
                    type="number"
                    title="Extra KM"
                    min={0}
                    name="extra_km"
                    placeholder="Extra KM"
                    value={formData.extra_km}
                    onChange={handleInputChange}
                    // disabled
                    className={`form-input ${errors.extra_km ? 'error' : ''}`}
                  />
                  {errors.extra_km && <div className="error-message font-size-text">{errors.extra_km}</div>}
                </div>
                <div className="flex-column">
                  <label htmlFor='total_km' className="form-labels  font-weight500 font-size-subheading" title="Total Amount">Total KM</label>
                  <input
                    id='total_km'
                    type="number"
                    title="Total KM"
                    min={0}
                    name="total_km"
                    placeholder="Total KM"
                    value={formData.total_km}
                    onChange={handleInputChange}
                    // disabled
                    className={`form-input ${errors.total_km ? 'error' : ''}`}
                  />
                  {errors.total_km && <div className="error-message font-size-text">{errors.total_km}</div>}
                </div>
                <div className="flex-column">
                  <label htmlFor='extra_amt_above_fix_km' className="form-labels  font-weight500 font-size-subheading" title="Extra Amount Per KM Above Fixed KM">Extra Amount Per KM Above Fixed KM</label>
                  <input
                    id='extra_amt_above_fix_km'
                    type="number"
                    title="Extra Amount Per KM Above Fixed KM"
                    min={0}
                    name="extra_amt_above_fix_km"
                    placeholder="Extra Amount Per KM Above Fixed KM"
                    value={formData.extra_amt_above_fix_km}
                    // onChange={handleInputChange}
                    disabled
                    className={`form-input ${errors.extra_amt_above_fix_km ? 'error' : ''}`}
                  />
                  {errors.extra_amt_above_fix_km && <div className="error-message font-size-text">{errors.extra_amt_above_fix_km}</div>}
                </div>
                <div className="flex-column">
                  <label htmlFor='fixed_bill_amt' className="form-labels  font-weight500 font-size-subheading" title="Fixed Amount">Fixed Amount<span className="required">*</span></label>
                  <input
                    id='fixed_bill_amt'
                    type="number"
                    title="Fixed Amount"
                    min={0}
                    name="fixed_bill_amt"
                    placeholder="Fixed Amount"
                    value={formData.fixed_bill_amt}
                    disabled
                    // onChange={handleInputChange}
                    className={`form-input ${errors.fixed_bill_amt ? 'error' : ''}`}
                  />
                  {errors.fixed_bill_amt && <div className="error-message font-size-text">{errors.fixed_bill_amt}</div>}
                </div>


                <div className="flex-column">
                  <label htmlFor='extra_amount' className="form-labels  font-weight500 font-size-subheading" title="Extra Amount">Extra Amount<span className="required">*</span></label>
                  <input
                    id='extra_amount'
                    type="number"
                    title="Extra Amount"
                    min={0}
                    name="extra_amount"
                    placeholder="Extra Amount"
                    value={formData.extra_amount}
                    disabled
                    // onChange={handleInputChange}
                    className={`form-input ${errors.extra_amount ? 'error' : ''}`}
                  />
                  {errors.extra_amount && <div className="error-message font-size-text">{errors.extra_amount}</div>}
                </div>



                <div className="flex-column">
                  <label htmlFor='final_total_amount' className="form-labels  font-weight500 font-size-subheading" title="Final Total Amount">Final Total Amount <span className="required">*</span></label>
                  <input
                    id='final_total_amount'
                    type="number"
                    title="Final Total Amount"
                    min={0}
                    name="final_total_amount"
                    placeholder="Final Total Amount"
                    value={formData.final_total_amount}
                    // onChange={handleInputChange}
                    disabled
                    className={`form-input ${errors.final_total_amount ? 'error' : ''}`}
                  />
                  {errors.final_total_amount && <div className="error-message font-size-text">{errors.final_total_amount}</div>}
                </div>
                {/* <div className="flex-column">
                  <label htmlFor='less_tds' className="form-labels  font-weight500 font-size-subheading" title="Less TDS">Less TDS <span className="required">*</span></label>
                  <input
                    id='less_tds'
                    type="number"
                    title="Less TDS"
                    min={0}
                    name="less_tds"
                    placeholder="Less TDS"
                    value={formData.less_tds}
                    onChange={handleInputChange}
                    className={`form-input ${errors.less_tds ? 'is-invalid' : ''}`}
                  />
                  {errors.less_tds && <div className="error-message font-size-text">{errors.less_tds}</div>}
                </div>
                <div className="flex-column">
                  <label htmlFor='payable_amount' className="form-labels  font-weight500 font-size-subheading" title="Payable Amount">Payable Amount <span className="required">*</span></label>
                  <input
                    id='payable_amount'
                    type="number"
                    title="Payable Amount"
                    min={0}
                    name="payable_amount"
                    placeholder="Payable Amount"
                    value={formData.payable_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.payable_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.payable_amount && <div className="error-message font-size-text">{errors.payable_amount}</div>}
                </div> */}
                <div className="button-models">
                  <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                    Cancel
                  </button>
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
                <p className="error-message font-size-text">
                  {isSubmitted && Object.keys(errors).length > 0 && (
                    Object.keys(errors).length > 5 ? (
                      <h5 className="text-center">Please fill all mandatory fields!</h5>
                    ) : (

                      <h6 className="text-center">
                        {Object.keys(errors).map((field) =>
                          field.charAt(0).toUpperCase() + field.slice(1)
                        ).join(', ') + ' are required!'}
                      </h6>
                    )
                  )}
                </p>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>




  )
}


const UpdateVehicleBillingDetail = ({ getVehicleBillingDetail, i }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [show, setShow] = useState(false);
  // const [setBuffering] = useState(false); //buffering logic

  const handleShow = () => {
    getVehicle();
    setShow(true);
    setFormData(i)
  }
  const role = sessionStorage.getItem('role');


  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(i);
    // setProject(i); 
  }



  const getVehicle = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapibyproj/${i.project_id}`);
      setVehicleList(veh.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      // setBuffering(false);// End Buffering
    }
  }

  const [formData, setFormData] = useState({
    vehicle_owner_details: "",
    vehicle: "",
    month: "",
    year: "",
    extra_amount: "",
    final_total_amount: "",
    less_tds: "",
    payable_amount: "",
  });

  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle",
      "month",
      "year",
      "extra_amount",
      "final_total_amount",

    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update input state (for validation or UI purposes)
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    // Update form data and calculate total_km
    if (name === "vehicle") {
      const selectedVehicle = vehicleList.find((vehicle) => vehicle.id === value);

      const updatedFormData = {
        ...formData,
        [name]: value,
        fixed_km: selectedVehicle ? selectedVehicle.fixed_km : "",
        fixed_bill_amt: selectedVehicle ? selectedVehicle.fixed_bill_amt : 0,
        account_holder_name: selectedVehicle ? selectedVehicle.account_holder_name : "",
        extra_amt_above_fix_km: selectedVehicle ? selectedVehicle.extra_amt_above_fix_km : 0,
      };

      // Calculate extra amount
      updatedFormData.extra_amount =
        (updatedFormData.extra_km || 0) * (updatedFormData.extra_amt_above_fix_km || 0);


      // Calculate total_km
      updatedFormData.total_km =
        Number(updatedFormData.fixed_km || 0) + Number(updatedFormData.extra_km || 0);

      // Calculate final total amount
      updatedFormData.final_total_amount =
        updatedFormData.fixed_bill_amt +
        updatedFormData.extra_charges +
        updatedFormData.extra_amount;

      setFormData(updatedFormData);
    } else {
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      // Calculate extra amount if extra_km or extra_amt_above_fix_km is updated
      if (name === "extra_km" || name === "extra_amt_above_fix_km") {
        updatedFormData.extra_amount =
          (updatedFormData.extra_km || 0) * (updatedFormData.extra_amt_above_fix_km || 0);
      }

      // Calculate total_km if fixed_km or extra_km is updated
      if (name === "fixed_km" || name === "extra_km") {
        updatedFormData.total_km =
          Number(updatedFormData.fixed_km || 0) + Number(updatedFormData.extra_km || 0);
      }

      // Calculate final total amount if relevant fields are updated
      if (
        name === "fixed_bill_amt" ||
        name === "extra_km" ||
        name === "extra_amt_above_fix_km"
      ) {
        updatedFormData.final_total_amount =
          Number(updatedFormData.fixed_bill_amt || 0) +
          Number(updatedFormData.extra_amount || 0);
      }

      setFormData(updatedFormData);
    }
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.put(
          `${BASE_URL}/siteproject/vehiclebillingdetails/${i.id}/`, formData);

        if (ress.status === 200) {
          await getVehicleBillingDetail();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (


    <div>
      <>
        <button title='Update Billing Details' className='model-edit-button' onClick={handleShow}>
          <UpdatePencil />
        </button>

        <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
          <Modal.Header closeButton>
            <Modal.Title>Update Vehicle Billing Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <div className="parent-div">
              <div className="bdy-div">
                <form>

                  <div title="Vehicle" className="flex-column form-group-selectt">
                    <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                      Vehicle <span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="vehicle"
                      name="vehicle"
                      placeholder="vehicle"
                      value={formData.vehicle}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                    >

                      <option value="">Select a vehicle</option>
                      {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                        <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                  </div>

                  {/* <div className="flex-column">
                  <label htmlFor='account_holder_name' className="form-labels  font-weight500 font-size-subheading" title="Vehicle Owner Name">Owner Name <span className="required">*</span></label>
                  <input
                    id='account_holder_name'
                    type="text"
                    title="Final Total Amount"
                    min={0}
                    name="account_holder_name"
                    placeholder="Owner Name"
                    value={formData.account_holder_name}
                    // onChange={handleInputChange}

                    className={`form-input ${errors.account_holder_name ? 'is-invalid' : ''}`}
                  />
                  {errors.account_holder_name && <div className="error-message font-size-text">{errors.account_holder_name}</div>}
                </div> */}

                  <div title="Month" className="flex-column form-group-selectt">
                    <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                      Month<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="month"
                      name="month"
                      placeholder="month"
                      value={formData.month}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.month ? "error" : inputState.month ? "success" : ""}`}
                    >

                      <option value="">Select a month</option>
                      {monthOptions.map((month) => (
                        <option key={month.id} value={month.id}>{month.name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.month && <span className="error-message font-size-text ">{errors.month}</span>}
                  </div>
                  <div title="Year" className="flex-column form-group-selectt">
                    <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                      Year<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="year"
                      name="year"
                      placeholder="year"
                      value={formData.year}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.year ? "error" : inputState.year ? "success" : ""}`}
                    >

                      <option value="">Select a Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.year && <span className="error-message font-size-text ">{errors.year}</span>}
                  </div>
                  <div className="flex-column">
                    <label htmlFor='fixed_km' className="form-labels  font-weight500 font-size-subheading" title="Fixed KM">Fixed KM<span className="required">*</span></label>
                    <input
                      id='fixed_km'
                      type="number"
                      title="Fixed KM"
                      min={0}
                      name="fixed_km"
                      placeholder="Fixed KM"
                      value={formData.fixed_km}
                      // onChange={handleInputChange}
                      disabled
                      className={`form-input ${errors.fixed_km ? 'is-invalid' : ''}`}
                    />
                    {errors.fixed_km && <div className="error-message font-size-text">{errors.fixed_km}</div>}
                  </div>
                  <div className="flex-column">
                    <label htmlFor='extra_km' className="form-labels  font-weight500 font-size-subheading" title="Extra Amount">Extra KM<span className="required">*</span></label>
                    <input
                      id='extra_km'
                      type="number"
                      title="Extra KM"
                      min={0}
                      name="extra_km"
                      placeholder="Extra KM"
                      value={formData.extra_km}
                      onChange={handleInputChange}
                      // disabled
                      className={`form-input ${errors.extra_km ? 'is-invalid' : ''}`}
                    />
                    {errors.extra_km && <div className="error-message font-size-text">{errors.extra_km}</div>}
                  </div>
                  {/* <div className="flex-column">
                  <label htmlFor='total_km' className="form-labels  font-weight500 font-size-subheading" title="Total Amount">Total KM<span className="required">*</span></label>
                  <input
                    id='total_km'
                    type="number"
                    title="Total KM"
                    min={0}
                    name="total_km"
                    placeholder="Total KM"
                    value={formData.total_km}
                    onChange={handleInputChange}
                    // disabled
                    className={`form-input ${errors.total_km ? 'is-invalid' : ''}`}
                  />
                  {errors.total_km && <div className="error-message font-size-text">{errors.total_km}</div>}
                </div> */}
                  <div className="flex-column">
                    <label htmlFor='extra_amt_above_fix_km' className="form-labels  font-weight500 font-size-subheading" title="Extra Amount Per KM Above Fixed KM">Extra Amount Per KM Above Fixed KM<span className="required">*</span></label>
                    <input
                      id='extra_amt_above_fix_km'
                      type="number"
                      title="Extra Amount Per KM Above Fixed KM"
                      min={0}
                      name="extra_amt_above_fix_km"
                      placeholder="Extra Amount Per KM Above Fixed KM"
                      value={formData.extra_amt_above_fix_km}
                      // onChange={handleInputChange}
                      disabled
                      className={`form-input ${errors.extra_amt_above_fix_km ? 'is-invalid' : ''}`}
                    />
                    {errors.extra_amt_above_fix_km && <div className="error-message font-size-text">{errors.extra_amt_above_fix_km}</div>}
                  </div>
                  <div className="flex-column">
                    <label htmlFor='fixed_bill_amt' className="form-labels  font-weight500 font-size-subheading" title="Fixed Amount">Fixed Amount<span className="required">*</span></label>
                    <input
                      id='fixed_bill_amt'
                      type="number"
                      title="Fixed Amount"
                      min={0}
                      name="fixed_bill_amt"
                      placeholder="Fixed Amount"
                      value={formData.fixed_bill_amt}
                      disabled
                      // onChange={handleInputChange}
                      className={`form-input ${errors.fixed_bill_amt ? 'is-invalid' : ''}`}
                    />
                    {errors.fixed_bill_amt && <div className="error-message font-size-text">{errors.fixed_bill_amt}</div>}
                  </div>


                  {/* <div className="flex-column">
                  <label htmlFor='extra_amount' className="form-labels  font-weight500 font-size-subheading" title="Extra Amount">Extra Amount<span className="required">*</span></label>
                  <input
                    id='extra_amount'
                    type="number"
                    title="Extra Amount"
                    min={0}
                    name="extra_amount"
                    placeholder="Extra Amount"
                    value={formData.extra_amount}
                    disabled
                    // onChange={handleInputChange}
                    className={`form-input ${errors.extra_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.extra_amount && <div className="error-message font-size-text">{errors.extra_amount}</div>}
                </div> */}



                  {/* <div className="flex-column">
                  <label htmlFor='final_total_amount' className="form-labels  font-weight500 font-size-subheading" title="Final Total Amount">Final Total Amount <span className="required">*</span></label>
                  <input
                    id='final_total_amount'
                    type="number"
                    title="Final Total Amount"
                    min={0}
                    name="final_total_amount"
                    placeholder="Final Total Amount"
                    value={formData.final_total_amount}
                    // onChange={handleInputChange}
                    disabled
                    className={`form-input ${errors.final_total_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.final_total_amount && <div className="error-message font-size-text">{errors.final_total_amount}</div>}
                </div> */}
                  {/* <div className="flex-column">
                  <label htmlFor='less_tds' className="form-labels  font-weight500 font-size-subheading" title="Less TDS">Less TDS <span className="required">*</span></label>
                  <input
                    id='less_tds'
                    type="number"
                    title="Less TDS"
                    min={0}
                    name="less_tds"
                    placeholder="Less TDS"
                    value={formData.less_tds}
                    onChange={handleInputChange}
                    className={`form-input ${errors.less_tds ? 'is-invalid' : ''}`}
                  />
                  {errors.less_tds && <div className="error-message font-size-text">{errors.less_tds}</div>}
                </div>
                <div className="flex-column">
                  <label htmlFor='payable_amount' className="form-labels  font-weight500 font-size-subheading" title="Payable Amount">Payable Amount <span className="required">*</span></label>
                  <input
                    id='payable_amount'
                    type="number"
                    title="Payable Amount"
                    min={0}
                    name="payable_amount"
                    placeholder="Payable Amount"
                    value={formData.payable_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.payable_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.payable_amount && <div className="error-message font-size-text">{errors.payable_amount}</div>}
                </div> */}
                  <div className="button-models">
                    <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                      Cancel
                    </button>
                    <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                      {loading ? 'Updating...' : 'Update'}
                    </button>
                  </div>
                </form>
              </div></div>
          </Modal.Body>
        </Modal>
      </>

    </div>


  )
}

const DeleteVehicleBillingDetail = ({ getVehicleBillingDetail, i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);

  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/vehiclebillingdetails/${i.id}/`);

      if (res.status === 200) {
        await getVehicleBillingDetail();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      console.log("Error")
      console.log(err)
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Vehicle Billing Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Vehicle Billing Details of "{i.vehicleownerdetails}"

            <div className="button-models">
              <button className="model-button model-button-cancel   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
}


// !  ********* Vehicle Billing Details Components End******** ?//


//?  ********* Vehicle Billing Details Components Start******** ?//


const GenerateforVehicle = ({ vehicleList }) => {

  const [show, setShow] = useState(false);


  const handleShow = () => {
    setShow(true);
  }

  const handleClose = () => {
    // getVehicle();
    setShow(false);
  }




  const [formData, setFormData] = useState({
    month: datearray[1],
    year: datearray[0],
    vehicle: "",
  });









  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle",
      "month",
      "year",

    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //


  const [generateAllVehicleloading, setGenerateAllVehicleLoading] = useState(false); //loading logic
  const handleGenerateAllVechicle = async (e) => {
    e.preventDefault();

    if (validateForm()) {



      setGenerateAllVehicleLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let ress = await axios.post(
          `${BASE_URL}/siteproject/generate-vehicle-bill-by-all-vehicle/${datearray[1]}/${datearray[0]}/`
        );

        if (ress.status === 200) {
          // await getVehicleBillingDetail();

        } else {
          alert(ress);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setGenerateAllVehicleLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }

    }
  };



  return (
    <>
      <button title="Add Vechile Billing Details" className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleShow}>
        Vehicle To Generate Bill
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Generate Bill of </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form>



                <div title="Month" className="flex-column form-group-selectt">
                  <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="month"
                    name="month"
                    placeholder="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input-date form-group-selection ${errors.month ? "error" : inputState.month ? "success" : ""}`}
                  >

                    <option value="">Select a month</option>
                    {monthOptions.map((month) => (
                      <option key={month.id} value={month.id}>{month.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>

                </div>

                <div title="Year" className="flex-column form-group-selectt">
                  <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="year"
                    name="year"
                    placeholder="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input-date form-group-selection ${errors.year ? "error" : inputState.year ? "success" : ""}`}
                  >

                    <option value="">Select a Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>

                </div>




                <div title="Vehicle" className="flex-column form-group-selectt">
                  <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                    Vehicle<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="vehicle"
                    name="vehicle"
                    placeholder="vehicle"
                    value={formData.vehicle}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                  >

                    <option value="">Select a vehicle</option>
                    {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                </div>



                <div className="button-models">
                  <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                    Cancel
                  </button>

                  <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleGenerateAllVechicle} disabled={generateAllVehicleloading}>
                    {generateAllVehicleloading ? 'Generating...' : 'Generate For All Vehicle'}
                  </button>

                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>




  )
}


const GenerateVehicleBillingDetails = ({ getVehicleBillingDetail, projectid, vehicleList, projectList }) => {

  const [show, setShow] = useState(false);


  const handleShow = () => {
    // getVehicle();
    setShow(true);
  }

  const handleClose = () => {
    // getVehicle();
    setShow(false);
  }




  const [formData, setFormData] = useState({
    month: datearray[1],
    year: datearray[0],
  });









  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  // !  ************** Validation start **************  ! //




  const handleInputChange = (e) => {
    const { name, value } = e.target;



    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [generateAllProjectloading, setGenerateAllProjectLoading] = useState(false); //loading logic
  const handleGenerateAllProject = async (e) => {
    e.preventDefault();


    setGenerateAllProjectLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



    try {
      let ress = await axios.post(
        `${BASE_URL}/siteproject/generate-vehicle-bill-by-all-project/${datearray[1]}/${datearray[0]}/`
      );

      if (ress.status === 200) {
        await getVehicleBillingDetail();

      } else {
        alert(ress);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setGenerateAllProjectLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      toast.dismiss(loadingToastId);
    }
  };





  const [generateAllVehicleloading, setGenerateAllVehicleLoading] = useState(false); //loading logic
  const handleGenerateAllVechicle = async (e) => {
    e.preventDefault();

    setGenerateAllVehicleLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let ress = await axios.post(
        `${BASE_URL}/siteproject/generate-vehicle-bill-by-all-vehicle/${datearray[1]}/${datearray[0]}/`
      );

      if (ress.status === 200) {
        await getVehicleBillingDetail();

      } else {
        alert(ress);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setGenerateAllVehicleLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      toast.dismiss(loadingToastId);
    }
  };



  return (
    <>
      <button title="Add Vechile Billing Details" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {" "} Generate Bills
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Generate Bills</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form>



                <div title="Month" className="flex-column form-group-selectt">
                  <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="month"
                    name="month"
                    placeholder="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input-date form-group-selection `}
                  >

                    <option value="">Select a month</option>
                    {monthOptions.map((month) => (
                      <option key={month.id} value={month.id}>{month.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>

                </div>

                <div title="Year" className="flex-column form-group-selectt">
                  <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="year"
                    name="year"
                    placeholder="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input-date form-group-selection`}
                  >

                    <option value="">Select a Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>

                </div>


                <div>
                  <button className="model-button  font-weight500  model-button-bill   model-button-leave font-size-heading" title="generateAllProjectloading" onClick={handleGenerateAllProject} disabled={generateAllProjectloading} >
                    {generateAllProjectloading ? 'Generating...' : 'Generate For All Project'}
                  </button>

                </div>

                <div>
                  <button className="model-button  font-weight500 model-button-bill    model-button-leave font-size-heading" title='generateAllVehicleloading' onClick={handleGenerateAllVechicle} disabled={generateAllVehicleloading} >
                    {generateAllVehicleloading ? 'Generating...' : 'Generate For All Vehicle'}
                  </button>

                </div>
                {/* 
                <div>
                <GenerateforVehicle vehicleList={vehicleList} />
                
                </div> */}



                <div className="button-models">
                  <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                    Cancel
                  </button>

                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>




  )
}


// !  ********* Vehicle Billing Details Components End******** ?//






const VehicleModelTable = ({ p_id, site_prcode }) => {

  const [vehicleModel, setVehiclemodel] = useState([]);
  const [siteVehicle, setSiteVehicle] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSearch, setSearch] = useState("");
  const { projectID } = usePermission();
  const [selectedProject, setSelectedProject] = useState(projectID ? projectID : p_id ? p_id : "null");

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const navigate = useNavigate();
  const role = sessionStorage.getItem('sitemanagement_role');

  const ViewVechile = (vehicle) => {
    navigate(`/${urlParts[3]}/Vechiclelogbook/${vehicle}/`
    );
  };



  const getVehicle = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/vehicle/`);
      const ress = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapibyproj/${selectedProject}/`);
      setVehiclemodel(res.data);
      setSiteVehicle(ress.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  }

  useEffect(() => {
    getVehicle();
  }, [selectedProject])

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearch, vehicleModel]);




  const applyFilters = () => {
    let filteredData = vehicleModel;
    if (selectedSearch !== "") {
      const lowercaseSelectedName = selectedSearch.toLowerCase();
      filteredData = filteredData.filter(project => {
        const name = project.name.toString().toLowerCase().includes(lowercaseSelectedName);
        const num = project.vehicle_num.toString().toLowerCase().includes(lowercaseSelectedName);
        return name || num;
      });
    }
    if (selectedProject !== "null") {
      filteredData = filteredData.filter(project => project.project === selectedProject);
    }

    setFilteredData(filteredData);
  };

  const project_idd = sessionStorage.getItem('sitemanagement_project')



  return (
    <div>

      <div className="attendance-heading  font-weight500    font-size-heading"> </div>

      {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? ("") : p_id ? <></> : (
        <div className="attendance-subcontainer margin">
          <div className="field-cont width-100vw justify-between">
            <div title='Search Filter' className="field-cont-div">
              <SearchBig />
              <input className="attendance-input-field width-25vw"
                placeholder='Search Vehicle Number / Vehicle Name'
                type="text"
                value={selectedSearch}
                onChange={(e) => setSearch(e.target.value)} />
              <hr className="field-cont-hr" />

              <Projects toggleProject={selectedProject} setToggleProject={setSelectedProject} />

            </div>

            <div>
              <AddVehicleModel getVehicle={getVehicle} projectId={project_idd} />
              {role === "Admin" &&
                <VehicleModelBulk getVehicle={getVehicle} />
              }
            </div>
          </div>
        </div>
      )}



      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="table-css-white-background overflow-hidden flex-column ">
        {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? (
          <div className='flex-row justify-end'>
            {/* <AddVehicleModel projectId={projectID} getVehicle={getVehicle} /> */}


          </div>
        ) : p_id ? <>
          <div className="table-css-white-background ">
            <div>
              <div className='flex-row justify-between'>
                <div className="jd-heading-outer-flex ">
                  <h2 className="jd-heading-main font-weight600 font-size-heading">Vehicles</h2>
                </div>
                <div>
                  <AddVehicleModel getVehicle={getVehicle} />
                  {role === "Admin" &&
                    <VehicleModelBulk getVehicle={getVehicle} site_prcode={site_prcode} />
                  }
                </div>
              </div>
              <div>
                <div className="jd-heading-bottom-bold"></div>
                <div className="jd-heading-bottom-light"></div>
              </div>
            </div>
          </div>
        </> : <></>
        }
        <div className='table-box scroll-container-table'
        // style={{ height: p_id ? "50vh" : "73vh" }}
        >

          <table className="table-css width-100vw">
            <thead className="table-heading">
              <tr className="custom-table-head-tr ">
                <th className="table-heading-text">S.No.</th>
                {p_id ? <></> : <th className="table-heading-text">Project Name</th>}
                <th className="table-heading-text">Vehicle Name</th>
                <th className="table-heading-text">Vehicle Number</th>
                <th className="table-heading-text">Fixed Kilometer</th>
                <th className="table-heading-text">Fixed Bill Amount</th>
                <th className="table-heading-text text-center ">Extra Amount Per KM <br /> After Fixed KM</th>

                <th className="table-heading-text text-center">Document </th>



                <th className="table-heading-text text-center">View Vehiclelog</th>
                <th className="table-heading-text text-center">Action </th>

              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (

              <tbody>
                {(sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? siteVehicle : filteredData).map((vehicle, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className="custom-table-head-td">
                      <td className="table-body">{monthIndex + 1}</td>
                      {p_id ? <></> : <td className="table-body">{vehicle.project_short_name}</td>}
                      <td className="table-body">{vehicle.name}</td>
                      <td className="table-body">{vehicle.vehicle_num}</td>
                      <td className="table-body">{vehicle.fixed_km} Km</td>
                      <td className="table-body text-center">₹ {formatRoundoff(vehicle.fixed_bill_amt)}</td>
                      <td className="table-body text-center">₹{formatRoundoff(vehicle.extra_amt_above_fix_km)}</td>

                      <td className='table-body'><DocumentModel siteVehicle={siteVehicle} vehicle={vehicle.id} getDocument={getVehicle} title={"Vehicle Document"} /></td>
                      {/* {siteRole === "Admin" ? (
                        <td className="table-body">
                          <Vehicledoc vehicle={vehicle} getVehicle={getVehicle} />
                        </td>) : ("")} */}

                      {/* <td className="table-body">{vehicle.</td> */}

                      <td className="table-body text-center">
                        <button onClick={() => ViewVechile(vehicle.id)} title="View Vehicle Log">
                          <Reciept />
                        </button>
                      </td>
                      <td className=" table-body flex-row  text-center">
                        <div className='flex-row'>
                          <UpdateVehicle i={vehicle} getVehicle={getVehicle} />
                          {sessionStorage.getItem('sitemanagement_role') === "Account" ? "" :
                            <>
                              <DeleteVehicle i={vehicle} getVehicle={getVehicle} />
                            </>

                          }
                        </div>
                      </td>



                    </tr>
                  </React.Fragment>
                ))}
              </tbody>

            )}
          </table>


        </div>
      </div>

    </div>
  )
}



const VehicleLogDetails = () => {
  const { projectID } = usePermission();
  const siteRole = sessionStorage.getItem("sitemanagement_role");
  const [VehicleLogDetails, setVehicleLogDetails] = useState([]);
  const [siteLogDetails, setSiteLogDetails] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [vehicle, setVehicle] = useState("null");
  const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);


  const endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

  const [project, setProject] = useState(projectID ? projectID : "null");

  const [fromdate, setFromDate] = useState(startDate.toISOString().split('T')[0]);
  const [todate, setToDate] = useState(endDate.toISOString().split('T')[0]);
  const [fromTime, setFromTime] = useState("null");
  const [toTime, setToTime] = useState("null");
  const [projectList, setProjectList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  console.log(project);
  const getStartEndOfMonth = () => {
    return { start: startDate, end: endDate };
  };

  const getPreviousAndCurrentDate = () => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return { from: yesterday, to: today };
  };

  useEffect(() => {
    if ((project === null || project === "null") && (vehicle === 'null' || vehicle === null)) {
      const { from, to } = getPreviousAndCurrentDate();
      setFromDate(from.toISOString().split('T')[0]);
      setToDate(to.toISOString().split('T')[0]);
    } else {

      const { start, end } = getStartEndOfMonth();
      setFromDate(start.toISOString().split('T')[0]);
      setToDate(end.toISOString().split('T')[0]);

    }
  }, [project, vehicle]);

  const getVehicle = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehicle-short/`);
      setVehicleList(veh.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  }

  console.log(projectID);


  const getProjects = async () => {
    setBuffering(true); // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);


      const projectIDs = sortedProjects.map(project => project.id);
      console.log("Project IDs:", projectIDs); // You can use this array as needed

    } catch (err) {
      console.log("Error:", err);
      handleErrorToast(err);
    } finally {
      setBuffering(false); // End Buffering
    }
  };

  const getVehicleLogDetail = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/vehiclelogdetailbyallfilter/${vehicle}/${project}/${fromdate}/${todate}/${fromTime}/${toTime}/`);
      setVehicleLogDetails(response.data);
    }
    catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  }
  useEffect(() => {
    getProjects();
    getVehicle();
    getVehicleLogDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle, project, fromdate, todate, fromTime, toTime]);


  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="attendance-subcontainer margin">
        <div className="field-cont width-100vw justify-between">
          <div title=' Filter' className="field-cont-div">
            {siteRole === "SiteManagement" ? ("") : (

              <Projects toggleProject={project} setToggleProject={setProject} />
            )}

            <div title="Vehicle Filter" className="field-cont-div ">
              <CircularGrid />
              <select
                id="vehicle"
                name="vehicle"
                placeholder="vehicle"
                value={vehicle}
                onChange={(e) => setVehicle(e.target.value)}
                className={`attendance-input-field width-10vw`}
              >

                <option value="null">Select Vehicle</option>
                {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                  <option key={index} value={i.id}>{i.name}-{i.vehicle_num}</option>
                ))}
              </select>


            </div>
            <hr className="field-cont-hr" />


            <div title='From Date' className="date-uppercase ">
              <input
                className="attendance-input-field width-10vw   date-field date-field-filter"
                placeholder="From Date"
                type="date"
                value={fromdate}
                onChange={(e) => setFromDate(e.target.value)}
              />

            </div>
            <hr className="field-cont-hr" />
            <div title='To Date' className="date-uppercase ">
              <input
                className="attendance-input-field width-10vw   date-field date-field-filter"
                placeholder="To Date"
                type="date"
                value={todate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <hr className="field-cont-hr" />
            <div title='From Time' className="date-uppercase ">
              <input
                className="attendance-input-field  date-field date-field-filter"
                placeholder="From Time"
                type="time"
                value={fromTime}
                onChange={(e) => setFromTime(e.target.value)}
              />
            </div>
            <hr className="field-cont-hr" />
            <div title='To time' className="date-uppercase ">
              <input
                className="attendance-input-field    date-field date-field-filter"
                placeholder="To time"
                type="time"
                value={toTime}
                onChange={(e) => setToTime(e.target.value)}
              />
            </div>
            <hr className="field-cont-hr" />
          </div>

          <div>
            <AddVehicleLogDetails getVehicleLogDetail={getVehicleLogDetail} projectid={projectID} />
          </div>
        </div>
      </div>

      <div className="table-css-white-background overflow-hidden flex-column ">

        <div class="table-box scroll-container-table">
          <table className="table-css width-100vw">
            <thead className="table-heading">
              <tr className="custom-table-head-tr ">
                <th className='table-heading-text'>S.No.</th>
                <th className='table-heading-text'>Vehicle Name</th>
                <th className='table-heading-text'>Vehicle Number</th>
                <th className='table-heading-text'>Date</th>
                <th className='table-heading-text'>From Time</th>
                <th className='table-heading-text'>To Time</th>
                <th className='table-heading-text'>Commencement of Trip</th>
                <th className='table-heading-text'>Conclusion of Trip</th>
                <th className='table-heading-text'>Person Using Conveyance</th>
                <th className='table-heading-text'>Purpose And Place</th>
                <th className='table-heading-text text-center'>Action</th>
              </tr>
            </thead>
            {buffer ? (
              <div className='spinner'></div>
            ) : (
              <tbody>
                {VehicleLogDetails.map((vehicle, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className='custom-table-head-td'>
                      <td className="table-body">{monthIndex + 1}</td>
                      <td className="table-body">{vehicle.vehicle_name}</td>
                      <td className="table-body">{vehicle.vehicle_number}</td>
                      <td className="table-body">{formattedDateLong(vehicle.date)}</td>
                      <td className="table-body">{vehicle.from_time}</td>
                      <td className="table-body">
                        {vehicle.to_time}
                      </td>


                      <td className="table-body">{vehicle.commencement_of_trip} Km</td>
                      <td className="table-body">{vehicle.conclusion_of_trip} Km</td>
                      <td className="table-body">
                        {Array.isArray(vehicle.employee) && vehicle.employee.length > 0
                          ?
                          <>
                            <tr>
                              <td className="padding-bottom-5px">S.no.</td>
                              <td style={{ padding: "0px 4px" }}>|</td>
                              <td className="padding-bottom-5px">Emp.Code</td>
                              <td style={{ padding: "0px 4px" }}>|</td>
                              <td className="">
                                Emp. Name
                              </td>
                            </tr>
                            {vehicle.employee_details.map((i, index) => (
                              <>

                                <tr key={index}>
                                  <td className="padding-bottom-5px"> {index + 1}.</td>
                                  <td style={{ padding: "0px 4px" }}>|</td>
                                  <td className="padding-bottom-5px"> {i.emp_code}</td>
                                  <td style={{ padding: "0px 4px" }}>|</td>
                                  <td className="">
                                    {i.name}
                                  </td>
                                </tr>

                              </>
                            ))}
                          </>
                          : vehicle.personusingconveyance
                        }
                      </td>

                      <td className="table-body">{vehicle.purpose_and_place}</td>

                      <td className="table-body text-center flex-row">
                        <div className='flex-row'>
                          <UpdateVehicleLogDetail i={vehicle} getVehicleLogDetail={getVehicleLogDetail} />
                          {siteRole === "Account" ? "" :
                            <DeleteVehicleLogDetail i={vehicle} getVehicleLogDetail={getVehicleLogDetail} />
                          }
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  )

}


const VehicleBillingDetails = () => {
  const [vehicleBillingDetails, setVehicleBillingDetails] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [project, setProject] = useState("null");
  const [search, setSearch] = useState("")
  const [projectList, setProjectList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [vehicle, setVehicle] = useState("null");
  const [vehicleList, setVehicleList] = useState([]);


  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;

  const [filterMonth, setFilterMonth] = useState(`${month}`);
  const [filterYear, setFilterYear] = useState(`${year}`);
  const siteRole = sessionStorage.getItem("sitemanagement_role");
  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getVehicleBillingDetail = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/filter-vehicle-billing/${project}/${vehicle}/${filterMonth}/${filterYear}/`);
      setVehicleBillingDetails(response.data);

    }
    catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  }
  useEffect(() => {
    getVehicleBillingDetail();
    getProjects();
    getVehicle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, vehicle, filterMonth, filterYear]);

  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const getMonthName = (monthNumber) => {
    const month = monthOptions.find(m => m.id.toString() === monthNumber);
    return month ? month.name : '';
  };

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);



  const getVehicle = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehicle-short/`);
      setVehicleList(veh.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  }





  const role = sessionStorage.getItem('role');


  console.log(vehicleBillingDetails)

  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {role === "SiteManagement" ? ("") : (

        <div className="attendance-subcontainer margin">
          <div className="field-cont width-100vw justify-between">
            <div className="flex-row">
              <div title='Month Filter' className="field-cont-div">
                <CircularGrid />

                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={filterMonth}
                  onChange={(e) => setFilterMonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>

                <hr className="field-cont-hr" />
              </div>

              <div title='Year Filter' className="field-cont-div">
                <CircularGrid />

                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={filterYear}
                  onChange={(e) => setFilterYear(e.target.value)}
                >
                  <option value="">Select Year</option>
                  {years.reverse().map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>

                <hr className="field-cont-hr" />
              </div>

              <Projects toggleProject={project} setToggleProject={setProject} />

              <div title="Vehicle Filter" className="field-cont-div ">
                <CircularGrid />
                <select
                  id="vehicle"
                  name="vehicle"
                  placeholder="vehicle"
                  value={vehicle}
                  onChange={(e) => setVehicle(e.target.value)}
                  className={`attendance-input-field width-10vw`}
                >

                  <option value="null">Select Vehicle</option>
                  {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.name}-{i.vehicle_num}</option>
                  ))}
                </select>


              </div>
            </div>

            <div>
              <AddVehicleBillingDetails getVehicleBillingDetail={getVehicleBillingDetail} />
              <GenerateVehicleBillingDetails getVehicleBillingDetail={getVehicleBillingDetail} vehicleList={vehicleList} projectList={projectList} />

            </div>
          </div>
        </div>
      )}
      <div className="table-css-white-background overflow-hidden flex-column ">
        {/* {role === "SiteManagement" ? (
          <div className='flex-row justify-end'>
            <AddVehicleBillingDetails projectid={projectID} getVehicleBillingDetail={getVehicleBillingDetail} />
          </div>
        ) : ("")
        }

        */}
        <div className='scroll-container-table'>
          <table className="table-css ">
            <thead className="table-heading">
              <tr className="custom-table-head-tr">
                <th className='table-heading-text'>S.no.</th>
                <th className='table-heading-text'>Vehicle No</th>
                <th className='table-heading-text'>Vehicle Name</th>
                <th className='table-heading-text'>Owner Name</th>
                <th className='table-heading-text'>Fixed Details</th>
                <th className='table-heading-text'>Extra Amt/Km Above Fixed KM</th>
                <th className='table-heading-text'>Extra Details</th>
                <th className='table-heading-text'>Total Details</th>



                <th className='table-heading-text text-center' >Action</th>
              </tr>




            </thead>
            {buffer ? (
              <div className='spinner'></div>
            ) : (
              <tbody>
                {vehicleBillingDetails.map((vehicle, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className='custom-table-head-td'>
                      <td className="table-body">{monthIndex + 1}.</td>
                      <td className="table-body">{vehicle.vehicle_num}</td>
                      <td className="table-body">{vehicle.vehicle_name}</td>
                      <td className="table-body">{vehicle.vehicle_details.account_holder_name}</td>

                      <td className="table-body">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className=" padding-bottom-5px">KM</td>
                              <td style={{ padding: "0px 4px 4px 5px" }}>:</td>
                              <td className="padding-bottom-5px align-right">{vehicle.fixed_km}</td>
                            </tr>
                            <tr>
                              <td className=" padding-bottom-5px">Amount</td>
                              <td style={{ padding: "0px 4px 4px 5px" }}>:</td>
                              <td className="padding-bottom-5px align-right">₹ {formatCurrencyIndian(vehicle.vehicle_details.fixed_bill_amt)}</td>

                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="table-body text-center"> ₹ {formatCurrencyIndian(vehicle.vehicle_details.extra_amt_above_fix_km)}</td>

                      <td className="table-body">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className=" padding-bottom-5px">KM</td>
                              <td style={{ padding: "0px 4px 4px 5px" }}>:</td>
                              <td className="padding-bottom-5px align-right">{vehicle.extra_km}</td>
                            </tr>
                            <tr>
                              <td className=" padding-bottom-5px">Amount</td>
                              <td style={{ padding: "0px 4px 4px 5px" }}>:</td>
                              <td className="padding-bottom-5px align-right">₹
                                {vehicle.vehicle_details.extra_amt_above_fix_km && vehicle.extra_km
                                  ? formatCurrencyIndian(Number(vehicle.vehicle_details.extra_amt_above_fix_km) * Number(vehicle.extra_km))
                                  : 0}
                              </td>

                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="table-body">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className=" padding-bottom-5px">KM</td>
                              <td style={{ padding: "0px 4px 4px 5px" }}>:</td>
                              <td className="padding-bottom-5px align-right">{Number(vehicle.fixed_km) + Number(vehicle.extra_km)}</td>
                            </tr>
                            <tr>
                              <td className=" padding-bottom-5px">Amount</td>
                              <td style={{ padding: "0px 4px 4px 5px" }}>:</td>
                              <td className=" padding-bottom-5px align-right">₹ {formatCurrencyIndian(Number(vehicle.vehicle_details.fixed_bill_amt) + Number(vehicle.vehicle_details.extra_amt_above_fix_km) * Number(vehicle.extra_km))}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>

                      <td className=" justify-center action-padding flex-row ">
                        <div className='flex-row'>
                          <UpdateVehicleBillingDetail i={vehicle} getVehicleBillingDetail={getVehicleBillingDetail} />
                          {siteRole === "Account" ? "" :
                            <DeleteVehicleBillingDetail i={vehicle} getVehicleBillingDetail={getVehicleBillingDetail} />
                          }
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  )

}


const VehicleManagement = () => {
  const projectId = sessionStorage.getItem("sitemanagement_project");
  const role = sessionStorage.getItem("sitemanagement_role");
  const [toggleState, setToggleState] = useState(1);
  const siteRole = sessionStorage.getItem("sitemanagement_role");

  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return "Vehicle Details";
      case 2:
        return "Vehicle Log Details";
      case 3:
        return "Vehicle Billing Details";
      default:
        return "Vehicle Details";
    }
  };

  console.log("projectIddddd");
  console.log(projectId);
  return (
    <div>
      <div>

        <Dashboardnavbar name={getSelectedTitle()} url="Vehicle Monitoring" />
      </div>
      <div className="content-tabs ">
        <div className='toggle-button-area flex-row justify-between' style={{ padding: "15px 30px" }}>
          <div>
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(1)}
            >
              Vehicle Details
            </button>

            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs "
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
              }
              onClick={() => setToggleState(2)}
            >
              Vehicle Log Detail
            </button>

            {siteRole === "SiteManagement" ? "" :
              <button
                className={
                  toggleState === 3
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                }
                onClick={() => setToggleState(3)}
              >
                Vehicle Billing Details
              </button>

            }
          </div>
          {role === "SiteManagement" &&
            (
              <div className='flex-row justify-end'>
                {toggleState === 1 ?
                  <AddVehicleModel projectId={projectId} />
                  : <></>}
              </div>
            )}
        </div>

      </div>
      <div className="content-tabs">
        {toggleState === 1 ? <VehicleModelTable /> : null}
        {toggleState === 2 ? <VehicleLogDetails /> : null}
        {toggleState === 3 ? <VehicleBillingDetails /> : null}

      </div>
    </div>
  )

}

export { VehicleManagement, VehicleModelTable, AddVehicleModel, AddVehicleBillingDetails, AddVehicleLogDetails }